import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
    // console.log('Attempting to log in with PrivateRoute:');
    const { isLoggedIn } = useAuth();
 
    return isLoggedIn ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;


