import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Key points data
const keyPoints = [
  {
    title: "Educational Activity",
    description:
      "Provide educational activities for your children from 1st grade to higher studies.",
  },
  {
    title: "Personal Behavior Classes",
    description:
      "Personal behavior classes for job seekers to enhance communication and workplace skills.",
  },
  {
    title: "Interview Related Activities",
    description:
      "Help with interview-related activities for newcomers and professionals to boost career success.",
  },
  {
    title: "Total Area Management",
    description:
      "Comprehensive management solutions for your area related to every aspect of development.",
  },
];

// Main Professional Component
const ProfessionalComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      //   sx={{
      //     padding: "2rem",
      //     backgroundColor: "#A8C36E",

      //     marginTop: "2rem",
      //   }}
      sx={{
        backgroundColor: "#A8C36E", // Light lavender background
        borderRadius: "16px", // Rounded corners for a soft appearance
        padding: "1.5rem", // Padding for inner spacing
        boxShadow: "0px 4px 20px rgba(138, 43, 226, 0.1)", // Violet shadow for depth
        textAlign: "center",
        marginBottom: "2rem", // Space below the box
        width: "100%", // Full width of the parent container
        // Maximum width for large screens
        marginTop: "2rem",
      }}
    >
      {/* Box Type Stylish Heading */}
      <Box
        sx={{
          backgroundColor: "#ecf0f1", // Light background for the heading box
          borderRadius: "16px", // Rounded corners for a smooth look
          padding: "1.5rem", // Padding inside the box
          boxShadow: "0px 4px 20px rgba(0,0,0,0.1)", // Shadow for a lifted look
          //boxShadow: "0px 4px 20px rgba(138, 43, 226, 0.3)",
          textAlign: "center", // Center align the text
          marginBottom: "2rem", // Space below the heading
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#2c3e50",
            fontSize: { xs: "2rem", sm: "3rem" }, // Responsive font size
          }}
        >
          "We are excited to introduce upcoming features to our system,
          enhancing education, personal development, and area management—all at
          no extra cost!"
        </Typography>
      </Box>

      {/* Key Points Section */}
      <Grid container spacing={4}>
        {keyPoints.map((point, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              sx={{
                boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                borderRadius: "16px",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <CardContent
                sx={{
                  padding: "2rem",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {/* Title */}
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    color: "#34495e",
                    marginBottom: "1rem",
                    fontSize: { xs: "1.5rem", sm: "1.75rem" }, // Responsive font size
                  }}
                >
                  {point.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    color: "#7f8c8d",
                    lineHeight: "1.75",
                    fontWeight: "400",
                  }}
                >
                  {point.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfessionalComponent;
