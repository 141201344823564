//datepikerstyle.js
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Popper } from "@mui/material";
// Styled TextField wrapper for the input box

export const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    backgroundColor: "#ffffff", // White background for the input
    borderRadius: "4px", // Rounded corners
    //padding: "0.5rem 1rem", // Padding for better spacing
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#648002", // Green border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#648002", // Green border color on hover
  },
  "& .MuiFormLabel-root": {
    color: "#648002", // Green label color
  },
  "& .MuiSvgIcon-root": {
    color: "#FF9900", // Orange calendar icon color
  },
  "& .MuiInputBase-input": {
    color: "#333", // Dark text color
    fontWeight: "bold", // Bold text
    fontSize: "1.5rem", // Slightly larger text size
  },
  "&:hover .MuiInputBase-root": {
    backgroundColor: "#f5f5f5", // Light gray background on hover
  },
});

export const CustomDatePicker = styled(DatePicker)({
  "& .MuiPaper-root": {
    backgroundColor: "#333", // Dark background for the popup
    color: "#fff", // White text inside the popup
  },
  "& .MuiPickersCalendarHeader-root": {
    backgroundColor: "#FF9900", // Orange header background
    color: "#fff", // White header text
  },
  "& .MuiPickersDay-root": {
    borderRadius: "4px", // Rounded corners for the days
    fontSize: "1rem", // Font size for the day cells
    color: "#fff", // Default text color
    "&:hover": {
      backgroundColor: "#FF9900", // Hover background color for days
      color: "#fff", // Hover text color for days
    },
  },
  "& .Mui-selected": {
    backgroundColor: "#648002", // Green background for the selected day
    color: "#fff", // White text for the selected day
  },
  "& .MuiIconButton-root": {
    color: "#fff", // Arrow buttons color
  },
});

// const theme = createTheme({
//   // Customize your theme here if needed
// });

// export const popperStyles = {
//   "& .MuiPaper-root": {
//     backgroundColor: "#ffffff", // Set the background color to white
//     border: "2px solid #648002", // Set the border color
//     borderRadius: theme.shape.borderRadius, // Ensure consistent border radius
//     boxShadow: theme.shadows[8], // Apply consistent box-shadow
//     padding: theme.spacing(2), // Add padding
//   },

//   "& .MuiPickersArrowSwitcher-root": {
//     "& .MuiIconButton-root": {
//       color: "#FF9900 !important", // Orange color for the next/previous arrows
//     },
//   },

//   // Weekday labels (Mon, Tue, etc.)
//   "& .MuiTypography-caption": {
//     fontSize: "1.5rem !important", // Increase font size of the weekday labels
//     color: "#648002 !important", // Primary green for weekday labels
//     fontWeight: "bold", // Make them bold
//     "&:nth-of-type(1), &:nth-of-type(7)": {
//       // Saturday and Sunday labels
//       color: "#FF9900 !important", // Orange for weekend labels
//     },
//   },

//   // Day numbers styling (includes Saturday and Sunday)
//   "& .MuiPickersDay-root": {
//     fontSize: "1.2rem !important", // Increase the font size of the date numbers
//     color: "#333 !important", // Default day text color
//     borderRadius: "50%", // Circular shape for dates
//     "&:hover": {
//       backgroundColor: "#FF9900 !important", // Orange hover background for all days
//       color: "#ffffff !important", // White text on hover
//     },
//     "&.Mui-selected": {
//       backgroundColor: "#648002 !important", // Primary green for the selected day
//       color: "#ffffff !important", // White text for selected day
//       fontWeight: "bold", // Bold selected text
//     },
//     // Specific styling for Saturday and Sunday numbers
//     "&.MuiPickersDay-saturday, &.MuiPickersDay-sunday": {
//       // Target Saturday and Sunday
//       color: "#FF9900 !important", // Orange text for Saturday and Sunday
//       "&:hover": {
//         backgroundColor: "#FF9900 !important", // Orange hover for weekends
//         color: "#ffffff !important", // White text on hover
//       },
//       "&.Mui-selected": {
//         backgroundColor: "#FF9900 !important", // Orange background for selected weekend
//         color: "#ffffff !important", // White text for selected weekend
//       },
//     },
//   },
// };

// Create a theme instance if you haven't already
const theme = createTheme({
  components: {
    // Customize DatePicker component
    MuiDatePicker: {
      styleOverrides: {
        // Year label and its dropdown arrow
        root: {
          "& .MuiTypography-root": {
            color: "#648002", // Year label color
            fontSize: "1.5rem",
            fontWeight: "bold",
          },
        },
        // Customize arrow buttons
        arrowSwitcher: {
          "& .MuiIconButton-root": {
            color: "#FF9900", // Arrow color
          },
        },
      },
    },
  },
});

export const popperStyles = {
  // Style the paper container
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
    border: "2px solid #648002",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
    padding: theme.spacing(2),
  },

  // Style the year label
  "& .MuiCalendarPicker-root": {
    "& .css-1v994a0": {
      // Adjust for your specific class
      color: "#648002 !important",
      fontSize: "1.5rem !important",
      fontWeight: "bold !important",
    },
  },

  // Style the year button
  "& .PrivatePickersYear-yearButton": {
    color: "#FF9900 !important", // Year button text color
    fontSize: "1.2rem !important", // Font size
    fontWeight: "bold !important", // Font weight
    border: "1px solid #648002 !important", // Border color
    borderRadius: "4px !important", // Rounded corners
    padding: "8px !important", // Padding
    "&:hover": {
      backgroundColor: "#648002 !important", // Background color on hover
      color: "#ffffff !important", // Text color on hover
    },
    "&.Mui-selected": {
      backgroundColor: "#648002 !important", // Selected background color
      color: "#ffffff !important", // Selected text color
    },
  },

  // Style the arrow buttons
  "& .MuiPickersArrowSwitcher-root": {
    "& .MuiIconButton-root": {
      color: "#FF9900 !important",
    },
  },

  // Style the weekday labels
  "& .MuiCalendarPicker-root .MuiTypography-caption": {
    fontSize: "1.5rem !important",
    color: "#648002 !important",
    fontWeight: "bold !important",
    // Ensure Saturday and Sunday labels are orange
    "&:nth-of-type(1), &:nth-of-type(7)": {
      color: "#FF9900 !important",
    },
  },

  // Style the day numbers
  "& .MuiPickersDay-root": {
    fontSize: "1.2rem !important",
    color: "#333 !important",
    borderRadius: "50% !important",
    transition:
      "background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
    "&:hover": {
      backgroundColor: "#FF9900 !important",
      color: "#ffffff !important",
    },
    "&.Mui-selected": {
      backgroundColor: "#648002 !important",
      color: "#ffffff !important",
      fontWeight: "bold !important",
    },
    // Ensure Saturday and Sunday numbers are orange
    "&.MuiPickersDay-saturday, &.MuiPickersDay-sunday": {
      color: "#FF9900 !important",
      "&:hover": {
        backgroundColor: "#FF9900 !important",
        color: "#ffffff !important",
      },
      "&.Mui-selected": {
        backgroundColor: "#FF9900 !important",
        color: "#ffffff !important",
      },
    },
    // Style for disabled dates
    "&.Mui-disabled": {
      color: "#B0B0B0 !important", // Faint color for disabled dates
      backgroundColor: "transparent !important", // Transparent background for disabled dates
      "&:hover": {
        backgroundColor: "transparent !important", // Transparent background on hover
      },
    },
  },
};
