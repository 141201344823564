import { useAuth } from "../../AuthContext";

export const userroles = ["Admin", "User", "Service Provider"];

export const expertiseTypes = [
  "Skilled Technician",
  "Experienced Specialist",
  "Technical Expert",
  "Certified Technician",
  "Proficient Engineer",
  "Seasoned Professional",
  "Master Technician",
  "Qualified Specialist",
  "Technical Specialist",
  "Industry Expert",
];

export const businessTypes = ["Self", "Business"]; // Options for Business Type

export const verifydeType = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const verificationMethodTypes = [
  { value: "1", label: "Manual" },
  { value: "2", label: "Using Id Proof and Telephonic" },
];

export const EditwritesTypes = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const ComplationStatus = [
  { value: 1, label: "Complete" },
  { value: 2, label: "InComplete" },
  { value: 3, label: "Close" },
  { value: 0, label: "Pending" },
];

export const usermsgadditem = `When a service provider visits your home, they will mark your service status
 as closed using an OTP. You can then view the completion status of the work, 
including the work done date, whether it is completed or incomplete, and the amount you have paid.`;
export const ServicesType = ["See Complaints", "See Technicians", "See Goods"];

// Custom hook to get ProcessStatusType based on the user's role
export const useProcessStatusType = () => {
  const { user } = useAuth(); // Fetch the user from context

  const adminRoles = ["Admin", "SUPERADMIN", "Service Provider", "User"];

  if (!user) {
    return [];
  }

  if (adminRoles.includes(user?.CurrentRole)) {
    return [
      { value: 1, label: "Hide for everyone" },
      { value: 2, label: "Permanently delete" },
      { value: 3, label: "Show for everyone" },
      { value: 4, label: "Process On Going" },
      { value: 5, label: "Process Closed" },
    ];
  } else if (user?.CurrentRole === "User") {
    return [
      { value: 3, label: "Show for everyone" },
      { value: 4, label: "Process On Going" },
      { value: 5, label: "Process Closed" },
    ];
  } else if (user?.CurrentRole === "Service Provider") {
    return [
      { value: 1, label: "Hide for everyone" },
      { value: 3, label: "Show for everyone" },
      { value: 4, label: "Process On Going" },
      { value: 5, label: "Process Closed" },
    ];
  } else {
    return [{ value: 3, label: "Show for everyone" }];
  }
};
