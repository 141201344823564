import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Avatar,
  Grid,
  Container,
  Box,
  Chip,
} from "@mui/material";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import Pagination from "../layoutcompo/Pagination";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import TruncateTextCell from "../servicecomponent/TruncateTextCell";
import { toast } from "react-toastify";
import {
  getImageDimensions,
  resizeImage,
  masterItemImageDimensions,
} from "../servicecomponent/imageService";

const MasterItem = () => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn } = useAuth();
  const [masterItems, setMasterItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({
    ItemId: 0,
    CategoryId: 0,
    ItemName: "",
    MasterItemImage: "",
    Deleted: "",
    ItemType: [],
  });
  const [buttonText, setButtonText] = useState("Save");
  const [Prefix, setPrefix] = useState("");
  const baseImageUrl = EnvVariables.BaseUrlforImage;
  const fileInputRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemTypeInput, setItemTypeInput] = useState("");

  useEffect(() => {
    loadMasterItems();
  }, [currentPage, rowsPerPage, Prefix]);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadMasterItems = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix },
        EnvVariables.Get_Master_Item
      );
      const data = response.data;

      if (Array.isArray(data) && data.length > 0) {
        setMasterItems(data);
        const totalRecords = data[0].totalRecords;
        setTotalRecords(totalRecords);
      } else {
        setMasterItems([]);
        setTotalRecords(0);
        console.error("Invalid response format:", response);
        showNotification("Error loading master items", "error");
      }
    } catch (error) {
      setMasterItems([]);
      setTotalRecords(0);
      console.error("Error loading master items:", error);
      showNotification("Error loading master items", "error");
    }
  };

  const loadCategories = async () => {
    try {
      const response = await apiService.postAPIwithoutdata(
        EnvVariables.Get_Item_Category_display
      );
      setCategories(response.data);
    } catch (error) {
      setCategories([]);
      console.error("Error loading categories:", error);
      showNotification("Error loading categories", "error");
    }
  };

  const insertItem = async (formData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Add Master Complaint",
      message: "Are you sure you want to Add this master complaint?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            formData,
            EnvVariables.Insert_Master_Item
          );
          if (response.data.RDatalist[0].SuccessFailed === 0) {
            showNotification(response.data.RDatalist[0].Msg, "error");
          } else {
            showNotification(response.data.RDatalist[0].Msg, "success");
            await loadMasterItems();
            resetState();
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const updateItem = async (formData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Update Master Complaint",
      message: "Are you sure you want to update this master complaint?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            formData,
            EnvVariables.Update_Master_Item
          );
          if (response.data.RDatalist[0].SuccessFailed === 0) {
            showNotification(response.data.RDatalist[0].Msg, "error");
          } else {
            showNotification(response.data.RDatalist[0].Msg, "success");
            await loadMasterItems();
            resetState();
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const deleteItem = async (ItemId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Master Complaint",
      message: "Are you sure you want to delete this master complaint?",
      onConfirm: async () => {
        try {
          showLoader();
          const formData = new FormData();
          formData.append("ItemId", ItemId);
          const response = await apiService.postAPI(
            formData,
            EnvVariables.Delete_Item_Master_Item
          );
          if (response.data.RDatalist[0].SuccessFailed === 1) {
            showNotification(response.data.RDatalist[0].Msg, "success");
            await loadMasterItems();
          } else {
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const resetState = () => {
    setForm({
      ItemId: 0,
      CategoryId: 0,
      ItemName: "",
      Deleted: "",
      ItemType: [],
      MasterItemImage: "",
    });
    setButtonText("Save");
    setItemTypeInput("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("ItemId", form.ItemId);
    formData.append("CategoryId", form.CategoryId);
    formData.append("ItemName", form.ItemName);
    formData.append("ItemType", form.ItemType.join(","));

    if (form.MasterItemImage) {
      formData.append("MasterItemImage", form.MasterItemImage);
    }
    if (form.ItemId === 0) {
      await insertItem(formData);
    } else {
      await updateItem(formData);
    }
  };

  const handleEdit = (item) => {
    setForm({
      ItemId: item.ItemId,
      CategoryId: item.CategoryId,
      ItemName: item.ItemName,
      ItemType: item.ItemType ? item.ItemType.split(",") : [],
      MasterItemImage: item.MasterItemImage
        ? `${baseImageUrl}${item.MasterItemImage}`
        : "",
    });

    setItemTypeInput("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setButtonText("Update");
  };

  const handleDelete = async (id) => {
    await deleteItem(id);
  };

  const handleSearchChange = (e) => {
    setPrefix(e.target.value);
    setCurrentPage(1);
  };

  const handleItemTypeChange = (e) => {
    setItemTypeInput(e.target.value);
  };

  const handleAddItemType = () => {
    if (itemTypeInput.trim() && !itemTypeInput.includes(",")) {
      const newItemType = itemTypeInput.trim();
      if (!form.ItemType.includes(newItemType)) {
        setForm((prevForm) => ({
          ...prevForm,
          ItemType: [...prevForm.ItemType, newItemType],
        }));
      } else {
        showNotification("ItemType already exists", "warning");
      }
      setItemTypeInput(""); // Clear the input field
    } else {
      alert("Please ensure the text does not contain a comma.");
    }
  };

  const handleRemoveItemType = (itemType) => {
    setForm((prevForm) => ({
      ...prevForm,
      ItemType: prevForm.ItemType.filter((type) => type !== itemType),
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { width, height } = await getImageDimensions(file);
      const resizedImage = await resizeImage(file, width, height);
      setForm((prevForm) => ({
        ...prevForm,
        MasterItemImage: resizedImage,
      }));
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setCurrentPage(1);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Master Complaints
      </Typography>

      <Box component="form" onSubmit={handleSubmit} mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Master Complaint"
              value={form.ItemName || ""}
              onChange={(e) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  ItemName: e.target.value,
                }))
              }
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Complaint Category</InputLabel>
              <Select
                value={form.CategoryId || ""}
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    CategoryId: e.target.value,
                  }))
                }
                required
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category.CategoryId}
                    value={category.CategoryId}
                  >
                    {category.CategoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Item Type"
              value={itemTypeInput}
              onChange={handleItemTypeChange}
              fullWidth
              placeholder="Add comma-separated item types"
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddItemType}
                    startIcon={<FaPlus />}
                    style={{ marginLeft: 8 }}
                  >
                    Add
                  </Button>
                ),
              }}
            />
            <Box mt={2}>
              {form.ItemType.map((type, index) => (
                <Chip
                  key={index}
                  label={type}
                  onDelete={() => handleRemoveItemType(type)}
                  style={{ marginRight: 8, marginBottom: 8 }}
                />
              ))}
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Complaint Type"
              value={itemTypeInput}
              onChange={handleItemTypeChange}
              fullWidth
              placeholder="Add Complaint types"
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddItemType}
                    startIcon={<FaPlus />}
                  >
                    Add
                  </Button>
                ),
              }}
            />
            <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
              {form.ItemType.map((type, index) => (
                <Chip
                  key={index}
                  label={type}
                  onDelete={() => handleRemoveItemType(type)}
                  sx={{
                    marginRight: 1,
                    marginBottom: 1,
                    padding: "0.5rem",
                    fontSize: "1.5rem",
                    backgroundColor: "#e0f7fa", // Light blue background color
                    color: "green", // Darker text color
                    "& .MuiChip-label": {
                      fontWeight: "bold", // Makes the text bold
                      whiteSpace: "nowrap", // Prevents text wrapping
                      overflow: "hidden", // Ensures text does not overflow the chip
                      textOverflow: "ellipsis", // Adds ellipsis if text is too long
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "#d32f2f", // Red delete icon color
                    },
                    "&:hover": {
                      backgroundColor: "#80deea", // Slightly darker blue on hover
                      color: "#004d40", // Darker text color on hover
                      cursor: "pointer", // Changes cursor to pointer
                    },
                  }}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }}
              id="master-item-image-upload"
            />
            <label htmlFor="master-item-image-upload">
              <Button variant="outlined" component="span" fullWidth>
                Upload Image
              </Button>
            </label>
            {form.MasterItemImage && (
              <Box mt={2} display="flex" justifyContent="center">
                <Avatar
                  src={form.MasterItemImage}
                  alt="Master Item Image"
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} container spacing={1}>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {buttonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={resetState}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <TextField
          label="Search Items"
          value={Prefix}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Complaint Category</TableCell>
              <TableCell>Master Complaint </TableCell>
              <TableCell>Complaint Type</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Deleted Staus</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.CategoryName}</TableCell>
                <TableCell>
                  <TruncateTextCell text={item.ItemName} />
                </TableCell>
                <TableCell>
                  {item.ItemType
                    ? item.ItemType.split(",").map((type, idx) => (
                        <Chip
                          key={idx}
                          label={type}
                          size="small"
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ))
                    : "-"}
                </TableCell>
                <TableCell>
                  {item.MasterItemImage && (
                    <Avatar
                      src={`${baseImageUrl}${item.MasterItemImage}`}
                      alt="Item Image"
                      sx={{ width: 50, height: 50 }}
                    />
                  )}
                </TableCell>
                <TableCell>{item.Deleted}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(item)}>
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(item.ItemId)}
                  >
                    <FaTrashAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {masterItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No items found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Container>
  );
};

export default MasterItem;
