import React, { useState, useEffect, useRef } from "react";
import { Paper, Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { EnvVariables } from "../EnvVariables";
import { useSwipeable } from "react-swipeable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styled components for UI
const BannerContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  height: "400px",
  color: "#fff",
  backgroundColor: theme.palette.grey[900],
  overflow: "hidden",
  borderRadius: "15px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  // marginTop: "0rem",

  [theme.breakpoints.down("md")]: {
    height: "300px",
    marginTop: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}));

const BannerWrapper = styled(Box)({
  display: "flex",
  transition: "transform 0.5s ease-in-out",
  width: "100%",
  height: "100%",
});

const BannerImage = styled("img")(({ theme }) => ({
  flexShrink: 0,
  width: "100%",
  height: "auto", // Ensure the aspect ratio is maintained
  objectFit: "cover", // Fill the container while maintaining aspect ratio
  maxWidth: "100%",
  maxHeight: "100%",
  transition: "transform 0.3s ease-in-out",

  [theme.breakpoints.up("sm")]: {
    "&:hover": {
      transform: "scale(1.05)",
    },
  },

  [theme.breakpoints.down("xs")]: {
    height: "auto", // Ensure the image resizes properly on small screens
    objectFit: "contain", // Adjust to show the entire image on smaller screens
  },
}));

const BannerContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "20px", // Adjusted for space between content and dots
  left: "50%",
  transform: "translateX(-50%)",
  textAlign: "center",
  zIndex: 2,
  width: "90%",
  maxWidth: "600px",
  padding: theme.spacing(2),
  color: "#fff", // Keep the text color white for good contrast
  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)", // Slightly darker shadow for better contrast
  backgroundImage:
    "linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))", // Light gradient only for highlight
  backgroundClip: "text",
  WebkitBackgroundClip: "text", // For better support in Webkit browsers
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1, 2),
    fontSize: "0.875rem",
  },
}));

const ArrowButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 3,
  color: "#fff",
  minWidth: "40px",
  minHeight: "40px",
  padding: 0,
  fontSize: "2rem",
  fontWeight: "bold",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

// const DotsContainer = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   bottom: 0, // Positioned at the bottom of the container
//   left: "50%",
//   transform: "translateX(-50%)",
//   display: "flex",
//   gap: "8px",
//   padding: theme.spacing(1), // Optional: adds padding for better spacing
// }));

// const Dot = styled("div")(({ theme, active }) => ({
//   width: "12px",
//   height: "12px",
//   borderRadius: "50%",
//   backgroundColor: active ? theme.palette.primary.main : "#ccc",
//   cursor: "pointer",
// }));
const DotsContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0, // Adjust as needed
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: "8px",
  padding: theme.spacing(1),
}));

const Dot = styled("div")(({ theme, active }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",

  border: `2px solid ${active ? theme.palette.primary.main : "#ccc"}`, // Border color
  backgroundColor: active ? theme.palette.primary.main : "#ccc",
  boxShadow: active ? `0 0 6px ${theme.palette.primary.main}` : "none", // Subtle shadow for active dot
  cursor: "pointer",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)", // Slightly enlarge on hover
    boxShadow: `0 0 8px ${theme.palette.primary.main}`, // Enhanced shadow on hover
  },
}));
const CardBanner = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const baseURL = EnvVariables.BaseUrlforImage;
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (items.length === 0) return;

    // Automatic slide every 5 seconds
    timeoutRef.current = setTimeout(handleNext, 5000);

    return () => resetTimeout();
  }, [currentIndex, items.length]);

  const handlePrev = () => {
    if (items.length === 0) return;

    setTransitionEnabled(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    if (items.length === 0) return;

    setTransitionEnabled(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handleTransitionEnd = () => {
    if (currentIndex === items.length) {
      setTransitionEnabled(false);
      setCurrentIndex(0);
    }
  };

  const handleMouseEnter = () => {
    resetTimeout(); // Stop the auto slide on mouse enter
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(handleNext, 5000); // Restart auto slide on mouse leave
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (items.length === 0) {
    return <div>No banners available</div>;
  }

  const handleDotClick = (index) => {
    setTransitionEnabled(true);
    setCurrentIndex(index);
  };

  return (
    <BannerContainer
      {...handlers}
      square
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <BannerWrapper
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: transitionEnabled ? "transform 0.5s ease-in-out" : "none",
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {items.map((item, index) => (
          <BannerImage
            key={index}
            src={`${baseURL}${item.Image || ""}`}
            alt={item.Title || "Banner image"}
          />
        ))}
      </BannerWrapper>
      <BannerContent>
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: "3rem", sm: "3.5rem", md: "4rem" },
            fontWeight: 900, // Bold for impact
            fontFamily: "'Poppins', sans-serif", // Modern, stylish font
            letterSpacing: "0.1rem", // Slight letter spacing
            textTransform: "uppercase", // Uppercase for bold style
            color: "#fff", // White color for contrast
            textShadow: "2px 2px 10px rgba(0, 0, 0, 0.7)", // Dramatic shadow effect
            marginBottom: "0px", // Remove bottom margin
          }}
        >
          {items[currentIndex]?.Title || ""}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            fontWeight: 300, // Lighter weight for contrast with the title
            fontFamily: "'Roboto', sans-serif", // Clean, readable font
            color: "#f0f0f0", // Lighter color for readability
            textShadow: "1px 1px 5px rgba(0, 0, 0, 0.6)", // Subtle shadow
            letterSpacing: "0.05rem", // Slight letter spacing
            marginBottom: "0px", // Remove bottom margin to close the gap
          }}
        >
          {items[currentIndex]?.Description || ""}
        </Typography>
        <NavButton
          variant="contained"
          sx={{
            // Light button with high contrast
            color: "#white", // Dark text for the button
            fontWeight: 600,
            padding: "10px 20px",
            marginTop: "16px",
            marginTop: "0px",
            textShadow: "none", // Clear button text without shadow
            "&:hover": {
              // Slightly brighter on hover
            },
          }}
        >
          Learn More
        </NavButton>
      </BannerContent>
      <DotsContainer>
        {items.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </DotsContainer>
      <ArrowButton onClick={handlePrev} style={{ left: 10 }}>
        <ArrowBackIosIcon />
      </ArrowButton>
      <ArrowButton onClick={handleNext} style={{ right: 10 }}>
        <ArrowForwardIosIcon />
      </ArrowButton>
    </BannerContainer>
  );
};

export default CardBanner;

// import React, { useState, useEffect, useRef } from "react";
// import { Paper, Button, Typography, Box } from "@mui/material";
// import { styled } from "@mui/system";
// import { EnvVariables } from "../EnvVariables";
// import { useSwipeable } from "react-swipeable";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// // Styled components for UI
// const BannerContainer = styled(Paper)(({ theme }) => ({
//   position: "relative",
//   height: "400px",
//   color: "#fff",
//   backgroundColor: theme.palette.grey[900],
//   overflow: "hidden",
//   borderRadius: "15px",
//   boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
//   [theme.breakpoints.down("md")]: {
//     height: "300px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     height: "200px",
//   },
// }));

// const BannerWrapper = styled(Box)({
//   display: "flex",
//   transition: "transform 0.5s ease-in-out",
//   width: "100%",
//   height: "100%",
// });

// const BannerImage = styled("img")(({ theme }) => ({
//   flexShrink: 0,
//   width: "100%",
//   height: "100%",
//   objectFit: "cover",
//   maxWidth: "100%",
//   maxHeight: "100%",
//   transition: "transform 0.3s ease-in-out",
//   [theme.breakpoints.up("sm")]: {
//     "&:hover": {
//       transform: "scale(1.05)",
//     },
//   },
// }));

// const BannerContent = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   bottom: "20px", // Space for dots
//   left: "50%",
//   transform: "translateX(-50%)",
//   textAlign: "center",
//   zIndex: 2,
//   width: "90%",
//   maxWidth: "600px",
//   padding: theme.spacing(2),
//   color: "#fff",
//   textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
//   boxSizing: "border-box",
//   [theme.breakpoints.down("md")]: {
//     padding: theme.spacing(1),
//   },
// }));

// const NavButton = styled(Button)(({ theme }) => ({
//   marginTop: theme.spacing(2),
//   padding: theme.spacing(1, 4),
//   backgroundColor: theme.palette.primary.main,
//   color: "#fff",
//   "&:hover": {
//     backgroundColor: theme.palette.primary.dark,
//   },
//   [theme.breakpoints.down("sm")]: {
//     padding: theme.spacing(1, 2),
//     fontSize: "0.875rem",
//   },
// }));

// const ArrowButton = styled(Button)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   transform: "translateY(-50%)",
//   zIndex: 3,
//   color: "#fff",
//   minWidth: "40px",
//   minHeight: "40px",
//   padding: 0,
//   fontSize: "2rem",
//   fontWeight: "bold",
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
//   borderRadius: "50%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   "&:hover": {
//     backgroundColor: "rgba(0, 0, 0, 0.8)",
//   },
// }));

// // const DotsContainer = styled(Box)(({ theme }) => ({
// //   position: "relative", // Positioned relative to its normal position
// //   bottom: "-20px", // Adjust this value to create space between the banner and dots
// //   left: "50%",
// //   transform: "translateX(-50%)",
// //   display: "flex",
// //   gap: "8px",
// //   padding: theme.spacing(1), // Optional: adds padding for better spacing
// // }));

// // const Dot = styled("div")(({ theme, active }) => ({
// //   width: "12px",
// //   height: "12px",
// //   borderRadius: "50%",
// //   backgroundColor: active ? theme.palette.primary.main : "#ccc",
// //   cursor: "pointer",
// // }));

// const DotsContainer = styled(Box)(({ theme }) => ({
//   position: "relative",
//   bottom: "-20px", // Adjust as needed
//   left: "50%",
//   transform: "translateX(-50%)",
//   display: "flex",
//   gap: "8px",
//   padding: theme.spacing(1),
// }));

// const Dot = styled("div")(({ theme, active }) => ({
//   width: "12px",
//   height: "12px",
//   borderRadius: "50%",
//   border: `2px solid ${active ? theme.palette.primary.main : "#ccc"}`, // Border color
//   backgroundColor: active ? theme.palette.primary.main : "#ccc",
//   boxShadow: active ? `0 0 6px ${theme.palette.primary.main}` : "none", // Subtle shadow for active dot
//   cursor: "pointer",
//   transition: "transform 0.3s ease, box-shadow 0.3s ease",
//   "&:hover": {
//     transform: "scale(1.2)", // Slightly enlarge on hover
//     boxShadow: `0 0 8px ${theme.palette.primary.main}`, // Enhanced shadow on hover
//   },
// }));

// const CardBanner = ({ items }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [transitionEnabled, setTransitionEnabled] = useState(true);
//   const baseURL = EnvVariables.BaseUrlforImage;
//   const timeoutRef = useRef(null);

//   const resetTimeout = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//   };

//   useEffect(() => {
//     if (items.length === 0) return;

//     // Automatic slide every 5 seconds
//     timeoutRef.current = setTimeout(handleNext, 5000);

//     return () => resetTimeout();
//   }, [currentIndex, items.length]);

//   const handlePrev = () => {
//     if (items.length === 0) return;

//     setTransitionEnabled(true);
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? items.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     if (items.length === 0) return;

//     setTransitionEnabled(true);
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//   };

//   const handleTransitionEnd = () => {
//     if (currentIndex === items.length) {
//       setTransitionEnabled(false);
//       setCurrentIndex(0);
//     }
//   };

//   const handleMouseEnter = () => {
//     resetTimeout(); // Stop the auto slide on mouse enter
//   };

//   const handleMouseLeave = () => {
//     timeoutRef.current = setTimeout(handleNext, 5000); // Restart auto slide on mouse leave
//   };

//   const handlers = useSwipeable({
//     onSwipedLeft: handleNext,
//     onSwipedRight: handlePrev,
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   if (items.length === 0) {
//     return <div>No banners available</div>;
//   }

//   const handleDotClick = (index) => {
//     setTransitionEnabled(true);
//     setCurrentIndex(index);
//   };

//   return (
//     <>
//       <BannerContainer
//         {...handlers}
//         square
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <BannerWrapper
//           style={{
//             transform: `translateX(-${currentIndex * 100}%)`,
//             transition: transitionEnabled
//               ? "transform 0.5s ease-in-out"
//               : "none",
//           }}
//           onTransitionEnd={handleTransitionEnd}
//         >
//           {items.map((item, index) => (
//             <BannerImage
//               key={index}
//               src={`${baseURL}${item.Image || ""}`}
//               alt={item.Title || "Banner image"}
//             />
//           ))}
//         </BannerWrapper>
//         <BannerContent>
//           <Typography
//             variant="h4"
//             sx={{
//               fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
//               fontWeight: 700,
//             }}
//           >
//             {items[currentIndex]?.Title || "Title"}
//           </Typography>
//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: { xs: "0.75rem", sm: "1rem", md: "1.125rem" },
//               marginTop: "8px",
//               fontWeight: 400,
//             }}
//           >
//             {items[currentIndex]?.Description || "Description"}
//           </Typography>
//           <NavButton variant="contained">Learn More</NavButton>
//         </BannerContent>
//         <ArrowButton onClick={handlePrev} style={{ left: 10 }}>
//           <ArrowBackIosIcon />
//         </ArrowButton>
//         <ArrowButton onClick={handleNext} style={{ right: 10 }}>
//           <ArrowForwardIosIcon />
//         </ArrowButton>
//       </BannerContainer>
//       <DotsContainer>
//         {items.map((_, index) => (
//           <Dot
//             key={index}
//             active={index === currentIndex}
//             onClick={() => handleDotClick(index)}
//           />
//         ))}
//       </DotsContainer>
//     </>
//   );
// };

// export default CardBanner;
