import React, { useState,useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography, Button } from '@mui/material';
import './ItemKeywordsSelect.css'; // Import the CSS file

const ItemKeywordsSelect = () => {
  const [itemKeywords, setItemKeywords] = useState([]);

  const itemKeywordsOptions = [
    { CategoryId: 1, CategoryName: 'keyword1' },
    { CategoryId: 2, CategoryName: 'keyword2' },
    { CategoryId: 3, CategoryName: 'keyword3' },
    { CategoryId: 4, CategoryName: 'keyword4' }
  ];
  
  const validationErrors = {
    itemKeywords: '' // No validation errors initially
  };

  const handleSetKeywords = () => {
    const preSelectedKeywordsString = '2,3'; // Comma-separated string of CategoryIds
    const preSelectedKeywords = preSelectedKeywordsString.split(',').map(Number); // Convert to array of numbers
    setItemKeywords(preSelectedKeywords);


   
  };
  useEffect(() => {
    console.log('Updated Selected Keywords:', itemKeywords);
  }, [itemKeywords]);
  const handleKeywordsChange = (e) => {
    setItemKeywords(e.target.value);
  };

  const handleRetrieveKeywords = () => {
    // const categoryIdOne = "1,2,3";
    // const selectedKeywords = [categoryIdOne.split(',')];
    // setItemKeywords(selectedKeywords);
    const categoryIdOne = "1,2,3"; // Example: Comma-separated string of CategoryIds
    const selectedKeywords = categoryIdOne.split(',').map(Number); // Split and convert to array of numbers
    setItemKeywords(selectedKeywords);

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth className="margin-top-100">
          <InputLabel>Item Keywords</InputLabel>
          <Select
            multiple
            value={itemKeywords}
            onChange={handleKeywordsChange}
            renderValue={(selected) => selected.map(id => {
              const keyword = itemKeywordsOptions.find(option => option.CategoryId === id);
              return keyword ? keyword.CategoryName : '';
            }).join(', ')}
            error={Boolean(validationErrors.itemKeywords)}
          >
            {itemKeywordsOptions.map((keyword) => (
              <MenuItem key={keyword.CategoryId} value={keyword.CategoryId}>
                <Checkbox checked={itemKeywords.indexOf(keyword.CategoryId) > -1} />
                <ListItemText primary={keyword.CategoryName} />
              </MenuItem>
            ))}
          </Select>
          {validationErrors.itemKeywords && (
            <Typography color="error">{validationErrors.itemKeywords}</Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button className="margin-top-100" onClick={handleSetKeywords}>Set Pre-selected Keywords</Button>
        <Button className="margin-top-100" onClick={handleRetrieveKeywords}>Retrieve Selected Keywords</Button>
      </Grid>
    </Grid>
  );
};

export default ItemKeywordsSelect;


