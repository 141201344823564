import axios from "axios";

const axiosInstance = axios.create({
  //baseURL: "https://localhost:7210/api/v1.0/",
  baseURL: "/api/v1.0/",
  //timeout: 50000000,
});

const setupInterceptors = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        // Optionally, redirect to the login page or show a message
      }
      return Promise.reject(error);
    }
  );
};

export { axiosInstance, setupInterceptors };
