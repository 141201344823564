import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  Grid,
  Grid2,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Slider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  ContactPhone as ContactIcon,
  Share as ShareIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  ShoppingCart as ShoppingCartIcon,
  Visibility,
  VisibilityOff,
  AddCircleOutline,
  AttachMoney as AttachMoneyIcon, // Use AttachMoneyIcon
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { EnvVariables } from "../EnvVariables";
import { useDI } from "../hooks/DIContext";
import LazyImage from "../servicecomponent/LazyImage";
import { useAuth } from "../AuthContext";
import { useCart } from "../hooks/CartContext";
import Imageslider from "../servicecomponent/Imageslider";
import { useTheme } from "../Themecolor/ThemeProviderCustContext";
import { toast } from "react-toastify";
import { calculateDistance } from "../servicecomponent/calculateDistance";
import DirectionsIcon from "@mui/icons-material/Directions";
// Card container with responsive layout
const StyledCardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)", // Softer shadow for modern feel
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: "#fff", // Bright background for a clean look
  position: "relative",

  "&:hover": {
    transform: "translateY(-8px)", // Slight hover lift
    boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)", // Stronger hover shadow
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Align horizontally on larger screens
    alignItems: "center", // Ensure alignment in the center on larger screens
    maxHeight: "100%",
    height: "auto",
  },
}));

const MediaContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column", // Ensure vertical layout for smaller screens
  justifyContent: "center", // Vertically center content
  alignItems: "center", // Horizontally center content
  height: "auto",
  paddingTop: "10px",
  //position: "relative", // Needed for pseudo-elements

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "50px",
    height: "4px",
    backgroundColor: "#3498db",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "4px",
    height: "50px",
    backgroundColor: "#3498db",
    zIndex: 1,
  },
  transition: "box-shadow 0.3s ease, transform 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)", // Add shadow effect on hover
    transform: "translateY(-4px)", // Slight lift on hover
  },
  [theme.breakpoints.down("xs")]: {
    marginBottom: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "10px",
    width: "15%", // Adjust width for better centering on larger screens
    marginRight: "16px",
    border: "2px solid #e0e0e0",
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxHeight: "150px", // Adjust to ensure better visibility
  objectFit: "contain", // Ensure image is properly displayed without overflow
  transition: "transform 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    transform: "scale(1.05)", // Slight zoom effect on hover
  },
  [theme.breakpoints.up("sm")]: {
    maxHeight: "200px", // Slightly larger for bigger screens
    //border: "1px solid #e0e0e0",
  },
}));

// Content container with dynamic height and padding adjustment
const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "auto",
  //border: "2px solid #e0e0e0", // Lighter border for subtle separation
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    width: "85%",
    paddingLeft: "16px",
  },
}));

// Card content with spacing adjustments
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "white",
  [theme.breakpoints.up("sm")]: {
    padding: "16px",
  },
}));

// const IconButtonContainer = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: 10,
//   right: 10,
//   display: "flex",
//   flexDirection: "row",

//   gap: 10,
//   zIndex: 1000, // Ensure it stays on top of other elements
//   [theme.breakpoints.down("xs")]: {
//     top: 5, // Adjust positioning for mobile screens
//     right: 5,
//     startsWith: "left",
//   },
// }));
const IconButtonContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 10, // Maintain a 10px distance from the top
  right: 10, // Maintain a 10px distance from the right
  display: "flex",
  flexDirection: "row",
  gap: 10,
  zIndex: 1000, // Ensure it stays on top of other elements
  [theme.breakpoints.down("xs")]: {
    top: 5, // Adjust positioning for mobile screens
    right: 5, // Maintain a 5px distance from the right on small screens
  },
}));

const ShareButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  //backgroundColor: "var(--shareicon)",
  color: "#1DA1F2", // Blue color for ShareIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    //backgroundColor: "#E1E8ED", // Light blue for hover effect
    transform: "scale(1.1)",
  },
}));

const CartButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  color: "#FF9900", // Orange color for ShoppingCartIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    // backgroundColor: "#FFE5B4", // Light orange for hover effect
    transform: "scale(1.1)",
  },
}));

const PhoneButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  color: "#34B7F1", // Cyan color for PhoneIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: "#B3E5FC", // Light cyan for hover effect
    transform: "scale(1.1)",
  },
}));
const messageStyle = {
  textAlign: "center",
  marginTop: "20px",
  marginBottom: "300px",
};
// Styled Toggle Button
const ToggleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#f4f4f4", // Light neutral background for a clean look
  color: "#3b5998", // Professional deep blue-gray color for the icon
  fontSize: "4rem", // Controls the size of the button (adjust if needed)
  transition: "background-color 0.3s, transform 0.3s, color 0.3s", // Smooth transition for color and hover effects
  "&:hover": {
    backgroundColor: "#d9eaf7", // Light blue for a modern hover effect
    color: "#365492", // Darker blue on hover for better contrast
    transform: "scale(1.1)", // Slight zoom on hover
  },
  "& .MuiSvgIcon-root": {
    fontSize: "inherit", // Icon size inherits the button font size
  },
}));
const AddComplaintButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3498db", // Blue button background for professional look
  color: "#fff", // White text for contrast
  padding: "6px 12px",
  fontWeight: 600,
  textTransform: "none", // Disable uppercase transformation
  display: "flex",
  alignItems: "center",
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: "#2980b9", // Darker blue on hover
    transform: "scale(1.05)",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "8px", // Spacing between icon and text
  },
}));
const CardComponent = ({ categoryId, latitude, longitude, distance, unit }) => {
  const location = useLocation();
  const { addToCart, handleOrderClick, selectedLocation } = useCart();
  const [itemlist, SetItemlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn, searchInput, user } = useAuth();
  const observer = useRef(null);
  const contentRef = useRef(null);
  const baseURL = EnvVariables.BaseUrlforImage;
  const pageSize = 10;
  const [expanded, setExpanded] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [imagePaths, setImagePaths] = useState([]);
  const [searchParams] = useSearchParams();
  const [urlCategoryId, setUrlCategoryId] = useState(null);
  const [urlUserItemDetailsId, setUrlUserItemDetailsId] = useState(null);
  const [isDetailPage, setIsDetailPage] = useState(null);
  const navigate = useNavigate();
  const [showJobCostingFor, setShowJobCosting] = useState({});
  const [costingData, setCostingData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [distances, setDistances] = useState(new Map());
  const [filteredItemlist, setFilteredItemlist] = useState([]);
  const [maxDistance, setMaxDistance] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fetchItemlist = useCallback(
    debounce(async (reset = false) => {
      if (loading) return;
      setLoading(true);
      showLoader();

      try {
        const currentPage = reset ? 1 : page;
        const requestData = {
          Prefix: searchInput || undefined,
          currentPage,
          pageSize,
          CategoryId: categoryId || null,
          // Add other parameters as needed
        };

        const response = await apiService.postAPI(
          requestData,
          EnvVariables.Getdatastaticlogout
        );
        const newItems = Array.isArray(response.data) ? response.data : [];

        if (reset) {
          SetItemlist(newItems); // Replace list when resetting
          setPage(1);
        } else {
          // Merge new items with existing, avoiding duplicates
          SetItemlist((prevItems) => [
            ...prevItems,
            ...newItems.filter(
              (newItem) =>
                !prevItems.some(
                  (item) => item.UserItemDetailsId === newItem.UserItemDetailsId
                )
            ),
          ]);
          setPage((prevPage) => prevPage + 1);
        }

        setHasMore(newItems.length === pageSize); // If less than pageSize, we've fetched all data
        await calculateDistances(newItems); // Recalculate distances for newly fetched items
      } catch (error) {
        console.error("Error fetching itemlist:", error);
      } finally {
        hideLoader();
        setLoading(false);
      }
    }, 300), // Debounce time
    [apiService, searchInput, categoryId, loading]
  );

  const calculateDistances = async (data) => {
    if (!selectedLocation) return; // Skip if no location is set

    const userCoords = {
      Latitude: selectedLocation?.latitude,
      Longitude: selectedLocation?.longitude,
    };

    if (!isValidCoords(userCoords)) return; // Skip if invalid coords

    const distancePromises = data.map(async (item) => {
      const techCoords = {
        TechLatitude: item?.UserLatitude,
        TechLongitude: item?.UserLongitude,
      };

      if (!isValidCoords(techCoords, true)) return null;

      try {
        const distance = await calculateDistance(techCoords, userCoords);
        return {
          UserItemDetailsId: item.UserItemDetailsId,
          distance: distance?.distance || 0,
        };
      } catch (error) {
        console.error("Distance calculation failed:", {
          techCoords,
          userCoords,
          error,
        });
        return null;
      }
    });

    const calculatedDistances = await Promise.all(distancePromises);
    const newDistances = new Map(
      calculatedDistances
        .filter(Boolean)
        .map(({ UserItemDetailsId, distance }) => [UserItemDetailsId, distance])
    );

    setDistances((prev) => new Map([...prev, ...newDistances])); // Merge new distances with the existing ones
  };

  const isValidCoords = (coords, isTechCoords = false) => {
    if (isTechCoords) {
      return (
        typeof coords.TechLatitude === "number" &&
        coords.TechLatitude !== null &&
        typeof coords.TechLongitude === "number" &&
        coords.TechLongitude !== null
      );
    } else {
      return (
        typeof coords.Latitude === "number" &&
        coords.Latitude !== null &&
        typeof coords.Longitude === "number" &&
        coords.Longitude !== null
      );
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      calculateDistances(itemlist); // Only recalculate distances for the fetched items
    }
  }, [selectedLocation, itemlist]);

  // Initial fetch on component mount or when scrolling/input search happens
  useEffect(() => {
    fetchItemlist(true); // Fetch first page on mount/reset
  }, [categoryId, searchInput]); // Add search input if necessary

  // Filter the fetched list based on distance and user input
  const applyFilters = (data) => {
    return data.filter((techdata, index) => {
      const distance = distances.get(techdata.UserItemDetailsId);
      const withinDistance =
        maxDistance <= 0 ||
        (distance !== undefined && parseFloat(distance) <= maxDistance);
      return withinDistance;
    });
  };

  useEffect(() => {
    fetchItemlist(true); // Pass true if you want to reset and fetch the first page
  }, []); // Empty dependency array means this runs once when the component mounts

  useEffect(() => {
    const shouldFetch = categoryId || searchInput;

    if (shouldFetch) {
      setPage(1);
      fetchItemlist(true);
    }
  }, [categoryId, searchInput]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !loading) {
        fetchItemlist();
      }
    },
    [fetchItemlist, hasMore, loading]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector("#load-more-trigger");

    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleObserver]);

  const toggleDescription = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Extract URL parameters
    const categoryIdParam = searchParams.get("CategoryId");
    const userItemDetailsIdParam = searchParams.get("UserItemDetailsId");
    const isDetailPageParam = searchParams.get("isDetailPage");

    // Update the state with the URL parameters
    setUrlCategoryId(categoryIdParam);
    setUrlUserItemDetailsId(userItemDetailsIdParam);
    setIsDetailPage(isDetailPageParam);
  }, [searchParams]);

  useEffect(() => {
    // Add a delay before making the API call to ensure state has updated
    const timer = setTimeout(() => {
      if (urlCategoryId && urlUserItemDetailsId) {
        fetchItemlist(true, urlCategoryId, urlUserItemDetailsId);
      }
    }, 50); // Adjust the delay as needed

    // Cleanup timer if component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, [urlCategoryId, urlUserItemDetailsId]);

  const handleShareClick = async (item, isDetailPage = false) => {
    const itemUrl = `${window.location.origin}?CategoryId=${item.CategoryId}&UserItemDetailsId=${item.UserItemDetailsId}&isDetailPage=${isDetailPage}`;
    const imageUrl = item.SingleItemImage
      ? `${baseURL}${item.SingleItemImage}`
      : null;

    try {
      if (navigator.share) {
        if (
          navigator.canShare &&
          navigator.canShare({ files: [] }) &&
          imageUrl
        ) {
          const response = await apiService.postAPI(
            { imageUrl },
            EnvVariables.Get_Image_File,
            {
              headers: { "Content-Type": "application/json" },
              responseType: "blob",
            }
          );

          if (response && response.status === 200) {
            const blob = response.data;
            const file = new File(
              [blob],
              `image.${imageUrl.split(".").pop()}`,
              { type: blob.type }
            );

            await navigator.share({
              title: item.ItemName,
              text: item.ItemDescription,
              url: itemUrl,
              files: [file],
            });
            return;
          } else {
            throw new Error("Failed to fetch image");
          }
        }

        await navigator.share({
          title: item.ItemName,
          text: item.ItemDescription,
          url: itemUrl,
        });
      } else {
        console.warn("Web Share API not supported in this browser.");
        alert(
          "Sharing is not supported on this browser. Please copy the link and share it manually."
        );
      }
    } catch (error) {
      console.error("Error occurred during sharing:", error);
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(itemUrl);
          alert(
            "Sharing failed, but the link has been copied to your clipboard."
          );
        } catch (clipboardError) {
          alert("Sharing failed. Please copy the link and share it manually.");
        }
      } else {
        alert("Sharing failed. Please copy the link and share it manually.");
      }
    }
  };

  const openDialog = (MasterItemImage) => {
    const imagesArray = MasterItemImage.split(",").map(
      (path) => `${baseURL}${path.trim()}`
    );
    setImagePaths(imagesArray);
    setModalOpen(true);
    // setDialogImages(imagesArray);
    // setCurrentImageIndex(initialIndex);
  };
  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImages([]);
  };
  const handlePrevImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : dialogImages.length - 1
      );
    }
  };

  const handleNextImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < dialogImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };
  const handleShowNoClick = (phoneno) => {
    if (isLoggedIn) {
      if (isMobileDevice()) {
        window.location.href = `tel:${phoneno}`;
      } else {
        showNotification(`Phone number: ${phoneno}`, "info");
      }
    } else {
      showNotification("Login first", "error");
    }
  };
  const isMobileDevice = () => {
    return (
      /Mobi|Android|iPhone|iPad|Windows Phone|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      ("ontouchstart" in window && navigator.maxTouchPoints > 0)
    );
  };
  const [showCards, setShowCards] = useState(true); // State to toggle cards visibility

  const toggleCards = () => {
    setShowCards(!showCards); // Toggle card visibility on button click
  };
  const handleRegisterComplaint = () => {
    if (isLoggedIn) navigate("/managecomplaint");
    else toast.error("Please login first.");
  };
  const toggleJobCosting = (UserItemDetailsId) => {
    setShowJobCosting((prevState) => ({
      ...prevState,
      [UserItemDetailsId]: !prevState[UserItemDetailsId],
    }));
  };
  const handleCostingChange = (id, field, value) => {
    setCostingData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value,
      },
    }));
  };

  // const handleAddCosting = (item) => {
  //   const userItemId = item.UserItemDetailsId;
  //   const isUpdating = item.YouAddedCostFlag === 1; // Check if the user is updating costing
  //   const currentCosting = {
  //     ...costingData[userItemId],
  //     UserItemDetailsId: userItemId,
  //   };

  //   // Start the loader first
  //   showLoader();

  //   // Validate the form for this item
  //   if (!validateForm(userItemId)) {
  //     hideLoader(); // Hide loader if validation fails
  //     return;
  //   }

  //   // Check if user is logged in
  //   if (!isLoggedIn) {
  //     hideLoader(); // Hide loader if user is not logged in
  //     toast.error("Please login first.");
  //     return;
  //   }

  //   // Check if user has the right to add/update costing
  //   else if (user && user.YesProvider !== 1) {
  //     hideLoader(); // Hide loader before opening confirmation dialog
  //     setConfirmDialog({
  //       isOpen: true,
  //       title: "Become a Service Provider",
  //       message:
  //         "You do not have the rights to add or update costing. To do this, you need to become a service provider.",
  //       onConfirm: async () => {
  //         setConfirmDialog({ isOpen: false });
  //         navigate("selfprofile");
  //       },
  //       onCancel: () => {
  //         setConfirmDialog({ isOpen: false });
  //         return; // Stop further actions
  //       },
  //     });
  //     return; // Prevent further execution if the user is not a service provider
  //   }

  //   // Proceed with the confirmation dialog and API call
  //   setConfirmDialog({
  //     isOpen: true,
  //     message: isUpdating
  //       ? "Are you sure you want to update the costing for this complaint?"
  //       : "Are you sure you want to add costing for this complaint?",
  //     onConfirm: async () => {
  //       try {
  //         const costmodel = {
  //           RequirementsDesc: user.RequirementsDesc,
  //           UserType: user.UserType,
  //           Businessname: user.Businessname,
  //           ExpertiseType: user.ExpertiseType,
  //           TechnicianCategoryId: user.TechnicianCategoryId,
  //           UserItemDetailsId: currentCosting.UserItemDetailsId,
  //           ServiceCharge: currentCosting.serviceCharge,
  //           MinCost: currentCosting.minCost,
  //           MaxCost: currentCosting.maxCost,
  //           Description: currentCosting.description,
  //         };

  //         // API call to add/update costing
  //         const response = await apiService.postAPI(
  //           { costmodel }, // Passing currentCosting data to API
  //           isUpdating
  //             ? EnvVariables.Update_Coasting // Use Update API if updating
  //             : EnvVariables.Add_Coasting // Use Add API if adding
  //         );

  //         // Check response for success or failure
  //         if (response.data.Iserror === false) {
  //           toast.success(response.data.RDatalist[0].Msg); // Success message
  //           handleCancel(); // Reset form or state (ensure `index` is passed if necessary)
  //         } else {
  //           toast.error(response.data.RDatalist[0].Msg); // Error message
  //         }
  //       } catch (error) {
  //         console.error("Error adding/updating costing:", error);
  //         toast.error("An error occurred while processing costing.");
  //       } finally {
  //         handleCancel(); // Ensure form reset
  //         setConfirmDialog({ isOpen: false }); // Close confirm dialog
  //         hideLoader(); // Hide loader after everything is done
  //       }
  //     },
  //     onCancel: () => {
  //       setConfirmDialog({ isOpen: false });
  //       hideLoader(); // Hide loader if the user cancels
  //     },
  //   });
  // };
  const handleAddCosting = (item) => {
    const userItemId = item.UserItemDetailsId;
    const isUpdating = item.YouAddedCostFlag === 1; // Check if the user is updating costing
    const currentCosting = {
      ...costingData[userItemId],
      UserItemDetailsId: userItemId,
    };

    // Start the loader first
    showLoader();

    // Validate the form for this item
    if (!validateForm(userItemId)) {
      hideLoader();
      return;
    }

    // Check if user is logged in
    if (!isLoggedIn) {
      hideLoader();
      toast.error("Please login first.");
      return;
    }

    // Check if user has the right to add/update costing
    if (user && user.YesProvider !== 1) {
      hideLoader();
      setConfirmDialog({
        isOpen: true,
        title: "Become a Service Provider",
        message:
          "You do not have the rights to add or update costing. To do this, you need to become a service provider.",
        onConfirm: async () => {
          setConfirmDialog({ isOpen: false });
          navigate("selfprofile");
        },
        onCancel: () => {
          setConfirmDialog({ isOpen: false });
        },
      });
      return;
    }

    // Create the unified costmodel with checks for completeness
    const costmodel = {
      RequirementsDesc: user.RequirementsDesc
        ? String(user.RequirementsDesc)
        : "", // Assuming this is included in your overall model
      UserType: user.UserType ? String(user.UserType) : null,
      Businessname: user.Businessname ? String(user.Businessname) : "N/A",
      ExpertiseType: user.ExpertiseType ? String(user.ExpertiseType) : "",

      UserItemDetailsId: String(currentCosting.UserItemDetailsId || userItemId), // Convert to string for the ID
      ServiceCharge: currentCosting.serviceCharge
        ? parseFloat(currentCosting.serviceCharge)
        : null, // Nullable decimal
      MinCost: currentCosting.minCost
        ? parseFloat(currentCosting.minCost)
        : null, // Nullable decimal
      MaxCost: currentCosting.maxCost
        ? parseFloat(currentCosting.maxCost)
        : null, // Nullable decimal
      Description: currentCosting.description
        ? String(currentCosting.description)
        : "", // Nullable string

      // Warranty/Guarantee fields
      WarrantyType: currentCosting.warrantyType || "", // Can be 'Warranty', 'Guarantee', or 'notIncluded'
      DurationType: currentCosting.durationType || null, // Nullable string for 'Year', 'Month', or 'Day'
      Duration: currentCosting.duration
        ? parseInt(currentCosting.duration, 10)
        : null, // Nullable integer
      CustomerId: item.CustomerId ? parseInt(item.CustomerId) : null, // Nullable integer
    };

    // Validate costmodel before making the API call
    const requiredFields = [
      "RequirementsDesc",
      "UserType",
      "Businessname",
      "ExpertiseType",
      "UserItemDetailsId",
      "ServiceCharge",
      "MinCost",
      "MaxCost",
      "Description",
    ];

    // Add Warranty-specific required fields based on warrantyType
    if (
      costmodel.WarrantyType === "Warranty" ||
      costmodel.WarrantyType === "Guarantee"
    ) {
      requiredFields.push("DurationType", "Duration");
    }

    for (const field of requiredFields) {
      if (!costmodel[field]) {
        toast.error(`Error: ${field} is required.`);
        hideLoader();
        return;
      }
    }

    // Proceed with the confirmation dialog and API call
    setConfirmDialog({
      isOpen: true,
      message: isUpdating
        ? "Are you sure you want to update the costing for this complaint?"
        : "Are you sure you want to add costing for this complaint?",
      onConfirm: async () => {
        try {
          // API call to add/update costing
          const response = await apiService.postAPI(
            costmodel,
            isUpdating
              ? EnvVariables.Update_Coasting
              : EnvVariables.Add_Coasting
          );

          // Check response for success or failure
          if (response.data.IsError === false) {
            toast.success(response.data.RDatalist[0].Msg);

            // const updatedItems = itemlist.map((itemnew) =>
            //   itemnew.UserItemDetailsId === item.UserItemDetailsId
            //     ? { ...item, YouAddedCostFlag: 1 }
            //     : item
            // );
            // SetItemlist(updatedItems);
            const updatedItems = itemlist.map((itemnew) => {
              // Ensure both IDs are compared as strings or integers
              return itemnew.UserItemDetailsId ===
                String(item.UserItemDetailsId)
                ? { ...itemnew, YouAddedCostFlag: 1 } // Update the flag only for the matched item
                : itemnew; // Keep the other items unchanged
            });
            SetItemlist(updatedItems);

            handleClearCosting(userItemId);

            // Hide the costing form
            toggleJobCosting(userItemId);
          } else {
            toast.error(response.data.RDatalist[0].Msg);
          }
        } catch (error) {
          console.error("Error adding/updating costing:", error);
          toast.error("An error occurred while processing costing.");
        } finally {
          handleCancel(); // Ensure form reset
          setConfirmDialog({ isOpen: false }); // Close confirm dialog
          hideLoader(); // Hide loader after everything is done
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
        hideLoader(); // Hide loader if the user cancels
      },
    });
  };

  const handleClearCosting = (userItemId) => {
    setCostingData((prevData) => ({
      ...prevData,
      [userItemId]: {
        serviceCharge: "",
        minCost: "",
        maxCost: "",
        gst: "",
        description: "",
        warrantyType: "", // Reset Warranty/Guarantee
        durationType: "", // Reset duration type (Year, Month, Day)
        duration: "", // Reset duration value
      },
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [userItemId]: {}, // Clear errors for the specific card
    }));
  };

  const validateForm = (UserItemDetailsId) => {
    const errors = {};
    const data = costingData[UserItemDetailsId] || {};

    // Convert values to numbers, handle null, undefined, and invalid numbers by defaulting to 0
    const serviceCharge =
      !isNaN(Number(data.serviceCharge)) && data.serviceCharge !== null
        ? Number(data.serviceCharge)
        : 0;
    const minCost =
      !isNaN(Number(data.minCost)) && data.minCost !== null
        ? Number(data.minCost)
        : 0;
    const maxCost =
      !isNaN(Number(data.maxCost)) && data.maxCost !== null
        ? Number(data.maxCost)
        : 0;

    // Validate serviceCharge
    if (isNaN(serviceCharge) || serviceCharge < 0) {
      errors.serviceCharge =
        "Service charge is required and cannot be negative.";
    }

    // Validate minCost - not allowed to be 0
    if (isNaN(minCost) || minCost <= 0) {
      errors.minCost =
        "Minimum cost is required and must be a positive number greater than zero.";
    }

    // Validate maxCost - not allowed to be 0
    if (isNaN(maxCost) || maxCost <= 0) {
      errors.maxCost =
        "Maximum cost is required and must be a positive number greater than zero.";
    }

    // Ensure minCost is less than maxCost
    if (!isNaN(minCost) && !isNaN(maxCost) && minCost >= maxCost) {
      errors.minCost = "Minimum cost should be less than the maximum cost.";
    }

    // Validate description
    if (!data.description?.trim()) {
      errors.description = "Description is required.";
    }

    // Validate Warranty/Guarantee/Not Included selection
    const warrantyType = data.warrantyType;
    if (!warrantyType) {
      errors.warrantyType =
        "Please select one option: Warranty, Guarantee, or Not Included.";
    }

    // If Warranty or Guarantee is selected, validate the duration and duration type
    if (warrantyType === "Warranty" || warrantyType === "Guarantee") {
      // Validate durationType
      if (!data.durationType) {
        errors.durationType =
          "Please select a duration type (Year, Month, or Day).";
      }

      // Validate duration
      const duration =
        !isNaN(Number(data.duration)) && data.duration !== null
          ? Number(data.duration)
          : 0;
      if (isNaN(duration) || duration <= 0) {
        errors.duration = "Duration must be a positive number.";
      }
    }

    // Set validation errors for the specific item
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [UserItemDetailsId]: errors,
    }));

    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handleCancel = (userItemId) => {
    // Clear the costing data for the specific user item ID
    handleClearCosting(userItemId);

    // Toggle the visibility of the job costing form
    toggleJobCosting(userItemId);
  };

  const handleDirectionsClick = (techCoords) => {
    if (!techCoords.TechLatitude || !techCoords.TechLongitude) {
      toast.error(" Customer Location details not available.");
      return;
    }

    const googleMapsUrl =
      selectedLocation?.latitude && selectedLocation?.longitude
        ? `https://www.google.com/maps/dir/?api=1&origin=${selectedLocation.latitude},${selectedLocation.longitude}&destination=${techCoords.TechLatitude},${techCoords.TechLongitude}`
        : `https://www.google.com/maps/search/?api=1&query=${techCoords.TechLatitude},${techCoords.TechLongitude}`;

    window.open(googleMapsUrl, "_blank");
  };
  const renderRows = () => {
    const filteredItems = applyFilters(itemlist);
    return filteredItems.map((item) => (
      <Grid item key={item.UserItemDetailsId} xs={12} sm={12}>
        <StyledCardContainer>
          <Box sx={{ marginBottom: "3rem" }}>
            <IconButtonContainer sx={{ marginBottom: "2rem" }}>
              <ShareButton onClick={() => handleShareClick(item)}>
                <ShareIcon />
              </ShareButton>
              <CartButton onClick={() => handleShowNoClick(item.PhoneNo)}>
                <PhoneIcon />
              </CartButton>
              <CartButton
                onClick={() => toggleJobCosting(item.UserItemDetailsId)}
              >
                {item.YouAddedCostFlag === 1 ? "Update Costing" : "Add Costing"}
              </CartButton>
            </IconButtonContainer>
          </Box>
          {/* Image container */}
          <MediaContainer>
            <Box>
              <StyledCardMedia
                component="img"
                src={`${baseURL}${item.SingleItemImage.split(",")[0]}`} // Ensure baseURL is correct
                alt={item.ItemName}
                onError={(e) =>
                  (e.target.src =
                    process.env.PUBLIC_URL + "images/errorimages/noimage.jpg")
                }
                onClick={() => openDialog(item.MasterItemImage)} // Open dialog on click
              />
            </Box>
          </MediaContainer>
          {/* Content container */}
          <ContentContainer>
            <StyledCardContent>
              {/* Top row with item name and icons */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.4rem" }, // Bigger font size for clearer reading
                    color: "#2c3e50", // Darker grey for improved contrast
                    fontWeight: 600, // Medium weight for readability
                    marginBottom: "8px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#34495e", // Slightly darker blue-grey for "Complaint Regarding"
                    }}
                  >
                    Complaint Regarding:
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      color: "#c0392b", // Warmer red for item name
                    }}
                  >
                    {item.ItemName}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  Distance:{" "}
                  {distances.get(item.UserItemDetailsId) ||
                    "Please select a location to view distances in km ..."}
                  <IconButton
                    onClick={() =>
                      handleDirectionsClick({
                        TechLatitude: item.TechLatitude,
                        TechLongitude: item.TechLongitude,
                      })
                    }
                  >
                    <DirectionsIcon
                      sx={{
                        fontSize: "2rem",
                        color: "secondary.main",
                      }}
                    />
                  </IconButton>
                </Typography>
              </Box>

              {/* Description Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: "1.05rem", sm: "1.2rem" }, // Increased font size for labels
                    color: "#2c3e50", // Dark grey for better readability
                    fontWeight: 500,
                    marginBottom: "6px",
                  }}
                >
                  Complaint Description:
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "1rem", sm: "1.1rem" }, // Clear body text size
                    lineHeight: "1.5", // Standard line height for comfort
                    color: "#5d6d7e", // Mid-grey for less strain on the eyes
                    wordWrap: "break-word",
                  }}
                >
                  {expanded[item.UserItemDetailsId]
                    ? item.ItemDescription
                    : `${item.ItemDescription.slice(0, 80)}...`}
                  {item.ItemDescription.length > 80 && (
                    <span
                      onClick={() => toggleDescription(item.UserItemDetailsId)}
                      style={{
                        color: "#2980b9", // Clearer blue for 'See More/Less'
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                    >
                      {expanded[item.UserItemDetailsId]
                        ? " See Less"
                        : " See More"}
                    </span>
                  )}
                </Typography>
              </Box>

              {/* Complaint Type and UOM */}
              <Box sx={{ marginTop: "12px" }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: "1.1rem", sm: "1.25rem" }, // Standardized heading size
                    color: "#2c3e50", // Dark grey for headings
                    fontWeight: 600,
                  }}
                >
                  <span style={{ color: "#34495e" }}>
                    Exact Complaint Type:
                  </span>{" "}
                  {item.ItemType}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#27ae60", // Clear green for UOM
                    fontWeight: 600,
                    fontSize: { xs: "1rem", sm: "1.1rem" }, // Consistent sizing
                    marginTop: "4px",
                  }}
                >
                  {item.UOM}
                </Typography>
              </Box>
              {/* Conditionally render job costing section */}
              {showJobCostingFor[item.UserItemDetailsId] && (
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  {/* Service Charge - Number Field */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Service Charge"
                      value={
                        costingData[item.UserItemDetailsId]?.serviceCharge || ""
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "") {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "serviceCharge",
                            value
                          );
                          return;
                        }
                        if (/^\d+$/.test(value)) {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "serviceCharge",
                            value
                          );
                        }
                      }}
                      fullWidth
                      error={Boolean(
                        validationErrors[item.UserItemDetailsId]?.serviceCharge
                      )}
                      helperText={
                        validationErrors[item.UserItemDetailsId]
                          ?.serviceCharge ? (
                          <span style={{ color: "red", fontSize: "1.2rem" }}>
                            {
                              validationErrors[item.UserItemDetailsId]
                                ?.serviceCharge
                            }
                          </span>
                        ) : null
                      }
                    />
                  </Grid>

                  {/* Minimum Cost - Number Field */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Minimum Cost"
                      value={costingData[item.UserItemDetailsId]?.minCost || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "") {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "minCost",
                            value
                          );
                          return;
                        }
                        if (/^\d+$/.test(value)) {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "minCost",
                            value
                          );
                        }
                      }}
                      fullWidth
                      error={Boolean(
                        validationErrors[item.UserItemDetailsId]?.minCost
                      )}
                      helperText={
                        validationErrors[item.UserItemDetailsId]?.minCost ? (
                          <span style={{ color: "red", fontSize: "1.2rem" }}>
                            {validationErrors[item.UserItemDetailsId]?.minCost}
                          </span>
                        ) : null
                      }
                    />
                  </Grid>

                  {/* Maximum Cost - Number Field */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Maximum Cost"
                      value={costingData[item.UserItemDetailsId]?.maxCost || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "") {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "maxCost",
                            value
                          );
                          return;
                        }
                        if (/^\d+$/.test(value)) {
                          handleCostingChange(
                            item.UserItemDetailsId,
                            "maxCost",
                            value
                          );
                        }
                      }}
                      fullWidth
                      error={Boolean(
                        validationErrors[item.UserItemDetailsId]?.maxCost
                      )}
                      helperText={
                        validationErrors[item.UserItemDetailsId]?.maxCost ? (
                          <span style={{ color: "red", fontSize: "1.2rem" }}>
                            {validationErrors[item.UserItemDetailsId]?.maxCost}
                          </span>
                        ) : null
                      }
                    />
                  </Grid>

                  {/* Description - Text Field */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Description"
                      value={
                        costingData[item.UserItemDetailsId]?.description || ""
                      }
                      onChange={(e) =>
                        handleCostingChange(
                          item.UserItemDetailsId,
                          "description",
                          e.target.value
                        )
                      }
                      fullWidth
                      multiline
                      rows={4}
                      error={Boolean(
                        validationErrors[item.UserItemDetailsId]?.description
                      )}
                      helperText={
                        validationErrors[item.UserItemDetailsId]
                          ?.description ? (
                          <span style={{ color: "red", fontSize: "1.2rem" }}>
                            {
                              validationErrors[item.UserItemDetailsId]
                                ?.description
                            }
                          </span>
                        ) : null
                      }
                    />
                  </Grid>

                  {/* Warranty and Guarantee - Radio Buttons */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold", marginBottom: 1 }}
                    >
                      Warranty/Guarantee
                    </Typography>
                    <RadioGroup
                      row
                      value={
                        costingData[item.UserItemDetailsId]?.warrantyType || ""
                      }
                      onChange={(e) =>
                        handleCostingChange(
                          item.UserItemDetailsId,
                          "warrantyType",
                          e.target.value
                        )
                      }
                    >
                      <Radio value="Warranty" />
                      <Typography component="span" sx={{ marginRight: 2 }}>
                        Warranty
                      </Typography>
                      <Radio value="Guarantee" />
                      <Typography component="span" sx={{ marginRight: 2 }}>
                        Guarantee
                      </Typography>
                      <Radio value="notIncluded" />
                      <Typography component="span">Not Included</Typography>
                    </RadioGroup>
                    {validationErrors[item.UserItemDetailsId]?.warrantyType ? (
                      <span style={{ color: "red" }}>
                        {validationErrors[item.UserItemDetailsId]?.warrantyType}
                      </span>
                    ) : null}
                  </Grid>

                  {/* Conditionally Show Duration Type Options */}
                  {(costingData[item.UserItemDetailsId]?.warrantyType ===
                    "Warranty" ||
                    costingData[item.UserItemDetailsId]?.warrantyType ===
                      "Guarantee") && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold", marginBottom: 1 }}
                        >
                          Duration Type
                        </Typography>
                        <RadioGroup
                          row
                          value={
                            costingData[item.UserItemDetailsId]?.durationType ||
                            ""
                          }
                          onChange={(e) =>
                            handleCostingChange(
                              item.UserItemDetailsId,
                              "durationType",
                              e.target.value
                            )
                          }
                        >
                          <Radio value="Year" />
                          <Typography component="span" sx={{ marginRight: 2 }}>
                            Year
                          </Typography>
                          <Radio value="Month" />
                          <Typography component="span" sx={{ marginRight: 2 }}>
                            Month
                          </Typography>
                          <Radio value="Day" />
                          <Typography component="span">Day</Typography>
                        </RadioGroup>
                        {validationErrors[item.UserItemDetailsId]
                          ?.durationType ? (
                          <span style={{ color: "red", fontSize: "1.2rem" }}>
                            {
                              validationErrors[item.UserItemDetailsId]
                                ?.durationType
                            }
                          </span>
                        ) : null}
                      </Grid>

                      {/* Duration - Number Field */}
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Duration"
                          value={
                            costingData[item.UserItemDetailsId]?.duration || ""
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "") {
                              handleCostingChange(
                                item.UserItemDetailsId,
                                "duration",
                                value
                              );
                              return;
                            }

                            const numericValue = Number(value);
                            const durationType =
                              costingData[item.UserItemDetailsId]?.durationType;

                            // Check based on durationType
                            if (
                              durationType === "Day" &&
                              numericValue >= 1 &&
                              numericValue <= 365
                            ) {
                              handleCostingChange(
                                item.UserItemDetailsId,
                                "duration",
                                value
                              );
                            } else if (
                              durationType === "Month" &&
                              numericValue >= 1 &&
                              numericValue <= 12
                            ) {
                              handleCostingChange(
                                item.UserItemDetailsId,
                                "duration",
                                value
                              );
                            } else if (
                              durationType === "Year" &&
                              numericValue === 1
                            ) {
                              handleCostingChange(
                                item.UserItemDetailsId,
                                "duration",
                                value
                              );
                            }
                          }}
                          fullWidth
                          error={Boolean(
                            validationErrors[item.UserItemDetailsId]?.duration
                          )}
                          helperText={
                            validationErrors[item.UserItemDetailsId]
                              ?.duration ? (
                              <Typography
                                component="span"
                                color="error"
                                sx={{ fontSize: "1.2rem" }}
                              >
                                {
                                  validationErrors[item.UserItemDetailsId]
                                    ?.duration
                                }
                              </Typography>
                            ) : null
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {/* Add Costing Button */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => handleAddCosting(item)}
                        >
                          {item.YouAddedCostFlag === 1
                            ? "Update Costing"
                            : "Add Costing"}
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={() =>
                            handleClearCosting(item.UserItemDetailsId)
                          }
                        >
                          Clear
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="outlined"
                          color="default"
                          fullWidth
                          onClick={() => handleCancel(item.UserItemDetailsId)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </StyledCardContent>
          </ContentContainer>
        </StyledCardContainer>
      </Grid>
    ));
  };

  return (
    <>
      {" "}
      <div>
        {itemlist.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "20px",
              padding: "10px 16px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Header text */}
            <Typography
              variant="h6"
              sx={{
                color: "#3b5998",
                fontWeight: 600,
                fontSize: {
                  xs: "1rem", // Adjust font size for small screens
                  sm: "1.25rem", // Adjust for medium screens
                  md: "1.5rem", // Default font size for larger screens
                },
              }}
            >
              Complaints will appear here until resolved. Click the eye icon to
              view or hide them.
            </Typography>

            {/* Button group for toggle and register */}
            <Box
              display="flex"
              alignItems="center"
              gap={2} // Space between buttons
              sx={{
                "& > *": {
                  marginRight: {
                    xs: "8px", // Smaller space on smaller screens
                    sm: "12px", // Medium space on larger screens
                  },
                },
              }}
            >
              {/* Toggle button with visibility icon */}
              <ToggleButton onClick={toggleCards}>
                {showCards ? <Visibility /> : <VisibilityOff />}
              </ToggleButton>

              {/* Register Complaint Button */}
              <AddComplaintButton
                onClick={handleRegisterComplaint}
                startIcon={<AddCircleOutline />}
              >
                Add Complaint
              </AddComplaintButton>
            </Box>
          </Box>
        )}

        {/* Cards container (visible based on state) */}
        {showCards && (
          <>
            {" "}
            {/* Add margin to the overall container */}
            <Box mt={2}>
              <Typography>Filter by Distance</Typography>
              <Slider
                value={maxDistance}
                onChange={(e, newValue) => setMaxDistance(newValue)}
                aria-labelledby="distance-slider"
                valueLabelDisplay="auto"
                min={0}
                max={500}
              />
              <Typography>Max Distance: {maxDistance} km</Typography>
            </Box>
            <Box mt={4}>
              {" "}
              {/* Add spacing between the slider section and the grid */}
              <Grid container spacing={2}>
                {renderRows()}
              </Grid>
            </Box>
          </>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogContent
          sx={{
            padding: 0,
            margin: 0,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "calc(100% - 10px)", // 5px margin on each side
              height: "calc(80vh - 10px)", // 5px margin on top and bottom
              margin: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <IconButton
              onClick={handlePrevImage}
              disabled={dialogImages.length === 0 || currentImageIndex === 0}
              sx={{
                position: "absolute",
                left: 16,
                zIndex: 1,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                borderRadius: "50%",
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${baseURL}${dialogImages[currentImageIndex]}`}
                alt="Dialog Image"
                style={{
                  maxWidth: "calc(100% - 10px)", // 5px margin on each side
                  maxHeight: "calc(100% - 10px)", // 5px margin on top and bottom
                  objectFit: "contain", // Ensures the image fits within the container while preserving aspect ratio
                }}
              />
            </Box>

            <IconButton
              onClick={handleNextImage}
              disabled={
                dialogImages.length === 0 ||
                currentImageIndex === dialogImages.length - 1
              }
              sx={{
                position: "absolute",
                right: 16,
                zIndex: 1,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                borderRadius: "50%",
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Imageslider
        open={modalOpen}
        onClose={handleCloseModal}
        imagePaths={imagePaths}
      />
    </>
  );
};
export default CardComponent;
