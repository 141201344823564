// import React, { useState, useEffect, useRef } from "react";
// import { Box, IconButton } from "@mui/material";
// import PaletteIcon from "@mui/icons-material/Palette";
// import { useTheme } from "./ThemeProviderCustContext";
// import { darkenRgb } from "./colorUtils";
// const ThemeSelectorSlider = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const { selectedColor, setSelectedColor } = useTheme("");
//   const [isMobile, setIsMobile] = useState(false);
//   const colorPickerRef = useRef(null);

//   useEffect(() => {
//     const checkIfMobile = () => {
//       setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
//     };

//     checkIfMobile();
//     window.addEventListener("resize", checkIfMobile);

//     return () => {
//       window.removeEventListener("resize", checkIfMobile);
//     };
//   }, []);

//   const handleColorChange = (newColor) => {
//     setSelectedColor(newColor);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         colorPickerRef.current &&
//         !colorPickerRef.current.contains(event.target)
//       ) {
//         setIsOpen(false);
//       }
//     };

//     if (isMobile && isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isMobile, isOpen]);

//   return (
//     <Box
//       ref={colorPickerRef}
//       sx={{
//         position: "fixed",
//         top: "50%",
//         right: 0,
//         transform: "translateY(-50%)",
//         zIndex: 1000,
//         height: "50px",
//         width: isOpen ? "200px" : "20px",
//         backgroundColor: selectedColor,
//         borderRadius: "10px 0 0 10px",
//         transition: "width 0.3s ease-in-out",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//       }}
//       onMouseEnter={() => !isMobile && setIsOpen(true)}
//       onMouseLeave={() => !isMobile && setIsOpen(false)}
//       onClick={() => isMobile && setIsOpen(!isOpen)}
//     >
//       {isOpen ? (
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: "10px",
//             padding: "0 10px",
//           }}
//         >
//           <IconButton
//             sx={{
//               backgroundColor: selectedColor,
//               color: "white",
//               "&:hover": { backgroundColor: darkenRgb(selectedColor, 10) },
//             }}
//           >
//             <PaletteIcon />
//           </IconButton>
//           <input
//             type="color"
//             value={selectedColor}
//             onChange={(e) => handleColorChange(e.target.value)}
//             style={{
//               border: "none",
//               cursor: "pointer",
//               width: "100px",
//               height: "40px",
//             }}
//           />
//         </Box>
//       ) : (
//         <IconButton sx={{ color: "white", fontSize: "20px" }}>
//           <PaletteIcon />
//         </IconButton>
//       )}
//     </Box>
//   );
// };
// export default ThemeSelectorSlider;

import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import { useTheme } from "./ThemeProviderCustContext";
import { darkenRgb } from "./colorUtils";

const ThemeSelectorSlider = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedColor, setSelectedColor } = useTheme("");
  const [isMobile, setIsMobile] = useState(false);
  const colorPickerRef = useRef(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setSelectedColor(newColor);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isMobile && isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, isOpen]);

  return (
    <Box
      ref={colorPickerRef}
      sx={{
        position: "fixed",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        zIndex: 1000,
        height: "40px",
        width: isOpen ? "100px" : "2px",
        backgroundColor: selectedColor,
        borderRadius: "10px 0 0 10px",
        transition: "width 0.3s ease-in-out",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onMouseEnter={() => !isMobile && setIsOpen(true)}
      onMouseLeave={() => !isMobile && setIsOpen(false)}
      onClick={() => isMobile && setIsOpen(!isOpen)}
    >
      {isOpen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "0 10px",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: selectedColor,
              color: "white",
              "&:hover": { backgroundColor: darkenRgb(selectedColor, 10) },
            }}
          >
            <PaletteIcon />
          </IconButton>
          <input
            type="color"
            value={selectedColor}
            onChange={handleColorChange}
          />
        </Box>
      ) : (
        <IconButton
          sx={{
            backgroundColor: selectedColor,
            color: "white",
            "&:hover": { backgroundColor: darkenRgb(selectedColor, 10) },
          }}
        >
          <PaletteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ThemeSelectorSlider;
