import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(255, 153, 0)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(100, 128, 2)", // Green color for secondary
      contrastText: "#fff", // White text on secondary color
    },
    error: {
      main: "#f44336", // Red color for error messages
    },
  },
  typography: {
    h4: {
      fontSize: "2.5rem",
    },
    h6: {
      fontSize: "1.75rem",
    },
    body1: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--mainColor)",
          // backgroundColor: "rgb(2, 72, 75)",
          color: "#fff",
          fontSize: "1.25rem",
          "&:hover": {
            // backgroundColor: "#005b62",
            backgroundColor: "var(--mainColor)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
          color: "initial", // Ensure label color is not affected by error state
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Override default label color when error is applied
          "& .MuiFormLabel-root": {
            color: "inherit", // Inherit color or set to desired default color
          },
          "& .MuiFormLabel-root.Mui-error": {
            color: "inherit", // Prevent label from turning red on error
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "1.25rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "1.5rem",
          fontWeight: "bold",
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 2,
        },
        body: {
          fontSize: "1.25rem",
        },
        sticky: {
          position: "sticky",
          left: 0,
          backgroundColor: "#fff",
          zIndex: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "1.25rem",
          "&:focus": {
            fontSize: "1.25rem",
          },
        },
        icon: {
          fontSize: "2rem", // Adjust the size of the select arrow
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          "&.Mui-focused": {
            fontSize: "1.4rem",
            marginTop: "-.6rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "rgb(255, 153, 0)",
          },
          "&.Mui-focused $notchedOutline": {
            borderColor: "rgb(255, 153, 0)",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: "2rem", // Default font size
            transition: "font-size 0.3s ease", // Smooth transition for font size
            "&:hover": {
              fontSize: "2.5rem", // Increased font size on hover
            },
          },
        },
      },
    },
  },
});

export default theme;
