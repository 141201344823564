import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  AppBar,
  Toolbar,
} from "@mui/material";
import { Share as ShareIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useCart } from "../hooks/CartContext";
import { EnvVariables } from "../EnvVariables";
import AddressPage from "../MasterPages/AddressPage";
import { useDI } from "../hooks/DIContext";
import Imageslider from "../servicecomponent/Imageslider";
import { FaPlus, FaMinus, FaTrashAlt, FaShoppingBag } from "react-icons/fa";
// Styled components
const FixedContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  marginTop: "10rem",
  left: 0,
  width: "40%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.divider}`,
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    position: "relative",
    borderRight: "none",
    marginTop: "0rem",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "fixed",
  marginLeft: "40%",
  padding: theme.spacing(2),
  height: "100vh",
  overflowY: "auto",
  marginTop: theme.spacing(-2),
  [theme.breakpoints.down("md")]: {
    position: "relative",
    marginLeft: 0,
    marginTop: theme.spacing(-3), // Add margin-top for spacing on mobile
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "block",
  },
}));

const LargeIconButton = styled(IconButton)(({ theme }) => ({
  width: "40px",
  height: "40px",
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));
const StyledShareIcon = styled(ShareIcon)({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  color: "#007bff",
  transition: "color 0.3s ease",
  fontSize: "3rem",
  "&:hover": {
    color: "#0056b3",
  },
});
const PriceTag = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));

const OldPriceTag = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  textDecoration: "line-through",
  color: "#757575",
}));
const ItemDetailPage = () => {
  let initialOrderItem =
    JSON.parse(localStorage.getItem("selectedorderItem")) || [];
  const { addToCart } = useCart();
  const baseURL = EnvVariables.BaseUrlforImage;
  const navigate = useNavigate();
  const [orderItem, setOrderItem] = useState(initialOrderItem || null);
  const { setCartItems, cartItems } = useCart();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isLoggedIn, addresses, setAddresses } = useAuth();
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [showAddNewAddressDialog, setShowAddNewAddressDialog] = useState(false);

  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/"); // Redirect to the home page if not authenticated
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("selectedorderItem"));
    if (item) {
      setOrderItem({
        ...item,
        quantity: item.quantity || 1,
        CartId: item.CartId || "", // Default to 1 if quantity is null or undefined
      });
    }
  }, []);

  const fetchedAddresses = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage: 1, pageSize: 100 },
        EnvVariables.Get_User_Address
      );
      const data = response.data.RDatalist;

      if (Array.isArray(data) && data.length > 0) {
        setAddresses(data);
        const defaultAddress = data.find(
          (addr) => addr.PrimaryAddress === "Yes"
        );
        if (defaultAddress) {
          setSelectedAddress(defaultAddress.UserAddressId);
        }
      } else {
        setAddresses([]);
        showNotification("Error loading addresses", "error");
      }
    } catch (error) {
      setAddresses([]);
      showNotification("Error loading addresses", "error");
    }
  };
  useEffect(() => {
    fetchedAddresses();
  }, []);
  useEffect(() => {
    console.log("Addresses:", addresses);
    const defaultAddress = addresses.find(
      (addr) => addr.PrimaryAddress === "Yes"
    );
    if (defaultAddress) {
      setSelectedAddress(defaultAddress.UserAddressId);
    }
  }, [addresses]);

  if (!orderItem) {
    return (
      <Box p={2} mt={1}>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  const imagePaths = orderItem.MasterItemImage
    ? orderItem.MasterItemImage.split(",").map(
        (image) => `${baseURL}${image.trim()}`
      )
    : [];

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < imagePaths.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const backtothehomepage = () => {
    setOrderItem(null);
    localStorage.removeItem("orderItem");
    navigate(`/`);
  };

  const selectedAddressObj = addresses.find(
    (addr) => addr.UserAddressId === selectedAddress
  );

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleAddAddress = () => {
    setShowAddressDialog(false);
    setShowAddNewAddressDialog(true);
  };

  const ordersend = async (item) => {
    setConfirmDialog({
      isOpen: true,
      title: "Order",
      message: "Are you sure you want to confirm this order?",
      onConfirm: async () => {
        try {
          showLoader();

          const itemsArray = Array.isArray(item) ? item : [item];

          const orderDetails = {
            Items: itemsArray.map((orderItem) => ({
              UserItemDetailsId: orderItem.UserItemDetailsId || null,
              ItemType: orderItem.ItemType || "DefaultType",
              ItemName: orderItem.ItemName,
              ItemDescription: orderItem.ItemDescription,
              CategoryName: orderItem.CategoryName,
              ItemKeywords: orderItem.ItemKeywords || "",
              ItemPrice: orderItem.ItemPrice,
              ItemDiscount: orderItem.ItemDiscount || 0,
              DiscountedPrice: orderItem.DiscountedPrice || 0,
              CartId: orderItem.CartId || "",
              quantity: orderItem.quantity || 1,
              TotalPrice:
                (orderItem.DiscountedPrice || 0) * (orderItem.quantity || 0),
              UOM: orderItem.UOM || "",
              SellerPhoneNo: orderItem.SellerPhoneNo || "", // Include SellerPhoneNo here
            })),

            BuyerAddress: selectedAddressObj?.FullAddress,
            SellerPhoneNo: itemsArray[0]?.PhoneNo || "", // Assign SellerPhoneNo from the first item
            GrandTotal: itemsArray.reduce(
              (total, orderItem) =>
                total + orderItem.DiscountedPrice * orderItem.quantity,
              0
            ),
            Orderquantity: itemsArray.reduce(
              (total, orderItem) => total + (orderItem.quantity || 0),
              0
            ),
          };

          const response = await apiService.postAPI(
            orderDetails,
            EnvVariables.Place_Single_Order
          );

          if (response.data.IsError) {
            showNotification("Error submitting order", "error");
          } else {
            if (response.data.RDatalist[0].SuccessFailed === 1) {
              showNotification("Order placed successfully", "success");

              // Remove ordered items from the cart
              setCartItems((prevCartItems) =>
                prevCartItems.filter(
                  (cartItem) =>
                    !itemsArray.some(
                      (orderItem) => orderItem.CartId === cartItem.CartId
                    )
                )
              );
              navigate("/");
            } else {
              showNotification("Failed to place order", "error");
            }
          }
        } catch (error) {
          showNotification("Error submitting the form", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const handleChangeAddressClick = () => {
    setShowAddressDialog(true);
  };

  const handleCloseDialog = () => {
    setShowAddressDialog(false);
  };

  const handleCloseNewAddressDialog = () => {
    setShowAddNewAddressDialog(false);
  };

  const updateQuantity = (newQuantity) => {
    if (newQuantity >= 1) {
      setOrderItem((prevItem) => ({
        ...prevItem,
        quantity: newQuantity,
      }));
    }
  };

  const calculateTotalPrice = () => {
    return (
      (orderItem.DiscountedPrice || orderItem.ItemPrice) * orderItem.quantity
    );
  };

  return (
    <>
      <Box sx={{ marginTop: "0rem" }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "var(--pagebar)", // Custom background color
            color: "#ffffff", // Ensure text color is white for contrast
          }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1, // Makes sure the text takes up available space
                textAlign: "left", // Centers the text
                marginLeft: "40%",
              }}
            >
              Order Detail
            </Typography>
          </Toolbar>
        </AppBar>
        <Box>
          <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
            <FixedContainer>
              <ImageContainer>
                {imagePaths.length > 0 ? (
                  <img
                    src={imagePaths[currentImageIndex]}
                    alt={orderItem.ItemName}
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    // onClick={() => setOpenImageModal(true)}
                    onClick={handleOpenModal}
                  />
                ) : (
                  <Typography variant="body1">No images available</Typography>
                )}
                <Box position="absolute" top="50%" left={10} zIndex={1}>
                  <LargeIconButton
                    onClick={handlePrevImage}
                    disabled={currentImageIndex === 0}
                  >
                    <NavigateBeforeIcon />
                  </LargeIconButton>
                </Box>
                <Box position="absolute" top="50%" right={10} zIndex={1}>
                  <LargeIconButton
                    onClick={handleNextImage}
                    disabled={currentImageIndex === imagePaths.length - 1}
                  >
                    <NavigateNextIcon />
                  </LargeIconButton>
                </Box>
                {/* <StyledShareIcon /> */}
              </ImageContainer>
            </FixedContainer>

            <ContentContainer>
              <Box p={2} mt={1} style={{ border: "1px solid #ccc" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <Typography
                        variant="h6"
                        style={{ marginRight: "8px", fontWeight: 500 }}
                      >
                        Delivery Address
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleChangeAddressClick}
                        style={{ padding: "4px 8px", fontSize: "0.875rem" }}
                      >
                        Change Address
                      </Button>
                    </Box>
                    <Grid item xs={8}>
                      <Box>
                        <Typography
                          variant="body2" // Changed from "body1" to "body2"
                          component="div" // Explicitly render as a <div> instead of <p>
                          style={{ marginTop: "4px" }}
                        >
                          {selectedAddress
                            ? addresses.find(
                                (addr) => addr.UserAddressId === selectedAddress
                              )?.FullAddress
                            : "No address selected."}
                        </Typography>
                      </Box>
                    </Grid>
                    <hr></hr>
                  </Grid>
                </Grid>
                {/* Address Change Dialog */}
                <Dialog open={showAddressDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Select Address</DialogTitle>
                  <DialogContent>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={selectedAddress || ""}
                        onChange={handleAddressChange}
                      >
                        {addresses.length > 0 ? (
                          addresses.map((address) => (
                            <FormControlLabel
                              key={address.UserAddressId} // Use a unique identifier as the key
                              value={address.UserAddressId} // Ensure this matches the key
                              control={<Radio />}
                              label={address.FullAddress} // Display the full address
                            />
                          ))
                        ) : (
                          <Typography>No addresses available</Typography>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleAddAddress}
                      color="primary"
                      variant="contained"
                    >
                      Add New Address
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={showAddNewAddressDialog}
                  onClose={handleCloseNewAddressDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>Add New Address</DialogTitle>
                  <DialogContent>
                    <AddressPage /> {/* Render AddressPage inside the dialog */}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseNewAddressDialog}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openImageModal}
                  onClose={() => setOpenImageModal(false)}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>Image Details</DialogTitle>
                  <DialogContent>
                    <img
                      src={imagePaths[currentImageIndex]}
                      alt={orderItem.ItemName}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageModal(false)}>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Box>
                  <Typography variant="h4">{orderItem.ItemName}</Typography>
                  <Typography variant="body1">
                    {orderItem.ItemDescription}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Type: {orderItem.ItemType}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {orderItem.ItemDiscount}% off
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      fontSize: "1rem",
                      textDecoration: "line-through",
                      color: "#757575",
                    }}
                  >
                    Original Price ₹{orderItem.ItemPrice}
                  </Typography>
                  <Typography>
                    {/* Price ₹{orderItem.DiscountedPrice} */}
                    <PriceTag>₹{orderItem.DiscountedPrice}</PriceTag>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "var(--mainColor)", fontSize: "14px" }}
                  >
                    Total Price: ₹{calculateTotalPrice().toFixed(2)}
                  </Typography>
                  <div className="quantity-controls">
                    <button
                      className="minus"
                      onClick={() => updateQuantity(orderItem.quantity - 1)}
                      disabled={orderItem.quantity <= 1}
                    >
                      <FaMinus />
                    </button>
                    <span>{orderItem.quantity}</span>
                    <button
                      className="plus"
                      onClick={() => updateQuantity(orderItem.quantity + 1)}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </Box>

                <Box>
                  <Button
                    onClick={() => addToCart(orderItem)}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                      fontWeight: 500,
                      padding: "8px",
                      borderRadius: "4px",
                      backgroundColor: "var(--addcartbutton)",
                      borderColor: "#007bff",
                      "&:hover": {
                        backgroundColor: "#0056b3",
                      },
                    }}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => ordersend(orderItem)}
                    style={{
                      fontWeight: 500,
                      padding: "8px",
                      marginTop: "2px",
                      marginBottom: "2px",
                      borderRadius: "4px",
                      backgroundColor: "var(--paybutton)",
                      borderColor: "#007bff",
                      "&:hover": {
                        backgroundColor: "#0056b3",
                      },
                    }}
                  >
                    Pay Now
                  </Button>
                  <Button
                    onClick={backtothehomepage}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            </ContentContainer>
          </Box>
        </Box>
      </Box>
      {/* <Button onClick={handleOpenModal}>Open Image Slider</Button> */}

      <Imageslider
        open={modalOpen}
        onClose={handleCloseModal}
        imagePaths={imagePaths}
      />
    </>
  );
};

export default ItemDetailPage;
