// EnvVariables.js
export class EnvVariables {
  //static BaseUrlforImage = "https://localhost:7210";
  static BaseUrlforImage = " https://ghargutisef.com";
  static gmapapikey = `AIzaSyAk6DnxsuAx7r3SkR21O-zNZJj8XSRdSoc`;
  static passwordLoginCall = `AuthoniticationUser/signin`;
  static otpLoginCall = `AuthoniticationUser/signinwithotp`;
  static getLoginOtpCall = `AuthoniticationUser/getotpforlogin`;
  static getSignupOtpCall = `AuthoniticationUser/getotpforsignup`;
  static signupCall = `AuthoniticationUser/signupwithotp`;
  static Validate_Token = `AuthoniticationUser/validate-token`;
  static User_Logout = `AuthoniticationUser/userlogout`;

  static Getdatastatic = `AuthoniticationUser/getcarddata`; // Your base URL
  static getformdataout = `AuthoniticationUser/loogedoutuser`;
  static getformdatain = `AuthoniticationUser/loogedinuser`;

  static GetMenuDataAfterLogin = `AuthoniticationUser/menubinding`;
  static GetMenuDataBeforeLogin = `AuthoniticationUser/menubindingwiyhoutlogin`;
  static UpdateProfileImage = `AuthoniticationUser/userimageupdate`;

  static Get_Encryption_key = `AuthoniticationUser/getencryptionkey`;
  static Get_Otp_Details = `Category/getotpdetails`;
  //   /Category and Item Releted/

  static Get_Item_Category = `Category/getitemcategory`; // Your base URL
  static Insert_Item_Category = `Category/insertitemcategory`;
  static Update_Item_Category = `Category/updateitemcategory`;
  static Get_Item_Category_display = `Category/getitemcategoryfordisplay`;
  static DeleteItemCategoryAPI = `Category/deleteitemcategory`;
  static Insert_Master_Item = `Category/insertmasteritem`;
  static Update_Master_Item = `Category/updatemasteritem`;
  static Get_Master_Item = `Category/getmasteritem`;
  static Delete_Item_Master_Item = `Category/deletemasteritem`;
  static Get_Master_Item_For_Display = `Category/getmasteritemfordisplay`;
  static Get_Category_Items = `Category/getcategoryanditems`;

  // seller pages
  static Insert_Seller_Item = `SellerAddItem/insertselleritem`;
  static Get_Seller_Item = `SellerAddItem/getuseritem`;
  static Update_Seller_Item = `SellerAddItem/updateuseritem`;
  static Delete_Seller_Item = `SellerAddItem/deleteselleritem`;
  static Update_Availability_Status = `SellerAddItem/updateavailabilitystatus`;
  static Get_Image_File = `SellerAddItem/fetch-image`;

  //removecustomercart;
  // bayer pages
  static Getdatastaticlogout = `SellerAddItem/getcarddatalogout`;
  static Remove_Customer_Cart = `Buyer/removecustomercart`;
  static Update_Customer_Cart = `Buyer/addquantitytocart`;
  static Add_Customer_Cart = `Buyer/addcustomercart`;
  static Get_Customer_Cart = `Buyer/getcustomercart`;
  static Buy_All_Items = `Buyer/buyallitems`;
  static Clear_All_Items = `Buyer/clearcart`;
  static Buy_Single_Item = `Buyer/buysingleitem`;

  //banner
  static Get_Banners = `Banner/getbanners`;
  static Update_Banner = `Banner/updatebanner`;
  static Create_Banner = `Banner/createbanner`;
  static Delete_Banner = `Banner/deletebanner`;
  //address
  static Insert_User_Address = `Address/createaddress`;
  static Get_User_Address = `Address/getaddresses`;
  static Update_User_Address = `Address/updateaddress`;
  static Delete_User_Address = `Address/deleteaddress`;

  //orderrelated
  static Place_Single_Order = `BuyOrder/singleorder`;
  static Place_All_item_Order = `BuyOrder/placeitemorder`;
  static Get_All_Item_Order = `BuyOrder/getallitemorder`;

  //profile
  static Change_Password = `AuthoniticationUser/changepassword`;
  static Become_Service_Provider = `AuthoniticationUser/updatemaintenacetype`;
  static Update_Search_Distance = `AuthoniticationUser/updatesearchdistance`;
  static Update_Location_API = `AuthoniticationUser/updatelocationapi`;

  // actionpanel
  static GetUserListAPI = `ActiononRole/getuserdetails`;
  static GetAreaName = `ActiononRole/getareanames`;
  static UpdateUserAPI = `ActiononRole/updateuserinfo`;
  static AddUserAPI = `ActiononRole/addnewuser`;
  static GetAria_for_Area_Page = `ActiononRole/getareanamesinareapage`;
  static Insert_Area_Master = `ActiononRole/insertarearecord`;
  static Update_Area_Master = `ActiononRole/updatareaerecord`;
  static Delete_Area_Master = `ActiononRole/deletearearecord`;

  static Get_Form_for_Mapping = `ActiononRole/getforms`;
  static Get_Form_Role_Mapping = `ActiononRole/getformrolemapping`;
  static Insert_Form_Mapping = `ActiononRole/insertroleformmapping`;
  static Update_Form_Mapping = `ActiononRole/updateroleformmapping`;

  //restriction panel
  static Update_Restriction_Charges = `RestrictionsCharges/updaterestrictioncharges`;
  static Get_Restriction_Charges = `RestrictionsCharges/getrestrictioncharges`;
  static Get_Use_Restriction_Charges = `RestrictionsCharges/getuserestrictioncharges`;

  // serviceprovider
  static Get_Technicians_Details = `Serviceprovider/gettechnicians`;
  static Add_Coasting = `Serviceprovider/addcoasting`;
  static Get_Coasting_data = `Serviceprovider/getcoastingdata`;
  static Update_Coasting = `Serviceprovider/updatecoasting`;

  static Accpet_Costing_By_Customer = `Serviceprovider/accpetcostingbycustomer`;

  static Get_Coasting_Details_For_Provider = `Serviceprovider/getcoastingdetailsforprovider`;
  static Get_Otp_Forworkdonestatus = `Serviceprovider/getotpforworkdonestatus`;
  static Call_For_Forworkdonestatus = `Serviceprovider/callforworkdonestatus`;
}
