// hooks/useConfirmationDialog.js
// hooks/useConfirmationDialog.js
import { useState } from 'react';

const useConfirmationDialog = () => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, message: '', onConfirm: null, onCancel: null });

    const showConfirmDialog = (message, onConfirm, onCancel) => {
        setConfirmDialog({ isOpen: true, message, onConfirm, onCancel });
    };

    const hideConfirmDialog = () => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
    };

    return { confirmDialog, showConfirmDialog, hideConfirmDialog, setConfirmDialog }; // Make sure to return setConfirmDialog
};

export default useConfirmationDialog;
