import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwipeable } from "react-swipeable";
import { useAuth } from "../AuthContext";
import { ServicesType } from "../servicecomponent/commondataclassfiles/commonarrays";

const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

export default function PopUpTabs() {
  const [selectedTab, setSelectedTab] = useState(ServicesType[0].toLowerCase());
  const { setBusinesstype } = useAuth();
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [itemWidth, setItemWidth] = useState(150); // Default width for items

  const handleTabClick = (tab, index) => {
    setBusinesstype(index + 2); // Adjust business type accordingly
    setSelectedTab(tab.toLowerCase());
  };

  useEffect(() => {
    const updateScrollPosition = () => {
      if (containerRef.current) {
        setScrollPosition(containerRef.current.scrollLeft);
      }
    };

    updateScrollPosition();
    containerRef.current?.addEventListener("scroll", updateScrollPosition);

    return () => {
      containerRef.current?.removeEventListener("scroll", updateScrollPosition);
    };
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const newScrollPosition =
        direction === "left"
          ? containerRef.current.scrollLeft - itemWidth
          : containerRef.current.scrollLeft + itemWidth;

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      containerRef.current?.scrollBy({
        left: itemWidth,
        behavior: "smooth",
      }),
    onSwipedRight: () =>
      containerRef.current?.scrollBy({
        left: -itemWidth,
        behavior: "smooth",
      }),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const maxScrollReached = containerRef.current
    ? scrollPosition >=
      containerRef.current.scrollWidth - containerRef.current.offsetWidth
    : true;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        mt: 4,
        mb: 4,
        position: "relative",
        overflow: "hidden",
        paddingLeft: { xs: 0, sm: "48px" },
        paddingRight: { xs: 0, sm: "48px" },
      }}
    >
      <Box display="flex" alignItems="center" width="100%">
        {!isTouchDevice() && (
          <IconButton
            onClick={() => handleScroll("left")}
            disabled={scrollPosition <= 0}
            sx={{
              color: scrollPosition <= 0 ? "#ccc" : "primary.main",
              zIndex: 1,
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              marginRight: "8px",
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Box
          display="flex"
          overflow="auto"
          ref={containerRef}
          {...(isTouchDevice() ? swipeHandlers : {})}
          sx={{
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": { display: "none" },
            flexGrow: 1,
          }}
        >
          {ServicesType.map((service, index) => (
            <Box
              key={service}
              flex="0 0 auto"
              padding="0 0.5rem"
              textAlign="center"
              onClick={() => handleTabClick(service, index)}
              sx={{
                cursor: "pointer",
                width: "300px", // Width for rectangle look
                height: "80px", // Height for rectangle look
                padding: "16px", // Internal padding for content
                backgroundColor:
                  selectedTab === service.toLowerCase()
                    ? "secondary.main"
                    : "#f5f5f5",
                color: selectedTab === service.toLowerCase() ? "#fff" : "#333",
                borderRadius: "8px", // Slightly rounded corners
                boxShadow:
                  selectedTab === service.toLowerCase()
                    ? "0px 4px 12px rgba(0, 0, 0, 0.2)"
                    : "0px 2px 8px rgba(0, 0, 0, 0.1)", // Box shadow for depth
                display: "flex", // Flexbox for centering content
                flexDirection: "column", // Content stacked vertically
                alignItems: "center", // Horizontally center content
                justifyContent: "center", // Vertically center content
                transition: "all 0.3s ease", // Smooth transition for hover/selection effects
                "&:hover": {
                  transform: "scale(1.05)", // Slight scaling on hover
                },
                background: (theme) =>
                  `linear-gradient(90deg, ${
                    selectedTab === service.toLowerCase()
                      ? `${theme.palette.primary.main}, rgb(255, 99, 71), ${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}, rgb(2, 102, 0), ${theme.palette.secondary.main}`
                  })`,
                backgroundSize: "200% 200%", // Makes the gradient bigger for smoother animation
                animation: "waveEffect 3s ease-in-out infinite", // Apply animation to all tabs
              }}
            >
              <Box
                sx={{
                  padding: "16px",
                  backgroundColor:
                    selectedTab === service.toLowerCase()
                      ? "primary.main"
                      : "#f5f5f5",
                  color:
                    selectedTab === service.toLowerCase() ? "#fff" : "#333",
                  borderRadius: "12px",
                  boxShadow:
                    selectedTab === service.toLowerCase()
                      ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                      : "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px", // Ensure consistency in tab height
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight:
                      selectedTab === service.toLowerCase() ? 600 : 500,
                    fontSize: "16px",
                  }}
                >
                  {service}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {!isTouchDevice() && (
          <IconButton
            onClick={() => handleScroll("right")}
            disabled={maxScrollReached}
            sx={{
              color: maxScrollReached ? "#ccc" : "primary.main",
              zIndex: 1,
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              marginLeft: "8px",
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
