import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import debounce from "lodash.debounce";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Typography,
  Badge,
  Box,
} from "@mui/material";
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  ShoppingCart as ShoppingCartIcon,
  AccountCircle,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { useDI } from "../hooks/DIContext";
import { useCart } from "../hooks/CartContext";
import { EnvVariables } from "../EnvVariables";
import "./Header.css";
import { toast } from "react-toastify";
const Header = ({ cartItemCount, toggleCart, toggleMenu }) => {
  const {
    isLoggedIn,
    logout,
    searchInput,
    updateSearchInput,
    setLoopContinue,
    businesstype,
  } = useAuth();
  const navigate = useNavigate();
  const { setCategoryId, setCartItems } = useCart();
  const { apiService, setConfirmDialog } = useDI();
  const dropdownRef = useRef(null);

  const [categoriesAndItems, setCategoriesAndItems] = useState([]);
  const [filteredCategoriesAndItems, setFilteredCategoriesAndItems] = useState(
    []
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchCategoriesAndItems = async () => {
      try {
        const response = await apiService.postAPIwithoutdata(
          EnvVariables.Get_Category_Items
        );
        setCategoriesAndItems(response.data);
      } catch (error) {
        console.error("Error fetching categories and items:", error);
      }
    };
    fetchCategoriesAndItems();
  }, [apiService]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLoginClick = () => {
    if (isLoggedIn) {
      setConfirmDialog({
        isOpen: true,
        message: "Are you sure you want to log out?",
        onConfirm: async () => {
          try {
            await logout(); // Ensure logout returns a promise
            setCartItems([]);
            navigate("/");
            toast.success("Logged out successfully!"); // Show success notification
          } catch (error) {
            toast.error("Logout failed. Please try again."); // Show error notification if logout fails
          } finally {
            setConfirmDialog({ isOpen: false }); // Close the dialog
          }
        },
        onCancel: () => {
          setConfirmDialog({ isOpen: false }); // Close the dialog without logging out
        },
      });
    } else {
      navigate("/login");
    }
  };
  const handleSearchChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    // setLoopContinue(true);
    updateSearchInput(inputValue);
    setCategoryId("");

    if (!inputValue) {
      setFilteredCategoriesAndItems([]);
      setShowDropdown(false);
      return;
    }

    const filteredCategoriesAndItems = categoriesAndItems.filter(
      (category) =>
        (category.CategoryName &&
          category.CategoryName.toLowerCase().includes(inputValue)) ||
        (category.Items &&
          category.Items.some(
            (item) =>
              item.ItemName && item.ItemName.toLowerCase().includes(inputValue)
          ))
    );

    setFilteredCategoriesAndItems(filteredCategoriesAndItems);
    setShowDropdown(true);
  };

  const handleSearchClick = debounce(() => {
    updateSearchInput(searchInput);
    setCategoryId("");
  }, 300);

  const handleLogoClick = () => {
    updateSearchInput("");
    setCategoryId("");
    navigate("/");
  };

  const handleItemSelect = (itemName) => {
    updateSearchInput(itemName);
    setShowDropdown(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "var(--mainColor)", color: "white" }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleLogoClick}
            sx={{ mr: 2 }}
          >
            <img
              src="Images/categoryimages/food.jpeg"
              alt="Category 1"
              style={{
                height: "4rem",
                width: "4rem",
                borderRadius: "50%",
                boxShadow: "0.5rem 1rem 2rem var(--universalShadow)",
              }}
            />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              marginLeft: "-15px",
            }}
            onClick={handleLogoClick}
          >
            {"  "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: { xs: "flex-end", md: "center" },
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: { xs: "100%", md: "50%" },
              mr: -0.3,
            }}
          >
            <InputBase
              placeholder={`Search ${
                businesstype === 2 ? "complaints" : "Technician"
              }`}
              value={searchInput}
              onChange={handleSearchChange}
              sx={{
                width: "100%",
                padding: "5px",
                paddingRight: "40px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "white",
                "&:focus": {
                  borderColor: "var(--mainColor)",
                  boxShadow: "0 0 5px var(--mainColor)",
                },
              }}
            />
            <IconButton
              className="search-icon"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                height: "100%",
                color: "#007bff",
              }}
              onClick={handleSearchClick}
            >
              <SearchIcon />
            </IconButton>
            {/* {showDropdown && filteredCategoriesAndItems.length > 0 && (
                            <Box sx={{ position: 'absolute', top: '100%', left: 0, right: 0, backgroundColor: 'white', border: '1px solid #ccc', zIndex: 1000, maxHeight: '300px', overflowY: 'auto', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '10px' }} ref={dropdownRef}>
                                {filteredCategoriesAndItems.map(category => (
                                    <Box key={category.CategoryId} sx={{ padding: '10px', borderBottom: '1px solid #eaeaea', '&:hover': { backgroundColor: '#f9f9f9' } }}>
                                        <Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>{category.CategoryName}</Typography>
                                        {category.Items && category.Items.map(item => (
                                            <Box key={item.ItemId} sx={{ padding: '8px 10px', cursor: 'pointer', borderRadius: '4px', '&:hover': { backgroundColor: '#f0eff1' } }} onClick={() => handleItemSelect(item.ItemName)}>
                                                <Typography variant="body1" sx={{ color: 'rgb(32, 4, 107)', marginLeft: '10px' }}>{item.ItemName}</Typography>
                                                {item.ItemTypes && item.ItemTypes.map((itemType, index) => (
                                                    <Typography key={index} variant="body2" sx={{ color: '#000000', marginLeft: '20px', cursor: 'pointer', '&:hover': { color: 'var(--mainColor)' } }} onClick={() => handleItemSelect(itemType)}>
                                                        {itemType}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                        )} */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            onClick={toggleMenu}
            sx={{ display: { xs: "block", md: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" onClick={toggleCart}>
            <Badge
              badgeContent={cartItemCount}
              color="secondary"
              sx={{ color: "orange" }}
            >
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
          {isLoggedIn ? (
            <IconButton color="inherit" onClick={handleLoginClick}>
              <ExitToAppIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit" onClick={handleLoginClick}>
              <AccountCircle />
            </IconButton>
          )}
        </Box>
      </Toolbar>

      <Box
        sx={{
          position: "absolute",
          bottom: -20,
          width: "100%",
          overflow: "hidden",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Background for better readability

          // padding: "0.5rem 0", // Padding for text space
          marginTop: "2rem",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "inline-block",
            whiteSpace: "nowrap",
            animation: "scroll-text 20s linear infinite",
            color: "#f69101", // Changed color to a vibrant blue #1E90FF
            fontWeight: 600, // Makes text bold
            //textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)", // Shadow to make text stand out
            letterSpacing: "0.1rem", // Adds spacing between letters for readability
            fontSize: "1.5rem", // Increased font size
          }}
        >
          Explore our comprehensive solutions and enjoy exceptional service for
          all your repair and management requirements.
        </Typography>
      </Box>
    </AppBar>
  );
};

export default Header;
