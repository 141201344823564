import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Toolbar,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { useAuth } from "../AuthContext";

const RestrictionChargesPage = () => {
  const [selectedRestriction, setSelectedRestriction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { restrictions, setRestrictions, fetchRestrictions } = useAuth();
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const [formData, setFormData] = useState({
    IsRestriction: "",
    LocationCount: "",
    DistanceCount: "",
    DistanceRange: "",
    ServiceCategoryCount: "",
    ServiceCharges: "",
    UpdateByUserId: "",
    Remarks: "",
  });

  // Fetch Data

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle dropdown change for IsRestriction
  const handleDropdownChange = (event) => {
    setFormData((prev) => ({ ...prev, IsRestriction: event.target.value }));
  };

  // Handle create or update
  const handleSave = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.IsRestriction) {
      showNotification("Please select a restriction option.", "warning");
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Save Restriction Charges",
      message: "Are you sure you want to save these restriction charges?",
      onConfirm: async () => {
        try {
          showLoader();

          // API call
          const response = await apiService.postAPI(
            formData, // The model object containing form data
            EnvVariables.Update_Restriction_Charges // API endpoint for saving restriction charges
          );

          if (!response.data.IsError) {
            setFormData({
              RestrictionId: null,
              IsRestriction: null,
              LocationCount: null,
              DistanceCount: null,
              DistanceRange: null,
              ServiceCategoryCount: null,
              ServiceCharges: null,
              UpdateByUserId: "",
              Remarks: "",
            });
            fetchRestrictions();
            setIsModalOpen(false);
            // Success response handling
            showNotification(response.data.RDatalist[0].Msg, "success");

            // Clear the form or reset the state
          } else {
            // Error message from API response
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
          // General error handling for the API call
          showNotification("Error saving restriction charges", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  // Handle open modal
  const handleOpenModal = (restriction = null) => {
    setSelectedRestriction(restriction);
    if (restriction) {
      setFormData({
        RestrictionId: restriction.RestrictionId,
        IsRestriction: restriction.IsRestriction,
        LocationCount: restriction.LocationCount,
        DistanceCount: restriction.DistanceCount,
        DistanceRange: restriction.DistanceRange,
        ServiceCategoryCount: restriction.ServiceCategoryCount,
        ServiceCharges: restriction.ServiceCharges,

        Remarks: restriction.Remarks,
      });
    } else {
      setFormData({
        IsRestriction: "",
        LocationCount: "",
        DistanceCount: "",
        DistanceRange: "",
        ServiceCategoryCount: "",
        ServiceCharges: "",
        UpdateByUserId: "",
        Remarks: "",
      });
    }
    setIsModalOpen(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRestriction(null);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await apiService.deleteAPI(`/api/restrictionandcharges/${id}`);
    } catch (error) {
      console.error("Error deleting restriction:", error);
    }
  };

  return (
    <Box>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Restriction and Charges Management
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Restriction and Charges
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add New
        </Button>

        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Restriction ID</TableCell>
                <TableCell>Is Restriction</TableCell>
                <TableCell>Location Count</TableCell>
                <TableCell>Distance Count</TableCell>
                <TableCell>Distance Range in Km</TableCell>

                <TableCell>Service Category Count</TableCell>
                <TableCell>Service Charges</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Update By User </TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(restrictions) &&
                restrictions.map((restriction) => (
                  <TableRow key={restriction.RestrictionId}>
                    <TableCell>{restriction.RestrictionId}</TableCell>
                    <TableCell>
                      {restriction.IsRestriction === 1 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{restriction.LocationCount}</TableCell>
                    <TableCell>{restriction.DistanceCount}</TableCell>
                    <TableCell>{restriction.DistanceRange}</TableCell>
                    <TableCell>{restriction.ServiceCategoryCount}</TableCell>

                    <TableCell>{restriction.ServiceCharges}</TableCell>
                    <TableCell>{restriction.UpdateDate}</TableCell>
                    <TableCell>{restriction.Name}</TableCell>
                    <TableCell>{restriction.Remarks}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModal(restriction)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(restriction.RestrictionId)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              padding: 3,
              width: 400,
              margin: "auto",
              marginTop: "100px",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              {selectedRestriction ? "Edit Restriction" : "Add Restriction"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="isRestriction-label">
                    Is Restriction
                  </InputLabel>
                  <Select
                    labelId="isRestriction-label"
                    id="isRestriction"
                    name="IsRestriction"
                    value={formData.IsRestriction}
                    onChange={handleDropdownChange}
                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={2}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Location Count"
                  name="LocationCount"
                  value={formData.LocationCount}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Distance Count"
                  name="DistanceCount"
                  value={formData.DistanceCount}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Distance Range"
                  name="DistanceRange"
                  value={formData.DistanceRange}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Service Category Count"
                  name="ServiceCategoryCount"
                  value={formData.ServiceCategoryCount}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Service Charges"
                  name="ServiceCharges"
                  value={formData.ServiceCharges}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Remarks"
                  name="Remarks"
                  value={formData.Remarks}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default RestrictionChargesPage;
