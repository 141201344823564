import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Pagination from "../layoutcompo/Pagination";

import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";

const AddressPage = () => {
  const { isLoggedIn, addresses, setAddresses } = useAuth();

  const [form, setForm] = useState({
    UserAddressId: 0,
    PrimaryAddress: "",
    FullAddress: "",
  });
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const [buttonText, setButtonText] = useState("Save");
  const [editingIndex, setEditingIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    loadAddresses();
  }, [currentPage, rowsPerPage]);

  const loadAddresses = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage },
        EnvVariables.Get_User_Address // Replace with your API endpoint
      );
      const data = response.data.RDatalist;
      if (Array.isArray(data) && data.length > 0) {
        setAddresses(data);
        const totalRecords = data[0].TotalRecords; // Adjust if needed
        setTotalRecords(totalRecords);
      } else {
        setAddresses([]);
        setTotalRecords(0);
        console.error("Invalid response format:", response);
        showNotification("Error loading addresses", "error");
      }
    } catch (error) {
      setAddresses([]);
      setTotalRecords(0);
      console.error("Error loading addresses:", error);
      showNotification("Error loading addresses", "error");
    }
  };

  const insertAddress = async (formData) => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        formData,
        EnvVariables.Insert_User_Address // Replace with your API endpoint
      );
      hideLoader();
      if (response.data.RDatalist[0].SuccessFailed === 0) {
        showNotification(response.data.RDatalist[0].Msg, "error");
      } else {
        showNotification(response.data.RDatalist[0].Msg, "success");
        resetState();
        await loadAddresses(); // Refresh addresses after insert
      }
    } catch (error) {
      hideLoader();
      showNotification("Error inserting address", "error");
    }
  };

  const updateAddress = async (formData) => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        formData,
        EnvVariables.Update_User_Address // Replace with your API endpoint
      );
      hideLoader();
      if (response.data.RDatalist[0].SuccessFailed === 0) {
        showNotification(response.data.RDatalist[0].Msg, "error");
      } else {
        showNotification(response.data.RDatalist[0].Msg, "success");
        resetState();
        await loadAddresses(); // Refresh addresses after update
      }
    } catch (error) {
      hideLoader();
      showNotification("Error updating address", "error");
    }
  };

  const deleteAddress = async (UserAddressId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Address",
      message: "Are you sure you want to Delete this address?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            { UserAddressId },
            EnvVariables.Delete_User_Address // Replace with your API endpoint
          );
          if (response.data.IsError) {
            showNotification("Error deleting address", "error");
          } else {
            if (response.data.RDatalist[0].SuccessFailed === 1) {
              showNotification("Address deleted successfully", "success");
              resetState();
              await loadAddresses(); // Refresh addresses after delete
            } else {
              showNotification("Failed to delete address", "error");
            }
          }
        } catch (error) {
          showNotification("Error submitting the form", "error");
        } finally {
          hideLoader(); // Hide loader after operation completes
          setConfirmDialog({ isOpen: false }); // Close the confirm dialog
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false }); // Close the confirm dialog
      },
    });
  };
  const resetState = () => {
    setForm({
      UserAddressId: 0,
      PrimaryAddress: "",
      FullAddress: "",
    });
    setIsEditing(false);
    setButtonText("Save");
    setEditingIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmDialog({
      isOpen: true,
      title: isEditing ? "Update Address" : "Add Address",
      message: isEditing
        ? "Are you sure you want to Add this address?"
        : "Are you sure you want to Update this address?",
      onConfirm: async () => {
        try {
          const formData = {
            UserAddressId: form.UserAddressId.toString(),
            PrimaryAddress: form.PrimaryAddress,
            FullAddress: form.FullAddress,
          };

          if (isEditing) {
            await updateAddress(formData);
          } else {
            await insertAddress(formData);
          }
        } catch (error) {
          showNotification("Error submitting the form", "error");
        } finally {
          hideLoader(); // Hide loader after operation completes
          setConfirmDialog({ isOpen: false }); // Close the confirm dialog
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false }); // Close the confirm dialog
      },
    });
  };
  const handleEdit = (index) => {
    const item = addresses[index];
    setForm({
      UserAddressId: item.UserAddressId,
      PrimaryAddress: item.PrimaryAddress,
      FullAddress: item.FullAddress,
    });
    setIsEditing(true);

    setEditingIndex(index);
  };

  const handleDelete = async (index) => {
    const item = addresses[index];
    await deleteAddress(item.UserAddressId);
  };

  const handleSearchChange = (e) => {
    // Implement search logic if needed
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Address Management
      </Typography>

      <form onSubmit={handleSubmit} style={{ marginBottom: "2rem" }}>
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Primary Address</InputLabel>
          <Select
            value={form.PrimaryAddress}
            onChange={(e) =>
              setForm({ ...form, PrimaryAddress: e.target.value })
            }
            label="Primary Address"
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Full Address"
          variant="outlined"
          fullWidth
          value={form.FullAddress}
          onChange={(e) => setForm({ ...form, FullAddress: e.target.value })}
          style={{ marginBottom: "1rem" }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginRight: "1rem" }}
        >
          {isEditing ? "Update" : "Save"}
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={resetState}
        >
          Reset
        </Button>
      </form>

      <TextField
        label="Search addresses..."
        variant="outlined"
        fullWidth
        onChange={handleSearchChange}
        style={{ marginBottom: "1rem" }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Address ID</TableCell>
              <TableCell>Primary Address</TableCell>
              <TableCell>Full Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(addresses) && addresses.length > 0 ? (
              addresses.map((item, index) => (
                <TableRow key={item.UserAddressId}>
                  <TableCell>{item.SrNo}</TableCell>
                  <TableCell>{item.PrimaryAddress}</TableCell>
                  <TableCell>{item.FullAddress}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(index)}
                    >
                      <FaEdit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(index)}
                    >
                      <FaTrashAlt />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No addresses found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Container>
  );
};

export default AddressPage;
