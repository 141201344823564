// src/services/imageService.js
export const bannerTypes = [
  { value: "SelectBanner", label: "Select Banner Type" },
  { value: "Header Banner", label: "Header Banner" },
  { value: "Promotional", label: "Promotional" },
  { value: "Seasonal", label: "Seasonal" },
  { value: "New Arrivals", label: "New Arrivals" },
];

export const bannerDimensions = {
  Promotional: {
    maxWidth: 800,
    maxHeight: 600,
    targetWidth: 700,
    targetHeight: 500,
  },
  Seasonal: {
    maxWidth: 1000,
    maxHeight: 800,
    targetWidth: 900,
    targetHeight: 700,
  },
  "New Arrivals": {
    maxWidth: 1200,
    maxHeight: 900,
    targetWidth: 1100,
    targetHeight: 800,
  },
  "Header Banner": {
    maxWidth: 1920,
    maxHeight: 1080,
    targetWidth: 1100,
    targetHeight: 800,
  },
};
export const masterItemImageDimensions = {
  MasterItem: {
    maxWidth: 100,
    maxHeight: 100,
  },
  CategoryImage: {
    maxWidth: 90,
    maxHeight: 80,
  },
  CardImage: {
    maxWidth: 3000,
    maxHeight: 3000,
  },
};
// export const resizeImage = (file, maxWidth, maxHeight) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     const canvas = document.createElement("canvas");
//     const ctx = canvas.getContext("2d");

//     img.onload = () => {
//       let width = img.width;
//       let height = img.height;

//       // Calculate the scaling factor
//       const widthScale = maxWidth / width;
//       const heightScale = maxHeight / height;
//       const scale = Math.min(widthScale, heightScale);

//       width = width * scale;
//       height = height * scale;

//       //   canvas.width = width;
//       //   canvas.height = height;

//       canvas.width = maxWidth;
//       canvas.height = maxHeight;

//       ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

//       // Convert the image to WebP format
//       canvas.toBlob(
//         (blob) => {
//           if (blob) {
//             const newFile = new File(
//               [blob],
//               `${file.name.split(".")[0]}.webp`,
//               {
//                 type: "image/webp",
//               }
//             );
//             resolve(newFile);
//           } else {
//             reject("Error resizing image.");
//           }
//         },
//         "image/webp",
//         0.7 // Quality setting for WebP, between 0 and 1
//       );
//     };

//     img.onerror = () => {
//       reject("Invalid image format.");
//     };

//     img.src = URL.createObjectURL(file);
//   });
// };
// export const resizeImage = (file, maxWidth, maxHeight) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     const canvas = document.createElement("canvas");
//     const ctx = canvas.getContext("2d");

//     img.onload = () => {
//       let width = img.width;
//       let height = img.height;

//       // Calculate the scaling factor
//       const widthScale = maxWidth / width;
//       const heightScale = maxHeight / height;
//       const scale = Math.min(widthScale, heightScale);

//       // Calculate the new width and height based on the scale factor
//       const scaledWidth = width * scale;
//       const scaledHeight = height * scale;

//       // Set canvas dimensions to the scaled image size
//       canvas.width = scaledWidth;
//       canvas.height = scaledHeight;
//       //console.log(scaledWidth, scaledHeight);
//       // Draw the image on the canvas at the new size
//       ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);

//       // Convert the image to WebP format
//       canvas.toBlob(
//         (blob) => {
//           if (blob) {
//             const newFile = new File(
//               [blob],
//               `${file.name.split(".")[0]}.webp`,
//               {
//                 type: "image/webp",
//               }
//             );
//             resolve(newFile);
//           } else {
//             reject("Error resizing image.");
//           }
//         },
//         "image/webp",
//         0.7 // Quality setting for WebP, between 0 and 1
//       );
//     };

//     img.onerror = () => {
//       reject("Invalid image format.");
//     };

//     img.src = URL.createObjectURL(file);
//   });
// };
export const resizeImage = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate the scaling factor to maintain aspect ratio
      const widthScale = maxWidth / width;
      const heightScale = maxHeight / height;
      const scale = Math.min(widthScale, heightScale);

      // Calculate the new width and height based on the scale factor
      const scaledWidth = width * scale;
      const scaledHeight = height * scale;

      // Set canvas dimensions to the final desired size
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Clear the canvas (in case of previous drawings)
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate x and y offsets to center the image on the canvas
      const offsetX = (maxWidth - scaledWidth) / 2;
      const offsetY = (maxHeight - scaledHeight) / 2;
      console.log(offsetX, offsetY, scaledWidth, scaledHeight);
      // Draw the image centered on the canvas
      ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

      // Convert the image to WebP format
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const newFile = new File(
              [blob],
              `${file.name.split(".")[0]}.webp`,
              {
                type: "image/webp",
              }
            );
            resolve(newFile);
          } else {
            reject("Error resizing image.");
          }
        },
        "image/webp",
        0.7 // Quality setting for WebP, between 0 and 1
      );
    };

    img.onerror = () => {
      reject("Invalid image format.");
    };

    img.src = URL.createObjectURL(file);
  });
};

export const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject("Error loading image.");
    };
    img.src = URL.createObjectURL(file);
  });
};
