// DIContext.js
import React, { createContext, useContext } from 'react';
import apiService from '../apiService';
import useLoading from './useLoading';
import useConfirmationDialog from './useConfirmationDialog'
import useNotification from './useNotification';



const DIContext = createContext();

export const DIProvider = ({ children }) => {
    const { isLoading, showLoader, hideLoader } = useLoading();
    const { confirmDialog, showConfirmDialog, hideConfirmDialog, setConfirmDialog } = useConfirmationDialog();
    const { showNotification, hideNotification } = useNotification();

    const services = {
        apiService,
        isLoading,
        showLoader,
        hideLoader,
        confirmDialog,
        showConfirmDialog,
        hideConfirmDialog,
        setConfirmDialog,
       
        showNotification, // Include showNotification
        hideNotification // Include hideNotification
    };

    return (
        <DIContext.Provider value={services}>
            {children}
        </DIContext.Provider>
    );
};

export const useDI = () => {
    return useContext(DIContext);
};

