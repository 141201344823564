import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Avatar,
  Grid,
  Container,
  Box,
  Chip,
} from "@mui/material";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import Pagination from "../layoutcompo/Pagination";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import TruncateTextCell from "../servicecomponent/TruncateTextCell";

const Viewotp = () => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn } = useAuth();
  const [masterItems, setMasterItems] = useState([]);

  const [Prefix, setPrefix] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    loadMasterItems();
  }, [currentPage, rowsPerPage, Prefix]);

  const loadMasterItems = async () => {
    try {
      const otpmodel = {
        currentPage,
        pageSize: rowsPerPage,
        Prefix,
      };
      const response = await apiService.postAPI(
        otpmodel,
        EnvVariables.Get_Otp_Details
      );
      const data = response.data;

      if (Array.isArray(data) && data.length > 0) {
        setMasterItems(data);
        const totalRecords = data[0].totalRecords;
        setTotalRecords(totalRecords);
      } else {
        setMasterItems([]);
        setTotalRecords(0);
        console.error("Invalid response format:", response);
        showNotification("Error loading master items", "error");
      }
    } catch (error) {
      setMasterItems([]);
      setTotalRecords(0);
      console.error("Error loading master items:", error);
      showNotification("Error loading master items", "error");
    }
  };
  const handleSearchChange = (e) => {
    setPrefix(e.target.value);
    setCurrentPage(1);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setCurrentPage(1);
  };
  return (
    <Container sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Otp details
      </Typography>

      <Box mb={3}>
        <TextField
          label="Search Items"
          value={Prefix}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phone/Email</TableCell>
              <TableCell>otp </TableCell>
              <TableCell>Otp Purpose </TableCell>
              <TableCell>otp date time</TableCell>

              <TableCell>OtpGeneratedCount </TableCell>
              <TableCell>Otp Expire status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.UserPhoneNo}</TableCell>
                <TableCell>
                  <TruncateTextCell text={item.OtpToken} />
                </TableCell>

                <TableCell>{item.OtpPurpose}</TableCell>
                <TableCell>{item.OtpTokenGeneratedTime}</TableCell>
                <TableCell>{item.OtpGeneratedCount}</TableCell>
                <TableCell>
                  {item.OtpExpire === 1 ? "Expired" : "Valid"}
                </TableCell>
              </TableRow>
            ))}
            {masterItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No items found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Container>
  );
};

export default Viewotp;
