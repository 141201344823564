// encryptionService.js
import CryptoJS from "crypto-js";
import apiService from "../../apiService"; // Import your apiService to handle API calls
import { EnvVariables } from "../../EnvVariables"; // Import your environment variables

export const fetchEncryptionKey = async () => {
  try {
    const response = await apiService.getAPIwithoutData(
      EnvVariables.Get_Encryption_key
    );
    // Ensure response contains the expected data
    if (response && response.data) {
      return response.data;
    } else {
      throw new Error("Failed to fetch encryption key");
    }
  } catch (error) {
    console.error("Error fetching encryption key:", error);
    throw error;
  }
};

export const encryptData = (data, key) => {
  const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV

  const encrypted = CryptoJS.AES.encrypt(data, keyUtf8, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Combine IV and encrypted data
  const encryptedData = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);

  return encryptedData;
};

export const GetOtpfordifferantpurpose = async ({
  phoneNumber,
  email,
  showLoader,
  hideLoader,
  showNotification,
  otppurpose,
  otpUrl,
  setOtpSessionId,
}) => {
  const validationResult = validatePhoneOrEmail(phoneNumber);
  if (!validationResult.isValid) {
    showNotification(validationResult.errorMessage, "error");
    return;
  }

  showLoader();
  try {
    const { sessionId, key } = await fetchEncryptionKey();

    const encryptedData = {
      SessionId: sessionId,
      PhoneNumber: encryptData(phoneNumber, key),
      OtpPurpose: otppurpose,
      CustomerEmail: email,
    };
    console.log("encryptedData", encryptedData);
    let response;

    response = await apiService.postAPI(encryptedData, otpUrl);

    if (response.data.IsError === false) {
      setOtpSessionId(response.data.RData.SessionId);
      showNotification("OTP sent successfully!", "success");
    } else {
      showNotification(response.data.EData[0], "error");
    }
  } catch (error) {
    showNotification("Failed to get OTP. Please try again.", "error");
  } finally {
    hideLoader();
  }
};

const validatePhoneNumber = (phoneNumber) => {
  return /^\d{10}$/.test(phoneNumber); // Validate 10-digit phone number
};

const validatePhoneOrEmail = (phoneOrEmail) => {
  // Regular expression for validating a 10-digit phone number
  const phoneRegex = /^\d{10}$/;

  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the input matches the phone number regex
  if (phoneRegex.test(phoneOrEmail)) {
    return { isValid: true, errorMessage: "" };
  }

  // Check if the input matches the email regex
  if (emailRegex.test(phoneOrEmail)) {
    return { isValid: true, errorMessage: "" };
  }

  // Determine if the input looks more like a phone number or email address
  const isNumeric = /^\d+$/.test(phoneOrEmail);
  if (isNumeric) {
    return {
      isValid: false,
      errorMessage: "Please enter a valid 10-digit phone number.",
    };
  } else {
    return {
      isValid: false,
      errorMessage: "Please enter a valid email address.",
    };
  }
};

export const validateOtp = (otp) => {
  return /^\d{6}$/.test(otp); // Validate 6-digit OTP
};

const currentDate = new Date();
export const formattedDate = `${
  currentDate.getMonth() + 1
}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
