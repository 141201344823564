import { Loader } from "@googlemaps/js-api-loader";
import { EnvVariables } from "../EnvVariables";

export const calculateDistance = async (techCoords, userCoords) => {
  // Handle null or undefined coordinates
  if (!techCoords || !userCoords) {
    console.error("Tech or user coordinates are missing.");
    return null;
  }

  console.log("Tech Coords:", techCoords);
  console.log("User Coords:", userCoords);

  // Destructure correct property names
  const { TechLatitude, TechLongitude } = techCoords;
  const { Latitude: UserLatitude, Longitude: UserLongitude } = userCoords;

  // Handle invalid coordinates
  if (
    typeof TechLatitude !== "number" ||
    typeof TechLongitude !== "number" ||
    typeof UserLatitude !== "number" ||
    typeof UserLongitude !== "number"
  ) {
    console.error("Invalid latitude or longitude values.");
    return null;
  }

  // Load the Google Maps API
  const loader = new Loader({
    apiKey: EnvVariables.gmapapikey, // Replace with your Google Maps API key
    libraries: ["places"],
  });

  try {
    await loader.load();

    const techLocation = new window.google.maps.LatLng(
      TechLatitude,
      TechLongitude
    );
    const userLocation = new window.google.maps.LatLng(
      UserLatitude,
      UserLongitude
    );

    const service = new window.google.maps.DistanceMatrixService();

    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: [techLocation],
          destinations: [userLocation],
          travelMode: window.google.maps.TravelMode.DRIVING, // Change this as needed
        },
        (response, status) => {
          console.log("DistanceMatrix API Response:", response);
          if (status === "OK") {
            const result = response.rows[0].elements[0];
            if (result.status === "OK") {
              const distanceInMeters = result.distance.value; // Distance in meters
              const distanceInKm = (distanceInMeters / 1000).toFixed(2); // Convert meters to kilometers
              resolve({
                distance: `${distanceInKm} km`,
                distanceValue: distanceInKm,
              });
            } else {
              console.error(
                "Error with the distance calculation: " + result.status
              );
              resolve(null);
            }
          } else {
            console.error("DistanceMatrix API error: " + status);
            reject(status);
          }
        }
      );
    });
  } catch (error) {
    console.error("Failed to load Google Maps API:", error);
    return null;
  }
};
