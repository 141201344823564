import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./LoginComponent.css";
import loginLogo from "../../src/assets/images/food.jpeg";
import CustomModal from "../commonlayers/CustomModal";
import PrivacyContent from "../commonlayers/PrivacyContent";
import TermsContent from "../commonlayers/TermsContent";
import LocationSelect from "../servicecomponent/translatecode/LocationSelect";

import { useCart } from "../hooks/CartContext";
import {
  TextField,
  MenuItem,
  Typography,
  FormControl,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";
const customStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "80%",
    maxHeight: "80%",
    overflowY: "auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1001,
  },
};
const LoginComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isSignup, setIsSignup] = useState(false); // Added state for signup visibility
  const [isOtpLogin, setIsOtpLogin] = useState(false); // State for OTP login mode
  const [isOtpBased, setisOtpBased] = useState(false);
  const [showSignupInputs, setShowSignupInputs] = useState(false); // State to manage signup inputs visibility
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { apiService, showLoader, hideLoader, showNotification } = useDI();
  const [OtpSessionId, setOtpSessionId] = useState(null);
  const [isemail, setIsemail] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [areas, setAreas] = useState([]);
  const [addAreaSearch, setAddAreaSearch] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const {
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    selectedLocation,
    setSelectedLocation,
  } = useCart();

  useEffect(() => {
    fetchAreas();
  }, []);
  const fetchAreas = async () => {
    try {
      const response = await apiService.postAPI({}, EnvVariables.GetAreaName);
      const data = response.data;
      if (data && data.RDatalist && Array.isArray(data.RDatalist)) {
        setAreas(data.RDatalist);
      } else {
        setAreas([]);
        showNotification("Error loading areas", "error");
      }
    } catch (error) {
      showNotification("Error fetching areas", "error");
    }
  };
  const addFilteredAreas = areas.filter((area) =>
    area.AreaName.toLowerCase().includes(addAreaSearch.toLowerCase())
  );
  const fetchEncryptionKey = async () => {
    try {
      const response = await apiService.getAPIwithoutData(
        EnvVariables.Get_Encryption_key
      );
      // Ensure response contains the expected data
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("Failed to fetch encryption key");
      }
    } catch (error) {
      console.error("Error fetching encryption key:", error);
      throw error;
    }
  };

  const encryptData = (data, key) => {
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV

    const encrypted = CryptoJS.AES.encrypt(data, keyUtf8, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Combine IV and encrypted data
    const encryptedData = iv
      .concat(encrypted.ciphertext)
      .toString(CryptoJS.enc.Base64);

    return encryptedData;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    try {
      // Fetch encryption key
      const { sessionId, key } = await fetchEncryptionKey();

      // Prepare encrypted data
      const encryptedData = {
        SessionId: sessionId || "", // Ensure default empty string if sessionId is undefined
        phoneNumber: encryptData(username, key) || "", // Ensure data is encrypted properly
        otp: encryptData(otp, key) || "",
        username: encryptData(username, key) || "",
        password: encryptData(password, key) || "",
        OtpSessionId: encryptData(OtpSessionId, key) || "",
      };

      let response;
      if (isOtpLogin) {
        // For OTP login
        response = await apiService.postAPI(
          encryptedData,
          EnvVariables.otpLoginCall
        );
      } else {
        // For password login
        response = await apiService.postAPI(
          encryptedData,
          EnvVariables.passwordLoginCall
        );
      }

      // Handle API response
      if (response.data.IsError) {
        showNotification(response.data.EData[0], "error");
      } else {
        await login(response.data.RData.InputDTO["Token"]);
        showNotification("Login successful!", "success");
        navigate("/");
      }
    } catch (error) {
      console.error("Login failed:", error);
      showNotification("Login failed. Please try again.", "error");
    } finally {
      setOtp("");
      setUserPhone("");
      setUserEmail("");
      setPassword("");

      hideLoader(); // Hide loader regardless of success or failure
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    if (!validateOtp(otp)) {
      showNotification("Please enter a valid 6-digit OTP.", "error");
      return;
    }
    if (isemail === false) {
      if (!validateEmail(userEmail)) {
        showNotification("Please enter a valid email.", "error");
        return;
      }
    } else {
      if (!validatePhoneNumber(userPhone)) {
        showNotification(
          "Please enter a valid 10 digit phone number.",
          "error"
        );
        return;
      }
    }

    if (!selectedLocation) {
      showNotification("Please enter a location.", "error");
      return;
    }
    // if (!selectedArea) {
    //   showNotification("Please select an area.", "error");
    //   return;
    // }

    showLoader();
    try {
      const { sessionId, key } = await fetchEncryptionKey();

      const encryptedData = {
        SessionId: sessionId,
        OtpSessionId: encryptData(OtpSessionId, key),
        phoneNumber: encryptData(phoneNumber, key),
        userPhone: encryptData(userPhone, key),
        otp: encryptData(otp, key),
        username: encryptData(username, key),
        userEmail: encryptData(userEmail, key),
        latitude: parseFloat(selectedLocation?.latitude),
        longitude: parseFloat(selectedLocation?.longitude),
        AreaNames: selectedArea.toString(),
      };

      const response = await apiService.postAPI(
        encryptedData,
        EnvVariables.signupCall
      );
      if (response.data.IsError) {
        //showNotification("Signup failed. Please try again.", "error");
        showNotification(response.data.EData[0], "error");
      } else {
        showNotification(
          "Signup successful! Your credentials have been sent to your email and phone number.",
          "success"
        );

        loginview();
      }
    } catch (error) {
      setOtp("");
      showNotification("Signup failed. Please try again.", "error");
    } finally {
      setOtp("");
      setUserPhone("");
      setUserEmail("");
      setPassword("");
      hideLoader();
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = `${position.coords.latitude},${position.coords.longitude}`;
          setSelectedLocation(coords);
        },
        (error) => {
          showNotification("Unable to retrieve your location.", "error");
        }
      );
    } else {
      showNotification(
        "Geolocation is not supported by this browser.",
        "error"
      );
    }
  };
  const loginview = () => {
    setIsSignup(false);
    setIsOtpLogin(false);
    setisOtpBased(false);
    setUsername(""); // Reset the username field
    setPassword(""); // Reset the password field
    setPhoneNumber(""); // Reset the phone number field
    setOtp(""); // Reset the OTP field
    setUserEmail("");
    setSelectedLocation("");
  };
  const handleToggleSignup = () => {
    setIsSignup(!isSignup); // Toggle between login and signup forms
    setUsername(""); // Reset username field when toggling
    setPassword(""); // Reset password field when toggling
    setPhoneNumber(""); // Reset phone number field when toggling
    setOtp(""); // Reset OTP field when toggling
    setUserEmail("");
    setSelectedLocation(""); // Reset selected location when toggling
    // Reset use current location checkbox when toggling
    setShowSignupInputs(false); // Hide signup inputs when toggling
    setIsOtpLogin(false); // Reset OTP login mode when toggling
  };

  const handleToggleLoginMethod = () => {
    setIsOtpLogin(!isOtpLogin); // Toggle between OTP and password login
    setShowSignupInputs(false); // Hide signup inputs when toggling login methods
    setIsSignup(false); // Reset to login mode when switching login methods
    setisOtpBased(true);
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber); // Validate 10-digit phone number
  };

  const validatePhoneOrEmail = (phoneOrEmail) => {
    // Regular expression for validating a 10-digit phone number
    const phoneRegex = /^\d{10}$/;

    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input matches the phone number regex
    if (phoneRegex.test(phoneOrEmail)) {
      setIsemail(false);
      return { isValid: true, errorMessage: "" };
    }

    // Check if the input matches the email regex
    if (emailRegex.test(phoneOrEmail)) {
      setIsemail(true);
      return { isValid: true, errorMessage: "" };
    }

    // Determine if the input looks more like a phone number or email address
    const isNumeric = /^\d+$/.test(phoneOrEmail);
    if (isNumeric) {
      setIsemail(false);
      return {
        isValid: false,
        errorMessage: "Please enter a valid 10-digit phone number.",
      };
    } else {
      setIsemail(true);
      return {
        isValid: false,
        errorMessage: "Please enter a valid email address.",
      };
    }
  };

  const validateOtp = (otp) => {
    return /^\d{6}$/.test(otp); // Validate 6-digit OTP
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleGetOtp = async () => {
    const validationResult = validatePhoneOrEmail(phoneNumber);
    if (!validationResult.isValid) {
      showNotification(validationResult.errorMessage, "error");
      return;
    }
    showLoader();
    try {
      const { sessionId, key } = await fetchEncryptionKey();

      const encryptedData = {
        SessionId: sessionId,
        PhoneNumber: encryptData(phoneNumber, key),
      };

      let response;
      if (isSignup) {
        response = await apiService.postAPI(
          encryptedData,
          EnvVariables.getSignupOtpCall
        );
      } else {
        response = await apiService.postAPI(
          encryptedData,
          EnvVariables.getLoginOtpCall
        );
      }

      if (response.data.IsError == false) {
        setOtpSessionId(response.data.RData.SessionId);
        showNotification("OTP sent successfully!", "success");

        if (isSignup) {
          setShowSignupInputs(true); // Show signup inputs after OTP is sent
        } else {
          setisOtpBased(false);
          setIsOtpLogin(true);
        }
      } else {
        showNotification(response.data.EData[0], "error");
      }
    } catch (error) {
      showNotification("Failed to get OTP. Please try again.", "error");
    } finally {
      hideLoader();
    }
  };

  const handleAcceptTerms = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const togglePrivacyModal = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };
  const handleLocationChange = (location) => {
    setSelectedLocation(location);

    if (location && location.value) {
      const { lat, lon } = location.value;
      setLatitude(lat);
      setLongitude(lon);
    }
  };
  return (
    <>
      <div className="auth-container">
        <div className="auth-form">
          <div className="loginlogo">
            <img src={loginLogo} alt="Logo" />
          </div>
          {/* <h2>{isSignup ? 'Signup' : 'Login'}</h2> */}
          <h2> </h2>
          {!isSignup ? (
            <>
              {!isOtpLogin ? (
                <>
                  <label>Phone No/Email</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label>Password:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button className="auth-button" onClick={handleLoginSubmit}>
                    Login
                  </button>
                  <div
                    className="toggle-link"
                    onClick={handleToggleLoginMethod}
                  >
                    Login with OTP instead
                  </div>
                  <div className="toggle-link" onClick={handleToggleSignup}>
                    Don’t have an account?{" "}
                    <span className="signup-link">Signup</span>
                  </div>
                </>
              ) : (
                <>
                  {isOtpBased ? (
                    <>
                      <label>
                        Mobile Number or Email Id to receive an OTP for login
                      </label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <button className="auth-button" onClick={handleGetOtp}>
                        Get OTP
                      </button>{" "}
                    </>
                  ) : (
                    <>
                      <label>OTP:</label>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />

                      <button
                        className="auth-button"
                        onClick={handleLoginSubmit}
                      >
                        Login
                      </button>
                    </>
                  )}
                  <div
                    className="toggle-link"
                    onClick={handleToggleLoginMethod}
                  >
                    Login with password instead
                  </div>
                  <div className="toggle-link" onClick={handleToggleSignup}>
                    Don’t have an account?{" "}
                    <span className="signup-link">Signup</span>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {!showSignupInputs ? (
                <>
                  <label>
                    Mobile Number or Email Id to receive an OTP for signup
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <button className="auth-button" onClick={handleGetOtp}>
                    Get OTP
                  </button>

                  <div className="toggle-link" onClick={handleToggleSignup}>
                    Already have an account?{" "}
                    <span className="login-link">Login</span>
                  </div>
                </>
              ) : (
                <>
                  <label>OTP:</label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  {!isemail ? (
                    <div>
                      <label>Email:</label>
                      <input
                        type="text"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        placeholder="Enter Email Id"
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Phone:</label>
                      <input
                        type="text"
                        value={userPhone}
                        onChange={(e) => setUserPhone(e.target.value)}
                        placeholder="Enter Phone Number"
                      />
                    </div>
                  )}

                  {/* <div className="curre-container"> */}

                  <LocationSelect
                    className="locationbutton"
                    onLocationChange={handleLocationChange}
                    selectedLocation={selectedLocation}
                    showExtraFields={false}
                  />
                  {/* <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Areas</InputLabel>
                      <Select
                        value={selectedArea || ""} // Default to an empty string if no area is selected
                        onChange={(e) => setSelectedArea(e.target.value)} // Update selected area
                        renderValue={(selected) => {
                          const selectedAreaObject = addFilteredAreas.find(
                            (area) => area.AreaId === selected
                          );
                          return selectedAreaObject
                            ? selectedAreaObject.AreaName
                            : "";
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 300,
                              display: "flex",
                              flexDirection: "column",
                              padding: 0,
                            },
                          },
                        }}
                      >
                        <MenuItem disableRipple>
                          <TextField
                            placeholder="Search Areas"
                            value={addAreaSearch} // Handle area search input
                            onChange={(e) => setAddAreaSearch(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{ marginBottom: 1 }}
                            onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                          />
                        </MenuItem>
                        {addFilteredAreas.length === 0 ? (
                          <MenuItem disabled>No areas found</MenuItem>
                        ) : (
                          addFilteredAreas.map((area) => (
                            <MenuItem key={area.AreaId} value={area.AreaId}>
                              {area.AreaName}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <div className="terms-container">
                    <div className="terms-checkbox">
                      <input
                        type="checkbox"
                        checked={acceptedTerms}
                        onChange={handleAcceptTerms}
                        id="terms-checkbox"
                      />
                    </div>
                    <div className="terms-label">
                      <label htmlFor="terms-checkbox">
                        I accept the
                        <span className="terms-link" onClick={toggleTermsModal}>
                          Terms and Conditions
                        </span>
                        and
                        <span
                          className="terms-link"
                          onClick={togglePrivacyModal}
                        >
                          Privacy Policy
                        </span>
                      </label>
                    </div>
                  </div>
                  <button
                    className="auth-button"
                    onClick={handleSignupSubmit}
                    disabled={!acceptedTerms}
                  >
                    Signup
                  </button>
                  <div className="toggle-link" onClick={handleToggleSignup}>
                    Already have an account?{" "}
                    <span className="login-link">Login</span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* Terms Modal */}
      <CustomModal
        isOpen={showTermsModal}
        onRequestClose={toggleTermsModal}
        style={customStyles}
        onClose={() => setShowTermsModal(false)}
        contentLabel="Terms and Conditions"
        title="Terms and Conditions"
      >
        <TermsContent />
      </CustomModal>

      <CustomModal
        isOpen={showPrivacyModal}
        onRequestClose={togglePrivacyModal}
        style={customStyles}
        onClose={() => setShowPrivacyModal(false)}
        contentLabel="Privacy Policy"
        title="Privacy Policy"
      >
        <PrivacyContent />
      </CustomModal>
    </>
  );
};

export default LoginComponent;
