import React from 'react';
import PropTypes from 'prop-types';
import { Box, Select, MenuItem, Button, Typography, Grid } from '@mui/material';

const Pagination = ({ totalRecords, rowsPerPage, currentPage, onPageChange, onRowsPerPageChange }) => {
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Box mt={2} p={2} display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" mr={2}>Select Page Size:</Typography>
        <Select 
          value={rowsPerPage} 
          onChange={(e) => onRowsPerPageChange(parseInt(e.target.value, 10))}
          variant="outlined"
          size="small"
        >
          {[5, 10, 25, 50].map((rows) => (
            <MenuItem key={rows} value={rows}>{rows}</MenuItem>
          ))}
        </Select>
      </Box>
      <Typography variant="body1">Page No: {currentPage} of {totalPages}</Typography>
      <Box display="flex" alignItems="center">
        <Button 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1} 
          variant="contained" 
          color="primary"
          size="small"
          sx={{ mr: 1 }}
        >
          Previous
        </Button>
        <Button 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages} 
          variant="contained" 
          color="primary"
          size="small"
        >
          Next
        </Button>
      </Box>
      <Typography variant="body1">Total Records: {totalRecords}</Typography>
    </Box>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

export default Pagination;
