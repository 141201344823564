import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Grid,
  Paper,
  InputAdornment,
  Autocomplete,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { userroles } from "../servicecomponent/commondataclassfiles/commonarrays";
const FormManagement = () => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();

  const [forms, setForms] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleFormMapping, setRoleFormMapping] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [newMapping, setNewMapping] = useState({
    FormId: [],
    SystemRole: "",
    selectedForms: [],
  });

  useEffect(() => {
    fetchData();
  }, []);
  // const fetchData = async () => {
  //   try {
  //     showLoader();
  //     const [formsResponse, roleFormMappingResponse] = await Promise.all([
  //       getForms(),
  //       getRoleFormMapping(),
  //     ]);
  //     fetchRoles();

  //     // Create a map from FormId to FormTitle
  //     const formsMap = new Map(
  //       formsResponse.map((form) => [form.FormId, form.FormTitle])
  //     );

  //     // Process roleFormMappingResponse
  //     const updatedMappings = roleFormMappingResponse.map((item) => {
  //       // Handle comma-separated FormTitle
  //       const formTitles = item.FormTitle.split(",").map((title) =>
  //         title.trim()
  //       );

  //       // Find FormIds based on formTitles
  //       const formIds = formsResponse
  //         .filter((form) => formTitles.includes(form.FormTitle))
  //         .map((form) => form.FormId);

  //       // Prepare the display titles
  //       const displayTitles = formTitles.join(", ");

  //       return {
  //         ...item,
  //         FormIds: formIds,
  //         FormTitles: displayTitles || "N/A", // Fallback to 'N/A' if empty
  //       };
  //     });

  //     setForms(formsResponse);
  //     setRoleFormMapping(updatedMappings);
  //   } catch (error) {
  //     console.error("Error fetching data", error);
  //     showNotification("Error fetching data", "error");
  //   } finally {
  //     hideLoader();
  //   }
  // };
  const fetchData = async () => {
    try {
      showLoader();

      // Fetch forms data
      const formsResponse = await getForms();

      // Fetch role form mapping data
      const roleFormMappingResponse = await getRoleFormMapping();

      // Fetch roles
      await fetchRoles();

      // Create a map from FormId to FormTitle
      const formsMap = new Map(
        formsResponse.map((form) => [form.FormId, form.FormTitle])
      );

      // Process roleFormMappingResponse
      const updatedMappings = roleFormMappingResponse.map((item) => {
        // Handle comma-separated FormTitle
        const formTitles = item.FormTitle.split(",").map((title) =>
          title.trim()
        );

        // Find FormIds based on formTitles
        const formIds = formsResponse
          .filter((form) => formTitles.includes(form.FormTitle))
          .map((form) => form.FormId);

        // Prepare the display titles
        const displayTitles = formTitles.join(", ");

        return {
          ...item,
          FormIds: formIds,
          FormTitles: displayTitles || "N/A", // Fallback to 'N/A' if empty
        };
      });

      setForms(formsResponse);
      setRoleFormMapping(updatedMappings);
    } catch (error) {
      console.error("Error fetching data", error);
      showNotification("Error fetching data", "error");
    } finally {
      hideLoader();
    }
  };

  const fetchRoles = () => {
    setRoles(userroles);
  };

  const addRoleFormMapping = async () => {
    if (newMapping.FormId.length === 0) {
      showNotification("Please select at least one form.", "error");
      return;
    }

    const commaSeparatedFormIds = newMapping.FormId.join(",");
    const dataToSend = {
      FormIds: commaSeparatedFormIds,
      SystemRole: newMapping.SystemRole,
    };

    try {
      showLoader();
      const response = await apiService.postAPI(
        dataToSend,
        EnvVariables.Insert_Form_Mapping
      );

      if (response.data.IsError) {
        showNotification(response.data.EData[0], "error");
      } else {
        fetchData();
        showNotification("Mapping added successfully", "success");
      }
    } catch (error) {
      console.error("Error adding mapping", error);
      showNotification("Error adding mapping", "error");
    } finally {
      hideLoader();
    }
  };

  const getRoleFormMapping = async () => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        {},
        EnvVariables.Get_Form_Role_Mapping
      );
      console.log("getRoleFormMapping data", response.data.RDatalist);
      return response.data.RDatalist || [];
    } catch (error) {
      console.error("Error loading role-form mappings:", error);
      showNotification("Error loading role-form mappings", "error");
      return [];
    } finally {
      hideLoader();
    }
  };

  const getForms = async () => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        {},
        EnvVariables.Get_Form_for_Mapping
      );
      console.log("getForms data", response.data.RDatalist);
      return response.data.RDatalist || [];
    } catch (error) {
      console.error("Error loading forms:", error);
      showNotification("Error loading forms", "error");
      return [];
    } finally {
      hideLoader();
    }
  };
  const handleSaveClick = (mappingId, updatedMapping) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Area",
      message: "Are you sure you want update this form mapping?",
      onConfirm: async () => {
        try {
          saveEdit(mappingId, updatedMapping);
        } catch (error) {
          showNotification("Error submitting the form", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const saveEdit = async (mappingId, updatedMapping) => {
    try {
      showLoader();

      // Convert FormIds array to a comma-separated string
      const commaSeparatedFormIds = updatedMapping.FormIds.join(",");

      // Create the object to send to the API
      const formsupdate = {
        RoleFormMappingId: parseInt(mappingId),
        FormIds: commaSeparatedFormIds,
      };

      // Call the API with the updated mapping
      const response = await apiService.postAPI(
        formsupdate,
        EnvVariables.Update_Form_Mapping
      );

      if (response.data.IsError) {
        showNotification(response.data.EData[0], "error");
      } else {
        fetchData();
        showNotification("Mapping updated successfully", "success");
      }
    } catch (error) {
      console.error("Error updating mapping", error);
      showNotification("Error updating mapping", "error");
    } finally {
      hideLoader();
      setEditingRowId(null); // Exit edit mode
    }
  };

  const handleEditClick = (mappingId) => {
    setEditingRowId(mappingId);
  };

  const handleCancelClick = () => {
    setEditingRowId(null); // Exit edit mode without saving
  };

  const handleRoleChange = (mappingId, event, value) => {
    setRoleFormMapping(
      roleFormMapping.map((mapping) =>
        mapping.RoleFormMappingId === mappingId
          ? { ...mapping, SystemRole: value || "" }
          : mapping
      )
    );
  };

  const handleFormsChange = (mappingId, event, value) => {
    setRoleFormMapping(
      roleFormMapping.map((mapping) =>
        mapping.RoleFormMappingId === mappingId
          ? { ...mapping, FormIds: value.map((form) => form.FormId) }
          : mapping
      )
    );
  };

  const filteredMappings = roleFormMapping.filter(
    (mapping) =>
      forms
        .filter((form) => mapping.FormIds.includes(form.FormId))
        .some((form) =>
          form.FormTitle.toLowerCase().includes(searchText.toLowerCase())
        ) || mapping.SystemRole.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box component={Paper} p={2}>
          <Typography variant="h6" gutterBottom>
            Role Form Mapping
          </Typography>
          <Box mb={2}>
            <Autocomplete
              multiple
              options={forms} // Ensure 'forms' is an array
              getOptionLabel={(option) => option.FormTitle || ""}
              value={forms.filter((form) =>
                newMapping.FormId.includes(form.FormId)
              )}
              onChange={(event, value) =>
                setNewMapping({
                  ...newMapping,
                  FormId: value.map((form) => form.FormId),
                  selectedForms: value.map((form) => ({
                    FormId: form.FormId,
                    FormTitle: form.FormTitle,
                  })),
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Forms"
                  placeholder="Search and select forms"
                  fullWidth
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Autocomplete
              options={roles}
              getOptionLabel={(option) => option || ""}
              value={newMapping.SystemRole || ""} // Directly use the role string
              onChange={(event, value) =>
                setNewMapping({
                  ...newMapping,
                  SystemRole: value || "",
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select System Role"
                  placeholder="Search and select a role"
                  fullWidth
                />
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={addRoleFormMapping}
            disabled={!newMapping.FormId.length || !newMapping.SystemRole}
          >
            Add Mapping
          </Button>
        </Box>
      </Grid>
      {/* Search Section */}
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <TextField
            label="Search"
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>

      {/* Table Section */}
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Form Title</TableCell>
                  <TableCell>System Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMappings.map((mapping) => (
                  <TableRow key={mapping.RoleFormMappingId}>
                    <TableCell>
                      {editingRowId === mapping.RoleFormMappingId ? (
                        <Autocomplete
                          multiple
                          options={forms}
                          getOptionLabel={(option) => option.FormTitle || ""}
                          value={forms.filter((form) =>
                            mapping.FormIds.includes(form.FormId)
                          )}
                          onChange={(event, value) =>
                            handleFormsChange(
                              mapping.RoleFormMappingId,
                              event,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Forms"
                              placeholder="Search and select forms"
                              fullWidth
                            />
                          )}
                        />
                      ) : (
                        mapping.FormTitles || "N/A" // Display comma-separated FormTitles
                      )}
                    </TableCell>
                    <TableCell>
                      {editingRowId === mapping.RoleFormMappingId ? (
                        <Autocomplete
                          options={roles}
                          getOptionLabel={(option) => option || ""}
                          value={mapping.SystemRole || ""}
                          onChange={(event, value) =>
                            handleRoleChange(
                              mapping.RoleFormMappingId,
                              event,
                              value
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select System Role"
                              placeholder="Search and select a role"
                              fullWidth
                            />
                          )}
                        />
                      ) : (
                        mapping.SystemRole || "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {editingRowId === mapping.RoleFormMappingId ? (
                        <>
                          <IconButton
                            onClick={() =>
                              handleSaveClick(mapping.RoleFormMappingId, {
                                FormIds: mapping.FormIds,
                                SystemRole: mapping.SystemRole,
                              })
                            }
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton onClick={handleCancelClick}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            onClick={() =>
                              handleEditClick(mapping.RoleFormMappingId)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              setConfirmDialog({
                                open: true,
                                onConfirm: async () => {
                                  // Handle deletion logic here
                                },
                              })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormManagement;
