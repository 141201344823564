import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { useSwipeable } from "react-swipeable";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { useAuth } from "../AuthContext";
import Verticalaccordiandisplay from "./verticalaccordiandisplay";
const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
const CategorySection = ({ setCategoryId }) => {
  const { apiService } = useDI();
  const { updateSearchInput, businesstype } = useAuth();
  const [categories, setCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState(
    JSON.parse(localStorage.getItem("selectedcategory")) || null
  );
  const [searchText, setSearchText] = useState(
    localStorage.getItem("searchText") || ""
  );
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [itemWidth, setItemWidth] = useState(100); // Default width of category items
  const [placeholderText, setPlaceholderText] = useState("");
  useEffect(() => {
    setPlaceholderText(
      `Search ${businesstype === 2 ? "complaints" : "Technician"} in ${
        searchCategory?.CategoryName || "selected category"
      }`
    );
  }, [businesstype]); // Correctly using businesstype as a dependency

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiService.postAPIwithoutdata(
          EnvVariables.Get_Item_Category_display
        );
        setCategories(response.data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [apiService]);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (containerRef.current) {
        setScrollPosition(containerRef.current.scrollLeft);
      }
    };

    updateScrollPosition();
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", updateScrollPosition);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          "scroll",
          updateScrollPosition
        );
      }
    };
  }, []);

  const handleCategoryClick = (category) => {
    setCategoryId(category.CategoryId);
    localStorage.setItem("selectedcategory", JSON.stringify(category));
    setSearchCategory(category);
    updateSearchInput("");
  };

  const handleCategoryDeselect = () => {
    localStorage.removeItem("selectedcategory");
    localStorage.removeItem("searchText");
    setSearchCategory(null);
    setCategoryId(null);
    setSearchText("");
    updateSearchInput("");
  };

  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    localStorage.setItem("searchText", text);
    updateSearchInput(text);
    // Call your search logic here
    console.log("Searching for:", text);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      containerRef.current?.scrollBy({
        left: itemWidth,
        behavior: "smooth",
      }),
    onSwipedRight: () =>
      containerRef.current?.scrollBy({
        left: -itemWidth,
        behavior: "smooth",
      }),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const newScrollPosition =
        direction === "left"
          ? containerRef.current.scrollLeft - itemWidth
          : containerRef.current.scrollLeft + itemWidth;

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const maxScrollReached = containerRef.current
    ? scrollPosition >=
      containerRef.current.scrollWidth - containerRef.current.offsetWidth
    : true;

  return (
    <>
      <Box>
        <Verticalaccordiandisplay></Verticalaccordiandisplay>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="1rem"
        position="relative"
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          mb: 4,
          overflow: "hidden",
          paddingLeft: { xs: "0", sm: "48px" }, // Hide arrows on mobile
          paddingRight: { xs: "0", sm: "48px" }, // Hide arrows on mobile
        }}
      >
        <Box display="flex" alignItems="center" width="100%">
          {!isTouchDevice() && (
            <IconButton
              onClick={() => handleScroll("left")}
              disabled={scrollPosition <= 0}
              sx={{
                color: scrollPosition <= 0 ? "#ccc" : "primary.main",
                zIndex: 1,
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                display: { xs: "none", sm: "flex" }, // Hide arrows on mobile
                alignItems: "center",
                justifyContent: "center",
                marginRight: "8px",
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <Box
            display="flex"
            overflow="auto"
            ref={containerRef}
            {...(isTouchDevice() ? swipeHandlers : {})}
            sx={{
              scrollSnapType: "x mandatory",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              flexGrow: 1,
            }}
          >
            {categories.map((category) => (
              <Box
                key={category.CategoryId}
                flex="0 0 auto"
                padding="0 0.5rem"
                textAlign="center"
                onClick={() => handleCategoryClick(category)}
                sx={{
                  cursor: "pointer",
                  scrollSnapAlign: "start",
                  width: `${itemWidth}px`, // Set dynamic width
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Box
                  component="img"
                  src={`${EnvVariables.BaseUrlforImage}${category.CategoryImage}`}
                  alt={category.CategoryName}
                  sx={{
                    width: "100%",
                    height: { xs: "60px", sm: "80px" },
                    objectFit: "cover",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <Typography variant="body2" color="textPrimary" sx={{ mt: 1 }}>
                  {category.CategoryName}
                </Typography>
              </Box>
            ))}
          </Box>
          {!isTouchDevice() && (
            <IconButton
              onClick={() => handleScroll("right")}
              disabled={maxScrollReached}
              sx={{
                color: maxScrollReached ? "#ccc" : "primary.main",
                zIndex: 1,
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                display: { xs: "none", sm: "flex" }, // Hide arrows on mobile
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "8px",
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
        {searchCategory && (
          <Box
            mt={2}
            width="100%"
            maxWidth="1200px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              padding: "8px",
            }}
          >
            <TextField
              fullWidth
              value={searchText}
              onChange={handleSearchChange}
              placeholder={placeholderText}
              InputProps={{
                startAdornment: searchCategory.CategoryName && (
                  <InputAdornment position="start">
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {searchCategory.CategoryName}
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCategoryDeselect}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "8px",
                  backgroundColor: "white",
                  paddingRight: "8px",
                  "& .MuiInputBase-input": {
                    padding: "12px 0",
                  },
                },
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CategorySection;
