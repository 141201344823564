import React, { useState } from "react";
import { Button } from "@mui/material";

const useTruncateText = (initialText = "", maxLength = 20) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [text, setText] = useState(initialText);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const renderTruncatedText = () => (
    <>
      {isExpanded ? (
        <>
          {text}
          <label
            onClick={toggleIsExpanded}
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              color: "#1976d2", // Primary color (blue)
              fontWeight: "bold",
            }}
            onMouseOver={(e) => (e.target.style.color = "#0d47a1")} // Darker shade on hover
            onMouseOut={(e) => (e.target.style.color = "#1976d2")} // Reset to original color
          >
            See less
          </label>
        </>
      ) : (
        <>
          {truncateText(text, maxLength)}
          {text.length > maxLength && (
            <label
              onClick={toggleIsExpanded}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                color: "#1976d2", // Primary color (blue)
                fontWeight: "bold",
              }}
              onMouseOver={(e) => (e.target.style.color = "#0d47a1")} // Darker shade on hover
              onMouseOut={(e) => (e.target.style.color = "#1976d2")} // Reset to original color
            >
              See more
            </label>
          )}
        </>
      )}
    </>
  );

  return {
    setText,
    renderTruncatedText,
  };
};

export default useTruncateText;
