import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Button,
  TextField,
  Avatar,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import LocationSelect from "../servicecomponent/translatecode/LocationSelect";
import { useCart } from "../hooks/CartContext";
import RestructionChargesPage from "./RestrictionChargesPage";
import {
  expertiseTypes,
  businessTypes,
} from "../servicecomponent/commondataclassfiles/commonarrays";
const ProfilePage = () => {
  const [tempProfileImage, setTempProfileImage] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [serviceProvider, setServiceProvider] = useState(false);
  // const [category, setCategory] = useState(null);
  // const [location, setLocation] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const baseImageUrl = EnvVariables.BaseUrlforImage;
  const { setPreviewImage, user, setUser, userestrictions } = useAuth();
  const [categoryIds, setCategoryIds] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [distance, setDistance] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [itemOptions, setItemOptions] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [expertiseType, setExpertiseType] = useState("Skilled Technician"); // Default to first value
  const [businessType, setBusinessType] = useState("Self"); // Default to 'Self'
  const [businessname, setBusinessname] = useState("");
  const {
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    selectedLocation,
    setSelectedLocation,
  } = useCart();
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);
      setConfirmDialog({
        isOpen: true,
        title: "Change Profile Image",
        message: "Are you sure you want to change your profile image?",
        onConfirm: async () => {
          try {
            showLoader();
            const response = await apiService.postAPI(
              formData,
              EnvVariables.UpdateProfileImage,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            hideLoader();
            const updatedProfileImage = response.data.Profileimg;
            setUser((prevUser) => ({
              ...prevUser,
              Profileimg: updatedProfileImage,
            }));
            showNotification("Profile image updated successfully", "success");
            setPreviewImage(`${baseImageUrl}${updatedProfileImage}`);
          } catch (error) {
            showNotification("Error updating profile image", "error");
          } finally {
            hideLoader();
            setConfirmDialog({ isOpen: false });
          }
        },
        onCancel: () => setConfirmDialog({ isOpen: false }),
      });
    }
  };

  const handleCancelImageChange = () => {
    setTempProfileImage(null);
    setOpenImageDialog(false);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showNotification("Passwords do not match!", "warning");
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Change Password",
      message: "Are you sure you want to change your password?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            { NewPassword: password },
            EnvVariables.Change_Password
          );

          if (!response.data.IsError) {
            showNotification(response.data.RDatalist[0].Msg, "success");
            setPassword("");
            setConfirmPassword("");
          } else {
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
          showNotification("Error changing password", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleDistanceUpdate = async (e) => {
    const distanceValue = parseFloat(distance);

    // Check if the parsed distance value is valid (not NaN, greater than 0)
    if (isNaN(distanceValue) || distanceValue <= 0) {
      showNotification(
        "Please enter a valid distance greater than 0 km.",
        "warning"
      );
      return;
    }

    if (userestrictions[0]?.IsRestriction === 1) {
      const searchDistanceCount = user?.SearchDistanceCount ?? 0;
      const distanceCountLimit = userestrictions[0]?.DistanceCount ?? Infinity;
      const distanceRangeLimit = userestrictions[0]?.DistanceRange ?? Infinity;

      // Check if the user has exceeded the distance change limit
      if (searchDistanceCount >= distanceCountLimit) {
        showNotification(
          "Your distance change limit has been reached. Please reset your limit to make further changes.",
          "warning"
        );
        return;
      }

      // Check if the entered distance exceeds the allowed range
      if (distanceValue > distanceRangeLimit) {
        showNotification(
          `This service is not available for distances greater than ${distanceRangeLimit} km.`,
          "warning"
        );
        return;
      }
    }

    setConfirmDialog({
      isOpen: true,
      title: "Update Distance",
      message: "Are you sure you want to change your distance?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            { SearchDistance: distanceValue },
            EnvVariables.Update_Search_Distance
          );

          if (!response.data.IsError) {
            showNotification(response.data.RDatalist[0].Msg, "success");
            setUser((prevUser) => ({
              ...prevUser,
              SearchDistanceCount: user.SearchDistanceCount + 1,
              SearchDistance: distanceValue,
            }));
            setDistance("");
          } else {
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
          showNotification("Error changing distance", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleDistanceChange = (event) => {
    const value = event.target.value; // Get the input value
    setDistance(value); // Update the distance state
  };

  const handleBecomeProvider = async () => {
    if (userestrictions[0]?.IsRestriction === 1) {
      const changeServiceCount = user?.ChangeServiceCount ?? 0; // Default to 0 if null or undefined
      const serviceCategoryCount =
        userestrictions[0]?.ServiceCategoryCount ?? Infinity; // Default to Infinity if null or undefined

      if (changeServiceCount >= serviceCategoryCount) {
        showNotification(
          "Your limit is ended, please reset your limit for services change.",
          "warning"
        );
        return;
      }
    }
    const handleSubmit = () => {
      if (!selectedCategory) {
        showNotification("Please select a category", "warning");
        return;
      } else if (!selectedSubcategories.length) {
        showNotification("Please select at least one subcategory", "warning");
        return;
      } else if (!businessType) {
        showNotification("Please select a business type", "warning");
        return;
      } else if (businessType === "Business" && !businessType) {
        showNotification(
          "Please enter a Business name for Business type",
          "warning"
        );
        return;
      } else if (!expertiseType) {
        showNotification("Please select an expertise type", "warning");
        return;
      }

      // If all validations pass, proceed with the form submission logic
      // Your form submission logic here
    };

    // Get the Category ID and selected Subcategory IDs
    const categoryId = selectedCategory;
    // Map selected subcategory names to their corresponding ItemIds
    const selectedSubcategoryIds = selectedSubcategories
      .map((subcategoryName) => {
        const matchedOption = itemOptions.find(
          (option) => option.ItemName === subcategoryName
        );
        return matchedOption ? matchedOption.ItemId : null; // Get ItemId or null if not found
      })
      .filter((id) => id !== null); // Filter out null values

    // Join selected subcategories as a comma-separated string
    const subcatId = selectedSubcategoryIds.join(",");
    const subcategoryText = selectedSubcategories.join(",");
    setConfirmDialog({
      isOpen: true,
      title: "Service providing list",
      message: "Are you sure you want to Add/update Service providing list?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            {
              TechnicianCategoryId: categoryId,
              RequirementsId: subcatId,
              RequirementsDesc: subcategoryText,
              UserType: businessType,
              Businessname: businessname,
              ExpertiseType: expertiseType,
            },
            EnvVariables.Become_Service_Provider
          );

          if (response.data.IsError === false) {
            showNotification(response.data.RDatalist[0].Msg, "success");
            setServiceProvider(true);
            setUser((prevUser) => ({
              ...prevUser,
              ChangeServiceCount: user.ChangeServiceCount + 1,
              RequirementsDesc: subcategoryText,
            }));
          } else {
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
          showNotification("Failed to become a service provider", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    clearselectedfield();
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const response = await apiService.postAPIwithoutdata(
        EnvVariables.Get_Item_Category_display
      );

      let categories = [
        { CategoryId: 0, CategoryName: "Select Category" }, // Add default option
      ];

      if (response.data && response.data.length > 0) {
        categories = [...categories, ...response.data]; // Append API categories
      } else {
        categories.push({
          CategoryId: "",
          CategoryName: "No Categories Available",
        });
      }

      setCategoryIds(categories); // Set categories including the default one
    } catch (error) {
      console.error("Error loading categories:", error);
      setCategoryIds([
        { CategoryId: 0, CategoryName: "Select Category" },
        { CategoryId: "", CategoryName: "Error Loading Categories" },
      ]);
    }
  };
  const clearselectedfield = () => {
    setSelectedCategoryName("");

    setEditingIndex(null);
    setValidationErrors({});
  };

  useEffect(() => {
    if (selectedCategory) {
      getMasterItems(); // Fetch subcategories based on the selected category
    } else {
      setItemOptions([]); // Clear subcategory options when no category is selected
    }
  }, [selectedCategory]); // Runs when the selected category changes

  const getMasterItems = async () => {
    try {
      if (selectedCategory) {
        setItemOptions([]);
        setSelectedItems([]); // Clear any selected subcategories
        setSelectedSubcategories([]);
        const response = await apiService.postAPI(
          { CategoryId: selectedCategory },
          EnvVariables.Get_Master_Item_For_Display
        );

        setItemOptions(response.data);
      }
    } catch (error) {
      setItemOptions([]);
      toast.error("Error loading items");
    }
  };
  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategory = categoryIds.find(
        (category) =>
          category.CategoryName.toLowerCase() === newValue.toLowerCase()
      );

      if (selectedCategory) {
        setSelectedCategory(selectedCategory.CategoryId || null);
      } else {
        // const newCategory = {
        //   CategoryId: categoryIds.length + 1,
        //   CategoryName: newValue,
        // };
        // setCategoryIds([...categoryIds, newCategory]);
        // setSelectedCategory(newCategory.CategoryId);
        setSelectedCategory(null);
      }
      setSelectedCategoryName(newValue);
    } else {
      setSelectedCategoryName("");
      setSelectedCategory(null);
    }
  };
  const handleCategoryBlur = (fff) => {
    // Ensure the selectedCategoryName is a string before applying toLowerCase
    const inputValue = fff ? fff.toLowerCase() : "";

    // Check if the typed value matches any category name
    const isMatchingOption = categoryIds.some(
      (category) => category.CategoryName.toLowerCase() === inputValue
    );

    // If no match is found, clear the input
    if (!isMatchingOption) {
      setSelectedCategoryName(""); // Clear the text input field
      setSelectedCategory(null); // Clear the ID as well
    }
  };

  const handleSubcategoryChange = (event, newValue) => {
    const selectedNames = newValue.map((item) => item.ItemName);
    const selectedIds = newValue.map((item) => item.ItemId);

    setSelectedSubcategories(selectedNames); // Update names
    setSelectedItemIds(selectedIds); // Update IDs
  };

  const handleSubcategoryInputChange = (event, newInputValue) => {
    if (newInputValue && itemOptions.length > 0) {
      const matchedOption = itemOptions.find(
        (option) => option && option.ItemName === newInputValue
      );

      if (matchedOption) {
        // If an option is matched, add it to selected subcategories
        setSelectedSubcategories((prev) => [...prev, matchedOption.ItemName]);
        setSelectedItemIds((prev) => [...prev, matchedOption.ItemId]); // Store ID as well
      }
    }
  };

  const handleSubcategoryClose = () => {
    // Ensure all selected items have matching options
    const validatedItems = selectedItems.filter((selectedItem) =>
      itemOptions.some(
        (option) =>
          option.ItemName.toLowerCase() === selectedItem.ItemName.toLowerCase()
      )
    );

    if (validatedItems.length !== selectedItems.length) {
      console.warn("Some selected items do not match the available options.");
      setSelectedItems(validatedItems); // Remove any non-matching selections
    }
  };

  const handleRemoveSubcategory = (indexToRemove) => {
    setSelectedSubcategories((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
    setSelectedItemIds(
      (prev) => prev.filter((_, index) => index !== indexToRemove) // Remove corresponding ID
    );
  };

  const handleClearAllSubcategories = () => {
    setSelectedSubcategories([]); // Clear all selected subcategories
    setSelectedItemIds([]); // Clear all selected IDs
  };
  const handleLocationChange = (location) => {
    setSelectedLocation(location);

    if (location && location.value) {
      const { lat, lon } = location.value;
      setLatitude(lat);
      setLongitude(lon);
    }
  };
  const handleLocationFieldChange = (field, value) => {
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };
  const handleUpdateLocation = async () => {
    if (userestrictions[0]?.IsRestriction === 1) {
      const locationUpdateCount = user?.LocationUpdateCount ?? 0; // Default to 0 if null or undefined
      const locationCount = userestrictions[0]?.LocationCount ?? Infinity; // Default to Infinity if null or undefined

      if (locationUpdateCount >= locationCount) {
        showNotification(
          "Your limit is ended, please reset your limit for location change.",
          "warning"
        );
        return;
      }
    }

    if (
      !selectedLocation ||
      !selectedLocation.formatted_address ||
      !selectedLocation.latitude ||
      !selectedLocation.longitude
    ) {
      showNotification(
        "Please select a valid location with coordinates.",
        "warning"
      );
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Update Location",
      message: "Are you sure you want to change your location?",
      onConfirm: async () => {
        try {
          showLoader();

          // Make an API call to update the location including latitude and longitude
          const response = await apiService.postAPI(
            {
              FullAddress: `Address: ${
                selectedLocation.formatted_address || "N/A"
              }, 
              Tehsil: ${selectedLocation.tehsil || "N/A"}, 
              District: ${selectedLocation.district || "N/A"}, 
              State: ${selectedLocation.state || "N/A"}, 
              Country: ${selectedLocation.country || "N/A"}, 
              Postal Code: ${selectedLocation.postal_code || "N/A"}
            `.replace(/\s*,\s*$/, ""),
              LocationAddress: selectedLocation.formatted_address || "N/A",
              State: selectedLocation.state,
              Country: selectedLocation.country,
              Tehsil: selectedLocation.tehsil,
              District: selectedLocation.district,
              PostalCode: selectedLocation.postal_code,
              Latitude: selectedLocation.latitude,
              Longitude: selectedLocation.longitude,
            },
            EnvVariables.Update_Location_API // Replace with your actual API endpoint
          );

          if (!response.data.IsError) {
            showNotification(response.data.RDatalist[0].Msg, "success");

            // Update user location in the state
            setUser((prevUser) => ({
              ...prevUser,
              // Update LocationCoordinates with latitude and longitude
              LocationUpdateCount: user.LocationUpdateCount + 1,
              Latitude: selectedLocation.latitude,
              Longitude: selectedLocation.longitude,

              // Update AddressDetails with each component displayed by name
              FullAddress: `
                Address: ${selectedLocation.formatted_address || "N/A"}, 
                Tehsil: ${selectedLocation.tehsil || "N/A"}, 
                District: ${selectedLocation.district || "N/A"}, 
                State: ${selectedLocation.state || "N/A"}, 
                Country: ${selectedLocation.country || "N/A"}, 
                Postal Code: ${selectedLocation.postal_code || "N/A"}
              `.replace(/\s*,\s*$/, ""), // Remove any trailing comma
            }));

            // Optionally clear the selectedLocation after update
            setSelectedLocation(null);
          } else {
            showNotification(response.data.RDatalist[0].Msg, "error");
          }
        } catch (error) {
          showNotification("Error updating location", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom textAlign="center">
        Profile Page
      </Typography>

      {/* Change Profile Image Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change Profile Image</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              src={
                user?.Profileimg
                  ? `${baseImageUrl}${user.Profileimg}`
                  : `${baseImageUrl}/default-profile.png`
              }
              alt="Profile"
              sx={{ width: 120, height: 120, mb: 2, cursor: "pointer" }}
              onClick={() => setOpenImageDialog(true)}
            />
            <Button variant="contained" component="label" color="primary">
              Change Profile Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Change Password Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handlePasswordChange}>
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Change Password
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Become Service Provider Accordion */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/* Change the accordion title dynamically */}
          <Typography variant="h6">
            {user && user.YesProvider === 1
              ? "Update your Services"
              : "Become a Service Provider"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Conditionally show the RequirementsDesc field */}
            {user && user.YesProvider === 1 && (
              <Grid item xs={12}>
                {/* Main Label */}
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "10px", // Space below the main heading
                    fontWeight: 800, // Bold font weight for emphasis
                    color: "#333333", // Darker shade for better readability
                    letterSpacing: "0.8px", // Increased for cleaner appearance
                    textTransform: "uppercase", // Adds a more formal look
                  }}
                >
                  Your business specification
                </Typography>
                {/* User Information Panel */}
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5", // Light background
                    padding: "16px",
                    borderRadius: "12px", // Rounded corners for a modern look
                    border: "1px solid #ddd", // Light border
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)", // Subtle shadow
                    marginBottom: "16px", // Space below panel
                  }}
                >
                  {/* UserType */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      marginBottom: "8px",
                      color: "#424242",
                    }}
                  >
                    User business type
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      color: "#555555",
                      marginBottom: "8px",
                      lineHeight: 1.6,
                    }}
                  >
                    {user.UserType || "N/A"}
                  </Typography>

                  {/* Businessname (only if UserType is 'Business') */}
                  {user.UserType === "Business" && (
                    <>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          marginBottom: "8px",
                          color: "#424242",
                        }}
                      >
                        Business Name
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1rem",
                          color: "#555555",
                          marginBottom: "8px",
                          lineHeight: 1.6,
                        }}
                      >
                        {user.Businessname || "No business name provided."}
                      </Typography>
                    </>
                  )}

                  {/* ExpertiseType */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      marginBottom: "8px",
                      color: "#424242",
                    }}
                  >
                    Expertise Type
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      color: "#555555",
                      lineHeight: 1.6,
                    }}
                  >
                    {user.ExpertiseType || "No expertise provided."}
                  </Typography>
                </Box>
                {/* Service Requirements Section */}
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5", // Light grey background for a clean look
                    padding: "16px", // Generous padding for space
                    borderRadius: "12px", // Rounded corners for a modern look
                    border: "1px solid #ddd", // Light border for definition
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)", // Soft shadow for depth
                    marginBottom: "16px", // Space between sections
                  }}
                >
                  {/* Heading/Label for Requirements Description */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600, // Bold to emphasize the title
                      marginBottom: "8px", // Space below the heading
                      color: "#424242", // Darker color for contrast
                    }}
                  >
                    Your taken Services
                  </Typography>

                  {/* Actual Requirements Description */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem", // Standard body font size
                      color: "#555555", // Softer text color for readability
                      fontWeight: 500, // Medium weight for a polished feel
                      marginBottom: "8px", // Space between lines
                      lineHeight: 1.6, // Optimal line spacing for longer text
                      wordBreak: "break-word", // Prevent text overflow
                    }}
                  >
                    {user.RequirementsDesc ||
                      "No service requirements provided."}
                  </Typography>
                </Box>

                {/* Service Change Count Section */}
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5", // Same style for consistency
                    padding: "16px",
                    borderRadius: "12px",
                    border: "1px solid #ddd",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  {/* Heading for Change Service Count */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600, // Emphasized heading
                      marginBottom: "8px",
                      color: "#424242", // Darker color for clarity
                    }}
                  >
                    Services Change Count
                  </Typography>

                  {/* Actual Service Change Count */}
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.95rem", // Slightly smaller for secondary text
                      color: "#555555",
                      fontWeight: 400,
                      lineHeight: 1.5,
                    }}
                  >
                    {"Services change count: "} {user.ChangeServiceCount || "0"}
                  </Typography>
                </Box>
              </Grid>
            )}

            {/* Complaint Category Autocomplete */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={businessTypes}
                value={businessType}
                onChange={(event, newValue) => {
                  setBusinessType(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business Type"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* Conditional Shop Name Text Field */}
            {businessType === "Business" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Business Name"
                  variant="outlined"
                  value={businessname}
                  onChange={(e) => setBusinessname(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={expertiseTypes}
                value={expertiseType}
                onChange={(event, newValue) => {
                  setExpertiseType(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Expertise Type"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={editingIndex !== null}
                freeSolo
                options={categoryIds.map((category) => category.CategoryName)}
                value={selectedCategoryName || ""}
                onChange={(event, newValue) => {
                  setSelectedCategoryName(newValue || ""); // Ensure the value is set correctly
                  handleCategoryChange(event, newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setSelectedCategoryName(newInputValue); // Updates when typing
                }}
                onBlur={() => handleCategoryBlur(selectedCategoryName)} // Handles blur event
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Complaint Category"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* Show clear button if user types a custom value */}
                          {selectedCategoryName &&
                            !categoryIds.some(
                              (category) =>
                                category.CategoryName === selectedCategoryName
                            ) && (
                              <IconButton
                                onClick={() => {
                                  setSelectedCategoryName(""); // Clear the input field
                                  setSelectedCategory(""); // Reset the selected category
                                }}
                              >
                                <ClearIcon /> {/* Add clear icon here */}
                              </IconButton>
                            )}
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={itemOptions}
                getOptionLabel={(option) => option.ItemName} // Specify how to access the label
                value={
                  selectedSubcategories.map((name) =>
                    itemOptions.find((option) => option.ItemName === name)
                  ) || []
                }
                onChange={handleSubcategoryChange}
                onInputChange={handleSubcategoryInputChange}
                renderTags={(selectedItems, getTagProps) =>
                  selectedItems.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    const { key, ...chipProps } = tagProps; // Destructure to get key and other props

                    return (
                      <Chip
                        key={option.ItemId} // Use ItemId as the key
                        label={option.ItemName} // Assuming option contains ItemName
                        onDelete={() =>
                          handleRemoveSubcategory(
                            selectedSubcategories.indexOf(option.ItemName)
                          )
                        }
                        deleteIcon={<ClearIcon />}
                        {...chipProps} // Spread the rest of the props except key
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Complaint Subcategory"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectedSubcategories.length > 0 && (
                            <IconButton onClick={handleClearAllSubcategories}>
                              {/* Clear icon or text can be added here */}
                            </IconButton>
                          )}
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {/* Become a Provider Button */}
            <Grid item xs={12}>
              <Button
                onClick={handleBecomeProvider}
                variant="contained"
                color="primary"
                fullWidth
              >
                {user && user.YesProvider === 1
                  ? "Update your Services"
                  : "Become a Provider"}
              </Button>
            </Grid>
          </Grid>
          <Grid mt={4}>
            {user && user.YesProvider === 1 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "10px",
                    fontWeight: "bold",
                    color: "#424242",
                    letterSpacing: "0.5px",
                  }}
                >
                  Your Allocated Distance
                </Typography>
                <Box
                  sx={{
                    mb: 2,
                    backgroundColor: "#fff3e0", // Light peach background
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0", // Light border
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow
                    padding: "12px 16px", // Padding for content
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 1.8, // Increased line height for readability
                      fontSize: "1.2rem", // Consistent font size
                      color: "#d76b30", // Darker orange for contrast
                      fontWeight: 600, // Bold text
                    }}
                  >
                    {user.SearchDistance + " Km" || "Not specified"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 1.8, // Increased line height for readability
                      fontSize: "1.2rem", // Consistent font size
                      color: "#d76b30", // Darker orange for contrast
                      fontWeight: 600, // Bold text
                    }}
                  >
                    {"Distance change count : "}{" "}
                    {user.SearchDistanceCount || "0"}
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  label="Update Distance (in km)"
                  variant="outlined"
                  value={distance}
                  onChange={handleDistanceChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 }, // Prevent negative numbers
                  }}
                  sx={{ mb: 2 }} // Space between text field and button
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth // Make button full width for a cleaner look
                  onClick={handleDistanceUpdate} // Function to handle distance update
                >
                  Update Distance
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Location Change Accordion */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change Location</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Current Location Field */}
            <Grid item xs={12}>
              {/* Main Label */}
              <Typography
                variant="h6"
                sx={{
                  marginBottom: "5px", // More space between label and content
                  fontWeight: "bold",
                  color: "#424242", // A darker color for better contrast
                  letterSpacing: "0.5px", // Slight letter spacing for a clean look
                }}
              >
                Your current location and Coordinates
              </Typography>

              {/* Service Requirements Description */}
              <Box
                sx={{
                  backgroundColor: "#fff3e0",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#d76b30",
                    fontWeight: 600,
                    marginBottom: "8px",
                  }}
                >
                  Current Location
                </Typography>

                {user ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 1.6,
                        fontSize: "1.2rem",
                        color: "#424242",
                        marginBottom: "4px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {user.FullAddress || "No current location."}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 1.6,
                        fontSize: "1.2rem",
                        color: "#424242",
                        marginBottom: "4px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {"Location change count : "}{" "}
                      {user.LocationUpdateCount || "0"}
                    </Typography>

                    {user.Latitude !== null && user.Longitude !== null ? (
                      <Typography
                        variant="body1"
                        sx={{
                          lineHeight: 1.6,
                          fontSize: "1.2rem",
                          color: "#424242",
                          letterSpacing: "0.5px",
                        }}
                      >
                        Coordinates: {user.Latitude}, {user.Longitude}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          lineHeight: 1.6,
                          fontSize: "1rem",
                          color: "#424242",
                        }}
                      >
                        No current Coordinates.
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body1" sx={{ color: "#424242" }}>
                    Loading user information...
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Editable Location Fields */}
            {selectedLocation ? (
              <>
                {/* Address Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Home,Street"
                    value={selectedLocation.formatted_address || ""}
                    onChange={(e) =>
                      handleLocationFieldChange(
                        "formatted_address",
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                </Grid>

                {/* State Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    value={selectedLocation.state || ""}
                    onChange={(e) =>
                      handleLocationFieldChange("state", e.target.value)
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Country Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    value={selectedLocation.country || ""}
                    onChange={(e) =>
                      handleLocationFieldChange("country", e.target.value)
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Tehsil Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tehsil"
                    value={selectedLocation.tehsil || ""}
                    onChange={(e) =>
                      handleLocationFieldChange("tehsil", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>

                {/* District Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="District"
                    value={selectedLocation.district || ""}
                    onChange={(e) =>
                      handleLocationFieldChange("district", e.target.value)
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Postal Code Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Postal Code"
                    value={selectedLocation.postal_code || ""}
                    onChange={(e) =>
                      handleLocationFieldChange("postal_code", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography>No location selected.</Typography>
              </Grid>
            )}

            {/* Location Selection Dropdown */}
            <Grid item xs={12}>
              <LocationSelect
                className="locationbutton"
                onLocationChange={handleLocationChange}
                selectedLocation={selectedLocation}
                showExtraFields={false}
              />
            </Grid>

            {/* Update Location Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleUpdateLocation}
              >
                Update Location
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Image Preview Dialog */}
      <Dialog open={openImageDialog} onClose={handleCancelImageChange}>
        <DialogTitle>Profile Image Preview</DialogTitle>
        <DialogContent>
          <img
            src={
              user?.Profileimg
                ? `${baseImageUrl}${user.Profileimg}`
                : `${baseImageUrl}/default-profile.png`
            }
            alt="Profile Preview"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelImageChange}>Cancel</Button>
          <Button variant="contained" component="label" color="primary">
            Change Profile Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProfilePage;
