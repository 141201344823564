import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import CreditDebitEntryForm from "./CreditDebitEntryForm";
import EntryTable from "./EntryTable";

const CreditDebit = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);

  useEffect(() => {
    // Replace with actual API call
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    // Replace with actual API call
    const response = await fetch("/api/entries");
    const data = await response.json();
    setEntries(data);
    setFilteredEntries(data);
    setTotalEntries(data.length);
  };

  const handleAddEntry = (entry) => {
    setEntries([...entries, entry]);
    setFilteredEntries([...filteredEntries, entry]);
    setTotalEntries(totalEntries + 1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (term) => {
    const lowerTerm = term.toLowerCase();
    const filtered = entries.filter(
      (entry) =>
        entry.name.toLowerCase().includes(lowerTerm) ||
        entry.type.toLowerCase().includes(lowerTerm) ||
        entry.description.toLowerCase().includes(lowerTerm)
    );
    setFilteredEntries(filtered);
    setTotalEntries(filtered.length);
    setPage(1);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "50px" }}>
      <Typography variant="h3" align="center" gutterBottom>
        Credit/Debit Entry
      </Typography>
      <CreditDebitEntryForm onAddEntry={handleAddEntry} />
      <Box my={3}>
        <EntryTable
          entries={filteredEntries}
          page={page}
          pageSize={pageSize}
          totalEntries={totalEntries}
          onPageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default CreditDebit;
