import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Container from "./layoutcompo/Container";
import LoginComponent from "./layoutcompo/LoginComponent";
import PrivateRoute from "./PrivateRoute";
import { DIProvider, useDI } from "./hooks/DIContext";
import { SnackbarProvider } from "notistack";
import Loader from "./servicecomponent/Loader";
import ConfirmationDialog from "./servicecomponent/ConfirmationDialog";
import "./App.css";
import "../src/sefstyle2.css";
import CategoryCard from "./layoutcompo/CategoryCard";
import AddItem from "./SellerPages/AddItem";
import ProductCategory from "./MasterPages/ProductCategory";
import MyCreditDebit from "./MasterPages/MyCreditDebit";
import CreditDebit from "./CreditDebit/CreditDebit";
import theme from "./hooks/theme";
import { ThemeProvider } from "@mui/material/styles"; // Ensure you are using @mui/material/styles
import MasterItem from "./MasterPages/MasterItem";
import ItemKeywordsSelect from "./SellerPages/ItemKeywordsSelect ";
import MyComponent from "./SellerPages/MyComponent";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemDetailPage from "./buyerpages/ItemDetailPage";
import BannerCreation from "./MasterPages/bannerCreation";
import AddressPage from "./MasterPages/AddressPage";
import AllItemBuyingPage from "./buyerpages/AllItemBuyingPage";
import { ThemeProviderCust } from "./Themecolor/ThemeProviderCustContext";
import ThemeSelectorSlider from "./Themecolor/ThemeSelector";
import OrderDetailsPage from "./buyerpages/OrderDetailsPage";
import ProfilePage from "./MasterPages/ProfilePage";
import ActionPanel from "./MasterPages/ActionPanel";
import AreaMasterPage from "./MasterPages/AreaMasterPage";
import FormManagement from "./MasterPages/formmapping";
import RestrictionChargesPage from "./MasterPages/RestrictionChargesPage";
import ServicesDetails from "./Serviceprovider/servicesDetails";
import Viewotp from "./MasterPages/viewotp";

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeProviderCust>
          <ThemeSelectorSlider />
          <ToastContainer />
          <SnackbarProvider maxSnack={3}>
            <DIProvider>
              <AuthProvider>
                <Router>
                  <DIConsumer>
                    {({ isLoading, confirmDialog }) => (
                      <>
                        <Loader isLoading={isLoading} />
                        {confirmDialog?.isOpen && (
                          <ConfirmationDialog
                            message={confirmDialog.message}
                            onConfirm={confirmDialog.onConfirm}
                            onCancel={confirmDialog.onCancel}
                          />
                        )}
                      </>
                    )}
                  </DIConsumer>

                  <Routes>
                    <Route path="/" element={<Container />}>
                      <Route index element={<CategoryCard />} />
                      <Route path="/login" element={<LoginComponent />} />
                      <Route path="/mycreditdebit" element={<CreditDebit />} />
                      <Route path="/mycheck" element={<ItemKeywordsSelect />} />
                      <Route path="/addbanner" element={<BannerCreation />} />
                      <Route path="/orderpage" element={<ItemDetailPage />} />
                      <Route path="/addaddress" element={<AddressPage />} />
                      <Route path="/selfprofile" element={<ProfilePage />} />
                      <Route path="/actionpanel" element={<ActionPanel />} />
                      <Route path="/areamaster" element={<AreaMasterPage />} />
                      <Route path="/formmgmt" element={<FormManagement />} />
                      <Route path="/seeotp" element={<Viewotp />} />
                      <Route
                        path="/servicedetails"
                        element={<ServicesDetails />}
                      />

                      <Route
                        path="/restriction"
                        element={<RestrictionChargesPage />}
                      />

                      <Route
                        path="/orderdetails"
                        element={<OrderDetailsPage />}
                      />
                      <Route
                        path="/allitembuying"
                        element={<AllItemBuyingPage />}
                      />

                      <Route
                        path="/complaintcategory"
                        element={
                          <PrivateRoute>
                            <ProductCategory />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/addmastercomplaint"
                        element={
                          <PrivateRoute>
                            <MasterItem />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/managecomplaint"
                        element={
                          <PrivateRoute>
                            <AddItem />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                  </Routes>
                </Router>
              </AuthProvider>
            </DIProvider>
          </SnackbarProvider>
        </ThemeProviderCust>
      </ThemeProvider>
    </div>
  );
};

const DIConsumer = ({ children }) => {
  const services = useDI();
  return children(services);
};

export default App;
