// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// const resources = {
//   en: {
//     translation: {
//       select_language: "Select Language",
//       select_location: "Select Location",
//       type_to_search_location: "Type to search location...",
//       language: "Language",
//       english: "English",
//       spanish: "Spanish",
//       french: "French",
//       german: "German",
//       hindi: "Hindi",
//       marathi: "Marathi",
//       // Add more translations as needed
//     },
//   },
//   es: {
//     translation: {
//       select_language: "Seleccionar Idioma",
//       select_location: "Seleccionar Ubicación",
//       type_to_search_location: "Escriba para buscar ubicación...",
//       language: "Idioma",
//       english: "Inglés",
//       spanish: "Español",
//       french: "Francés",
//       german: "Alemán",
//       hindi: "Hindi",
//       marathi: "Marathi",
//       // Add more translations as needed
//     },
//   },
//   hi: {
//     translation: {
//       select_language: "भाषा चुनें",
//       select_location: "स्थान चुनें",
//       type_to_search_location: "स्थान खोजने के लिए टाइप करें...",
//       language: "भाषा",
//       english: "अंग्रेज़ी",
//       spanish: "स्पेनिश",
//       french: "फ्रेंच",
//       german: "जर्मन",
//       hindi: "हिंदी",
//       marathi: "मराठी",
//       // Add more translations as needed
//     },
//   },
//   mr: {
//     translation: {
//       select_language: "भाषा निवडा",
//       select_location: "स्थान निवडा",
//       type_to_search_location: "स्थान शोधण्यासाठी टाइप करा...",
//       language: "भाषा",
//       english: "इंग्रजी",
//       spanish: "स्पॅनिश",
//       french: "फ्रेंच",
//       german: "जर्मन",
//       hindi: "हिंदी",
//       marathi: "मराठी",

//       // Add more translations as needed
//     },
//   },
//   // Add more languages as needed
// };

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "en", // default language
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpBackend from "i18next-http-backend";

// i18n
//   .use(HttpBackend)
//   .use(initReactI18next)
//   .init({
//     lng: "en", // Default language
//     fallbackLng: "en", // Fallback language
//     interpolation: {
//       escapeValue: false, // React already safes from xss
//     },
//     backend: {
//       // Path to your JSON files
//       loadPath:
//         "/src/servicecomponent/translatecode/localslanguage/{{lng}}/translation.json",
//     },
//   });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation files
import enTranslation from "./localslanguage/en/translation.json";
import esTranslation from "./localslanguage/es/translation.json";
import hiTranslation from "./localslanguage/hi/translation.json";
import mrTranslation from "./localslanguage/mr/translation.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
  mr: {
    translation: mrTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
