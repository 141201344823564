import React, { useState, useEffect } from "react";

import Header from "./Header";
import Cart from "./Cart";
import { EnvVariables } from "../EnvVariables";
import { useAuth } from "../AuthContext";
import MenuItem from "./MenuItem"; // Adjusted import path
import { useDI } from "../hooks/DIContext";
import "./Container.css";
import Footer from "./SefFooter";
import { Outlet } from "react-router-dom";
import { CartProvider } from "../hooks/CartContext";
import { Navigate } from "react-router-dom";
import LoginComponent from "./LoginComponent";
import { toast } from "react-toastify";
import LanguageSelect from "../servicecomponent/translatecode/LanguageSelect";
import LocationSelect from "../servicecomponent/translatecode/LocationSelect";
import { Box, Grid, Typography, ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Container = () => {
  const {
    isLoggedIn,
    user,
    logout,
    searchInput,
    updateSearchInput,
    selectedLocation,
    setSelectedLocation,
  } = useAuth(); // Include logout and searchInput
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [orderItem, setOrderItem] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Corrected initial state to false
  const { apiService, showLoader, hideLoader, showNotification } = useDI();
  const [categoryId, setCategoryId] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default language
  // Default location
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [distance, setDistance] = useState(0);
  const [unit, setUnit] = useState("");
  useEffect(() => {
    if (isLoggedIn) {
      fetchCartItems();
    }
  }, [isLoggedIn]);

  const fetchCartItems = async () => {
    if (isLoggedIn) {
      try {
        const response = await apiService.postAPIwithoutdata(
          EnvVariables.Get_Customer_Cart
        );
        const data = response.data.RDatalist;

        // Ensure the data is an array
        if (Array.isArray(data)) {
          setCartItems(data);
        } else {
          setCartItems([]);
        }

        calculateTotalPrice(data);
      } catch (error) {
        showNotification("Error fetching cart items", "error");
      }
    }
  };

  const handleOrderClick = (item) => {
    if (!isLoggedIn) {
      showNotification("Login First", "error");
    } else {
      setOrderItem(item);
      localStorage.setItem("selectedorderItem", JSON.stringify(item));

      navigate(`/orderpage`);
    }
  };
  const addToCart = async (item) => {
    showLoader();

    // Ensure the item has a quantity property
    if (!item.quantity) {
      item.quantity = 1;
    }
    const itemExists = cartItems.some(
      (cartItem) => cartItem.UserItemDetailsId === item.UserItemDetailsId
    );

    if (itemExists) {
      // Display message that the item is already in the cart
      showNotification("Item is already in the cart", "error");
      hideLoader(); // Ensure loader is hidden
      return; // Exit the function
    }

    if (isLoggedIn) {
      try {
        // Check if the item is already in the cart
        const response = await apiService.postAPIwithoutdata(
          EnvVariables.Get_Customer_Cart
        );
        const cartItems = response.data.RDatalist;

        const itemExists1 = cartItems.some(
          (cartItem) => cartItem.UserItemDetailsId === item.UserItemDetailsId
        );

        if (itemExists1) {
          // Display message that the item is already in the cart
          showNotification("Item is already in the cart", "error");
          hideLoader(); // Ensure loader is hidden
          return; // Exit the function
        }
        // Add item to cart
        const insertResponse = await apiService.postAPI(
          {
            UserItemDetailsId: item.UserItemDetailsId,
            ItemId: item.ItemId,
            ItemName: item.ItemName,
            CategoryId: item.CategoryId,
            ItemDescription: item.ItemDescription,
            ItemDiscount: item.ItemDiscount,
            ItemPrice: item.ItemPrice,
            ItemBarcode: item.ItemBarcode,
            MinQty: item.MinQty,
            Deleted: item.Deleted,
            SingleItemImage: item.SingleItemImage,
            Qty: item.quantity, // Ensure Qty is sent
            DiscountedPrice: item.DiscountedPrice,
          },
          EnvVariables.Add_Customer_Cart
        );

        if (insertResponse.data.IsError === false) {
          // Fetch updated cart items
          const updatedResponse = await apiService.postAPIwithoutdata(
            EnvVariables.Get_Customer_Cart
          );
          const updatedCartItems = updatedResponse.data.RDatalist;

          setCartItems(updatedCartItems);
          calculateTotalPrice(updatedCartItems);
          showNotification("Item added to cart successfully", "success");
        } else {
          showNotification(insertResponse.data[0].Msg, "error");
        }
      } catch (error) {
        console.error("Error adding item to cart:", error);
        showNotification("Error adding item to cart", "error");
      } finally {
        hideLoader();
      }
    } else {
      const updatedCart = [...cartItems, item];
      setCartItems(updatedCart);
      calculateTotalPrice(updatedCart);
      showNotification("Item added to cart successfully", "success");
      hideLoader();
    }
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState); // Toggle menu state
  };

  const calculateTotalPrice = (items) => {
    const total = items.reduce(
      (sum, item) => sum + item.DiscountedPrice * item.quantity,
      0
    );
    setTotalPrice(total);
  };
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  // const handleLocationChange = (location) => {
  //   setSelectedLocation(location);
  // };
  const handleLocationChange = (location) => {
    console.log("Location changed:", location); // Debugging line
    setSelectedLocation(location);
    if (location) {
      setLatitude(location.lat);
      setLongitude(location.lon);
    }
  };
  // const handleDistanceChange = (newDistance) => {
  //   const roundedDistance = Math.ceil(newDistance);
  //   setDistance(roundedDistance);
  // };
  const handleDistanceChange = (event) => {
    const newDistance = event.target.value;

    // Allow backspace (empty input) or numeric values only
    if (newDistance === "" || /^[0-9]*$/.test(newDistance)) {
      setDistance(newDistance === "" ? 0 : parseInt(newDistance));
    }
  };

  const handleUnitChange = (event) => {
    const selectedUnit = event.target.value;
    setUnit(selectedUnit);
  };
  const handleClearUnit = () => {
    setUnit(""); // Clear the unit
  };
  const becomeProvider = () => {
    if (!isLoggedIn) {
      hideLoader();
      toast.error("Please login first.");
      return;
    }
    navigate("/selfprofile");
  };
  const navigatelogin = () => {
    navigate("/login");
  };

  return (
    <>
      <CartProvider
        value={{
          addToCart,
          handleOrderClick,
          setOrderItem,
          orderItem,
          categoryId,
          setCategoryId,
          setCartItems,
          cartItems,
          latitude,
          setLatitude,
          longitude,
          setLongitude,
          selectedLocation,
          setSelectedLocation,
          selectedLanguage,
          distance,
          unit,
          onClearUnit: handleClearUnit,
        }}
      >
        <div className="container">
          <Box>
            <Box>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{
                  marginTop: "1rem",
                  //backgroundColor: "#f5f5f5",

                  // "&:hover": { backgroundColor: "#e0e0e0" },
                }}
              >
                {/* Left Side Grid for LocationSelect */}
                <Grid item xs={4}>
                  <LocationSelect
                    onLocationChange={handleLocationChange}
                    selectedLocation={selectedLocation}
                    showExtraFields={true}
                    onDistanceChange={handleDistanceChange}
                    onUnitChange={handleUnitChange}
                    distance={distance}
                    unit={unit}
                    onClearUnit={handleClearUnit}
                  />
                </Grid>

                {/* Right Side Grid for "Become a provider" and inner content */}
                <Grid item xs={8} sx={{ textAlign: "right" }}>
                  {user && user.YesProvider === 1 ? (
                    // Render Subscription expiry date for providers
                    <Box
                      sx={{
                        color: "secondary.main",
                        fontWeight: "normal",
                        fontSize: "1.3rem",
                        display: "inline-flex",
                        alignItems: "center",
                        backgroundColor: "secondary.main", // Primary background color
                        padding: "0.5rem 1rem",
                        borderRadius: "8px",
                        boxShadow: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white", // Attention-grabbing color for text
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Subscription expiry date
                      </Typography>
                    </Box>
                  ) : (
                    // Render Become a Service Provider with ButtonBase for clicking
                    <>
                      {isLoggedIn ? (
                        // If user is logged in and not a provider
                        <Box
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            backgroundColor: "secondary.main", // Primary background color
                            padding: "0.5rem 1rem",
                            borderRadius: "8px",
                            boxShadow: 2,
                            cursor: "pointer",
                            "@keyframes pulse": {
                              "0%": { transform: "scale(1)" },
                              "50%": { transform: "scale(1.05)" },
                              "100%": { transform: "scale(1)" },
                            },
                            animation: "pulse 2s infinite ease-in-out",
                          }}
                          onClick={becomeProvider}
                        >
                          <Typography
                            sx={{
                              color: "white", // Attention-grabbing color for text
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Become a Service Provider
                          </Typography>
                        </Box>
                      ) : (
                        // If user is not logged in, display alternative message (or nothing)
                        // <Typography sx={{ fontStyle: "italic", color: "gray" }}>
                        //   Please log in to become a service provider.
                        // </Typography>
                        <Box
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            backgroundColor: "secondary.main", // Primary background color
                            padding: "0.5rem 1rem",
                            borderRadius: "8px",
                            boxShadow: 2,
                            cursor: "pointer",
                            "@keyframes pulse": {
                              "0%": { transform: "scale(1)" },
                              "50%": { transform: "scale(1.05)" },
                              "100%": { transform: "scale(1)" },
                            },
                            animation: "pulse 2s infinite ease-in-out",
                          }}
                          onClick={navigatelogin}
                        >
                          <Typography
                            sx={{
                              color: "white", // Attention-grabbing color for text
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Log in to unlock services.
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}

                  <Grid container spacing={2} justifyContent="flex-end">
                    {/* Add other inner content here if necessary */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Header
              cartItemCount={cartItems.length}
              toggleCart={toggleCart}
              toggleMenu={toggleMenu}
            />

            <MenuItem menuVisible={isMenuOpen} setMenuVisible={setIsMenuOpen} />

            <Outlet />
          </Box>
        </div>
      </CartProvider>
      <Footer></Footer>
    </>
  );
};

export default Container;
