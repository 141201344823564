// import React, { useState, useEffect } from "react";
// import { useAuth } from "../AuthContext";
// import { useDI } from "../hooks/DIContext";
// import { EnvVariables } from "../EnvVariables";
// import { Typography, Container, Button, TextField } from "@mui/material";

// const OrderDetailsPage = () => {
//   const { isLoggedIn } = useAuth(); // Check if the user is logged in
//   const [billNo, setBillNo] = useState("");
//   const [orderTemplate, setOrderTemplate] = useState("");
//   const { apiService, showNotification, showLoader, hideLoader } = useDI();
//   const [noRecordsFound, setNoRecordsFound] = useState(false);
//   useEffect(() => {
//     if (billNo) {
//       fetchOrderDetails();
//     }
//   }, [billNo]);

//   const fetchOrderDetails = async () => {
//     try {
//       showLoader();
//       const response = await apiService.postAPI(
//         { BillNo: billNo },
//         EnvVariables.Get_All_Item_Order
//       );
//       hideLoader();

//       if (response.data && !response.data.IsError) {
//         const retrievedData = response.data.RDatalist;

//         if (retrievedData && retrievedData.length > 0) {
//           const retrievedTemplate = retrievedData[0]?.BillTemplate || "";
//           setOrderTemplate(retrievedTemplate);

//           showNotification("Order details retrieved successfully", "success");
//         } else {
//           showNotification("No order details found", "info");
//           setOrderTemplate(null);
//         }
//       } else {
//         showNotification("No order details found", "info");
//         setOrderTemplate(null);
//       }
//     } catch (error) {
//       hideLoader();
//       showNotification("Error retrieving order details", "error");
//       setOrderTemplate(null);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (billNo) {
//       fetchOrderDetails();
//     } else {
//       showNotification("Please enter a valid Bill No", "warning");
//     }
//   };

//   useEffect(() => {
//     // Attach the printBill function to the window object
//     window.printBill = (billIndex) => {
//       const billContent = document.getElementById(`bill-${billIndex}`);
//       if (billContent) {
//         const printWindow = window.open("", "", "height=600,width=800");
//         printWindow.document.write("<html><head><title>Print Bill</title>");
//         printWindow.document.write("</head><body >");
//         printWindow.document.write(billContent.innerHTML);
//         printWindow.document.write("</body></html>");
//         printWindow.document.close();
//         printWindow.focus();
//         printWindow.print();
//       }
//     };
//   }, []);

//   return (
//     <Container>
//       <Typography variant="h4" gutterBottom>
//         Order Details
//       </Typography>
//       <form onSubmit={handleSubmit} style={{ marginBottom: "2rem" }}>
//         <TextField
//           label="Bill No"
//           variant="outlined"
//           fullWidth
//           value={billNo}
//           onChange={(e) => setBillNo(e.target.value)}
//           style={{ marginBottom: "1rem" }}
//         />
//         <Button type="submit" variant="contained" color="primary">
//           Get Order Details
//         </Button>
//       </form>
//       {orderTemplate ? (
//         <div>
//           <div dangerouslySetInnerHTML={{ __html: orderTemplate }} />
//         </div>
//       ) : (
//         <Typography variant="body1">
//           Enter a Bill No to see the order details.
//         </Typography>
//       )}
//     </Container>
//   );
// };

// export default OrderDetailsPage;
import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { Typography, Container, Button, TextField } from "@mui/material";

const OrderDetailsPage = () => {
  const { isLoggedIn } = useAuth();
  const [billNo, setBillNo] = useState("");
  const [orderTemplate, setOrderTemplate] = useState("");
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const { apiService, showNotification, showLoader, hideLoader } = useDI();

  // useEffect(() => {
  //   if (billNo) {
  //     fetchOrderDetails();
  //   }
  // }, [billNo]);

  const fetchOrderDetails = async () => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        { BillNo: billNo },
        EnvVariables.Get_All_Item_Order
      );
      hideLoader();

      if (response.data && !response.data.IsError) {
        const retrievedData = response.data.RDatalist;

        if (retrievedData && retrievedData.length > 0) {
          const retrievedTemplate = retrievedData[0]?.BillTemplate || "";
          setOrderTemplate(retrievedTemplate);
          setNoRecordsFound(false);
          showNotification("Order details retrieved successfully", "success");
        } else {
          showNotification("No order details found", "info");
          setOrderTemplate("");
        }
      } else {
        showNotification("No order details found", "info");
        setOrderTemplate("");
      }
    } catch (error) {
      hideLoader();
      showNotification("Error retrieving order details", "error");
      setOrderTemplate("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (billNo.trim().length > 3) {
      fetchOrderDetails();
    } else {
      showNotification("Bill No must be at least 4 characters long", "warning");
    }
  };

  useEffect(() => {
    window.printBill = (billIndex) => {
      const billContent = document.getElementById(`bill-${billIndex}`);
      if (billContent) {
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><head><title>Print Bill</title>");
        printWindow.document.write("</head><body >");
        printWindow.document.write(billContent.innerHTML);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      }
    };
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Order Details
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginBottom: "2rem" }}>
        <TextField
          label="Bill No"
          variant="outlined"
          fullWidth
          value={billNo}
          onChange={(e) => setBillNo(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        <Button type="submit" variant="contained" color="primary">
          Get Order Details
        </Button>
      </form>
      {orderTemplate ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: orderTemplate }} />
        </div>
      ) : (
        <Typography variant="body1">No order details found.</Typography>
      )}
      {/* {noRecordsFound && (
        <Typography
          variant="body1"
          style={{
            marginTop: "1rem",
            color: "red",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          No order details found.
        </Typography>
      )} */}
    </Container>
  );
};

export default OrderDetailsPage;
