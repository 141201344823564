import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  MenuItem,
  IconButton,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  ListItemText,
  InputLabel,
  ListSubheader,
  Box,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { EnvVariables } from "../EnvVariables"; // Adjust the path to your EnvVariables
import { useDI } from "../hooks/DIContext";
import Pagination from "../layoutcompo/Pagination";
import CancelIcon from "@mui/icons-material/Cancel";
import { userroles } from "../servicecomponent/commondataclassfiles/commonarrays";
import {
  verifydeType,
  EditwritesTypes,
  verificationMethodTypes,
} from "../servicecomponent/commondataclassfiles/commonarrays";
const ControlPanel = () => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [areas, setAreas] = useState([]);
  const [roleSearchText, setRoleSearchText] = useState("");
  const [areaSearchText, setAreaSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Prefix, setPrefix] = useState("");
  const [editUserId, setEditUserId] = useState(null);
  const [editUserBackup, setEditUserBackup] = useState(null); // For cancel functionality
  const [techverified, setTechverified] = useState("Yes"); // Default to 'Self'
  const initialUserState = {
    Name: "",
    Email: "",
    PhoneNo: "",
    UserAddress: "",
    UserPassword: "",
    selectedRoles: [],
    selectedAreas: "",
  };
  const [newUser, setNewUser] = useState(initialUserState);

  const [touched, setTouched] = useState({
    Name: false,
    Email: false,
    PhoneNo: false,
    UserAddress: false,
    UserPassword: false,
    selectedRoles: false,
    selectedAreas: false,
  });

  const [addRoleSearch, setAddRoleSearch] = useState("");
  const [addAreaSearch, setAddAreaSearch] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const handleClearForm = () => {
    setNewUser(initialUserState); // Reset form state
    setValidationErrors({}); // Clear validation errors
  };
  // Filter roles based on search input
  const addFilteredRoles = roles.filter((role) =>
    role.toLowerCase().includes(addRoleSearch.toLowerCase())
  );

  // Filter areas based on search input
  const addFilteredAreas = areas.filter((area) =>
    area.AreaName.toLowerCase().includes(addAreaSearch.toLowerCase())
  );
  useEffect(() => {
    fetchUserList();
    fetchAreas();
    fetchRoles();
  }, [currentPage, rowsPerPage, Prefix]);

  const fetchUserList = async () => {
    try {
      // Start loading indicator
      showLoader();

      // Make API request to fetch user list
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix },
        EnvVariables.GetUserListAPI
      );

      // Extract the data from the response
      const data = response.data;

      // Check if the data structure is valid
      if (data && data.RDatalist && Array.isArray(data.RDatalist)) {
        // Transform the user data
        const users = data.RDatalist.map((user) => {
          const roles = user.SystemRole
            ? user.SystemRole.split(",").map((role) => role.trim())
            : [];
          return {
            ...user,
            selectedRoles: roles,
            selectedAreas: user.AreaNames
              ? user.AreaNames.split(",").map((area) => area.trim())
              : [],
          };
        });

        // Update state with the fetched user data
        setUserList(users);

        // Extract and set the total records count
        const totalRecords = data.RDatalist[0]?.TotalRecords || 0;
        setTotalRecords(totalRecords);
      } else {
        // If the data is not valid, reset user list and total records
        setUserList([]);
        setTotalRecords(0);
        showNotification("Error loading user list", "error");
      }
    } catch (error) {
      // Handle errors
      showNotification("Error fetching user list", "error");
    } finally {
      // Stop the loading indicator or any cleanup
      hideLoader();
      console.log("User list fetch attempt completed.");
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix },
        EnvVariables.GetAreaName
      );
      const data = response.data;
      if (data && data.RDatalist && Array.isArray(data.RDatalist)) {
        setAreas(data.RDatalist);
      } else {
        setAreas([]);
        showNotification("Error loading areas", "error");
      }
    } catch (error) {
      showNotification("Error fetching areas", "error");
    }
  };

  const fetchRoles = async () => {
    setRoles(userroles);
  };

  const handleRoleChange = (userId, event) => {
    const { value } = event.target;
    const updatedRoles = typeof value === "string" ? value.split(",") : value;

    setUserList((prev) => {
      // Find the user being updated
      const userToUpdate = prev.find((user) => user.UserId === userId);
      if (!userToUpdate) return prev; // Exit if user is not found

      // Check if any of the updatedRoles already exist
      const existingRoles = userToUpdate.selectedRoles || [];
      const duplicateRoles = updatedRoles.filter((role) =>
        existingRoles.includes(role)
      );

      if (duplicateRoles.length > 0) {
        // Display message if duplicates are found
        console.log(
          `The following roles already exist: ${duplicateRoles.join(",")}`
        );
      }

      // Update the user's roles
      return prev.map((user) =>
        user.UserId === userId ? { ...user, selectedRoles: updatedRoles } : user
      );
    });
  };

  const handleAreaChange = (userId, event) => {
    const { value } = event.target;
    const updatedAreas = typeof value === "string" ? value.split(",") : value;

    setUserList((prev) =>
      prev.map((user) =>
        user.UserId === userId
          ? {
              ...user,
              selectedAreas: updatedAreas,
            }
          : user
      )
    );
  };
  const handleUpdateUser = async (userId) => {
    const userToUpdate = userList.find((user) => user.UserId === userId);

    if (!userToUpdate) {
      showNotification("User not found", "error");
      return;
    }

    // Extract only the numbers from selectedAreas
    const areaNumbers = userToUpdate.selectedAreas
      .map((area) => area.split("-")[0].trim()) // Assuming format is "number-name"
      .join(",");

    const payload = {
      UserId: userId,
      SystemRole: userToUpdate.selectedRoles.join(","),
      AreaNames: areaNumbers,
      Email: userToUpdate.Email,
      PhoneNo: userToUpdate.PhoneNo,
      Name: userToUpdate.Name,
      IsVerified: userToUpdate.techverified,
      VerifiedMethod: userToUpdate.verificationmethod,
      Verifieddesc: userToUpdate.verificationdescription,
      EditWrites: userToUpdate.Editwrites,
    };
    setConfirmDialog({
      isOpen: true,
      message: `Are you sure you want to update the record?`,
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            payload,
            EnvVariables.UpdateUserAPI
          );
          const data = response.data;

          if (data.IsError) {
            showNotification(
              data.RDatalist?.[0]?.Msg || "Error updating user",
              "error"
            );
          } else {
            setEditUserId(null);
            showNotification(
              data.RDatalist?.[0]?.Msg || "User updated successfully",
              "success"
            );
          }
        } catch (error) {
          showNotification("Error updating user", "error");
        } finally {
          hideLoader();
          // This will always execute whether the update is successful or fails
          await fetchUserList(); // Refresh user list after update
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const filteredRoles = roles.filter((role) =>
    role.toLowerCase().includes(roleSearchText.toLowerCase())
  );

  const filteredAreas = areas.filter((area) =>
    area.AreaName.toLowerCase().includes(areaSearchText.toLowerCase())
  );
  const handleEditUser = (userId) => {
    const user = userList.find((user) => user.UserId === userId);
    setEditUserBackup({ ...user }); // Backup the current state
    setEditUserId(userId);
  };

  const handleInputChange = (userId, field, value) => {
    setUserList((prev) =>
      prev.map((user) =>
        user.UserId === userId ? { ...user, [field]: value } : user
      )
    );
  };
  const handleDeleteUser = (userId) => {
    setEditUserId(userId);
  };
  const handleCancelEdit = () => {
    setUserList((prevList) =>
      prevList.map((user) =>
        user.UserId === editUserId ? { ...editUserBackup } : user
      )
    );
    setEditUserId(null); // Exit edit mode
    setEditUserBackup(null); // Clear backup
  };

  const handleSearchChange = (e) => {
    setPrefix(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
    fetchUserList(); // Trigger search
  };
  const validateForm = () => {
    const errors = {};

    if (!newUser.Name || newUser.Name.trim().length === 0) {
      errors.Name = "Name is required.";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!newUser.Email || !emailPattern.test(newUser.Email)) {
      errors.Email = "Please enter a valid email address.";
    }

    const phonePattern = /^[0-9]{10}$/;
    if (!newUser.PhoneNo || !phonePattern.test(newUser.PhoneNo)) {
      errors.PhoneNo = "Please enter a valid 10-digit phone number.";
    }

    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!newUser.UserPassword || !passwordPattern.test(newUser.UserPassword)) {
      errors.UserPassword =
        "Password must be at least 8 characters long and include at least one letter and one number.";
    }

    if (!newUser.UserAddress || newUser.UserAddress.trim().length === 0) {
      errors.UserAddress = "Address is required.";
    }

    if (!newUser.selectedRoles || newUser.selectedRoles.length === 0) {
      errors.selectedRoles = "Please select at least one role.";
    }

    if (!newUser.selectedAreas || newUser.selectedAreas.length === 0) {
      errors.selectedAreas = "Please select at least one area.";
    }

    return errors;
  };

  const handleAddUser = async () => {
    const errors = validateForm();

    setValidationErrors(errors);
    setTouched({
      Name: true,
      Email: true,
      PhoneNo: true,
      UserAddress: true,
      UserPassword: true,
      selectedRoles: true,
      selectedAreas: true,
    });

    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      showNotification(errors[firstErrorKey], "error");
      return;
    }

    const areaNumbers = newUser.selectedAreas.toString();

    const payload = {
      SystemRole: newUser.selectedRoles.join(","),
      AreaNames: areaNumbers,
      Email: newUser.Email,
      PhoneNo: newUser.PhoneNo,
      Name: newUser.Name,
      Password: newUser.UserPassword,
      FullAddress: newUser.UserAddress,
    };
    setConfirmDialog({
      isOpen: true,
      message: `Are you sure you want to add the new user?`,
      onConfirm: async () => {
        try {
          showLoader();

          const response = await apiService.postAPI(
            payload,
            EnvVariables.AddUserAPI
          );

          const data = response.data;

          if (data.IsError) {
            showNotification(
              data.RDatalist?.[0]?.Msg || "Error adding user",
              "error"
            );
          } else {
            setNewUser({
              Name: "",
              Email: "",
              PhoneNo: "",
              UserPassword: "",
              UserAddress: "",
              selectedRoles: [],
              selectedAreas: "",
            });

            showNotification(
              data.RDatalist?.[0]?.Msg || "User added successfully",
              "success"
            );
          }
        } catch (error) {
          showNotification("Error adding user", "error");
        } finally {
          hideLoader(); // Hide loader after API call
          await fetchUserList(); // Refresh user list after addition
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };
  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };
  return (
    <div style={{ padding: 20 }}>
      {/* Form for Adding New Users */}
      <Box
        sx={{
          mb: 2,
          padding: 2,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
          Add New User
        </Typography>
        <Grid container spacing={3}>
          {/* Name Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={newUser.Name}
              onChange={(e) => setNewUser({ ...newUser, Name: e.target.value })}
              onBlur={() => handleBlur("Name")}
              variant="outlined"
              fullWidth
              error={touched.Name && Boolean(validationErrors.Name)}
              helperText={touched.Name && validationErrors.Name}
            />
          </Grid>

          {/* Email Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              value={newUser.Email}
              onChange={(e) =>
                setNewUser({ ...newUser, Email: e.target.value })
              }
              onBlur={() => handleBlur("Email")}
              variant="outlined"
              fullWidth
              error={touched.Email && Boolean(validationErrors.Email)}
              helperText={touched.Email && validationErrors.Email}
            />
          </Grid>

          {/* Phone Number Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              value={newUser.PhoneNo}
              onChange={(e) =>
                setNewUser({ ...newUser, PhoneNo: e.target.value })
              }
              onBlur={() => handleBlur("PhoneNo")}
              variant="outlined"
              fullWidth
              error={touched.PhoneNo && Boolean(validationErrors.PhoneNo)}
              helperText={touched.PhoneNo && validationErrors.PhoneNo}
            />
          </Grid>

          {/* Address Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="House Address"
              value={newUser.UserAddress}
              onChange={(e) =>
                setNewUser({ ...newUser, UserAddress: e.target.value })
              }
              onBlur={() => handleBlur("UserAddress")}
              variant="outlined"
              fullWidth
              error={
                touched.UserAddress && Boolean(validationErrors.UserAddress)
              }
              helperText={touched.UserAddress && validationErrors.UserAddress}
            />
          </Grid>

          {/* Password Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              value={newUser.UserPassword}
              onChange={(e) =>
                setNewUser({ ...newUser, UserPassword: e.target.value })
              }
              onBlur={() => handleBlur("UserPassword")}
              variant="outlined"
              fullWidth
              error={
                touched.UserPassword && Boolean(validationErrors.UserPassword)
              }
              helperText={touched.UserPassword && validationErrors.UserPassword}
            />
          </Grid>

          {/* Roles Dropdown with Search */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                touched.selectedRoles && Boolean(validationErrors.selectedRoles)
              }
            >
              <InputLabel>Roles</InputLabel>
              <Select
                multiple
                value={newUser.selectedRoles}
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    selectedRoles:
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value,
                  })
                }
                onBlur={() => handleBlur("selectedRoles")}
                input={<OutlinedInput label="Roles" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      display: "flex",
                      flexDirection: "column",
                      padding: 0,
                    },
                  },
                }}
              >
                <MenuItem disableRipple>
                  <TextField
                    placeholder="Search Roles"
                    value={addRoleSearch}
                    onChange={(e) => setAddRoleSearch(e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  />
                </MenuItem>
                {addFilteredRoles.length === 0 ? (
                  <MenuItem disabled>No roles found</MenuItem>
                ) : (
                  addFilteredRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox
                        checked={newUser.selectedRoles.indexOf(role) > -1}
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))
                )}
              </Select>
              {touched.selectedRoles && validationErrors.selectedRoles && (
                <Typography color="error">
                  {validationErrors.selectedRoles}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Areas Dropdown with Search */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                touched.selectedAreas && Boolean(validationErrors.selectedAreas)
              }
            >
              <InputLabel>Areas</InputLabel>
              <Select
                value={newUser.selectedAreas}
                onChange={(e) =>
                  setNewUser({ ...newUser, selectedAreas: e.target.value })
                }
                renderValue={(selected) => {
                  const selectedArea = addFilteredAreas.find(
                    (area) => area.AreaId === selected
                  );
                  return selectedArea ? selectedArea.AreaName : "";
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      display: "flex",
                      flexDirection: "column",
                      padding: 0,
                    },
                  },
                }}
              >
                <MenuItem disableRipple>
                  <TextField
                    placeholder="Search Areas"
                    value={addAreaSearch}
                    onChange={(e) => setAddAreaSearch(e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  />
                </MenuItem>
                {addFilteredAreas.length === 0 ? (
                  <MenuItem disabled>No areas found</MenuItem>
                ) : (
                  addFilteredAreas.map((area) => (
                    <MenuItem key={area.AreaId} value={area.AreaId}>
                      {area.AreaName}
                    </MenuItem>
                  ))
                )}
              </Select>
              {touched.selectedAreas && validationErrors.selectedAreas && (
                <Typography color="error">
                  {validationErrors.selectedAreas}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Add User Button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUser}
              >
                Add User
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClearForm}
                sx={{ marginLeft: 2 }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search"
          value={Prefix}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
        />
      </Box>

      {/* User List Table */}
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Permanent AreaName and Id</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Areas</TableCell>
                <TableCell>Verify Status</TableCell>
                <TableCell>Verify Method</TableCell>
                <TableCell>Edit Rights</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.length > 0 ? (
                userList.map((seruser, index) => (
                  <TableRow key={index}>
                    <TableCell>{seruser.RowNum}</TableCell>
                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <TextField
                          value={seruser.Email || ""}
                          onChange={(e) =>
                            handleInputChange(
                              seruser.UserId,
                              "Email",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        seruser.Email
                      )}
                    </TableCell>
                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <TextField
                          value={seruser.PhoneNo || ""}
                          onChange={(e) =>
                            handleInputChange(
                              seruser.UserId,
                              "PhoneNo",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        seruser.PhoneNo
                      )}
                    </TableCell>

                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <TextField
                          value={seruser.Name || ""}
                          onChange={(e) =>
                            handleInputChange(
                              seruser.UserId,
                              "Name",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        seruser.Name
                      )}
                    </TableCell>
                    <TableCell>{seruser.PermanentAreaName}</TableCell>
                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <FormControl fullWidth>
                          <Select
                            multiple
                            value={seruser.selectedRoles || []}
                            onChange={(e) =>
                              handleRoleChange(seruser.UserId, e)
                            }
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              selected.length === 0 ? (
                                <span>Select roles</span>
                              ) : (
                                selected.join(",")
                              )
                            }
                          >
                            <ListSubheader>
                              <TextField
                                size="small"
                                placeholder="Search roles"
                                fullWidth
                                value={roleSearchText}
                                onChange={(e) =>
                                  setRoleSearchText(e.target.value)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </ListSubheader>
                            {roles.map((role) => (
                              <MenuItem key={role} value={role}>
                                <Checkbox
                                  checked={
                                    seruser.selectedRoles.indexOf(role) > -1
                                  }
                                />
                                <ListItemText primary={role} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        (seruser.selectedRoles || []).join(",")
                      )}
                    </TableCell>

                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <FormControl fullWidth>
                          <Select
                            multiple
                            value={seruser.selectedAreas || []}
                            onChange={(e) =>
                              handleAreaChange(seruser.UserId, e)
                            }
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              selected.length === 0 ? (
                                <span>Select areas</span>
                              ) : (
                                selected.join(",")
                              )
                            }
                          >
                            <ListSubheader>
                              <TextField
                                size="small"
                                placeholder="Search areas"
                                fullWidth
                                value={areaSearchText}
                                onChange={(e) =>
                                  setAreaSearchText(e.target.value)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </ListSubheader>
                            {areas.map((area) => (
                              <MenuItem key={area.AreaId} value={area.AreaName}>
                                <Checkbox
                                  checked={
                                    seruser.selectedAreas.indexOf(
                                      area.AreaName
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={area.AreaName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        (seruser.selectedAreas || []).join(",")
                      )}
                    </TableCell>
                    {/* <TableCell>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={verifydeType}
            value={techverified}
            onChange={(event, newValue) => {
              setTechverified(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Type"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </TableCell> */}
                    <TableCell>
                      {
                        editUserId === seruser.UserId ? (
                          <Autocomplete
                            options={verifydeType} // Numeric values and labels
                            getOptionLabel={(option) => option.label} // Show "Yes" or "No"
                            value={
                              verifydeType.find(
                                (option) =>
                                  Number(option.value) ===
                                  Number(seruser.IsVerified)
                              ) || null
                            } // Find matching value for seruser.IsVerified
                            onChange={(event, newValue) => {
                              handleInputChange(
                                seruser.UserId,
                                "techverified", // Should this be "IsVerified" or "techverified"? Based on context it seems like it should update "IsVerified".
                                newValue ? newValue.value : null
                              ); // Update seruser-specific IsVerified value
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Verification Status"
                                variant="outlined"
                              />
                            )}
                            disabled={
                              Number(seruser.IsVerified) === 1 &&
                              Number(seruser.EditWrites) === 0
                            } // Disable if both conditions match
                          />
                        ) : Number(seruser.IsVerified) === 1 ? (
                          "Yes"
                        ) : Number(seruser.IsVerified) === 0 ? (
                          "No"
                        ) : (
                          "N/A"
                        ) // Display "Yes", "No", or "N/A" based on the value of IsVerified
                      }
                    </TableCell>

                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <>
                          {/* Autocomplete for verification method */}
                          <Autocomplete
                            options={verificationMethodTypes} // Updated options with new values
                            getOptionLabel={(option) => option.label} // Show "Manual", "Using Id Proof and Telephonic"
                            value={
                              verificationMethodTypes.find(
                                (option) =>
                                  String(option.value) ===
                                  String(seruser.VerifiedMethod)
                              ) || null
                            } // Ensure value comparison between string and int
                            onChange={(event, newValue) => {
                              handleInputChange(
                                seruser.UserId,
                                "verificationmethod",
                                newValue ? newValue.value : null
                              ); // Update the seruser-specific verificationmethod value
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Verification Method"
                                variant="outlined"
                              />
                            )}
                            disabled={
                              Number(seruser.IsVerified) === 1 &&
                              Number(seruser.EditWrites) === 0
                            } // Disable if both conditions match
                          />

                          {/* TextField for verification description */}
                          <Grid container sx={{ width: "100%" }}>
                            <Grid item xs={12} sm={12} md={12}>
                              {" "}
                              {/* Adjusts width for different screen sizes */}
                              <TextField
                                multiline
                                rows={4}
                                label="Verification Description"
                                variant="outlined"
                                sx={{
                                  width: "100%", // Ensures the TextField takes up full width of the Grid item
                                  mt: 2, // Margin top for spacing
                                }}
                                value={seruser.Verifieddesc || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    seruser.UserId,
                                    "verificationdescription",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  Number(seruser.IsVerified) === 1 &&
                                  Number(seruser.EditWrites) === 0
                                } // Disable if both conditions match
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {/* Display verification method and description */}
                          {seruser.VerifiedMethod === 1
                            ? "Manual"
                            : seruser.VerifiedMethod === 2
                            ? "Using Id Proof and Telephonic"
                            : "N/A"}
                          <br />
                          {seruser.Verifieddesc || "N/A"}
                        </>
                      )}
                    </TableCell>

                    <TableCell>
                      {
                        editUserId === seruser.UserId ? (
                          <Autocomplete
                            options={EditwritesTypes} // Numeric values and labels for Editwrites
                            getOptionLabel={(option) => option.label} // Show "Yes" or "No"
                            value={
                              EditwritesTypes.find(
                                (option) => option.value === seruser.Editwrites
                              ) || null
                            } // Find matching value for seruser.Editwrites
                            onChange={(event, newValue) => {
                              handleInputChange(
                                seruser.UserId,
                                "Editwrites",
                                newValue ? newValue.value : null
                              ); // Update seruser-specific Editwrites value
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Editwrites"
                                variant="outlined"
                              />
                            )}
                            disabled={
                              (seruser.techverified === 1 ||
                                seruser.techverified === "1") &&
                              (seruser.Editwrites === 0 ||
                                seruser.Editwrites === "0")
                            } // Disable if techverified is 1 and Editwrites is 0
                          />
                        ) : seruser.EditWrites === 1 ||
                          seruser.EditWrites === "1" ? (
                          "Yes"
                        ) : seruser.EditWrites === 0 ||
                          seruser.EditWrites === "0" ? (
                          "No"
                        ) : (
                          "N/A"
                        ) // Display "Yes", "No", or "N/A" for each seruser
                      }
                    </TableCell>

                    <TableCell>
                      {editUserId === seruser.UserId ? (
                        <>
                          <IconButton
                            onClick={() => handleUpdateUser(seruser.UserId)}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton onClick={handleCancelEdit}>
                            <CancelIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => handleEditUser(seruser.UserId)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          onRowsPerPageChange={(size) => setRowsPerPage(size)}
        />
      </div>

      {/* Pagination Component */}
    </div>
  );
};

export default ControlPanel;
