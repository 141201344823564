import React, { useState, useEffect, createContext, useContext } from "react";
import AuthService from "./AuthService";
import { useDI } from "./hooks/DIContext";
import { EnvVariables } from "./EnvVariables";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [loopContinue, setLoopContinue] = useState(true);
  const { apiService } = useDI();
  const [allOrderDetails, setAllOrderDetails] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);
  const [user, setUser] = useState(null);
  const [restrictions, setRestrictions] = useState([]);
  const [userestrictions, setUserRestrictions] = useState([]);
  const [businesstype, setBusinesstype] = useState(2);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const fetchRestrictions = async () => {
    try {
      const response = await apiService.postAPI(
        {},
        EnvVariables.Get_Restriction_Charges
      );
      setRestrictions(
        Array.isArray(response.data.RData) ? response.data.RData : []
      );
    } catch (error) {
      console.error("Error fetching restrictions:", error);
      setRestrictions([]);
    }
  };

  const fetchUserRestrictions = async () => {
    try {
      const response = await apiService.postAPI(
        {},
        EnvVariables.Get_Use_Restriction_Charges
      );
      setUserRestrictions(
        Array.isArray(response.data.RData) ? response.data.RData : []
      );
    } catch (error) {
      console.error("Error fetching user restrictions:", error);
      setUserRestrictions([]);
    }
  };

  useEffect(() => {
    fetchRestrictions();
    fetchUserRestrictions();
  }, []);

  useEffect(() => {
    const token = AuthService.getuserToken();
    if (token) {
      validateToken(token);
    } else {
      setIsLoading(false);
    }
  }, []);

  const validateToken = async (token) => {
    try {
      const response = await apiService.postAPI(
        { token },
        EnvVariables.Validate_Token
      );
      if (response.data.IsValid) {
        setIsLoggedIn(true);
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error validating token:", error);
      logout();
    }
  };

  const login = (token) => {
    AuthService.setToken(token);
    setIsLoggedIn(true);
    // Optionally fetch user details here
  };

  const logout = async () => {
    const token = AuthService.getuserToken();
    try {
      await apiService.postAPI({ token }, EnvVariables.User_Logout);
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      AuthService.logout();
      setIsLoggedIn(false);
      // Clear other state as necessary
    }
  };

  const updateSearchInput = (input) => {
    setSearchInput(input);
    setLoopContinue(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        login,
        logout,
        searchInput,
        loopContinue,
        setLoopContinue,
        updateSearchInput,
        setAllOrderDetails,
        allOrderDetails,
        addresses,
        setAddresses,
        previewImage,
        setPreviewImage,
        user,
        setUser,
        restrictions,
        setRestrictions,
        fetchRestrictions,
        userestrictions,
        businesstype,
        setBusinesstype,
        selectedLocation,
        setSelectedLocation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
