import React, { createContext, useContext, useState, useEffect } from "react";
import {
  darkenRgb,
  lightenRgb,
  darkenRgba,
  lightenRgba,
  darkenHex,
  lightenHex,
  parseColor,
} from "./colorUtils";

const ColorThemeContext = createContext();

export const useTheme = () => useContext(ColorThemeContext);

export const ThemeProviderCust = ({ children }) => {
  const [selectedColor, setSelectedColor] = useState("#648002");

  const applyThemeColors = (baseColor) => {
    if (baseColor) {
      const computedStyle = getComputedStyle(document.documentElement);
      const themeColors = {
        mainColor: parseColor(computedStyle.getPropertyValue("--mainColor")),
        mainKaFikaColor: parseColor(
          computedStyle.getPropertyValue("--mainKaFikaColor")
        ),
        afterHoverColor: parseColor(
          computedStyle.getPropertyValue("--afterHoverColor")
        ),
        universalShadow: parseColor(
          computedStyle.getPropertyValue("--universalShadow")
        ),
        lightGrey: parseColor(computedStyle.getPropertyValue("--lightGrey")),
        buttonColor: parseColor(
          computedStyle.getPropertyValue("--buttoncolor")
        ),
        secondaryColor: parseColor(
          computedStyle.getPropertyValue("--secondaryColor")
        ),
        addCartButton: parseColor(
          computedStyle.getPropertyValue("--addcartbutton")
        ),
        shareIcon: parseColor(computedStyle.getPropertyValue("--shareicon")),
        phoneIcon: parseColor(computedStyle.getPropertyValue("--phoneicon")),
        payButton: parseColor(computedStyle.getPropertyValue("--paybutton")),
        orderButton: parseColor(
          computedStyle.getPropertyValue("--orderbutton")
        ),
        pageBar: parseColor(computedStyle.getPropertyValue("--pagebar")),
        boxShadow: parseColor(computedStyle.getPropertyValue("--boxshadow")),
        priceDrop: parseColor(computedStyle.getPropertyValue("--pricedrop")),

        priceHike: parseColor(computedStyle.getPropertyValue("--pricehike")),
        priceDropColor: parseColor(
          computedStyle.getPropertyValue("--pricedropcolor")
        ),
        priceHikeColor: parseColor(
          computedStyle.getPropertyValue("--pricehikecolor")
        ),
        priceDropBorder: parseColor(
          computedStyle.getPropertyValue("--pricedropborder")
        ),
        priceHikeBorder: parseColor(
          computedStyle.getPropertyValue("--pricehikeborder")
        ),
      };

      // console.log("Retrieved Theme Colors: ", themeColors);

      // Processed Colors
      const processedColors = {
        mainColor: darkenRgb(baseColor, 10),
        mainKaFikaColor: lightenRgba(themeColors.mainKaFikaColor, 10),
        afterHoverColor: darkenHex(themeColors.afterHoverColor, 10),
        // universalShadow: lightenRgba(themeColors.universalShadow, 50),
        lightGrey: lightenHex(themeColors.lightGrey, 10),
        buttonColor: darkenHex(themeColors.buttonColor, 15),
        secondaryColor: lightenHex(themeColors.secondaryColor, 20),
        addCartButton: darkenHex(themeColors.addCartButton, 15),
        shareIcon: darkenHex(themeColors.shareIcon, 10),
        phoneIcon: darkenHex(themeColors.phoneIcon, 10),
        payButton: darkenHex(themeColors.payButton, 20),
        orderButton: darkenHex(themeColors.orderButton, 20),
        pageBar: darkenHex(themeColors.pageBar, 10),
        boxShadow: darkenRgba(themeColors.boxShadow, 10),
        priceDrop: darkenRgb(themeColors.priceDrop, 20),
        priceHike: lightenRgb(themeColors.priceHike, 20),
        priceDropColor: darkenHex(themeColors.priceDropColor, 15),
        priceHikeColor: lightenHex(themeColors.priceHikeColor, 15),
        priceDropBorder: darkenHex(themeColors.priceDropBorder, 20),
        priceHikeBorder: lightenHex(themeColors.priceHikeBorder, 20),
      };

      // console.log("Processed Theme Colors: ", processedColors);

      // Apply processed colors back to CSS variables
      Object.entries(processedColors).forEach(([key, color]) => {
        if (color) {
          document.documentElement.style.setProperty(`--${key}`, color);
        }
      });
    }
  };

  useEffect(() => {
    applyThemeColors(selectedColor);
  }, [selectedColor]);

  return (
    <ColorThemeContext.Provider value={{ selectedColor, setSelectedColor }}>
      {children}
    </ColorThemeContext.Provider>
  );
};
