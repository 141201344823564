import React from "react";
import "./TermsContent.css";

const TermsContent = () => {
  return (
    <div className="terms-content-wrapper">
      <div className="terms-content">
        <h1>Terms and Conditions</h1>
        <span>
          Welcome to our application. If you continue to browse and use this
          application, you are agreeing to comply with and be bound by the
          following terms and conditions of use, which together with our privacy
          policy govern our company's relationship with you in relation to this
          application. If you disagree with any part of these terms and
          conditions, please do not use our application.
        </span>
        <h2>Use of the Application</h2>
        <span>
          The use of this application is subject to the following terms of use:
          <ul>
            <li>
              The content of the pages of this application is for your general
              information and use only. It is subject to change without notice.
            </li>
            <li>
              This application uses cookies to monitor browsing preferences. If
              you do allow cookies to be used, personal information may be
              stored by us for use by third parties.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              this application for any particular purpose. You acknowledge that
              such information and materials may contain inaccuracies or errors
              and we expressly exclude liability for any such inaccuracies or
              errors to the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on this application is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this application meet
              your specific requirements.
            </li>
          </ul>
        </span>
        <h2>Privacy Policy</h2>
        <span>
          Our privacy policy, which sets out how we will use your information,
          can be found at [insert link]. By using this application, you consent
          to the processing described therein and warrant that all data provided
          by you is accurate.
        </span>
        <h2>Changes to the Terms</h2>
        <span>
          We reserve the right to update or modify these terms at any time
          without prior notice. Your use of the application following any such
          change constitutes your agreement to follow and be bound by the terms
          as changed. For this reason, we encourage you to review these terms
          whenever you use this application.
        </span>
      </div>
    </div>
  );
};

export default TermsContent;
