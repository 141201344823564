import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MenuItem.css"; // Ensure to define your CSS styles
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables"; // Ensure to import your environment variables

const MenuItem = ({ menuVisible, setMenuVisible }) => {
  const menuRef = useRef(null);
  const roleDropdownRef = useRef(null); // Ref for the role switch dropdown
  const navigate = useNavigate();
  const {
    isLoggedIn,
    isLoading,
    previewImage,
    setPreviewImage,
    user,
    setUser,
  } = useAuth();
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const [roles, setRoles] = useState([]);
  const [roleListVisible, setRoleListVisible] = useState(false); // State to manage role list visibility
  const { apiService, showLoader, hideLoader, showNotification } = useDI();

  // Define base URL for images
  const baseImageUrl = EnvVariables.BaseUrlforImage;

  // State for image modal
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        (!roleDropdownRef.current ||
          !roleDropdownRef.current.contains(event.target))
      ) {
        setMenuVisible(false);
        setRoleListVisible(false); // Close role list when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuVisible]);

  useEffect(() => {
    // Fetch menu items only after loading completes
    let initialRole = isLoggedIn ? user?.systemrole || null : "Guest";
    fetchMenuItems(initialRole);
  }, [isLoggedIn]);

  const fetchMenuItems = async (role) => {
    try {
      if (!isLoggedIn) {
        const data = {};
        const combinedMenuItems = [
          { title: "HR", items: data.HrList || [], direct: true },
          { title: "Masters", items: data.MasterList || [] },
          { title: "NareshAdmin", items: data.NareshAdminList || [] },
          { title: "Dash", items: data.DashList || [] },
          { title: "Common", items: data.CommonList || [] },
        ];
        setMenuItems(combinedMenuItems);
        setUser(
          data.personalDatas || { Name: "Welcome", CurrentRole: "Guest" }
        );
        setRoles((data.personalDatas?.Role || "").split(","));
      } else {
        showLoader();
        const response = isLoggedIn
          ? await apiService.postAPI(
              { role },
              EnvVariables.GetMenuDataAfterLogin
            )
          : await apiService.postAPI(
              { role },
              EnvVariables.GetMenuDataBeforeLogin
            );

        hideLoader();

        const data = response.data.RData || {};
        const combinedMenuItems = [
          { title: "HR", items: data.HrList || [], direct: true },
          { title: "Masters", items: data.MasterList || [] },
          { title: "NareshAdmin", items: data.NareshAdminList || [] },
          { title: "Dash", items: data.DashList || [] },
          { title: "Common", items: data.CommonList || [] },
        ];
        setMenuItems(combinedMenuItems);

        if (isLoggedIn) {
          setUser(
            data.personalDatas || { Name: "Welcome", CurrentRole: "Guest" }
          );
          setRoles((data.personalDatas?.Role || "").split(","));
        } else {
          setUser(
            data.personalDatas || { Name: "Welcome", CurrentRole: "Guest" }
          );
          setRoles((data.personalDatas?.Role || "").split(","));
        }
      }
    } catch (error) {
      hideLoader();
      showNotification("Failed to fetch menu items", "error");
      console.error("Failed to fetch menu items:", error);
    }
  };

  // Function to toggle submenu visibility
  const toggleSubmenu = (menuTitle) => {
    setOpenSubmenu(openSubmenu === menuTitle ? null : menuTitle);
  };

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
    setMenuVisible(false);
  };

  // Function to handle role switching
  const switchRole = (role) => {
    showNotification(`Switched to role: ${role}`, "success");
    fetchMenuItems(role); // Fetch menu items for the selected role
    if (role === "User") navigate("/managecomplaint");
    else if (role === "Rental") navigate("/");
    else if (role === "SUPERADMIN") navigate("/addmastercomplaint");
    else {
      navigate("/");
    }
  };

  // Function to handle profile image click (open modal)
  const handleProfileImageClick = () => {
    setPreviewImage(
      user.Profileimg
        ? `${baseImageUrl}${user.Profileimg}`
        : `${baseImageUrl}/default-profile.png`
    );
    setImageModalOpen(true);
  };

  // Function to handle profile image change
  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);

      try {
        showLoader();
        const response = await apiService.postAPI(
          formData,
          EnvVariables.UpdateProfileImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        hideLoader();
        const updatedProfileImage = response.data.Profileimg;
        setUser((prevUser) => ({
          ...prevUser,
          Profileimg: updatedProfileImage,
        }));
        showNotification("Profile image updated successfully", "success");
        setPreviewImage(`${baseImageUrl}${updatedProfileImage}`);
      } catch (error) {
        hideLoader();
        showNotification("Failed to update profile image", "error");
        console.error("Failed to update profile image:", error);
      }
    }
  };

  // Function to handle closing the image modal
  const closeImageModal = () => {
    setPreviewImage(null);
    setImageModalOpen(false);
  };

  return (
    <div className={`menu-area ${menuVisible ? "active" : ""}`} ref={menuRef}>
      {user && (
        <div className="user-info">
          <img
            src={
              user.Profileimg
                ? `${baseImageUrl}${user.Profileimg}`
                : `${baseImageUrl}/default-profile.png`
            }
            alt="User Profile"
            className="profile-image"
            onClick={isLoggedIn ? handleProfileImageClick : undefined}
            style={{ cursor: isLoggedIn ? "pointer" : "default" }}
          />
          <div className="user-details">
            <div className="user-name">
              {isLoggedIn ? user.Name : "Welcome"}
            </div>
            <div className="user-role">
              {isLoggedIn ? user.CurrentRole : "Guest"}
            </div>
            {roles && roles.length > 1 && (
              <div
                className={`role-switch-dropdown ${
                  roleListVisible ? "active" : ""
                }`}
                ref={roleDropdownRef} // Assign ref to role switch dropdown
                onMouseEnter={() => setRoleListVisible(true)}
                onMouseLeave={() => setRoleListVisible(false)}
              >
                <button className="role-switch-button">Switch Role</button>
                <ul className="role-list">
                  {roles.map((rolen, index) => (
                    <li
                      key={index} // Assign a unique key, even if role is null
                      className="role-item"
                      onClick={() => switchRole(rolen)}
                    >
                      {rolen || "Unknown Role"}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      <ul className="vertical-menu">
        {menuItems.map((menu) =>
          menu.direct
            ? menu.items.map((item, index) => (
                <li
                  key={index}
                  className="menu-item direct-item"
                  onClick={() => handleNavigation(`/${item.FormName}`)}
                >
                  {item.FormName}
                </li>
              ))
            : menu.items.length > 0 && (
                <li
                  key={menu.title}
                  className={`menu-item ${
                    menu.items.length > 0 ? "has-submenu" : ""
                  }`}
                >
                  <div
                    className="menu-title"
                    onClick={() => toggleSubmenu(menu.title)}
                  >
                    {menu.title}
                  </div>
                  {menu.items.length > 0 && openSubmenu === menu.title && (
                    <ul className="submenu-list">
                      {menu.items.map((submenu, index) => (
                        <li
                          key={index}
                          className="submenu-item"
                          onClick={() =>
                            handleNavigation(`/${submenu.FormName}`)
                          }
                        >
                          {submenu.FormTitle}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
        )}
      </ul>

      {/* Image Preview Modal */}
      {imageModalOpen && (
        <div className="image-preview-modal">
          <div className="image-preview-container">
            <img src={previewImage} alt="Preview" className="preview-image" />
            <label htmlFor="profileImage" className="change-image-label">
              Change Image
            </label>
            <input
              type="file"
              id="profileImage"
              accept="image/*"
              onChange={handleProfileImageChange}
              style={{ display: "none" }}
            />
            <button className="close-modal-button" onClick={closeImageModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
