import React from 'react';
import useTruncateText from './useTruncateText';

const TruncateTextCell = ({ text, maxLength = 20 }) => {
    const { renderTruncatedText } = useTruncateText(text, maxLength);

    return <div>{renderTruncatedText()}</div>;
};

export default TruncateTextCell;
