import React, { createContext, useContext } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children, value }) => (
    <CartContext.Provider value={value}>
        {children}
    </CartContext.Provider>
);
