import React from "react";
import "./Footer.css"; // Ensure this path is correct and points to your CSS file

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      &copy; {currentYear} Bpt Pvt Ltd. All rights reserved.
    </div>
  );
};

export default Footer;
