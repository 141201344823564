import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  TextField,
  Radio,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { useCart } from "../hooks/CartContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAlert from "@mui/material/Alert"; // For Snackbar alert
import DirectionsIcon from "@mui/icons-material/Directions";
import { useDI } from "../hooks/DIContext"; // Ensure this import is correct
import { toast } from "react-toastify";
import { EnvVariables } from "../EnvVariables";
import Imageslider from "../servicecomponent/Imageslider";
import TruncateTextCell from "../servicecomponent/TruncateTextCell";
import {
  GetOtpfordifferantpurpose,
  validateOtp,
  formattedDate,
  fetchEncryptionKey,
  encryptData,
} from "../servicecomponent/commondataclassfiles/encryptionService";
import {
  usermsgadditem,
  ComplationStatus,
} from "../servicecomponent/commondataclassfiles/commonarrays";
import { calculateDistance } from "../servicecomponent/calculateDistance";

const ServicesDetails = ({ userItemId, onAcceptCostingResult }) => {
  const { selectedLocation } = useCart();
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();

  const [costingData, setCostingData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [distances, setDistances] = useState([]);
  //const [distances, setDistances] = useState(new Map());
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [maxDistance, setMaxDistance] = useState(0);
  const [filteredCostingData, setFilteredCostingData] = useState([]);
  const [otp, setOtp] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [OtpSessionId, setOtpSessionId] = useState(null);
  const [imagePaths, setImagePaths] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const baseURL = EnvVariables.BaseUrlforImage;
  // Fetch costing data
  const fetchCostingData = async () => {
    try {
      const pagingDto = {
        currentPage,
        PageSize: rowsPerPage,
        ServiceCostingId: userItemId,
      };

      const response = await apiService.postAPI(
        pagingDto,
        EnvVariables.Get_Coasting_Details_For_Provider
      );
      const data = response.data?.RDatalist || null;

      if (Array.isArray(data) && data.length > 0) {
        setCostingData(data);
        setTotalRecords(data[0]?.TotalRecords || 0);

        // Call calculateDistances to calculate only valid distances
        calculateDistances(data);
      } else {
        setSnackbarMessage("No costing data found.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching costing data:", error);
      setSnackbarMessage("Failed to fetch costing data.");
      setSnackbarOpen(true);
    }
  };

  // Calculate distances based on technician and user locations
  const calculateDistances = async (data) => {
    const userCoords = {
      Latitude: selectedLocation?.latitude,
      Longitude: selectedLocation?.longitude,
    };

    if (!isValidCoords(userCoords)) {
      console.error("Invalid user coordinates:", userCoords);
      return;
    }

    const distancePromises = data.map(async (costing) => {
      const techCoords = {
        TechLatitude: costing?.CustomerLatitude,
        TechLongitude: costing?.CustomerLongitude,
      };

      if (!isValidCoords(techCoords, true)) {
        console.error("Invalid technical coordinates:", techCoords);
        return null;
      }

      try {
        const distance = await calculateDistance(techCoords, userCoords);
        return distance; // Ensure this returns a valid distance object
      } catch (error) {
        console.error("Distance calculation failed:", {
          techCoords,
          userCoords,
          error,
        });
        return null;
      }
    });

    const calculatedDistances = await Promise.all(distancePromises);
    setDistances(calculatedDistances.filter((distance) => distance !== null)); // Ensure nulls are filtered out
  };
  // Validate coordinates
  const isValidCoords = (coords, isTechCoords = false) => {
    if (isTechCoords) {
      return (
        typeof coords.TechLatitude === "number" &&
        coords.TechLatitude !== null &&
        typeof coords.TechLongitude === "number" &&
        coords.TechLongitude !== null
      );
    } else {
      return (
        typeof coords.Latitude === "number" &&
        coords.Latitude !== null &&
        typeof coords.Longitude === "number" &&
        coords.Longitude !== null
      );
    }
  };

  // Apply filters to costing data
  const applyFilters = useCallback(
    (data) => {
      const distancesMap = new Map(
        distances.map((distance) => [
          distance.serviceCoastingId,
          distance.distance,
        ])
      );

      return data.filter((costing) => {
        const matchesText =
          !filterText ||
          (costing.Name?.toLowerCase().includes(filterText.toLowerCase()) ??
            false) ||
          (costing.Description?.toLowerCase().includes(
            filterText.toLowerCase()
          ) ??
            false);

        const distance = distancesMap.get(costing.ServiceCoastingId);
        const withinDistance =
          maxDistance <= 0 ||
          distance === undefined || // If no distance, don't filter by it
          parseFloat(distance) <= maxDistance;

        return matchesText && withinDistance;
      });
    },
    [distances, filterText, maxDistance]
  );

  useEffect(() => {
    fetchCostingData();
  }, [userItemId, selectedLocation, currentPage, rowsPerPage, filterText]);

  useEffect(() => {
    if (costingData.length > 0) {
      setFilteredCostingData(applyFilters(costingData));
    }
  }, [filterText, maxDistance, costingData, distances]);

  const getStatusLabel = (workDoneStatus) => {
    return (
      ComplationStatus.find((status) => status.value === workDoneStatus)
        ?.label || "Unknown Status"
    );
  };

  const handleDirectionsClick = (techCoords) => {
    if (!techCoords.TechLatitude || !techCoords.TechLongitude) {
      setSnackbarMessage(
        "Please update your location details in your profile."
      );
      setSnackbarOpen(true);
      return;
    }

    const googleMapsUrl =
      selectedLocation?.latitude && selectedLocation?.longitude
        ? `https://www.google.com/maps/dir/?api=1&origin=${selectedLocation.latitude},${selectedLocation.longitude}&destination=${techCoords.TechLatitude},${techCoords.TechLongitude}`
        : `https://www.google.com/maps/search/?api=1&query=${techCoords.TechLatitude},${techCoords.TechLongitude}`;

    window.open(googleMapsUrl, "_blank");
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleCostingChange = (id, field, value) => {
    setCostingData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value,
      },
    }));
  };

  const handleGetOtp = async (id, email) => {
    console.log(id, email);
    await GetOtpfordifferantpurpose({
      phoneNumber: id,
      email, // Change this to 'email' instead of 'CustomerEmail'
      showLoader,
      hideLoader,
      showNotification,
      otppurpose: "OTP for the Work Done Process Related to Your Complaint",
      otpUrl: EnvVariables.Get_Otp_Forworkdonestatus,
      setOtpSessionId,
    });
  };

  const handleworkdone = async (item) => {
    const { sessionId, key } = await fetchEncryptionKey();
    const userItemId = item.ServiceCostingId;

    const currentCosting = {
      ...costingData[userItemId],
      ServiceCostingId: userItemId,
    };

    // Start the loader first
    showLoader();

    // Validate the form for this item
    if (!validateForm(userItemId)) {
      hideLoader();
      return;
    }

    // Create the unified cost model with required fields
    const costmodel = {
      SessionId: sessionId,
      OtpSessionId: encryptData(OtpSessionId, key),
      ServiceCostingId: String(currentCosting.ServiceCostingId || userItemId), // Convert to string for the ID
      PaidAmountToProvider: currentCosting.amountToPay
        ? parseFloat(currentCosting.amountToPay)
        : null, // Paid Amount field
      OTP: encryptData(currentCosting.otp, key), // Ensure OTP is included
      // Automatically setting the completion date to today
      WorkDoneStatus: currentCosting.status || null,
      CustomerEmail: item.CustomerEmail || "",
    };

    // Validate costmodel before making the API call
    const requiredFields = ["ServiceCostingId", "PaidAmountToProvider", "OTP"]; // Ensure these fields are filled

    for (const field of requiredFields) {
      if (!costmodel[field]) {
        toast.error(`Error: ${field} is required.`);
        hideLoader();
        return;
      }
    }

    // Proceed with the confirmation dialog and API call
    setConfirmDialog({
      isOpen: true,
      message: "Are you sure you want to proceed to complete the work?",
      onConfirm: async () => {
        try {
          // API call to add/update costing
          const response = await apiService.postAPI(
            costmodel,
            EnvVariables.Call_For_Forworkdonestatus
          );

          // Check response for success or failure
          if (response.data.IsError === false) {
            toast.success(response.data.RDatalist[0].Msg);

            const updatedItems = filteredCostingData.map((itemnew) =>
              String(itemnew.ServiceCostingId) === String(item.ServiceCostingId)
                ? {
                    ...itemnew,

                    WorkDoneStatus: costmodel.WorkDoneStatus,
                    PaidAmountToProviderDate: formattedDate, // Update completion date
                    PaidAmountToProvider: costmodel.PaidAmountToProvider, // Update with PaidAmountToProvider
                  }
                : itemnew
            );
            setFilteredCostingData(updatedItems); // Update the state with filteredCostingData

            handleClearCosting(userItemId);
          } else {
            toast.error(response.data.RDatalist[0].Msg);
          }
        } catch (error) {
          console.error("Error adding/updating costing:", error);
          toast.error("An error occurred while processing costing.");
        } finally {
          handleCancel(); // Ensure form reset
          setConfirmDialog({ isOpen: false }); // Close confirm dialog
          hideLoader(); // Hide loader after everything is done
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
        hideLoader(); // Hide loader if the user cancels
      },
    });
  };
  const handleClearCosting = (userItemId) => {
    setCostingData((prev) => ({
      ...prev,
      [userItemId]: {
        amountToPay: "",
        otp: "",
        // Reset other fields as necessary
      },
    }));
    // If you need to clear validation errors
    setValidationErrors((prev) => ({
      ...prev,
      [userItemId]: {},
    }));
  };
  const handleCancel = () => {
    // Any cleanup logic if needed
    setSnackbarMessage(""); // Clear snackbar message if applicable
    setValidationErrors({}); // Clear validation errors
    // Optionally reset costing data or other states
  };
  const validateForm = (ServiceCostingId) => {
    const errors = {};
    const data = costingData[ServiceCostingId] || {};

    // Service charge validation

    // Status validation
    if (
      data.status === undefined ||
      data.status === null ||
      data.status === ""
    ) {
      errors.status = "Please select a status.";
    }

    // Amount to Pay validation
    const amountToPay = Number(data.amountToPay || 0);
    if (isNaN(amountToPay) || amountToPay <= 0) {
      errors.amountToPay = "Amount to pay must be a positive number.";
    }

    // OTP validation

    if (!validateOtp(data.otp)) {
      showNotification("Please enter a valid 6-digit OTP.", "error");
      return;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [ServiceCostingId]: errors,
    }));

    return Object.keys(errors).length === 0;
  };
  const openDialog = (MasterItemImage) => {
    const imagesArray = MasterItemImage.split(",").map(
      (path) => `${baseURL}${path.trim()}`
    );
    setImagePaths(imagesArray);
    setModalOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImages([]);
  };
  const handlePrevImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : dialogImages.length - 1
      );
    }
  };

  const handleNextImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < dialogImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: "#f0f0f0",
          p: 2,
          borderRadius: 2,
          textAlign: "center",
          boxShadow: 1,
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "secondary.main", letterSpacing: 1 }}
        >
          Manage your Complaints
        </Typography>
      </Box>

      <Box sx={{ padding: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
        <TextField
          label="Filter"
          variant="outlined"
          fullWidth
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Filter by Name or Description"
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1">Filter by Maximum Distance</Typography>
          <Slider
            value={maxDistance}
            onChange={(event, newValue) => setMaxDistance(newValue)}
            aria-labelledby="distance-slider"
            valueLabelDisplay="auto"
            min={0}
            max={500}
          />
          <Typography variant="body2">
            Max Distance: {maxDistance} km
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {filteredCostingData.length > 0 ? (
            filteredCostingData.map((costing, index) => (
              <Grid item xs={12} key={index}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ fontSize: "3rem", color: "primary.main" }}
                      />
                    }
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      transition: "background-color 0.3s ease",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                      //padding: "16px 24px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Costing {index + 1} - {"Accept by customer : "}{" "}
                          {costing.CustomerName}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ mt: 2, color: "secondary.main" }}
                        >
                          {costing.AcceptStatusDatestr ? (
                            <>
                              Accept this costing as on date:{" "}
                              {costing.AcceptStatusDatestr}
                            </>
                          ) : (
                            "This costing has not been accepted till date."
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Distance from you:
                        </Typography>

                        <Typography variant="body1">
                          {selectedLocation
                            ? distances[index]?.distanceValue ||
                              "Calculating distance..."
                            : "Please select a location to view distances in km..."}
                          <DirectionsIcon
                            sx={{
                              fontSize: "2rem",
                              color: "secondary.main",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDirectionsClick({
                                TechLatitude: costing.TechLatitude,
                                TechLongitude: costing.TechLongitude,
                              })
                            }
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            padding: 1,
                            bgcolor: "#f5f5f5",
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="secondary.main"
                            sx={{ fontWeight: "bold" }}
                          >
                            Your Information
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Cost Added Date:
                        </Typography>
                        <Typography variant="body1">
                          {costing.AddedDatestr}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Service Provider Name:
                        </Typography>
                        <Typography variant="body1">{costing.Name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Business type:
                        </Typography>
                        <Typography variant="body1">
                          {costing.UserType}
                        </Typography>
                      </Grid>

                      {costing.UserType !== "Self" && (
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Business Name:
                          </Typography>
                          <Typography variant="body1">
                            {costing.Businessname}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Service Charge:
                        </Typography>
                        <Typography variant="body1">
                          {costing.ServiceCharge}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Min Cost:
                        </Typography>
                        <Typography variant="body1">
                          {costing.MinCost}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Max Cost:
                        </Typography>
                        <Typography variant="body1">
                          {costing.MaxCost}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Description:
                        </Typography>
                        <Typography variant="body1">
                          <TruncateTextCell
                            text={costing.Description}
                            maxLength={50}
                          />
                        </Typography>
                      </Grid>
                      {costing.WarrantyType !== "notIncluded" ? (
                        <>
                          <Grid item xs={12} sm={6} md={3}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Warranty Type:
                            </Typography>
                            <Typography variant="body1">
                              {costing.WarrantyType}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Duration Time :
                            </Typography>
                            <Typography variant="body1">
                              {costing.Duration} {costing.DurationType}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", color: "red" }}
                          >
                            You have not given any warranty.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    {/* customer information */}
                    <Grid container spacing={2} mt={2}>
                      {/* Title for Customer Information */}
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            padding: 1,
                            bgcolor: "#f5f5f5",
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="secondary.main"
                            sx={{ fontWeight: "bold" }}
                          >
                            Complaint Information
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Customer Image */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Box
                          component="img"
                          src={`${baseURL}${
                            costing.SingleItemImage.split(",")[0]
                          }`}
                          alt={costing.ItemName}
                          onClick={() => openDialog(costing.MasterItemImage)}
                          sx={{
                            width: { xs: "100%", md: "100px" },
                            height: { xs: "auto", md: "100px" },
                            objectFit: "cover",
                            borderRadius: 1,
                            cursor: "pointer",
                            boxShadow: 2,
                          }}
                        />
                      </Grid>

                      {/* Customer Name */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Customer Name:
                        </Typography>
                        <Typography variant="body1">
                          {costing.CustomerName}
                        </Typography>
                      </Grid>

                      {/* Phone Number */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Phone Number:
                        </Typography>
                        <Typography variant="body1">
                          {costing.CustomerPhoneNo}
                        </Typography>
                      </Grid>

                      {/* Complaint Category */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Complaint Category:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            {costing.CategoryName}
                          </Typography>
                        </Typography>
                      </Grid>

                      {/* Complaint Regarding */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Complaint Regarding:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            {costing.ItemName}
                          </Typography>
                        </Typography>
                      </Grid>

                      {/* Exact Complaint Type */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Exact Complaint Type:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            {costing.ItemType}
                          </Typography>
                        </Typography>
                      </Grid>

                      {/* Priority */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Priority:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            {costing.UOM}
                          </Typography>
                        </Typography>
                      </Grid>

                      {/* Complaint Date */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Complaint Date:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            {costing.CreationDatestr}
                          </Typography>
                        </Typography>
                      </Grid>

                      {/* Description */}
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Description:
                          <Typography
                            component="span"
                            sx={{ fontWeight: "normal" }}
                          >
                            <TruncateTextCell
                              text={costing.ItemDescription}
                              maxLength={50}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>

                    {costing.AcceptStatus === 1 ? (
                      costing.WorkDoneStatus !== 1 ? (
                        <>
                          <Grid container spacing={2} mt={2}>
                            {/* Section Title Box */}
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  padding: 2,
                                  bgcolor: "#f5f5f5",
                                  borderRadius: 1,
                                  mb: 2,
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  color="secondary.main"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  Proceed to Complete the Task
                                </Typography>
                              </Box>
                            </Grid>

                            {/* Radio buttons for Work Status */}
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Work Status:
                              </Typography>
                              {ComplationStatus.map((status) => (
                                <Grid
                                  key={status.value}
                                  container
                                  alignItems="center"
                                  sx={{ mb: 1 }}
                                >
                                  <Radio
                                    checked={
                                      costingData[costing.ServiceCostingId]
                                        ?.status === status.value
                                    }
                                    onChange={() =>
                                      handleCostingChange(
                                        costing.ServiceCostingId,
                                        "status",
                                        status.value
                                      )
                                    }
                                  />
                                  <Typography>{status.label}</Typography>
                                </Grid>
                              ))}
                              {/* Display error message for status */}
                              {validationErrors[costing.ServiceCostingId]
                                ?.status && (
                                <Typography
                                  variant="body2"
                                  sx={{ color: "red", fontSize: "1rem", mt: 1 }}
                                >
                                  {
                                    validationErrors[costing.ServiceCostingId]
                                      ?.status
                                  }
                                </Typography>
                              )}
                            </Grid>

                            {/* Text field for Amount to Pay */}
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Amount to Pay"
                                value={
                                  costingData[costing.ServiceCostingId]
                                    ?.amountToPay || ""
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Allow only numbers and prevent negative numbers or '--'
                                  const validValue = inputValue
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/^0+(?=\d)/, "");

                                  handleCostingChange(
                                    costing.ServiceCostingId,
                                    "amountToPay",
                                    validValue
                                  );
                                }}
                                fullWidth
                                error={Boolean(
                                  validationErrors[costing.ServiceCostingId]
                                    ?.amountToPay
                                )}
                                helperText={
                                  validationErrors[costing.ServiceCostingId]
                                    ?.amountToPay ? (
                                    <span
                                      style={{ color: "red", fontSize: "1rem" }}
                                    >
                                      {
                                        validationErrors[
                                          costing.ServiceCostingId
                                        ]?.amountToPay
                                      }
                                    </span>
                                  ) : null
                                }
                              />
                            </Grid>

                            {/* Text field for OTP */}
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="OTP"
                                value={
                                  costingData[costing.ServiceCostingId]?.otp ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleCostingChange(
                                    costing.ServiceCostingId,
                                    "otp",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                error={Boolean(
                                  validationErrors[costing.ServiceCostingId]
                                    ?.otp
                                )}
                                helperText={
                                  validationErrors[costing.ServiceCostingId]
                                    ?.otp ? (
                                    <span
                                      style={{ color: "red", fontSize: "1rem" }}
                                    >
                                      {
                                        validationErrors[
                                          costing.ServiceCostingId
                                        ]?.otp
                                      }
                                    </span>
                                  ) : null
                                }
                              />
                            </Grid>

                            {/* Button to trigger OTP */}
                            <Grid item xs={12} sm={6} md={3}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  handleGetOtp(
                                    costing.CustomerPhoneNo,
                                    costing.CustomerEmail
                                  )
                                }
                                fullWidth
                              >
                                Get OTP
                              </Button>
                            </Grid>
                          </Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleworkdone(costing)}
                            sx={{ mt: 2 }}
                          >
                            Complete Process {index + 1}
                          </Button>
                          <Typography
                            variant="body1"
                            sx={{ mt: 2, color: "secondary.main" }}
                          >
                            You will proceed to complete the process using the
                            Get OTP button. The OTP will be sent to the
                            customer's phone number and email. Fill in all the
                            fields, then click on Complete Process.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={2} mt={2}>
                            {/* Section Title Box */}
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  padding: 2,
                                  bgcolor: "#f5f5f5",
                                  borderRadius: 1,
                                  mb: 2,
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  color="secondary.main"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  This work process complated.
                                </Typography>
                              </Box>
                              <Grid container spacing={2} mt={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Status of Your Work:
                                  </Typography>
                                  <Typography variant="body1">
                                    {getStatusLabel(costing.WorkDoneStatus)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Work Status Date:
                                  </Typography>
                                  <Typography variant="body1">
                                    {costing.PaidAmountToProviderDatestr ||
                                      "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Paid Amount:
                                  </Typography>
                                  <Typography variant="body1">
                                    {costing.PaidAmountToProvider || "N/A"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Typography
                            variant="body1"
                            sx={{ mt: 2, color: "secondary.main" }}
                          >
                            This process is completed.
                          </Typography>
                        </>
                      )
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ mt: 2, color: "secondary.main" }}
                      >
                        Once the customer accepts this costing, the next field
                        will be visible to complete the process using an OTP.
                        The OTP will be sent to the customer's phone number and
                        email.
                      </Typography>
                    )}

                    {/* Accept Button for each costing entry */}

                    {/* {costing.WorkDoneStatus !== 1 ? (
                    costing.AcceptStatus !== 1 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleworkdone(costing)}
                          sx={{ mt: 2 }}
                        >
                          Complete Process {index + 1}
                        </Button>
                        <Typography
                          variant="body1"
                          sx={{ mt: 2, color: "secondary.main" }}
                        >
                          You will proceed to complete the process using the Get
                          OTP button. The OTP will be sent to the customer's
                          phone number and email. Fill in all the fields, then
                          click on Complete Process.
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ mt: 2, color: "secondary.main" }}
                      >
                        Once the customer accepts this costing, the next field
                        will be visible to complete the process using an OTP.
                        The OTP will be sent to the customer's phone number and
                        email.
                      </Typography>
                    )
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ mt: 2, color: "secondary.main" }}
                    >
                      This process is completed.
                    </Typography>
                  )} */}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1">
                No costing data available after filtering.
              </Typography>
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecords / rowsPerPage)}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            shape="rounded"
            sx={{ marginRight: 2 }} // Space between Pagination and FormControl
          />
          <FormControl variant="outlined" sx={{ width: 120 }}>
            {" "}
            {/* Set a fixed width */}
            <InputLabel>Rows per page</InputLabel>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
            >
              {[1, 5, 10, 25, 100].map((rows) => (
                <MenuItem key={rows} value={rows}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            severity="info"
            sx={{ width: "100%" }}
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Box>
          <Dialog
            open={dialogOpen}
            onClose={closeDialog}
            fullWidth
            maxWidth="md"
          >
            <DialogContent
              sx={{
                padding: 0,
                margin: 0,
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "calc(100% - 10px)", // 5px margin on each side
                  height: "calc(80vh - 10px)", // 5px margin on top and bottom
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <IconButton
                  onClick={handlePrevImage}
                  disabled={
                    dialogImages.length === 0 || currentImageIndex === 0
                  }
                  sx={{
                    position: "absolute",
                    left: 16,
                    zIndex: 1,
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                    borderRadius: "50%",
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>

                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${baseURL}${dialogImages[currentImageIndex]}`}
                    alt="Dialog Image"
                    style={{
                      maxWidth: "calc(100% - 10px)", // 5px margin on each side
                      maxHeight: "calc(100% - 10px)", // 5px margin on top and bottom
                      objectFit: "contain", // Ensures the image fits within the container while preserving aspect ratio
                    }}
                  />
                </Box>

                <IconButton
                  onClick={handleNextImage}
                  disabled={
                    dialogImages.length === 0 ||
                    currentImageIndex === dialogImages.length - 1
                  }
                  sx={{
                    position: "absolute",
                    right: 16,
                    zIndex: 1,
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                    borderRadius: "50%",
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </DialogContent>
          </Dialog>
          <Imageslider
            open={modalOpen}
            onClose={handleCloseModal}
            imagePaths={imagePaths}
          />
        </Box>
      </Box>
    </>
  );
};

export default ServicesDetails;
