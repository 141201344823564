import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
} from "@mui/material";
import { PhotoCamera, Edit, Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import Pagination from "../layoutcompo/Pagination";
import { toast } from "react-toastify";
import {
  getImageDimensions,
  resizeImage,
  bannerDimensions,
  bannerTypes,
} from "../servicecomponent/imageService";
const BannerCreation = () => {
  const [banners, setBanners] = useState([]);
  const [form, setForm] = useState({
    BannerId: "",
    Title: "",
    Description: "",
    BannerType: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBannerId, setCurrentBannerId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);

  const baseURL = EnvVariables.BaseUrlforImage;

  useEffect(() => {
    fetchBanners();
  }, [currentPage, rowsPerPage, Prefix]);

  const fetchBanners = async () => {
    try {
      showLoader();
      setLoading(true);
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix },
        EnvVariables.Get_Banners
      );
      const data = response.data.RDatalist;

      if (Array.isArray(data) && data.length > 0) {
        setBanners(data);
        setTotalRecords(response.data.RDatalist[0].totalRecords || 0);
      } else {
        setBanners([]);
      }
    } catch (error) {
      enqueueSnackbar("Error fetching banners", { variant: "error" });
    } finally {
      setLoading(false);
      hideLoader();
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleFileChange = async (e) => {
    if (!form.BannerType || form.BannerType === "SelectBanner") {
      toast.error("Please select a banner type first.");
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const { maxWidth, maxHeight } = bannerDimensions[form.BannerType];

      try {
        // Get the dimensions of the image
        const { width, height } = await getImageDimensions(file);

        // Check if the dimensions are within ±20 pixels of the target dimensions
        // if (

        //   Math.abs(width - maxWidth) <= 20 &&
        //   Math.abs(height - maxHeight) <= 20
        // )

        if (1) {
          // Proceed with resizing
          const resizedImageFile = await resizeImage(file, maxWidth, maxHeight);
          setImagePreview(URL.createObjectURL(resizedImageFile));
          setSelectedImage(resizedImageFile);
        } else {
          setImagePreview(null);
          setSelectedImage(null);

          toast.error(
            `Please select an image with dimensions approximately ${maxWidth}x${maxHeight} pixels. Your image dimensions are ${width}x${height} pixels. Required dimensions should be within ±50 pixels.`
          );
        }
      } catch (error) {
        setImagePreview(null);
        setSelectedImage(null);
        toast.error("Error processing the image. Please try again.");
      }
    }
  };
  const handleSubmit = async () => {
    setConfirmDialog({
      isOpen: true,
      title: isEditing ? "Update Banner" : "Add Banner",
      message: isEditing
        ? "Are you sure you want to update this banner?"
        : "Are you sure you want to add this banner?",
      onConfirm: async () => {
        showLoader(); // Show loader when operation starts

        try {
          const formData = new FormData();
          formData.append("BannerId", form.BannerId);
          formData.append("Title", form.Title);
          formData.append("Description", form.Description);
          formData.append("BannerType", form.BannerType);

          if (selectedImage) {
            formData.append("bannerimage", selectedImage, selectedImage.name);
          } else if (isEditing && imagePreview) {
            formData.append("existingImage", imagePreview);
          }

          const url = isEditing
            ? EnvVariables.Update_Banner
            : EnvVariables.Create_Banner;

          const response = await apiService.postAPI(formData, url);

          if (response.data.IsError === false) {
            if (response.data.RDatalist[0].SuccessFailed === 1) {
              enqueueSnackbar(response.data.RDatalist[0].Msg, {
                variant: "success",
              });
              fetchBanners();
              handleCloseDialog(); // Close the dialog after success
            } else {
              enqueueSnackbar(response.data.RDatalist[0].Msg, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar("Unexpected response format", { variant: "error" });
          }
        } catch (error) {
          enqueueSnackbar("Error submitting the form", { variant: "error" });
        } finally {
          hideLoader(); // Hide loader after operation completes
          setConfirmDialog({ isOpen: false }); // Close the confirm dialog
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false }); // Close the confirm dialog
      },
    });
  };

  const handleEdit = (banner) => {
    setForm({
      BannerId: banner.BannerId,
      Title: banner.Title,
      Description: banner.Description,
      BannerType: banner.BannerType,
    });

    setImagePreview(`${baseURL}${banner.Image}`);

    setSelectedImage(null);

    const existingImageName = `old_${banner.Image.split("/").pop()}`;

    setCurrentBannerId(banner.BannerId);
    setIsEditing(true);
    setIsDialogOpen(true);
  };

  const handleDelete = async (bannerId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Banner",
      message:
        "Are you sure you want to update the availability status for all items?",
      onConfirm: async () => {
        try {
          showLoader();
          const bannerIdInt = parseInt(bannerId, 10);
          const response = await apiService.postAPI(
            { BannerId: bannerIdInt },
            EnvVariables.Delete_Banner
          );

          if (response.data.IsError === false) {
            if (response.data.RDatalist[0].SuccessFailed === 1) {
              enqueueSnackbar(response.data.RDatalist[0].Msg, {
                variant: "success",
              });
              fetchBanners();
            } else {
              enqueueSnackbar(response.data.RDatalist[0].Msg, {
                variant: "error",
              });
            }
          }
        } catch (error) {
          enqueueSnackbar("Error deleting the banner", { variant: "error" });
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEditing(false);
    setForm({
      BannerId: "",
      Title: "",
      Description: "",
      BannerType: "",
    });
    setImagePreview(null);
    setSelectedImage(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Banner Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsDialogOpen(true)}
      >
        Add Banner
      </Button>

      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Banner Type</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners.map((banner) => (
                <TableRow key={banner.BannerId}>
                  <TableCell>{banner.Title}</TableCell>
                  <TableCell>{banner.Description}</TableCell>
                  <TableCell>{banner.BannerType}</TableCell>
                  <TableCell>
                    <img
                      src={`${baseURL}${banner.Image}`}
                      alt={banner.Title}
                      style={{
                        width: 100,
                        height: 50,
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(banner)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(banner.BannerId)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        currentPage={currentPage}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        onPageChange={(page) => setCurrentPage(page)}
        onRowsPerPageChange={(size) => setRowsPerPage(size)}
      />

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? "Edit Banner" : "Add Banner"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                name="Title"
                value={form.Title}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="Description"
                value={form.Description}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Banner Type</InputLabel>
                <Select
                  name="BannerType"
                  value={form.BannerType}
                  onChange={handleChange}
                >
                  {bannerTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={1}
                style={{
                  padding: "10px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                )}
                <input
                  accept="image/*"
                  id="upload-image"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="upload-image">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {isEditing ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BannerCreation;
