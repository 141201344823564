// apiService.js

import { axiosInstance ,setupInterceptors } from './axios-interceptors';

setupInterceptors();

const apiService = {
    searchEmployees: (data, api) => {
        return axiosInstance.get(api, { params: data });
    },
    postAPI: (data, api) => {
        return axiosInstance.post(api, data);
    },
    postAPIwithoutdata: (api) => {
        return axiosInstance.post(api);
    },
    getAPI: (data, api) => {
        return axiosInstance.get(api, data);
    },
    getAPIwithoutData: (api) => {
        return axiosInstance.get(api);
    },
    postAPIchangerole: (data, api) => {
        return axiosInstance.post(api, data);
    },
    postapisingle: (api) => {
        return axiosInstance.post(api, {});
    },
    checkToken: (api) => {
        return axiosInstance.get(api);
    },
    postAPIFD: (data, api) => {
        return axiosInstance.post(api, data);
    }
};

export default apiService;
