import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Chip,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { FaEdit, FaTrashAlt, FaTimes } from "react-icons/fa";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import {
  getImageDimensions,
  resizeImage,
  masterItemImageDimensions,
} from "../servicecomponent/imageService";
import { toast } from "react-toastify";

const ProductCategory = () => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn } = useAuth();
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({
    CategoryId: 0,
    CategoryName: "",
    CategoryImage: null,
    UOMs: [],
  });
  const [buttonText, setButtonText] = useState("Save");
  const [selectedUOM, setSelectedUOM] = useState([]);
  const availableUOMs = ["High Priority", "Low Priority"];
  const [editingIndex, setEditingIndex] = useState(null);
  const baseImageUrl = EnvVariables.BaseUrlforImage;
  const fileInputRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    loadCategories();
  }, [currentPage, rowsPerPage, prefix]);

  const loadCategories = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix: prefix },
        EnvVariables.Get_Item_Category
      );
      const data = response.data;

      if (Array.isArray(data) && data.length > 0) {
        setCategories(data);
        const totalRecords = data[0]?.totalRecords || 0;
        setTotalRecords(totalRecords);
      } else {
        setCategories([]);
        setTotalRecords(0);
        showNotification("No categories found", "info");
      }
    } catch (error) {
      console.error("Error loading categories:", error);
      setCategories([]);
      setTotalRecords(0);
      showNotification("Error loading categories", "error");
    }
  };

  const insertCategory = async (formData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Add Complaint Category",
      message: "Are you sure you want to Add this master complaint category?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            formData,
            EnvVariables.Insert_Item_Category,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          const result = response.data?.RDatalist[0];
          if (result?.SuccessFailed === 0) {
            showNotification(result.Msg, "error");
          } else {
            showNotification(result.Msg, "success");
            resetState();
            await loadCategories();
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const updateCategory = async (formData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Update Complaint Category",
      message:
        "Are you sure you want to update this master complaint category?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            formData,
            EnvVariables.Update_Item_Category,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          const result = response.data?.RDatalist[0];
          if (result?.SuccessFailed === 0) {
            showNotification(result.Msg, "error");
          } else {
            showNotification(result.Msg, "success");
            resetState();
            await loadCategories();
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const deleteCategory = async (CategoryId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Complaint Category",
      message:
        "Are you sure you want to delete this master complaint category?",
      onConfirm: async () => {
        try {
          showLoader();
          const formData = new FormData();
          formData.append("CategoryId", CategoryId);

          const response = await apiService.postAPI(
            formData,
            EnvVariables.DeleteItemCategoryAPI
          );
          const result = response.data?.RDatalist[0];
          if (result?.SuccessFailed === 1) {
            showNotification("Category deleted successfully", "success");
            await loadCategories();
          } else {
            showNotification("Failed to delete category", "error");
          }
        } catch (error) {
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const resetState = () => {
    setForm({
      CategoryId: 0,
      CategoryName: "",
      CategoryImage: null,
      UOMs: [],
    });
    setSelectedUOM([]);
    setButtonText("Save");
    setEditingIndex(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uomString = selectedUOM.join(",");
    const formData = new FormData();
    formData.append("CategoryId", form.CategoryId);
    formData.append("CategoryName", form.CategoryName);
    formData.append("UOM", uomString);
    if (form.CategoryImage) {
      formData.append("CategoryImage", form.CategoryImage);
    }

    if (form.CategoryId === 0) {
      await insertCategory(formData);
    } else {
      await updateCategory(formData);
    }
  };

  const handleEdit = (index) => {
    const item = categories[index];
    setForm({
      CategoryId: item.CategoryId,
      CategoryName: item.CategoryName,
      CategoryImage: item.CategoryImage,
      UOMs: item.UOM ? item.UOM.split(",") : [],
    });
    setSelectedUOM(item.UOM ? item.UOM.split(",") : []);
    setButtonText("Update");
    setEditingIndex(index);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file instanceof Blob) {
      const { maxWidth, maxHeight } = masterItemImageDimensions["MasterItem"];
      try {
        const { width, height } = await getImageDimensions(file);
        const resizedImageFile = await resizeImage(file, maxWidth, maxHeight);
        setForm({ ...form, CategoryImage: resizedImageFile });

        // Create object URL for image preview
        const imageUrl = URL.createObjectURL(resizedImageFile);
        setForm((prevForm) => ({ ...prevForm, imageUrl }));
      } catch (error) {
        toast.error("Error processing the image. Please try again.");
      }
    } else {
      toast.error("Selected file is not valid.");
    }
  };

  const removeImage = () => {
    setForm({ ...form, CategoryImage: null, imageUrl: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDelete = async (index) => {
    const item = categories[index];
    await deleteCategory(item.CategoryId);
  };

  const handleSearchChange = (e) => {
    setPrefix(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleUOMSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUOM(value);
  };

  const removeUOM = (uomToRemove) => {
    setSelectedUOM((prevSelectedUOM) =>
      prevSelectedUOM.filter((uom) => uom !== uomToRemove)
    );
  };

  return (
    <div style={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Complaint Category
      </Typography>

      <form
        onSubmit={handleSubmit}
        style={{
          marginBottom: "20px",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Complaint Name"
              value={form.CategoryName}
              onChange={(e) =>
                setForm({ ...form, CategoryName: e.target.value })
              }
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Priority Type</InputLabel>
              <Select
                multiple
                value={selectedUOM}
                onChange={handleUOMSelection}
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => removeUOM(value)}
                        style={{ margin: "2px" }}
                      />
                    ))}
                  </div>
                )}
              >
                {availableUOMs.map((uom) => (
                  <MenuItem key={uom} value={uom}>
                    {uom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInputRef.current.click()}
              style={{ marginRight: "16px" }}
            >
              Upload Image
            </Button>
            {form.imageUrl && (
              <Box mt={2} position="relative" display="inline-block">
                <img
                  src={form.imageUrl}
                  alt="Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "4px",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "white",
                    borderRadius: "50%",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                  onClick={removeImage}
                >
                  <FaTimes />
                </IconButton>
              </Box>
            )}
          </Grid>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              {buttonText}
            </Button>
          </Box>
        </Grid>
      </form>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        onChange={handleSearchChange}
        style={{ marginBottom: "20px" }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Complaint Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Deleted Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow key={category.CategoryId}>
                <TableCell>{category.CategoryName}</TableCell>
                <TableCell>
                  {category.CategoryImage && (
                    <img
                      src={`${baseImageUrl}${category.CategoryImage}`}
                      alt={category.CategoryName}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>{category.UOM}</TableCell>
                <TableCell>{category.Deleted}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(index)} color="primary">
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(index)}
                    color="secondary"
                  >
                    <FaTrashAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Pagination
          count={Math.ceil(totalRecords / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
        <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
          <InputLabel>Rows per page</InputLabel>
          <Select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[5, 10, 25].map((rows) => (
              <MenuItem key={rows} value={rows}>
                {rows}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default ProductCategory;
