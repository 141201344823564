// // src/servicecomponent/Loader.js
// import React from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
// import Backdrop from '@mui/material/Backdrop';

// const Loader = ({ isLoading }) => {
//     console.log('isLoading:', isLoading); // Log the value of isLoading
//     return (
//         <Backdrop open={isLoading || false} style={{ zIndex: 1000 }}>
//             <CircularProgress color="inherit" />
//         </Backdrop>
//     );
// };

// export default Loader;
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useDI } from '../hooks/DIContext';

const Loader = () => {
    const { isLoading } = useDI();

    return (
        <Backdrop open={isLoading} style={{ zIndex: 1000 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loader;