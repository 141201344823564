
// import { useSnackbar } from 'notistack';

// const useNotification = () => {
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();

//     const showNotification = (message, variant = 'default') => {
//         console.log('showNotification called:', message, variant); // Log to verify function call
//         enqueueSnackbar(message, { variant });
//     };

//     const hideNotification = (key) => {
//         closeSnackbar(key);
//     };

//     return { showNotification, hideNotification };
// };

// export default useNotification;


// import { useSnackbar } from 'notistack';

// const useNotification = () => {
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();

//     const showNotification = (message, variant = 'default') => {
//         // Customize snackbar appearance based on the variant
//         const options = {
//             variant,
//             autoHideDuration: 3000, // Adjust auto-hide duration
//             anchorOrigin: { vertical: 'top', horizontal: 'center' }, // Adjust position
//             // Add other options as needed
//         };

//         enqueueSnackbar(message, options);
//     };

//     const hideNotification = (key) => {
//         closeSnackbar(key);
//     };

//     return { showNotification, hideNotification };
// };

// export default useNotification;

import { useSnackbar } from 'notistack';

const useNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showNotification = (message, variant = 'default') => {
        // Customize snackbar appearance based on the variant
        const options = {
            variant,
            autoHideDuration: 3000, // Adjust auto-hide duration
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, // Adjust position
            // Customize appearance
            style: {
                backgroundColor: variant === 'success' ? 'green' : 'red', // Custom background color based on variant
                color: 'white', // Custom text color
            },
            // Customize behavior when closed
            onClose: (event, reason, key) => {
                if (reason === 'clickaway') {
                    // Do something if snackbar is dismissed by clicking away
                    console.log('Snackbar dismissed by clicking away');
                } else {
                    // Do something if snackbar is dismissed by clicking on the close button
                    console.log('Snackbar dismissed by clicking on the close button');
                }
            },
            // Add other options as needed
        };

        enqueueSnackbar(message, options);
    };

    const hideNotification = (key) => {
        closeSnackbar(key);
    };

    return { showNotification, hideNotification };
};

export default useNotification;
