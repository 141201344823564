import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAlert from "@mui/material/Alert"; // For Snackbar alert
import DirectionsIcon from "@mui/icons-material/Directions";
import { useDI } from "../hooks/DIContext"; // Ensure this import is correct
import { toast } from "react-toastify";
import { EnvVariables } from "../EnvVariables";
import TruncateTextCell from "../servicecomponent/TruncateTextCell";
import {
  usermsgadditem,
  ComplationStatus,
} from "../servicecomponent/commondataclassfiles/commonarrays";
import { calculateDistance } from "../servicecomponent/calculateDistance";
import { useCart } from "../hooks/CartContext";

const CostingComponent = ({ userItemId, onAcceptCostingResult }) => {
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { selectedLocation } = useCart();

  const [costingData, setCostingData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [distances, setDistances] = useState([]);
  //const [distances, setDistances] = useState(new Map());
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [maxDistance, setMaxDistance] = useState(0);
  const [filteredCostingData, setFilteredCostingData] = useState([]);

  // Fetch costing data
  const fetchCostingData = async () => {
    try {
      const pagingDto = {
        currentPage,
        PageSize: rowsPerPage,
        UserItemDetailsId: userItemId,
      };

      const response = await apiService.postAPI(
        pagingDto,
        EnvVariables.Get_Coasting_data
      );
      const data = response.data?.RDatalist || null;

      if (Array.isArray(data) && data.length > 0) {
        setCostingData(data);
        setTotalRecords(data[0]?.TotalRecords || 0);

        // Call calculateDistances to calculate only valid distances
        calculateDistances(data);
      } else {
        setSnackbarMessage("No costing data found.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching costing data:", error);
      setSnackbarMessage("Failed to fetch costing data.");
      setSnackbarOpen(true);
    }
  };

  // Calculate distances based on technician and user locations
  // const calculateDistances = async (data) => {
  //   const userCoords = {
  //     Latitude: selectedLocation?.latitude,
  //     Longitude: selectedLocation?.longitude,
  //   };

  //   if (!isValidCoords(userCoords)) {
  //     console.error("Invalid user coordinates:", userCoords);
  //     return;
  //   }

  //   const distancePromises = data.map(async (costing) => {
  //     const techCoords = {
  //       TechLatitude: costing?.TechLatitude,
  //       TechLongitude: costing?.TechLongitude,
  //     };

  //     if (!isValidCoords(techCoords, true)) {
  //       console.error("Invalid technical coordinates:", techCoords);
  //       return null;
  //     }

  //     try {
  //       const distance = await calculateDistance(techCoords, userCoords);
  //       return distance; // Ensure this returns a valid distance object
  //     } catch (error) {
  //       console.error("Distance calculation failed:", {
  //         techCoords,
  //         userCoords,
  //         error,
  //       });
  //       return null;
  //     }
  //   });

  //   const calculatedDistances = await Promise.all(distancePromises);
  //   setDistances(calculatedDistances.filter((distance) => distance !== null)); // Ensure nulls are filtered out
  // };
  const calculateDistances = async (data) => {
    const userCoords = {
      Latitude: selectedLocation?.latitude,
      Longitude: selectedLocation?.longitude,
    };

    if (!isValidCoords(userCoords)) {
      console.error("Invalid user coordinates:", userCoords);
      return;
    }

    const distancePromises = data.map(async (costing) => {
      const techCoords = {
        TechLatitude: costing?.TechLatitude,
        TechLongitude: costing?.TechLongitude,
      };

      if (!isValidCoords(techCoords, true)) {
        console.error("Invalid technical coordinates:", techCoords);
        return null;
      }

      try {
        const distance = await calculateDistance(techCoords, userCoords);
        return distance; // Ensure this returns a valid distance object
      } catch (error) {
        console.error("Distance calculation failed:", {
          techCoords,
          userCoords,
          error,
        });
        return null;
      }
    });

    const calculatedDistances = await Promise.all(distancePromises);
    setDistances(calculatedDistances.filter((distance) => distance !== null)); // Ensure nulls are filtered out
  };

  // Validate coordinates
  const isValidCoords = (coords, isTechCoords = false) => {
    if (isTechCoords) {
      return (
        typeof coords.TechLatitude === "number" &&
        coords.TechLatitude !== null &&
        typeof coords.TechLongitude === "number" &&
        coords.TechLongitude !== null
      );
    } else {
      return (
        typeof coords.Latitude === "number" &&
        coords.Latitude !== null &&
        typeof coords.Longitude === "number" &&
        coords.Longitude !== null
      );
    }
  };

  //Apply filters to costing data
  const applyFilters = useCallback(
    (data) => {
      const distancesMap = new Map(
        distances.map((distance) => [
          distance.serviceCoastingId,
          distance.distance,
        ])
      );

      return data.filter((costing) => {
        const matchesText =
          !filterText ||
          (costing.Name?.toLowerCase().includes(filterText.toLowerCase()) ??
            false) ||
          (costing.Description?.toLowerCase().includes(
            filterText.toLowerCase()
          ) ??
            false);

        const distance = distancesMap.get(costing.ServiceCoastingId);
        const withinDistance =
          maxDistance <= 0 ||
          distance === undefined || // If no distance, don't filter by it
          parseFloat(distance) <= maxDistance;

        return matchesText && withinDistance;
      });
    },
    [distances, filterText, maxDistance]
  );
  useEffect(() => {
    fetchCostingData();
  }, [userItemId, selectedLocation, currentPage, rowsPerPage, filterText]);

  useEffect(() => {
    if (costingData.length > 0) {
      setFilteredCostingData(applyFilters(costingData));
    }
  }, [filterText, maxDistance, costingData, distances]);

  const handleAcceptCosting = async (id) => {
    setConfirmDialog({
      isOpen: true,
      message: "Are you sure you want to accept this costing?",
      onConfirm: async () => {
        try {
          showLoader();

          const costingDetails = {
            ServiceCostingId: id,
          };

          const response = await apiService.postAPI(
            costingDetails,
            EnvVariables.Accpet_Costing_By_Customer
          );

          if (response.data.IsError === false) {
            toast.success(response.data.RDatalist[0].Msg);
            await updatelivedata(id);
            if (onAcceptCostingResult) {
              onAcceptCostingResult({
                success: true,
                message: "Costing accepted successfully",
              });
            }
          } else {
            if (onAcceptCostingResult) {
              onAcceptCostingResult({
                success: false,
                message: "Failed to accept costing: ",
              });
            }
            toast.error(response.data.RDatalist[0].Msg);
          }
        } catch (error) {
          console.error("Error accepting costing:", error);
          toast.error("Error accepting costing.");
        } finally {
          setConfirmDialog({ isOpen: false });
          hideLoader();
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const updatelivedata = async (serviceCostingId) => {
    const currentDate = new Date();
    const formattedDate = `${
      currentDate.getMonth() + 1
    }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    // Update the specific costing entry where the ID matches
    const updatedCostingData = filteredCostingData.map((costing) => {
      if (costing.ServiceCostingId === serviceCostingId) {
        return {
          ...costing,
          AcceptStatus: 1, // Update the status to accepted
          AcceptStatusDatestr: formattedDate, // Update with current date string
        };
      }
      return costing;
    });

    // Update the state with the modified costing data
    setFilteredCostingData(updatedCostingData);
  };

  const getStatusLabel = (workDoneStatus) => {
    return (
      ComplationStatus.find((status) => status.value === workDoneStatus)
        ?.label || "Unknown Status"
    );
  };

  const handleDirectionsClick = (techCoords) => {
    if (!techCoords.TechLatitude || !techCoords.TechLongitude) {
      setSnackbarMessage(
        "Please update your location details in your profile."
      );
      setSnackbarOpen(true);
      return;
    }

    const googleMapsUrl =
      selectedLocation?.latitude && selectedLocation?.longitude
        ? `https://www.google.com/maps/dir/?api=1&origin=${selectedLocation.latitude},${selectedLocation.longitude}&destination=${techCoords.TechLatitude},${techCoords.TechLongitude}`
        : `https://www.google.com/maps/search/?api=1&query=${techCoords.TechLatitude},${techCoords.TechLongitude}`;

    window.open(googleMapsUrl, "_blank");
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  return (
    <Box sx={{ padding: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
      <TextField
        label="Filter"
        variant="outlined"
        fullWidth
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Filter by Name or Description"
        sx={{ marginBottom: 2 }}
      />
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="body1">Filter by Maximum Distance</Typography>
        <Slider
          value={maxDistance}
          onChange={(event, newValue) => setMaxDistance(newValue)}
          aria-labelledby="distance-slider"
          valueLabelDisplay="auto"
          min={0}
          max={500}
        />
        <Typography variant="body2">Max Distance: {maxDistance} km</Typography>
      </Box>

      <Grid container spacing={2}>
        {filteredCostingData.length > 0 ? (
          filteredCostingData.map((costing, index) => (
            <Grid item xs={12} key={index}>
              <Accordion>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ fontSize: "3rem", color: "primary.main" }}
                    />
                  }
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    //padding: "16px 24px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Costing {index + 1} from - {costing.Name}{" "}
                        {costing.UserType}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2, color: "secondary.main" }}
                      >
                        Accept this costing as on date:{" "}
                        {costing.AcceptStatusDatestr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Distance from you:
                      </Typography>
                      {/* <Typography variant="body1">
                        {selectedLocation
                          ? distances[index]?.distance ||
                            "Calculating distance..."
                          : "Please select a location to view distances."}
                        <DirectionsIcon
                          sx={{
                            fontSize: "2rem",
                            color: "secondary.main",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDirectionsClick({
                              TechLatitude: costing.TechLatitude,
                              TechLongitude: costing.TechLongitude,
                            })
                          }
                        />
                      </Typography> */}
                      <Typography variant="body1">
                        {selectedLocation
                          ? distances[index]?.distanceValue ||
                            "Calculating distance..."
                          : "Please select a location to view distances in km..."}
                        <DirectionsIcon
                          sx={{
                            fontSize: "2rem",
                            color: "secondary.main",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDirectionsClick({
                              TechLatitude: costing.TechLatitude,
                              TechLongitude: costing.TechLongitude,
                            })
                          }
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Cost Added Date:
                      </Typography>
                      <Typography variant="body1">
                        {costing.AddedDatestr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Service Provider Name:
                      </Typography>
                      <Typography variant="body1">{costing.Name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Business type:
                      </Typography>
                      <Typography variant="body1">
                        {costing.UserType}
                      </Typography>
                    </Grid>

                    {costing.UserType !== "Self" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Business Name:
                        </Typography>
                        <Typography variant="body1">
                          {costing.Businessname}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Service Charge:
                      </Typography>
                      <Typography variant="body1">
                        {costing.ServiceCharge}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Min Cost:
                      </Typography>
                      <Typography variant="body1">{costing.MinCost}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Max Cost:
                      </Typography>
                      <Typography variant="body1">{costing.MaxCost}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Description:
                      </Typography>
                      <Typography variant="body1">
                        <TruncateTextCell
                          text={costing.Description}
                          maxLength={50}
                        />
                      </Typography>
                    </Grid>
                    {costing.WarrantyType !== "notIncluded" ? (
                      <>
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Warranty Type:
                          </Typography>
                          <Typography variant="body1">
                            {costing.WarrantyType}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Duration Time :
                          </Typography>
                          <Typography variant="body1">
                            {costing.Duration} {costing.DurationType}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "red" }}
                        >
                          No warranty information available.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  {costing.WorkDoneStatus === 1 ? (
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Status of Your Work:
                        </Typography>
                        <Typography variant="body1">
                          {getStatusLabel(costing.WorkDoneStatus)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Work Status Date:
                        </Typography>
                        <Typography variant="body1">
                          {costing.PaidAmountToProviderDatestr || ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Paid Amount:
                        </Typography>
                        <Typography variant="body1">
                          {costing.PaidAmountToProvider || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2, color: "primary.main" }}
                      >
                        {usermsgadditem}
                      </Typography>
                    </Grid>
                  )}

                  {/* Accept Button for each costing entry */}
                  {costing.AcceptStatus !== 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleAcceptCosting(costing.ServiceCostingId)
                      }
                      sx={{ mt: 2 }}
                    >
                      Accept Costing {index + 1}
                    </Button>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ mt: 2, color: "secondary.main" }}
                    >
                      You accept this costing.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1">
              Service Provider has not given any warranty.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
          alignItems: "center",
        }}
      >
        <Pagination
          count={Math.ceil(totalRecords / rowsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          variant="outlined"
          shape="rounded"
          sx={{ marginRight: 2 }} // Space between Pagination and FormControl
        />
        <FormControl variant="outlined" sx={{ width: 120 }}>
          {" "}
          {/* Set a fixed width */}
          <InputLabel>Rows per page</InputLabel>
          <Select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[1, 5, 10, 25, 100].map((rows) => (
              <MenuItem key={rows} value={rows}>
                {rows}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          severity="info"
          sx={{ width: "100%" }}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CostingComponent;
