import React, { useState, useEffect } from "react";

import CardComponent from "./CardComponent";
import { useCart } from "../hooks/CartContext";
import CategorySection from "./CategorySection ";
import CardBanner from "../banners/CardBanner";
import { Box } from "@mui/material";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import { useSnackbar } from "notistack";
import ProfessionalComponent from "./ProfessionalComponent";
import { useAuth } from "../AuthContext";
import TechnicianCardComponent from "./TechnicianCardComponent";

const CategoryCard = () => {
  const { categoryId, setCategoryId, latitude, longitude, distance, unit } =
    useCart();
  const [bannerItems, setBannerItems] = useState([]);
  const { apiService } = useDI();
  const { enqueueSnackbar } = useSnackbar();
  const { businesstype } = useAuth();
  useEffect(() => {
    const fetchBanners = async () => {
      console.log("Fetching banners...");
      try {
        const response = await apiService.postAPI(
          { currentPage: null, pageSize: null, Prefix: "" },
          EnvVariables.Get_Banners
        );
        console.log("Banners response:", response);
        const data = response.data?.RDatalist; // Use optional chaining

        if (Array.isArray(data) && data.length > 0) {
          setBannerItems(data);
        } else {
          setBannerItems([]);
        }
      } catch (error) {
        enqueueSnackbar("Error fetching banners", { variant: "error" });
      }
    };

    fetchBanners();
  }, [apiService, enqueueSnackbar]); // Dependency array

  return (
    <div>
      <CardBanner items={bannerItems} />
      <CategorySection setCategoryId={setCategoryId} />
      {businesstype === 2 ? (
        <CardComponent
          categoryId={categoryId}
          latitude={latitude}
          longitude={longitude}
          distance={distance}
          unit={unit}
        />
      ) : businesstype === 3 ? (
        <TechnicianCardComponent
          categoryId={categoryId}
          latitude={latitude}
          longitude={longitude}
          distance={distance}
          unit={unit}
        />
      ) : businesstype === 4 ? (
        <Box
          sx={{
            width: "100%",
            height: "200px", // Height of the box
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px", // Slightly rounded corners
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)", // Adding a subtle shadow
            color: "#fff",
            fontWeight: "bold",
            fontSize: "28px",
            textAlign: "center",
            padding: "15px",
            background:
              "linear-gradient(90deg, rgb(255, 153, 0), rgb(255, 102, 0), rgb(255, 153, 0))",
            backgroundSize: "200% 200%", // Gradients take more space for smooth animation
            animation: "waveEffect 6s ease-in-out infinite", // Apply the wave animation
          }}
        >
          Coming Soon
        </Box>
      ) : (
        <></>
      )}
      <style>
        {`
    @keyframes waveEffect {
      0% {
        background-position: 200% 0%; // Start from right
      }
      50% {
        background-position: 0% 0%; // Move to left
      }
      100% {
        background-position: 200% 0%; // Return to right
      }
    }
  `}
      </style>
      ;<ProfessionalComponent></ProfessionalComponent>
    </div>
  );
};

export default CategoryCard;
