import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Pagination from "../layoutcompo/Pagination";
import { useAuth } from "../AuthContext";
import { EnvVariables } from "../EnvVariables";
import { useDI } from "../hooks/DIContext";

const AreaMasterPage = () => {
  const [form, setForm] = useState({
    AreaId: "",
    TehCityVillageId: "",
    AreaName: "",
    AreaAddress: "",
    PhoneNo: "",
    IsDeleted: 0,
  });

  const {
    apiService,
    showNotification,
    setConfirmDialog,
    showLoader,
    hideLoader,
  } = useDI();

  const [areas, setAreas] = useState([]);
  const [touched, setTouched] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [buttonText, setButtonText] = useState("Add Area");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    loadAreas();
  }, [currentPage, rowsPerPage]);

  const loadAreas = async () => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        {},
        EnvVariables.GetAria_for_Area_Page
      );
      const data = response.data.RDatalist;
      if (Array.isArray(data) && data.length > 0) {
        setAreas(data);
        setTotalRecords(data[0].TotalRecords); // Adjust based on actual API response
      } else {
        setAreas([]);
        setTotalRecords(0);
        showNotification("No areas found", "info");
      }
    } catch (error) {
      setAreas([]);
      setTotalRecords(0);
      console.error("Error loading areas:", error);
      showNotification("Error loading areas", "error");
    } finally {
      hideLoader();
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!form.TehCityVillageId) {
      errors.TehCityVillageId = "Teh City Village ID is required";
    }
    if (!form.AreaName) {
      errors.AreaName = "Area Name is required";
    }
    if (!form.AreaAddress) {
      errors.AreaAddress = "Area Address is required";
    }
    if (!form.PhoneNo) {
      errors.PhoneNo = "Phone No is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    validateForm(); // Validate on blur
  };

  const resetState = () => {
    setForm({
      AreaId: "",
      TehCityVillageId: "",
      AreaName: "",
      AreaAddress: "",
      PhoneNo: "",
      IsDeleted: 0,
    });
    setTouched({});
    setValidationErrors({});
    setButtonText("Add Area");
    setIsEditing(false);
    setEditingIndex(null);
  };

  const insertArea = async (formData) => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        formData,
        EnvVariables.Insert_Area_Master
      );
      if (response.data.RDatalist[0].SuccessFailed === 0) {
        showNotification(response.data.RDatalist[0].Msg, "error");
      } else {
        showNotification(response.data.RDatalist[0].Msg, "success");
        resetState();
        await loadAreas(); // Refresh areas after insert
      }
    } catch (error) {
      showNotification("Error inserting area", "error");
    } finally {
      hideLoader();
    }
  };

  const updateArea = async (formData) => {
    try {
      showLoader();
      const response = await apiService.postAPI(
        formData,
        EnvVariables.Update_Area_Master
      );
      if (response.data.RDatalist[0].SuccessFailed === 0) {
        showNotification(response.data.RDatalist[0].Msg, "error");
      } else {
        showNotification(response.data.RDatalist[0].Msg, "success");
        resetState();
        await loadAreas(); // Refresh areas after update
      }
    } catch (error) {
      showNotification("Error updating area", "error");
    } finally {
      hideLoader();
    }
  };

  const deleteArea = async (AreaId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Area",
      message: "Are you sure you want to delete this area?",
      onConfirm: async () => {
        try {
          showLoader();
          const response = await apiService.postAPI(
            { AreaId: parseInt(AreaId) },
            EnvVariables.Delete_Area_Master
          );
          if (response.data.IsError) {
            showNotification(response.data.EData[0], "error");
          } else {
            showNotification(response.data.RDatalist[0].Msg, "success");
            await loadAreas(); // Refresh areas after delete
          }
        } catch (error) {
          showNotification("Error submitting the form", "error");
        } finally {
          hideLoader();
          setConfirmDialog({ isOpen: false });
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setConfirmDialog({
      isOpen: true,
      title: isEditing ? "Update Area" : "Add Area",
      message: isEditing
        ? "Are you sure you want to update this area?"
        : "Are you sure you want to add this area?",
      onConfirm: async () => {
        const formData = {
          AreaId: parseInt(form.AreaId),
          TehCityVillageId: parseInt(form.TehCityVillageId),
          AreaName: form.AreaName,
          AreaAddress: form.AreaAddress,
          PhoneNo: form.PhoneNo,
          IsDeleted: form.IsDeleted,
        };

        console.log(formData);
        if (isEditing) {
          await updateArea(formData);
        } else {
          await insertArea(formData);
        }
        setConfirmDialog({ isOpen: false });
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleEdit = (index) => {
    const item = areas[index];
    setForm({
      AreaId: item.AreaId,
      TehCityVillageId: item.TehCityVillageId,
      AreaName: item.AreaName,
      AreaAddress: item.AreaAddress,
      PhoneNo: item.PhoneNo,
      IsDeleted: item.IsDeleted,
    });
    setIsEditing(true);
    setEditingIndex(index);
    setButtonText("Update Area");
  };

  const handleDelete = async (index) => {
    const item = areas[index];
    await deleteArea(item.AreaId);
  };

  const handleSearchChange = (e) => {
    // Implement search logic if needed
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Teh City Village ID"
              value={form.TehCityVillageId}
              onChange={(e) =>
                setForm({ ...form, TehCityVillageId: e.target.value })
              }
              onBlur={() => handleBlur("TehCityVillageId")}
              variant="outlined"
              fullWidth
              error={
                touched.TehCityVillageId &&
                Boolean(validationErrors.TehCityVillageId)
              }
              helperText={
                touched.TehCityVillageId &&
                validationErrors.TehCityVillageId ? (
                  <Typography color="error">
                    {validationErrors.TehCityVillageId}
                  </Typography>
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Area Name"
              value={form.AreaName}
              onChange={(e) => setForm({ ...form, AreaName: e.target.value })}
              onBlur={() => handleBlur("AreaName")}
              variant="outlined"
              fullWidth
              error={touched.AreaName && Boolean(validationErrors.AreaName)}
              helperText={
                touched.AreaName && validationErrors.AreaName ? (
                  <Typography color="error">
                    {validationErrors.AreaName}
                  </Typography>
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Area Address"
              value={form.AreaAddress}
              onChange={(e) =>
                setForm({ ...form, AreaAddress: e.target.value })
              }
              onBlur={() => handleBlur("AreaAddress")}
              variant="outlined"
              fullWidth
              error={
                touched.AreaAddress && Boolean(validationErrors.AreaAddress)
              }
              helperText={
                touched.AreaAddress && validationErrors.AreaAddress ? (
                  <Typography color="error">
                    {validationErrors.AreaAddress}
                  </Typography>
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone No"
              value={form.PhoneNo}
              onChange={(e) => setForm({ ...form, PhoneNo: e.target.value })}
              onBlur={() => handleBlur("PhoneNo")}
              variant="outlined"
              fullWidth
              error={touched.PhoneNo && Boolean(validationErrors.PhoneNo)}
              helperText={
                touched.PhoneNo && validationErrors.PhoneNo ? (
                  <Typography color="error">
                    {validationErrors.PhoneNo}
                  </Typography>
                ) : null
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {buttonText}
            </Button>
            {isEditing && (
              <Button
                variant="contained"
                color="secondary"
                onClick={resetState}
                style={{ marginLeft: "1rem" }}
              >
                Cancel Edit
              </Button>
            )}
          </Grid> */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {buttonText}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={resetState}
              style={{ marginLeft: "1rem" }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>

      <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Area Id</TableCell>
              <TableCell>TehCityVillage Id</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>Area Address</TableCell>
              <TableCell>Phone No</TableCell>
              <TableCell>Is Deleted</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.map((area, index) => (
              <TableRow key={index}>
                <TableCell>{area.AreaId}</TableCell>
                <TableCell>{area.TehCityVillageId}</TableCell>
                <TableCell>{area.AreaName}</TableCell>
                <TableCell>{area.AreaAddress}</TableCell>
                <TableCell>{area.PhoneNo}</TableCell>
                <TableCell>{area.IsDeleted ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(index)}>
                    <FaEdit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(index)}>
                    <FaTrashAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Container>
  );
};

export default AreaMasterPage;
