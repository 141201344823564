class AuthService {
  setToken(token) {
    localStorage.setItem("authToken", token);
  }

  logout() {
    localStorage.removeItem("authToken");
  }
  getuserToken() {
    return localStorage.getItem("authToken");
  }
  isAuthenticated() {
    const token = localStorage.getItem("authToken");
    return !!token;
  }
}

export default new AuthService();
