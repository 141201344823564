import React, { useEffect, useRef } from 'react';
import './CustomModal.css';

const CustomModal = ({ isOpen, onClose, title, children }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal" ref={modalRef}>
                <div className="custom-modal-header">
                    <h2>{title}</h2>
                    <button className="custom-modal-close" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="custom-modal-body">
                    {children}
                </div>
                <div className="custom-modal-footer">
                    <button className="custom-modal-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
