import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Popover,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from "@mui/icons-material/Clear";
import { useJsApiLoader } from "@react-google-maps/api";
import { EnvVariables } from "../../EnvVariables";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";
import TruncateTextCell from "../TruncateTextCell";
import { useDI } from "../../hooks/DIContext";

const libraries = ["places"];

const LocationSelect = ({
  onLocationChange,
  selectedLocation,
  showExtraFields = true,
  onDistanceChange,
  onUnitChange,
  distance,
  unit,
  onClearUnit,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null); // For Popover
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { user, isLoggedIn } = useAuth();
  const apiKey = EnvVariables.gmapapikey;
  const inputRef = useRef(null);
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget); // Opens the Popover
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClose = () => {
    setAnchorEl(null); // Closes the Popover
  };

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setSearchTerm(value);

  //   if (value) {
  //     const service = new window.google.maps.places.AutocompleteService();
  //     service.getPlacePredictions({ input: value }, (predictions, status) => {
  //       if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //         setSuggestions(predictions);
  //       } else {
  //         setSuggestions([]);
  //       }
  //     });
  //   } else {
  //     setSuggestions([]);
  //   }
  // };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      const service = new window.google.maps.places.AutocompleteService();

      const options = {
        input: value,
        componentRestrictions: { country: "in" }, // Limit results to India
        language: "en", // Ensure consistency in language
        region: "IN", // This focuses on the region (India in this case)
      };

      // Fetch place predictions
      service.getPlacePredictions(options, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    showLoader();
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails({ placeId: suggestion.place_id }, (place, status) => {
      hideLoader();
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (place && place.geometry && place.geometry.location) {
          // // Manually extract relevant address components
          // const placeLat = place.geometry.location.lat();
          // const placeLng = place.geometry.location.lng();

          // // Now we use the selected location as the center point for nearby search
          // const request = {
          //   location: { lat: placeLat, lng: placeLng },
          //   radius: 10000, // 10 km radius
          // };

          const locationDetails = {
            label: suggestion.description,
            formatted_address: place.formatted_address, // Use for full address
            state: getAddressComponent(place, "administrative_area_level_1"),
            country: getAddressComponent(place, "country"),
            tehsil: getTehsilOrDistrict(place), // Handle tehsil/district more accurately
            district: getTehsilOrDistrict(place),
            postal_code: getAddressComponent(place, "postal_code"),
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          };
          onLocationChange(locationDetails);
          setSearchTerm(suggestion.description);
          setSuggestions([]);
          handleClose(); // Close the Popover after selecting a suggestion
        }
      } else {
        hideLoader();
        console.error("Failed to get place details:", status);
        alert("Error fetching location details. Please try again.");
      }
    });
  };

  // Helper function to get specific address components
  const getAddressComponent = (place, type) => {
    const component = place.address_components.find((c) =>
      c.types.includes(type)
    );
    return component ? component.long_name : null;
  };

  // Custom function for tehsil (may not always be present in the same format)
  const getTehsilOrDistrict = (place) => {
    // Try fetching tehsil or district, based on available data
    const tehsil = getAddressComponent(place, "administrative_area_level_2");
    const district = getAddressComponent(place, "administrative_area_level_3");

    // Return tehsil if available, otherwise fallback to district
    return tehsil || district;
  };

  const handleClearInput = () => {
    setSearchTerm("");
    inputRef.current.focus();
  };

  const handleClearLocation = () => {
    onLocationChange(null);
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      showLoader();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
          )
            .then((response) => response.json())
            .then((data) => {
              hideLoader();
              if (data.results && data.results.length > 0) {
                const place = data.results[0];
                if (place && place.geometry) {
                  const locationDetails = {
                    label: place.formatted_address,
                    formatted_address: place.formatted_address,
                    state: getAddressComponent(
                      place,
                      "administrative_area_level_1"
                    ),
                    country: getAddressComponent(place, "country"),
                    // tehsil: getAddressComponent(
                    //   place,
                    //   "administrative_area_level_2"
                    // ),
                    tehsil: getTehsilOrDistrict(place),
                    district: getAddressComponent(
                      place,
                      "administrative_area_level_3"
                    ),
                    postal_code: getAddressComponent(place, "postal_code"),
                    latitude: latitude,
                    longitude: longitude,
                  };

                  onLocationChange(locationDetails);
                  setSearchTerm(place.formatted_address);
                }
              }
            })
            .catch((error) => {
              hideLoader(); // Ensure loader is hidden if an error occurs
              console.error("Error fetching location:", error);
            });
        },
        () => {
          hideLoader(); // Hide loader if geolocation fails
          console.warn("Geolocation permission denied or unavailable.");
        },
        { enableHighAccuracy: true }
      );
    }
  };
  const handleuserlocation = () => {
    // Check if user exists and user.Latitude and user.Longitude are not null or undefined
    if (user && user.Latitude != null && user.Longitude != null) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${user.Latitude},${user.Longitude}&key=${apiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results.length > 0) {
            const place = data.results[0];
            if (place && place.geometry) {
              const locationDetails = {
                label: place.formatted_address,
                formatted_address: place.formatted_address,
                state: getAddressComponent(
                  place,
                  "administrative_area_level_1"
                ),
                country: getAddressComponent(place, "country"),
                tehsil: getTehsilOrDistrict(place),
                district: getAddressComponent(
                  place,
                  "administrative_area_level_3"
                ),
                postal_code: getAddressComponent(place, "postal_code"),
                latitude: user.Latitude,
                longitude: user.Longitude,
              };

              onLocationChange(locationDetails);
              setSearchTerm(place.formatted_address);
            }
          }
        })
        .catch((error) => console.error("Error fetching location:", error));
    }
    // If user, Latitude, or Longitude is null/undefined, do nothing.
  };

  // Call the function on page load when user.Latitude and user.Longitude are valid
  useEffect(() => {
    if (user && user.Latitude != null && user.Longitude != null) {
      handleuserlocation();
    } else {
      handleCurrentLocation();
    }
  }, [user?.Latitude, user?.Longitude]); // Empty dependency array means this runs once on component mount

  const open = Boolean(anchorEl); // Control whether Popover is open

  return (
    <Box sx={{ position: "relative", mt: 2, width: "100%", maxWidth: "400px" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <IconButton color="primary" onClick={handleOpen} sx={{ mr: 1 }}>
          <LocationOnIcon />
        </IconButton>
        {selectedLocation ? (
          <>
            <TruncateTextCell text={selectedLocation.label} maxLength={0} />
            <IconButton onClick={handleClearLocation} size="small">
              <ClearIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <Typography variant="body1" component="span">
            {t("select_location")}
          </Typography>
        )}
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: "100%", // Full width on small screens
            maxWidth: {
              lg: "20%", // Max width set to 20% on larger screens
            },
            borderRadius: 2, // Softer corners (you can adjust the value)
            boxShadow: (theme) => theme.shadows[3], // Optional: to add a shadow effect
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t("type_to_search_location")}
            inputRef={inputRef}
            value={searchTerm}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearInput} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{ mb: 2 }}
          />

          {suggestions.length > 0 && (
            <List
              sx={{
                maxHeight: "200px",
                overflow: "auto",
                border: "1px solid #ccc",
                borderRadius: "8px",
                bgcolor: "background.paper",
              }}
            >
              {suggestions.map((suggestion) => (
                <ListItem
                  button
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <ListItemText primary={suggestion.description} />
                </ListItem>
              ))}
            </List>
          )}

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCurrentLocation}
            sx={{ mb: 2, width: "100%" }}
          >
            {t("Use Current Location")}
          </Button>
          {isLoggedIn && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleuserlocation}
              sx={{ mb: 2, width: "100%" }}
            >
              {t("Use Registered Location")}
            </Button>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default LocationSelect;
