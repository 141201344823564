import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  Grid,
  Grid2,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Rating,
  Tooltip,
  Slider,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  ContactPhone as ContactIcon,
  Share as ShareIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  ShoppingCart as ShoppingCartIcon,
  Visibility,
  VisibilityOff,
  AddCircleOutline,
  AttachMoney as AttachMoneyIcon, // Use AttachMoneyIcon
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { EnvVariables } from "../EnvVariables";
import { useDI } from "../hooks/DIContext";
import LazyImage from "../servicecomponent/LazyImage";
import { useAuth } from "../AuthContext";
import { useCart } from "../hooks/CartContext";
import Imageslider from "../servicecomponent/Imageslider";
import { useTheme } from "../Themecolor/ThemeProviderCustContext";
import { toast } from "react-toastify";
import { CheckCircleOutline as VerifiedIcon } from "@mui/icons-material";
import DirectionsIcon from "@mui/icons-material/Directions";
import { calculateDistance } from "../servicecomponent/calculateDistance";
// Card container with responsive layout
const StyledCardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)", // Softer shadow for modern feel
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: "#fff", // Bright background for a clean look
  position: "relative",

  "&:hover": {
    transform: "translateY(-8px)", // Slight hover lift
    boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)", // Stronger hover shadow
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Align horizontally on larger screens
    alignItems: "center", // Ensure alignment in the center on larger screens
    maxHeight: "100%",
    height: "auto",
  },
}));

const MediaContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column", // Ensure vertical layout for smaller screens
  justifyContent: "center", // Vertically center content
  alignItems: "center", // Horizontally center content
  height: "auto",
  paddingTop: "10px",
  //position: "relative", // Needed for pseudo-elements

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "50px",
    height: "4px",
    backgroundColor: "#3498db",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "4px",
    height: "50px",
    backgroundColor: "#3498db",
    zIndex: 1,
  },
  transition: "box-shadow 0.3s ease, transform 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)", // Add shadow effect on hover
    transform: "translateY(-4px)", // Slight lift on hover
  },
  [theme.breakpoints.down("xs")]: {
    marginBottom: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "10px",
    width: "15%", // Adjust width for better centering on larger screens
    marginRight: "16px",
    border: "2px solid #e0e0e0",
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxHeight: "150px", // Adjust to ensure better visibility
  objectFit: "contain", // Ensure image is properly displayed without overflow
  transition: "transform 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    transform: "scale(1.05)", // Slight zoom effect on hover
  },
  [theme.breakpoints.up("sm")]: {
    maxHeight: "200px", // Slightly larger for bigger screens
    //border: "1px solid #e0e0e0",
  },
}));

// Content container with dynamic height and padding adjustment
const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "auto",
  //border: "2px solid #e0e0e0", // Lighter border for subtle separation
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    width: "85%",
    paddingLeft: "16px",
  },
}));

// Card content with spacing adjustments
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "white",
  [theme.breakpoints.up("sm")]: {
    padding: "16px",
  },
}));

const IconButtonContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 10, // Maintain a 10px distance from the top
  left: 10, // Maintain a 10px distance from the right
  display: "flex",
  flexDirection: "row",
  gap: 10,
  zIndex: 1000, // Ensure it stays on top of other elements
  [theme.breakpoints.down("xs")]: {
    top: 5, // Adjust positioning for mobile screens
    left: 5, // Maintain a 5px distance from the right on small screens
  },
}));

const ShareButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  //backgroundColor: "var(--shareicon)",
  color: "#1DA1F2", // Blue color for ShareIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    //backgroundColor: "#E1E8ED", // Light blue for hover effect
    transform: "scale(1.1)",
  },
}));

const CartButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  color: "#FF9900", // Orange color for ShoppingCartIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    // backgroundColor: "#FFE5B4", // Light orange for hover effect
    transform: "scale(1.1)",
  },
}));

const PhoneButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  color: "#34B7F1", // Cyan color for PhoneIcon
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: "#B3E5FC", // Light cyan for hover effect
    transform: "scale(1.1)",
  },
}));
const messageStyle = {
  textAlign: "center",
  marginTop: "20px",
  marginBottom: "300px",
};
// Styled Toggle Button
const ToggleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#f4f4f4", // Light neutral background for a clean look
  color: "#3b5998", // Professional deep blue-gray color for the icon
  fontSize: "4rem", // Controls the size of the button (adjust if needed)
  transition: "background-color 0.3s, transform 0.3s, color 0.3s", // Smooth transition for color and hover effects
  "&:hover": {
    backgroundColor: "#d9eaf7", // Light blue for a modern hover effect
    color: "#365492", // Darker blue on hover for better contrast
    transform: "scale(1.1)", // Slight zoom on hover
  },
  "& .MuiSvgIcon-root": {
    fontSize: "inherit", // Icon size inherits the button font size
  },
}));
const AddComplaintButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3498db", // Blue button background for professional look
  color: "#fff", // White text for contrast
  padding: "6px 12px",
  fontWeight: 600,
  textTransform: "none", // Disable uppercase transformation
  display: "flex",
  alignItems: "center",
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: "#2980b9", // Darker blue on hover
    transform: "scale(1.05)",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "8px", // Spacing between icon and text
  },
}));
const TechnicianCardComponent = ({
  categoryId,
  latitude,
  longitude,
  distance,
  unit,
}) => {
  const location = useLocation();
  const { addToCart, handleOrderClick, selectedLocation } = useCart();
  const [itemlist, SetItemlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn, searchInput, user } = useAuth();
  const observer = useRef(null);
  const contentRef = useRef(null);
  const baseURL = EnvVariables.BaseUrlforImage;
  const pageSize = 10;
  const [expanded, setExpanded] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [imagePaths, setImagePaths] = useState([]);
  const [searchParams] = useSearchParams();
  const [urlCategoryId, setUrlCategoryId] = useState(null);
  const [urlServiceUserId, setUrlServiceUserId] = useState(null);
  const [isDetailPage, setIsDetailPage] = useState(null);
  const [distances, setDistances] = useState(new Map());
  const [filteredItemlist, setFilteredItemlist] = useState([]);
  const [maxDistance, setMaxDistance] = useState(0);
  const [filterText, setFilterText] = useState(""); // State for text-based filter
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fetchItemlist = useCallback(
    debounce(async (reset = false) => {
      if (loading) return;
      setLoading(true);
      showLoader();

      try {
        const currentPage = reset ? 1 : page;
        const requestData = {
          Prefix: searchInput || undefined,
          currentPage,
          pageSize,
          CategoryId: categoryId || null,
          // Add other parameters as needed
        };

        const response = await apiService.postAPI(
          requestData,
          EnvVariables.Get_Technicians_Details
        );
        const newItems = Array.isArray(response.data) ? response.data : [];

        if (reset) {
          SetItemlist(newItems); // Replace list when resetting
          setPage(1);
        } else {
          // Merge new items with existing, avoiding duplicates
          SetItemlist((prevItems) => [
            ...prevItems,
            ...newItems.filter(
              (newItem) =>
                !prevItems.some(
                  (item) => item.ServiceUserId === newItem.ServiceUserId
                )
            ),
          ]);
          setPage((prevPage) => prevPage + 1);
        }

        setHasMore(newItems.length === pageSize); // If less than pageSize, we've fetched all data
        await calculateDistances(newItems); // Recalculate distances for newly fetched items
      } catch (error) {
        console.error("Error fetching itemlist:", error);
      } finally {
        hideLoader();
        setLoading(false);
      }
    }, 300), // Debounce time
    [apiService, searchInput, categoryId, loading]
  );
  const calculateDistances = async (data) => {
    if (!selectedLocation) return; // Skip if no location is set

    const userCoords = {
      Latitude: selectedLocation?.latitude,
      Longitude: selectedLocation?.longitude,
    };

    if (!isValidCoords(userCoords)) return; // Skip if invalid coords

    const distancePromises = data.map(async (item) => {
      const techCoords = {
        TechLatitude: item?.TechLatitude,
        TechLongitude: item?.TechLongitude,
      };

      if (!isValidCoords(techCoords, true)) return null;

      try {
        const distance = await calculateDistance(techCoords, userCoords);
        return {
          ServiceUserId: item.ServiceUserId,
          distance: distance?.distance || 0,
        };
      } catch (error) {
        console.error("Distance calculation failed:", {
          techCoords,
          userCoords,
          error,
        });
        return null;
      }
    });

    const calculatedDistances = await Promise.all(distancePromises);
    const newDistances = new Map(
      calculatedDistances
        .filter(Boolean)
        .map(({ ServiceUserId, distance }) => [ServiceUserId, distance])
    );

    setDistances((prev) => new Map([...prev, ...newDistances])); // Merge new distances with the existing ones
  };

  const isValidCoords = (coords, isTechCoords = false) => {
    if (isTechCoords) {
      return (
        typeof coords.TechLatitude === "number" &&
        coords.TechLatitude !== null &&
        typeof coords.TechLongitude === "number" &&
        coords.TechLongitude !== null
      );
    } else {
      return (
        typeof coords.Latitude === "number" &&
        coords.Latitude !== null &&
        typeof coords.Longitude === "number" &&
        coords.Longitude !== null
      );
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      calculateDistances(itemlist); // Only recalculate distances for the fetched items
    }
  }, [selectedLocation, itemlist]);

  // Initial fetch on component mount or when scrolling/input search happens
  useEffect(() => {
    fetchItemlist(true); // Fetch first page on mount/reset
  }, [categoryId, searchInput]); // Add search input if necessary

  // Filter the fetched list based on distance and user input
  const applyFilters = (data) => {
    return data.filter((techdata, index) => {
      const distance = distances.get(techdata.ServiceUserId);
      const withinDistance =
        maxDistance <= 0 ||
        (distance !== undefined && parseFloat(distance) <= maxDistance);
      return withinDistance;
    });
  };

  useEffect(() => {
    fetchItemlist(true); // Pass true if you want to reset and fetch the first page
  }, []); // Empty dependency array means this runs once when the component mounts

  useEffect(() => {
    const shouldFetch = categoryId || searchInput;

    if (shouldFetch) {
      setPage(1);
      fetchItemlist(true);
    }
  }, [categoryId, searchInput]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !loading) {
        fetchItemlist();
      }
    },
    [fetchItemlist, hasMore, loading]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    const target = document.querySelector("#load-more-trigger");

    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleObserver]);

  const toggleDescription = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    // Extract URL parameters
    const categoryIdParam = searchParams.get("CategoryId");
    const ServiceUserIdParam = searchParams.get("ServiceUserId");
    const isDetailPageParam = searchParams.get("isDetailPage");

    // Update the state with the URL parameters
    setUrlCategoryId(categoryIdParam);
    setUrlServiceUserId(ServiceUserIdParam);
    setIsDetailPage(isDetailPageParam);
  }, [searchParams]);

  useEffect(() => {
    // Add a delay before making the API call to ensure state has updated
    const timer = setTimeout(() => {
      if (urlCategoryId && urlServiceUserId) {
        fetchItemlist(true, urlCategoryId, urlServiceUserId);
      }
    }, 50); // Adjust the delay as needed

    // Cleanup timer if component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, [urlCategoryId, urlServiceUserId]);

  const hasFetched = useRef(false);
  const handleShareClick = async (item, isDetailPage = false) => {
    const itemUrl = `${window.location.origin}?CategoryId=${item.CategoryId}&ServiceUserId=${item.ServiceUserId}&isDetailPage=${isDetailPage}`;
    const imageUrl = item.SingleItemImage
      ? `${baseURL}${item.SingleItemImage}`
      : null;

    try {
      if (navigator.share) {
        if (
          navigator.canShare &&
          navigator.canShare({ files: [] }) &&
          imageUrl
        ) {
          const response = await apiService.postAPI(
            { imageUrl },
            EnvVariables.Get_Image_File,
            {
              headers: { "Content-Type": "application/json" },
              responseType: "blob",
            }
          );

          if (response && response.status === 200) {
            const blob = response.data;
            const file = new File(
              [blob],
              `image.${imageUrl.split(".").pop()}`,
              { type: blob.type }
            );

            await navigator.share({
              title: item.ItemName,
              text: item.ItemDescription,
              url: itemUrl,
              files: [file],
            });
            return;
          } else {
            throw new Error("Failed to fetch image");
          }
        }

        await navigator.share({
          title: item.ItemName,
          text: item.ItemDescription,
          url: itemUrl,
        });
      } else {
        console.warn("Web Share API not supported in this browser.");
        alert(
          "Sharing is not supported on this browser. Please copy the link and share it manually."
        );
      }
    } catch (error) {
      console.error("Error occurred during sharing:", error);
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(itemUrl);
          alert(
            "Sharing failed, but the link has been copied to your clipboard."
          );
        } catch (clipboardError) {
          alert("Sharing failed. Please copy the link and share it manually.");
        }
      } else {
        alert("Sharing failed. Please copy the link and share it manually.");
      }
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const openDialog = (MasterItemImage) => {
    const imagesArray = MasterItemImage.split(",").map(
      (path) => `${baseURL}${path.trim()}`
    );
    setImagePaths(imagesArray);
    setModalOpen(true);
    // setDialogImages(imagesArray);
    // setCurrentImageIndex(initialIndex);
  };
  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImages([]);
  };
  const handlePrevImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : dialogImages.length - 1
      );
    }
  };

  const handleNextImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < dialogImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };
  const handleShowNoClick = (phoneno) => {
    if (isLoggedIn) {
      if (isMobileDevice()) {
        window.location.href = `tel:${phoneno}`;
      } else {
        showNotification(`Phone number: ${phoneno}`, "info");
      }
    } else {
      showNotification("Login first", "error");
    }
  };
  const isMobileDevice = () => {
    return (
      /Mobi|Android|iPhone|iPad|Windows Phone|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      ("ontouchstart" in window && navigator.maxTouchPoints > 0)
    );
  };
  const [showCards, setShowCards] = useState(true); // State to toggle cards visibility

  const toggleCards = () => {
    setShowCards(!showCards); // Toggle card visibility on button click
  };
  const handleDirectionsClick = (techCoords) => {
    if (!techCoords.TechLatitude || !techCoords.TechLongitude) {
      setSnackbarMessage(
        "Please update your location details in your profile."
      );
      setSnackbarOpen(true);
      return;
    }

    const googleMapsUrl =
      selectedLocation?.latitude && selectedLocation?.longitude
        ? `https://www.google.com/maps/dir/?api=1&origin=${selectedLocation.latitude},${selectedLocation.longitude}&destination=${techCoords.TechLatitude},${techCoords.TechLongitude}`
        : `https://www.google.com/maps/search/?api=1&query=${techCoords.TechLatitude},${techCoords.TechLongitude}`;

    window.open(googleMapsUrl, "_blank");
  };
  const renderRows = () => {
    const filteredItems = applyFilters(itemlist);
    return filteredItems.map((item) => (
      <Grid item key={item.ServiceUserId} xs={12} sm={12}>
        <StyledCardContainer>
          {/* Image container */}
          <MediaContainer>
            <Box sx={{ marginBottom: "3rem" }}>
              <IconButtonContainer sx={{ marginBottom: "2rem" }}>
                <ShareButton onClick={() => handleShareClick(item)}>
                  <ShareIcon />
                </ShareButton>
                <CartButton onClick={() => handleShowNoClick(item.PhoneNo)}>
                  <PhoneIcon />
                </CartButton>
              </IconButtonContainer>
            </Box>
            <Box>
              <StyledCardMedia
                component="img"
                src={`${baseURL}${item.SingleItemImage.split(",")[0]}`} // Ensure baseURL is correct
                alt={item.ItemName}
                onError={(e) =>
                  (e.target.src =
                    process.env.PUBLIC_URL + "images/errorimages/noimage.jpg")
                }
                onClick={() => openDialog(item.MasterItemImage)} // Open dialog on click
              />
            </Box>
          </MediaContainer>
          {/* Content container */}
          <ContentContainer>
            <StyledCardContent>
              {/* Top row with item name, person name, and icons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginBottom: { xs: "4px", sm: "8px" }, // Reduced margin for small screens
                }}
              >
                {/* Item Name */}
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.4rem" },
                    color: "#2c3e50",
                    fontWeight: 600,
                    marginBottom: { xs: "4px", sm: "6px" }, // Reduced margin
                    flexBasis: { xs: "100%", sm: "auto" },
                  }}
                >
                  Technician Type{" "}
                  <span style={{ fontWeight: 700, color: "#c0392b" }}>
                    {item.CategoryName}
                  </span>
                </Typography>

                {/* Person Name */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "1rem", sm: "1.2rem" },
                    color: "secondary.main",
                    fontWeight: 600,
                    marginBottom: { xs: "4px", sm: "6px" }, // Reduced margin
                  }}
                >
                  Person Name : {item.Name}
                </Typography>
              </Box>

              {/* Business Type and Shop Name (Conditional Rendering) */}
              <Box
                sx={{
                  marginTop: { xs: "4px", sm: "8px" }, // Margin for business section
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    color: "#2c3e50",
                    fontWeight: 600,
                  }}
                >
                  Business Type: {item.UserType}
                </Typography>

                {/* Conditionally render Shop Name if BuissnessType is "Shop" */}
                {item.UserType !== "Self" && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#34495e",
                      fontWeight: 600,
                      fontSize: { xs: "1rem", sm: "1.1rem" },
                      marginTop: { xs: "4px", sm: "6px" }, // Reduced marginTop
                    }}
                  >
                    Business Name: {item.Businessname}
                  </Typography>
                )}
              </Box>

              {/* Second row with Rating and Verified */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: { xs: "2px", sm: "6px" }, // Reduced marginTop
                  marginBottom: { xs: "4px", sm: "8px" }, // Reduced marginBottom
                }}
              >
                {/* Rating */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: { xs: "4px", sm: "0" },
                  }}
                >
                  <Rating
                    name="item-rating"
                    value={item.AverageRating || 0}
                    precision={0.5}
                    readOnly
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                      color: "#2c3e50",
                      marginLeft: "8px",
                    }}
                  >
                    ({item.RatingCount || 0} reviews)
                  </Typography>
                </Box>

                {/* Verified */}
                {item.IsVerified === 1 && (
                  <Tooltip title="Verified Seller">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#27ae60",
                      }}
                    >
                      <VerifiedIcon sx={{ fontSize: "1.5rem" }} />

                      <Typography
                        sx={{
                          marginLeft: "4px",
                          fontSize: { xs: "0.9rem", sm: "1rem" },
                          fontWeight: 600,
                        }}
                      >
                        Verified
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>

              {/* Separate Row for Distance and Navigation Icon */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginTop: { xs: "2px", sm: "6px" }, // Reduced marginTop
                  marginBottom: { xs: "4px", sm: "8px" }, // Reduced marginBottom
                }}
              >
                {/* Distance from You */}
                <Box>
                  <Typography variant="body1">
                    Distance:{" "}
                    {distances.get(item.ServiceUserId) ||
                      "Please select a location to view distances in km ..."}
                    <IconButton
                      onClick={() =>
                        handleDirectionsClick({
                          TechLatitude: item.TechLatitude,
                          TechLongitude: item.TechLongitude,
                        })
                      }
                    >
                      <DirectionsIcon
                        sx={{
                          fontSize: "2rem",
                          color: "secondary.main",
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Box>

                {/* Directions Icon */}
              </Box>

              {/* Description Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: { xs: "4px", sm: "6px" }, // Reduced marginTop
                  marginBottom: { xs: "4px", sm: "8px" }, // Reduced marginBottom
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex", // Use flexbox to align items
                    alignItems: "center", // Center align items vertically
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    lineHeight: "1.5",
                    color: "#5d6d7e",
                    wordWrap: "break-word", // Ensures long words wrap correctly
                  }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Technician Description:
                  </span>{" "}
                  {/* Label for description */}
                  <span style={{ flex: 1 }}>
                    {" "}
                    {/* This ensures the text takes up available space */}
                    {expanded[item.ServiceUserId]
                      ? item.ExpertiseType
                      : `${item.ExpertiseType.slice(0, 20)}`}
                  </span>
                  {item.ExpertiseType.length > 20 && (
                    <span
                      onClick={() => toggleDescription(item.ServiceUserId)}
                      style={{
                        color: "#2980b9",
                        cursor: "pointer",
                        fontWeight: 500,
                        marginLeft: "5px", // Space between the text and the link
                      }}
                    >
                      {expanded[item.ServiceUserId] ? " See Less" : " See More"}
                    </span>
                  )}
                </Typography>
              </Box>

              {/* Technical Expertise and UOM */}
              <Box sx={{ marginTop: { xs: "4px", sm: "6px" } }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    color: "#2c3e50",
                    fontWeight: 600,
                  }}
                >
                  Technical Expertise In: {item.RequirementsDesc}
                </Typography>
              </Box>
            </StyledCardContent>
          </ContentContainer>
        </StyledCardContainer>
      </Grid>
    ));
  };

  return (
    <>
      {" "}
      <div>
        {itemlist.length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "20px",
              padding: "10px 16px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Header text */}
            <Typography
              variant="h6"
              sx={{
                color: "#3b5998",
                fontWeight: 600,
                fontSize: {
                  xs: "1rem", // Adjust font size for small screens
                  sm: "1.25rem", // Adjust for medium screens
                  md: "1.5rem", // Default font size for larger screens
                },
              }}
            >
              Click the eye icon to view or hide them technician.
            </Typography>

            {/* Button group for toggle and register */}
            <Box
              display="flex"
              alignItems="center"
              gap={2} // Space between buttons
              sx={{
                "& > *": {
                  marginRight: {
                    xs: "8px", // Smaller space on smaller screens
                    sm: "12px", // Medium space on larger screens
                  },
                },
              }}
            >
              {/* Toggle button with visibility icon */}
              <ToggleButton onClick={toggleCards}>
                {showCards ? <Visibility /> : <VisibilityOff />}
              </ToggleButton>

              {/* Register Complaint Button */}
              {/* <AddComplaintButton
                onClick={handleRegisterComplaint}
                startIcon={<AddCircleOutline />}
              >
                Add Complaint
              </AddComplaintButton> */}
            </Box>
          </Box>
        )}

        {/* Cards container (visible based on state) */}
        {showCards && (
          <>
            {" "}
            {/* Add margin to the overall container */}
            <Box mt={2}>
              <Typography>Filter by Distance</Typography>
              <Slider
                value={maxDistance}
                onChange={(e, newValue) => setMaxDistance(newValue)}
                aria-labelledby="distance-slider"
                valueLabelDisplay="auto"
                min={0}
                max={500}
              />
              <Typography>Max Distance: {maxDistance} km</Typography>
            </Box>
            <Box mt={4}>
              {" "}
              {/* Add spacing between the slider section and the grid */}
              <Grid container spacing={2}>
                {renderRows()}
              </Grid>
            </Box>
          </>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogContent
          sx={{
            padding: 0,
            margin: 0,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "calc(100% - 10px)", // 5px margin on each side
              height: "calc(80vh - 10px)", // 5px margin on top and bottom
              margin: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <IconButton
              onClick={handlePrevImage}
              disabled={dialogImages.length === 0 || currentImageIndex === 0}
              sx={{
                position: "absolute",
                left: 16,
                zIndex: 1,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                borderRadius: "50%",
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${baseURL}${dialogImages[currentImageIndex]}`}
                alt="Dialog Image"
                style={{
                  maxWidth: "calc(100% - 10px)", // 5px margin on each side
                  maxHeight: "calc(100% - 10px)", // 5px margin on top and bottom
                  objectFit: "contain", // Ensures the image fits within the container while preserving aspect ratio
                }}
              />
            </Box>

            <IconButton
              onClick={handleNextImage}
              disabled={
                dialogImages.length === 0 ||
                currentImageIndex === dialogImages.length - 1
              }
              sx={{
                position: "absolute",
                right: 16,
                zIndex: 1,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                borderRadius: "50%",
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Imageslider
        open={modalOpen}
        onClose={handleCloseModal}
        imagePaths={imagePaths}
      />
    </>
  );
};
export default TechnicianCardComponent;
