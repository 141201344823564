import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Close as CloseIcon } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";

// Styled components for UI
const BannerWrapper = styled(Box)({
  display: "flex",
  transition: "transform 0.5s ease-in-out",
  width: "100%",
  height: "100%",
});

const BannerImage = styled("img")({
  flexShrink: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
  maxWidth: "100%",
  maxHeight: "100%",
  transition: "transform 0.3s ease-in-out",
});

const DotsContainer = styled(Box)({
  position: "absolute",
  bottom: 10,
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: "8px",
  padding: "8px",
});

const Dot = styled("div")(({ theme, active }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  border: `2px solid ${active ? theme.palette.primary.main : "#ccc"}`,
  backgroundColor: active ? theme.palette.primary.main : "#ccc",
  boxShadow: active ? `0 0 6px ${theme.palette.primary.main}` : "none",
  cursor: "pointer",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    boxShadow: `0 0 8px ${theme.palette.primary.main}`,
  },
}));

const Imageslider = ({ open, onClose, imagePaths }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleNext = () => {
    if (imagePaths.length === 0) return;
    setTransitionEnabled(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
  };

  useEffect(() => {
    if (imagePaths.length === 0) return;

    // Automatic slide every 5 seconds
    timeoutRef.current = setTimeout(handleNext, 5000);

    return () => resetTimeout();
  }, [currentIndex, imagePaths.length]);

  const handleTransitionEnd = () => {
    if (currentIndex === imagePaths.length) {
      setTransitionEnabled(false);
      setCurrentIndex(0);
    }
  };

  const handleMouseEnter = () => {
    resetTimeout(); // Stop the auto slide on mouse enter
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(handleNext, 5000); // Restart auto slide on mouse leave
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1
      ),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleDotClick = (index) => {
    setTransitionEnabled(true);
    setCurrentIndex(index);
  };
  useEffect(() => {
    if (open) {
      setCurrentIndex(0);
    }
  }, [open]);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        {...handlers}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: "relative",
          width: "100%",
          height: "80%",
          margin: "9rem auto auto auto",
          backgroundColor: "#000",
          color: "#fff",
          borderRadius: "10px",
          overflow: "hidden",
          outline: "none",
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
            border: "1px solid rgba(255, 255, 255, 0.7)", // Light border to enhance visibility
            borderRadius: "50%", // Round button
            zIndex: 1,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker background on hover
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <BannerWrapper
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: transitionEnabled
              ? "transform 0.5s ease-in-out"
              : "none",
          }}
          onTransitionEnd={handleTransitionEnd}
        >
          {imagePaths.map((path, index) => (
            <BannerImage key={index} src={path} alt={`Slide ${index}`} />
          ))}
        </BannerWrapper>

        <DotsContainer>
          {imagePaths.map((_, index) => (
            <Dot
              key={index}
              active={index === currentIndex}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </DotsContainer>
      </Box>
    </Modal>
  );
};

export default Imageslider;
