import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import AddressPage from "../MasterPages/AddressPage";
import { useCart } from "../hooks/CartContext";
const AllItemBuyingPage = () => {
  const { allOrderDetails, setAllOrderDetails, addresses, setAddresses } =
    useAuth();
  const navigate = useNavigate();
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();

  const [selectedAddress, setSelectedAddress] = useState(null);

  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [showAddNewAddressDialog, setShowAddNewAddressDialog] = useState(false);
  const baseURL = EnvVariables.BaseUrlforImage;
  const [loading, setLoading] = useState(true);
  const { setCartItems, cartItems } = useCart();
  useEffect(() => {
    if (allOrderDetails) {
      setLoading(false);
    } else {
      // Redirect to home page if no orders are found
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (Array.isArray(allOrderDetails.Items)) {
      const totalCost = allOrderDetails.Items.reduce(
        (total, item) => total + item.DiscountedPrice * (item.quantity || 1),
        0
      );
      setAllOrderDetails((prev) => ({ ...prev, TotalCost: totalCost }));
    }
  }, [allOrderDetails.Items]);

  const calculateTotalPrice = (item) => {
    return (
      (item?.DiscountedPrice * (item?.quantity || 1))?.toFixed(2) || "0.00"
    );
  };
  const calculateTotalCost = () => {
    if (Array.isArray(allOrderDetails?.Items)) {
      const totalCost = allOrderDetails.Items.reduce(
        (total, item) =>
          total +
          (item.DiscountedPrice || item.ItemPrice) * (item.quantity || 1),
        0
      );
      setAllOrderDetails((prev) => ({ ...prev, TotalCost: totalCost }));
    }
  };

  const fetchedAddresses = async () => {
    try {
      const response = await apiService.postAPI(
        { currentPage: 1, pageSize: 100 },
        EnvVariables.Get_User_Address
      );
      const data = response.data.RDatalist;

      if (Array.isArray(data) && data.length > 0) {
        setAddresses(data);
        const defaultAddress = data.find(
          (addr) => addr.PrimaryAddress === "Yes"
        );
        if (defaultAddress) {
          setSelectedAddress(defaultAddress.UserAddressId);
        }
      } else {
        setAddresses([]);
        showNotification("Error loading addresses", "error");
      }
    } catch (error) {
      setAddresses([]);
      showNotification("Error loading addresses", "error");
    }
  };
  useEffect(() => {
    fetchedAddresses();
  }, []);
  useEffect(() => {
    console.log("Addresses:", addresses);
    const defaultAddress = addresses.find(
      (addr) => addr.PrimaryAddress === "Yes"
    );
    if (defaultAddress) {
      setSelectedAddress(defaultAddress.UserAddressId);
    }
  }, [addresses]);

  const handleQuantityChange = (index, change) => {
    const updatedItems = allOrderDetails.Items.map((item, i) =>
      i === index
        ? { ...item, quantity: Math.max((item.quantity || 1) + change, 1) }
        : item
    );
    setAllOrderDetails({ ...allOrderDetails, Items: updatedItems });
  };

  const handleRemoveItem = (index) => {
    const updatedItems = allOrderDetails.Items.filter((_, i) => i !== index);
    setAllOrderDetails({ ...allOrderDetails, Items: updatedItems });
    calculateTotalCost();
  };

  const handlePayNow = () => {
    setConfirmDialog({
      isOpen: true,
      title: "Confirm Order",
      message: "Are you sure you want to proceed with this payment?",
      onConfirm: () => ordersend(),
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleAddAddress = () => {
    setShowAddressDialog(false);
    setShowAddNewAddressDialog(true);
  };

  const handleCloseDialog = () => {
    setShowAddressDialog(false);
  };

  const handleCloseNewAddressDialog = () => {
    setShowAddNewAddressDialog(false);
  };

  const ordersend = async () => {
    try {
      showLoader();

      // Step 1: Group items by PhoneNo
      const groupedItems = allOrderDetails.Items.reduce((acc, orderItem) => {
        const phoneNo = orderItem.PhoneNo;
        if (!acc[phoneNo]) {
          acc[phoneNo] = [];
        }
        acc[phoneNo].push({
          UserItemDetailsId: orderItem.UserItemDetailsId || null,
          ItemType: orderItem.ItemType || "DefaultType",
          ItemName: orderItem.ItemName,
          ItemDescription: orderItem.ItemDescription,
          CategoryName: orderItem.CategoryName,
          ItemKeywords: orderItem.ItemKeywords || "",
          ItemPrice: orderItem.ItemPrice,
          ItemDiscount: orderItem.ItemDiscount || 0,
          DiscountedPrice: orderItem.DiscountedPrice || orderItem.ItemPrice,
          quantity: orderItem.quantity || 1,
          CartId: orderItem.CartId || "",
          TotalPrice:
            (orderItem.DiscountedPrice || 0) * (orderItem.quantity || 0),
          UOM: orderItem.UOM || "",
        });
        return acc;
      }, {});

      // Step 2: Prepare the final structure
      const itemsArrayWithCosts = [];
      const cartIdsToDelete = new Set();

      for (const phoneNo in groupedItems) {
        const items = groupedItems[phoneNo];
        const grandTotal = items.reduce((sum, item) => {
          return sum + item.DiscountedPrice * item.quantity;
        }, 0);
        const orderquantity = items.reduce((sum, item) => {
          return sum + item.quantity;
        }, 0);

        // Collect CartIds to delete
        items.forEach((item) => {
          cartIdsToDelete.add(item.CartId);
        });

        // Push items, total cost, and phoneNo to the array
        itemsArrayWithCosts.push({
          SellerPhoneNo: phoneNo, // Include the phone number here
          Items: items,
          GrandTotal: grandTotal,
          Orderquantity: orderquantity,
        });
      }

      // Step 3: Create the final orderDetails object
      const orderDetails = {
        Groups: itemsArrayWithCosts,

        BuyerAddress: selectedAddressObj?.FullAddress || "",
      };

      // Step 4: Send the orderDetails object to the backend
      const response = await apiService.postAPI(
        orderDetails,
        EnvVariables.Place_All_item_Order
      );

      // Step 5: Handle the response
      if (response.data.IsError) {
        showNotification("Error submitting order", "error");
      } else {
        if (response.data.RDatalist[0].SuccessFailed === 1) {
          showNotification("Order placed successfully", "success");

          let cartItemsArray = [];

          if (Array.isArray(cartItems)) {
            // If cartItems is already an array, use it directly
            cartItemsArray = cartItems;
          } else if (typeof cartItems === "object" && cartItems !== null) {
            // Convert object to array using Object.values() to get an array of values
            cartItemsArray = Object.values(cartItems);
          } else {
            console.error(
              "cartItems is neither an array nor an object:",
              cartItems
            );
            // Handle the case where cartItems is neither an array nor an object
            cartItemsArray = []; // Set to empty array to avoid further errors
          }

          // Now filter the array to remove items with CartId in cartIdsToDelete
          const updatedCartItems = cartItemsArray.filter((cartItem) => {
            if (!cartItem.CartId) {
              console.error("CartItem does not have a CartId:", cartItem);
              return false; // Skip this item
            }
            return !cartIdsToDelete.has(cartItem.CartId);
          });

          // Set the updated cart items to your state or context
          setCartItems(updatedCartItems);
          setAllOrderDetails({ Items: [], TotalCost: 0 });
          navigate("/");
        } else {
          showNotification("Failed to place order", "error");
        }
      }
    } catch (error) {
      console.log(error);
      showNotification("Error submitting the form", "error");
    } finally {
      hideLoader();
      setConfirmDialog({ isOpen: false });
    }
  };

  const selectedAddressObj = addresses.find(
    (addr) => addr.UserAddressId === selectedAddress
  );

  if (!allOrderDetails?.Items?.length) {
    return <p>Go to the cart and again click on By all button...</p>;
  }
  const handleChangeAddressClick = () => {
    setShowAddressDialog(true);
  };

  return (
    <Grid container direction="column" spacing={2} sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Order Summary
      </Typography>
      <Box p={2} mt={1} style={{ border: "1px solid #ccc" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography
                variant="h6"
                style={{ marginRight: "8px", fontWeight: 500 }}
              >
                Delivery Address
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleChangeAddressClick}
                style={{ padding: "4px 8px", fontSize: "0.875rem" }}
              >
                Change Address
              </Button>
            </Box>
            <Typography
              variant="body2"
              component="div"
              style={{ marginTop: "4px" }}
            >
              {selectedAddress
                ? selectedAddressObj?.FullAddress
                : "No address selected."}
            </Typography>
            <hr />
          </Grid>
        </Grid>
        <Dialog open={showAddressDialog} onClose={handleCloseDialog}>
          <DialogTitle>Select Address</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedAddress || ""}
                onChange={handleAddressChange}
              >
                {addresses.length > 0 ? (
                  addresses.map((address) => (
                    <FormControlLabel
                      key={address.UserAddressId}
                      value={address.UserAddressId}
                      control={<Radio />}
                      label={address.FullAddress}
                    />
                  ))
                ) : (
                  <Typography>No addresses available</Typography>
                )}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddAddress}>Add New Address</Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showAddNewAddressDialog}
          onClose={handleCloseNewAddressDialog}
          maxWidth="md"
        >
          <DialogTitle>Add New Address</DialogTitle>
          <DialogContent>
            <AddressPage onClose={handleCloseNewAddressDialog} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewAddressDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          {allOrderDetails.Items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <CardMedia
                        component="img"
                        image={`${baseURL}${item.SingleItemImage}`}
                        alt={item.ItemName}
                        style={{ height: "120px", objectFit: "contain" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h6">{item.ItemName}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {item.ItemDescription}
                      </Typography>
                      <Typography variant="body1">
                        Price: ₹{item.ItemPrice?.toFixed(2) || "0.00"}
                      </Typography>
                      <Typography variant="body1" color="primary">
                        Discounted Price: ₹
                        {item.DiscountedPrice?.toFixed(2) || "N/A"}
                      </Typography>
                      <Typography variant="body1">
                        Total Price: ₹
                        {typeof item.DiscountedPrice === "number"
                          ? calculateTotalPrice(item)
                          : 0.0}
                      </Typography>
                      <Typography variant="body1">
                        Category: {item.CategoryName}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          onClick={() => handleQuantityChange(index, -1)}
                          disabled={(item.quantity || 1) <= 1}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography variant="body1" sx={{ mx: 1 }}>
                          {item.quantity || 1}
                        </Typography>
                        <IconButton
                          onClick={() => handleQuantityChange(index, 1)}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="error"
                          onClick={() => handleRemoveItem(index)}
                          sx={{ ml: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Box ml={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handlePayNow(item)}
                          >
                            Pay Now
                          </Button>
                          {/* <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleRemoveItem(index)}
                            sx={{ ml: 1 }}
                          >
                            Cancel
                          </Button> */}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">
          Total Cost: ₹{allOrderDetails.TotalCost?.toFixed(2) || "0.00"}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePayNow}
          disabled={!selectedAddress}
        >
          Pay Now
        </Button>
      </Box>
    </Grid>
  );
};

export default AllItemBuyingPage;
