// export const parseColor = (color) => {
//   if (!color) {
//     console.error("Color is undefined or null.");
//     return null;
//   }

//   color = color.trim();

//   // HEX color
//   if (color.startsWith("#")) {
//     const hexMatch = /^#([0-9A-F]{3,4}){1,2}$/i.exec(color);
//     if (!hexMatch) {
//       console.error("Invalid HEX color format:", color);
//       return null;
//     }

//     if (color.length === 9) {
//       // Handle #RRGGBBAA format
//       return {
//         type: "rgba",
//         value: {
//           r: parseInt(color.slice(1, 3), 16),
//           g: parseInt(color.slice(3, 5), 16),
//           b: parseInt(color.slice(5, 7), 16),
//           a: parseInt(color.slice(7, 9), 16) / 255,
//         },
//       };
//     }

//     return {
//       type: "hex",
//       value: color,
//     };
//   }

//   // RGB color
//   if (color.startsWith("rgb(")) {
//     const rgbPattern = /^rgb\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\s*\)$/i;
//     const rgbMatch = rgbPattern.exec(color);
//     if (!rgbMatch) {
//       console.error("Invalid RGB color format:", color);
//       return null;
//     }
//     return {
//       type: "rgb",
//       value: {
//         r: parseInt(rgbMatch[1], 10),
//         g: parseInt(rgbMatch[2], 10),
//         b: parseInt(rgbMatch[3], 10),
//       },
//     };
//   }

//   // RGBA color
//   if (color.startsWith("rgba(")) {
//     const rgbaPattern =
//       /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([\d.]+)\s*\)$/i;
//     const rgbaMatch = rgbaPattern.exec(color);
//     if (!rgbaMatch) {
//       console.error("Invalid RGBA color format:", color);
//       return null;
//     }
//     return {
//       type: "rgba",
//       value: {
//         r: parseInt(rgbaMatch[1], 10),
//         g: parseInt(rgbaMatch[2], 10),
//         b: parseInt(rgbaMatch[3], 10),
//         a: parseFloat(rgbaMatch[4]),
//       },
//     };
//   }

//   console.warn("Unsupported color format:", color);
//   return null;
// };
// Optional: Named color to HEX mapping
const namedColors = {
  coral: "#FF7F50",
  tomato: "#FF6347",
  // Add more named colors as needed
};

export const parseColor = (color) => {
  if (!color) {
    console.error("Color is undefined or null.");
    return null;
  }

  color = color.trim();

  // Check if the color is a named color
  if (namedColors[color.toLowerCase()]) {
    color = namedColors[color.toLowerCase()];
  }

  // HEX color
  if (color.startsWith("#")) {
    const hexMatch = /^#([0-9A-F]{3,4}){1,2}$/i.exec(color);
    if (!hexMatch) {
      console.error("Invalid HEX color format:", color);
      return null;
    }

    if (color.length === 9) {
      // Handle #RRGGBBAA format
      return {
        type: "rgba",
        value: {
          r: parseInt(color.slice(1, 3), 16),
          g: parseInt(color.slice(3, 5), 16),
          b: parseInt(color.slice(5, 7), 16),
          a: parseInt(color.slice(7, 9), 16) / 255,
        },
      };
    } else if (color.length === 7 || color.length === 4) {
      // Handle #RRGGBB or #RGB formats
      return {
        type: "hex",
        value: color,
      };
    }
  }

  // RGB color
  if (color.startsWith("rgb(")) {
    const rgbPattern = /^rgb\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\s*\)$/i;
    const rgbMatch = rgbPattern.exec(color);
    if (!rgbMatch) {
      console.error("Invalid RGB color format:", color);
      return null;
    }
    return {
      type: "rgb",
      value: {
        r: parseInt(rgbMatch[1], 10),
        g: parseInt(rgbMatch[2], 10),
        b: parseInt(rgbMatch[3], 10),
      },
    };
  }

  // RGBA color
  if (color.startsWith("rgba(")) {
    const rgbaPattern =
      /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([\d.]+)\s*\)$/i;
    const rgbaMatch = rgbaPattern.exec(color);
    if (!rgbaMatch) {
      console.error("Invalid RGBA color format:", color);
      return null;
    }
    return {
      type: "rgba",
      value: {
        r: parseInt(rgbaMatch[1], 10),
        g: parseInt(rgbaMatch[2], 10),
        b: parseInt(rgbaMatch[3], 10),
        a: parseFloat(rgbaMatch[4]),
      },
    };
  }

  console.warn("Unsupported color format:", color);
  return null;
};

// Ensure color string format
const ensureColorString = (color) => {
  // console.log("Input color:", color);
  if (typeof color === "object" && color !== null) {
    if (color.type === "hex" && typeof color.value === "string") {
      return color.value;
    }
    if (color.type === "rgb" && typeof color.value === "object") {
      return `rgb(${color.value.r}, ${color.value.g}, ${color.value.b})`;
    }
    if (color.type === "rgba" && typeof color.value === "object") {
      return `rgba(${color.value.r}, ${color.value.g}, ${color.value.b}, ${color.value.a})`;
    }
  }
  if (typeof color === "string") {
    return color;
  }
  console.error("Unsupported color format:", color);
  return null;
};

// HEX to RGB Conversion
const hexToRgb = (hex) => {
  hex = ensureColorString(hex);
  if (!hex) return null;

  // Normalize HEX color format
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    console.error("Invalid HEX color format:", hex);
    return null;
  }

  let r, g, b;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  }

  return [r, g, b];
};

// RGB to HEX Conversion
const rgbToHex = (r, g, b) => {
  const toHex = (n) => n.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Adjust RGB color by percentage
const adjustRgbColor = ([r, g, b], percentage) => {
  const adjust = (value) =>
    Math.min(255, Math.max(0, Math.round(value * (1 + percentage / 100))));
  return [adjust(r), adjust(g), adjust(b)];
};

// Convert RGBA to RGB
const rgbaToRgb = (rgba, background = [255, 255, 255]) => {
  rgba = ensureColorString(rgba);
  if (!rgba) return `rgb(${background.join(",")})`;

  const rgbaValues = rgba.match(/\d+(\.\d+)?/g)?.map(Number);
  if (!rgbaValues || rgbaValues.length < 3) {
    console.error("Invalid RGBA values:", rgbaValues);
    return `rgb(${background.join(",")})`;
  }

  const [r, g, b, a = 1] = rgbaValues;
  const toRgb = (c, bg, alpha) => Math.round((1 - alpha) * bg + alpha * c);
  const rFinal = toRgb(r, background[0], a);
  const gFinal = toRgb(g, background[1], a);
  const bFinal = toRgb(b, background[2], a);

  return `rgb(${rFinal}, ${gFinal}, ${bFinal})`;
};

// Adjust RGBA color by percentage
const adjustRgbaColor = (rgba, percentage) => {
  rgba = ensureColorString(rgba);
  if (!rgba) return rgba;

  const rgb = rgbaToRgb(rgba);
  if (!rgb) return rgba;

  const a = parseFloat(rgba.match(/[\d.]+(?=\))$/)?.[0]) || 1;
  const [newR, newG, newB] = adjustRgbColor(
    rgb.match(/\d+/g)?.map(Number) || [0, 0, 0],
    percentage
  );

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

// Darken or lighten RGB color
const adjustRgb = (color, percentage) => {
  const rgbColor = ensureColorString(color);

  if (!rgbColor) return rgbColor;

  // Determine color type and convert to RGB if needed
  if (rgbColor.startsWith("#")) {
    const rgb = hexToRgb(rgbColor);
    if (!rgb) return rgbColor;
    return rgbToHex(...adjustRgbColor(rgb, percentage));
  } else if (rgbColor.startsWith("rgba")) {
    const rgb = rgbaToRgb(rgbColor);
    if (!rgb) return rgbColor;
    const [r, g, b] = rgb.match(/\d+/g).map(Number);
    const [newR, newG, newB] = adjustRgbColor([r, g, b], percentage);
    return `rgba(${newR}, ${newG}, ${newB}, ${
      parseFloat(rgb.match(/[\d.]+(?=\))$/)?.[0]) || 1
    })`;
  } else if (rgbColor.startsWith("rgb")) {
    const match = rgbColor.match(/\d+/g);
    if (!match || match.length !== 3) {
      console.error("Invalid RGB color format:", rgbColor);
      return rgbColor;
    }
    const [r, g, b] = match.map(Number);
    const [newR, newG, newB] = adjustRgbColor([r, g, b], percentage);
    return `rgb(${newR}, ${newG}, ${newB})`;
  } else {
    console.error("Unsupported color format:", rgbColor);
    return rgbColor;
  }
};

// Darken RGB color
export const darkenRgb = (rgb, percentage) => adjustRgb(rgb, -percentage);

// Lighten RGB color
export const lightenRgb = (rgb, percentage) => adjustRgb(rgb, percentage);

// Darken RGBA color
export const darkenRgba = (rgba, percentage) =>
  adjustRgbaColor(rgba, -percentage);

// Lighten RGBA color
export const lightenRgba = (rgba, percentage) =>
  adjustRgbaColor(rgba, percentage);

// Darken HEX color
export const darkenHex = (hex, percentage) => {
  hex = ensureColorString(hex);
  if (!hex) return hex;

  const rgb = hexToRgb(hex);
  if (!rgb) {
    console.error("Failed to convert HEX to RGB:", hex);
    return hex;
  }

  const [r, g, b] = rgb;
  return rgbToHex(...adjustRgbColor([r, g, b], -percentage));
};

// Lighten HEX color
export const lightenHex = (hex, percentage) => {
  hex = ensureColorString(hex);
  if (!hex) return hex;

  const rgb = hexToRgb(hex);
  if (!rgb) {
    console.error("Failed to convert HEX to RGB:", hex);
    return hex;
  }

  const [r, g, b] = rgb;
  return rgbToHex(...adjustRgbColor([r, g, b], percentage));
};
