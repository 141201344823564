import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, isValid, parse } from "date-fns";

import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
  StyledTextField,
  popperStyles,
} from "../servicecomponent/datepikerstyle";

import {
  Grid,
  Button,
  CardMedia,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  TableSortLabel,
  Dialog,
  DialogContent,
  Box,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  Save as SaveIcon, // Corrected the import name
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { useAuth } from "../AuthContext";
import { useDI } from "../hooks/DIContext";
import { EnvVariables } from "../EnvVariables";
import Pagination from "../layoutcompo/Pagination";
import TruncateTextCell from "../servicecomponent/TruncateTextCell";
import "./AddItem.css";
import { useCart } from "../hooks/CartContext";
import { useTranslation } from "react-i18next";
import {
  getImageDimensions,
  resizeImage,
  masterItemImageDimensions,
} from "../servicecomponent/imageService";
import { toast } from "react-toastify";
import Imageslider from "../servicecomponent/Imageslider";
import { styled } from "@mui/system";

import { useProcessStatusType } from "../servicecomponent/commondataclassfiles/commonarrays";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components from Material-UI
import Collapse from "@mui/material/Collapse";

// Import your CostingComponent
import CostingComponent from "./CostingComponent";
const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.5s ease, background-color 0.3s ease",
  overflow: "hidden",
  position: "relative",
  objectPosition: "center",
  margin: "30px 0 10px -10px",

  "&:hover": {
    transform: "scale(1.01)", // Slight zoom on hover
    backgroundColor: "rgba(0, 0, 0, 0.1)", // Add slight background effect on hover
  },

  // Responsive styling for smaller screens
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100px",
    maxHeight: "100px",
    objectFit: "contain",
  },
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100px",
    maxHeight: "100px",
    objectFit: "contain",
  },
  [theme.breakpoints.up("md")]: {
    maxHeight: "50px",
    objectFit: "contain",
  },
}));

const AddItem = () => {
  const ProcessStatusType = useProcessStatusType();

  const { t, i18n } = useTranslation();
  const { selectedLocation, selectedLanguage } = useCart();
  const [translatedData, setTranslatedData] = useState([]);
  const {
    apiService,
    showNotification,
    showLoader,
    hideLoader,
    setConfirmDialog,
  } = useDI();
  const { isLoggedIn } = useAuth();
  const [categoryIds, setCategoryIds] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);

  const [userItemDetailsId, setUserItemDetailsId] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [itemOptions, setItemOptions] = useState([]);
  const [items, setItems] = useState([]);

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectItemTypeOptions, setSelectItemTypeOptions] = useState([]);
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [itemDescription, setItemDescription] = useState([]);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [itemTypeUOM, setItemTypeUOM] = useState([]);
  const [selectedItemTypeUOM, setSelectedItemTypeUOM] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  //pagination
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowStatuses, setRowStatuses] = useState({});

  const [textStatusRemark, setTextStatusRemark] = useState({});
  const [isOption3Or4Selected, setIsOption3Or4Selected] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [imagePaths, setImagePaths] = useState([]);
  const baseURL = EnvVariables.BaseUrlforImage;
  const [todate, setTodate] = useState("");

  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedItemName, setSelectedItemName] = useState("");

  const handleDateChange = (newValue) => {
    // Check if the new date is valid and not in the future
    if (isValid(newValue) && newValue <= new Date()) {
      setTodate(newValue);
      setValidationErrors((prevErrors) => ({ ...prevErrors, todate: "" }));
    } else {
      // If invalid, clear the date and show an error message
      setTodate(null);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        todate: "Invalid date. Please select a valid date.",
      }));
    }
  };
  const clearForm = () => {
    setSelectItemTypeOptions([]);
    setItemDescription("");

    setSelectedItemTypeUOM([]);
  };
  const clearselectedfield = () => {
    setSelectedItemId("");
    setSelectedCategoryName("");
    setSelectedItemName("");
    setItemTypeOptions([]);
    setItemTypeUOM([]);

    setItemDescription("");

    setSelectedImages([]);
    setEditingIndex(null);
    setValidationErrors({});
  };

  const allclearForm = () => {
    clearForm();
    clearselectedfield();
  };
  useEffect(() => {
    loadUserItem();
  }, [currentPage, rowsPerPage, searchQuery]);

  const loadUserItem = async () => {
    try {
      setItems([]);

      const response = await apiService.postAPI(
        { currentPage, pageSize: rowsPerPage, Prefix: searchQuery },
        EnvVariables.Get_Seller_Item
      );
      const data = response.data;

      if (Array.isArray(data) && data.length > 0) {
        // Create a new array to avoid direct mutation of state
        const updatedItems = [...data];
        setItems(updatedItems); // This should trigger a re-render

        const totalRecords = data[0]?.totalRecords || 0;
        setTotalRecords(totalRecords);
      } else {
        setItems([]); // Empty list will re-render as well
        setTotalRecords(0);
        toast.error("Error loading items");
      }
    } catch (error) {
      console.error("Error loading items:", error);
      setItems([]);
      setTotalRecords(0);
      toast.error("Error loading items");
    }
  };

  useEffect(() => {
    clearselectedfield();
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const response = await apiService.postAPIwithoutdata(
        EnvVariables.Get_Item_Category_display
      );

      let categories = [
        { CategoryId: 0, CategoryName: "Select Category" }, // Add default option
      ];

      if (response.data && response.data.length > 0) {
        categories = [...categories, ...response.data]; // Append API categories
      } else {
        categories.push({
          CategoryId: "",
          CategoryName: "No Categories Available",
        });
      }

      setCategoryIds(categories); // Set categories including the default one
    } catch (error) {
      console.error("Error loading categories:", error);
      setCategoryIds([
        { CategoryId: 0, CategoryName: "Select Category" },
        { CategoryId: "", CategoryName: "Error Loading Categories" },
      ]);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      getMasterItems(); // Fetch items based on the selected category
    } else {
      // Clear item options and selected subcategory if no category is selected
      setItemOptions([]);
      setSelectedItemName("");
      setSelectedItemId(null);
    }
  }, [selectedCategory]);

  const updateFormFields = (selectedItem) => {
    const itemTypes = selectedItem.ItemType?.split(",") || [];
    const uom = selectedItem.UOM?.split(",") || [];

    // Check and set item types
    if (itemTypes && itemTypes.length > 0) {
      setSelectItemTypeOptions(itemTypes);
    } else {
      // Corrected this line
      setSelectItemTypeOptions(["Select Item Type"]); // Use parentheses for function call
    }

    // Check and set UOM
    if (uom && uom.length > 0) {
      setSelectedItemTypeUOM(uom);
    } else {
      // Corrected this line
      setSelectedItemTypeUOM(["Select UOM"]); // Use parentheses for function call
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = itemOptions.find(
        (item) => item.ItemId === selectedItemId
      );
      if (selectedItem) {
        updateFormFields(selectedItem);
      }
    }
  }, [selectedItemId, itemOptions]);

  const getMasterItems = async () => {
    try {
      if (selectedCategory) {
        setItemOptions([]);
        const response = await apiService.postAPI(
          { CategoryId: selectedCategory },
          EnvVariables.Get_Master_Item_For_Display
        );
        setItemOptions(response.data);
      } else {
        setItemOptions([]);
      }
    } catch (error) {
      setItemOptions([]);
      toast.error("Error loading items");
    }
  };

  const handleEditItem = (index) => {
    const item = items[index];

    // Set the selected category, item ID, and user item details ID
    setSelectedCategory(item.CategoryId);
    setSelectedCategoryName(item.CategoryName);
    setSelectedItemId(item.ItemId);
    setSelectedItemName(item.ItemName);
    setUserItemDetailsId(item.UserItemDetailsId);
    setItemDescription(item.ItemDescription);

    // Set the item images, prefixing them with 'old_' to distinguish them as existing images
    const itemImages = item.MasterItemImage
      ? item.MasterItemImage.split(",").map((imageUrl, imgIndex) => ({
          file: null, // Since these are existing images, the file will be null
          imageUrl: `${baseURL}${imageUrl}`, // Full URL for the image
          fileName: `old_${imgIndex + 1}`, // Prefix with 'old_' to indicate these are existing images
        }))
      : [];

    // Update the state with the selected images and set the main image if available
    setSelectedImages(itemImages);
    setMainImage(itemImages.length > 0 ? itemImages[0].imageUrl : null);

    // Split item type, description, and keywords into arrays and update the state
    //const itemTypeOptions = item.ItemType ? item.ItemType.split(",") : [];
    const itemTypeOptions = item.ItemType || "";
    setItemTypeOptions(itemTypeOptions);

    const itemTypeUOM = item.UOM ? item.UOM.split(",") : [];
    setItemTypeUOM(itemTypeUOM);

    // Set the editing index to the current item's index
    setEditingIndex(index);
  };

  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);

    const newImages = await Promise.all(
      files.map(async (file) => {
        try {
          // Get image dimensions
          const dimensions = await getImageDimensions(file);

          const { maxWidth, maxHeight } =
            masterItemImageDimensions["CardImage"];
          // Resize the image if necessary
          let resizedFile = file;
          // if (dimensions.width > maxWidth || dimensions.height > maxHeight) {
          //   resizedFile = await resizeImage(file, maxWidth, maxHeight);
          // }

          resizedFile = await resizeImage(file, maxWidth, maxHeight);

          return {
            file: resizedFile,
            imageUrl: URL.createObjectURL(resizedFile),
            fileName: `new_${resizedFile.name}`,
          };
        } catch (error) {
          return null;
        }
      })
    );

    // Filter out any null values (in case of errors during processing)
    const validImages = newImages.filter((image) => image !== null);

    // Update the selected images state, ensuring a maximum of 5 images
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages, ...validImages].slice(0, 5);

      // If there's no main image set yet, set the first new image as the main image
      if (!mainImage && updatedImages.length > 0) {
        setMainImage(updatedImages[0].imageUrl);
      }

      return updatedImages;
    });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = selectedImages.filter(
      (_, imgIndex) => imgIndex !== index
    );
    setSelectedImages(updatedImages);
    if (updatedImages.length > 0) {
      setMainImage(updatedImages[0].imageUrl);
    } else {
      setMainImage(null);
    }
  };

  const validateForm = () => {
    const errors = {};

    // Item Barcode validation
    if (!itemTypeOptions || itemTypeOptions.length === 0) {
      errors.itemTypeOptions = "Complaint type is required";
    }

    if (!itemDescription.trim()) {
      errors.itemPrice = "Complaint description is required";
    }

    if (selectedImages.length > 5) {
      errors.images = "You must select 3 or fewer images";
    }

    if (!selectedCategory) {
      errors.selectedCategory = "Complaint category is required";
    }

    if (!itemTypeUOM) {
      errors.itemTypeUOM = "Complaint Priority required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddItem = async () => {
    showLoader();

    if (!validateForm()) {
      hideLoader();
      return;
    }

    const newItem = {
      ItemDescription: itemDescription,
      CategoryId: selectedCategory,
      ItemId: selectedItemId,

      ItemType: itemTypeOptions,
      UOM: itemTypeUOM,
      MasterItemImage: selectedImages.map((img) => ({
        file: img.file,
        fileName: img.file ? `new_${img.file.name}` : img.fileName,
        prefix: img.file ? "new_" : "old_",
      })),
      UserItemDetailsId: userItemDetailsId,
    };
    console.log("newdata", newItem.GovCompDate);
    const formData = new FormData();
    Object.keys(newItem).forEach((key) => {
      if (key !== "MasterItemImage") {
        formData.append(key, newItem[key]);
      }
    });

    newItem.MasterItemImage.forEach(({ file, fileName }) => {
      if (file) {
        formData.append("MasterItemImages", file, fileName);
      } else {
        formData.append("MasterItemImages", fileName);
      }
    });

    try {
      const url =
        editingIndex !== null
          ? EnvVariables.Update_Seller_Item
          : EnvVariables.Insert_Seller_Item;
      const response = await apiService.postAPI(formData, url, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.RDatalist[0].SuccessFailed === 0) {
        toast.error(response.data.RDatalist[0].Msg);
      } else {
        toast.success(response.data.RDatalist[0].Msg);

        setEditingIndex(null);
        allclearForm();
        loadUserItem();
      }
    } catch (error) {
      console.error("Error saving item:", error);
      showNotification("Error saving item", "error");
    } finally {
      hideLoader();
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset currentPage to 1 when rows per page changes
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setCurrentPage(1);
    setSearchQuery(query);
  };

  const handleStatusOptionChange = (index) => (event) => {
    const value = event.target.value;

    setRowStatuses((prevStatuses) => ({
      ...prevStatuses,
      [index]: value,
    }));

    if (value === "4" || value === "5") {
      // Show the text box for this row and initialize its value if needed
      setTextStatusRemark((prevValues) => ({
        ...prevValues,
        [index]: prevValues[index] || "", // Initialize with an empty string if not set
      }));
      setIsOption3Or4Selected(true); // Set option 3 or 4 as selected
    } else {
      // Hide the text box and clear its value for this row
      setTextStatusRemark((prevValues) => {
        const updatedValues = { ...prevValues };
        delete updatedValues[index];
        return updatedValues;
      });

      // Check if option 3 or 4 is selected in any other row, otherwise set it to false
      const anyOption3Or4Selected = Object.values(rowStatuses).some(
        (status) => status === "4" || status === "5"
      );
      setIsOption3Or4Selected(anyOption3Or4Selected);
    }
  };

  // Handle text box value change
  const handleTextBoxChange = (index) => (event) => {
    setTextStatusRemark((prevValues) => ({
      ...prevValues,
      [index]: event.target.value,
    }));
  };

  const updatecurrentstatus = async (item, index) => {
    setConfirmDialog({
      isOpen: true,
      message: "Are you sure you want to update the status of complaint?",
      onConfirm: async () => {
        try {
          showLoader();
          const statusremark = textStatusRemark[index] ?? ""; // Empty string as fallback
          const processStatus = rowStatuses[index] ?? 0; // Fallback to a default status
          const complaintstatus = {
            ProcessRemark: statusremark,
            UserItemDetailsId: item.UserItemDetailsId ?? null, // Ensure it's not undefined
            ProcessStatus: parseInt(processStatus, 10),
          };

          const response = await apiService.postAPI(
            complaintstatus,
            EnvVariables.Update_Availability_Status
          );
          if (response.data.RDatalist[0].SuccessFailed === 1) {
            toast.success(response.data.RDatalist[0].Msg);
            handleCancel(index);
            await loadUserItem();
          } else {
            toast.error(response.data.RDatalist[0].Msg);
          }
        } catch (error) {
        } finally {
          handleCancel(index);
          setConfirmDialog({ isOpen: false });
          hideLoader();
        }
      },
      onCancel: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };
  const handleCancel = (index) => {
    console.log("Cancel action triggered for row:", index);

    // Reset the state for this row (clear text box and row status)
    setRowStatuses((prevStatuses) => {
      const updatedStatuses = { ...prevStatuses };
      delete updatedStatuses[index]; // Reset the status
      return updatedStatuses;
    });
    setTextStatusRemark((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[index]; // Clear the text box value
      return updatedValues;
    });
    setIsOption3Or4Selected(false);
  };

  const openDialog = (MasterItemImage) => {
    const imagesArray = MasterItemImage.split(",").map(
      (path) => `${baseURL}${path.trim()}`
    );
    setImagePaths(imagesArray);
    setModalOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
    setDialogImages([]);
  };
  const handlePrevImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : dialogImages.length - 1
      );
    }
  };

  const handleNextImage = () => {
    if (dialogImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < dialogImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategory = categoryIds.find(
        (category) =>
          category.CategoryName.toLowerCase() === newValue.toLowerCase()
      );

      if (selectedCategory) {
        setSelectedCategory(selectedCategory.CategoryId || null);
      } else {
        setSelectedCategory(null);
      }
      setSelectedCategoryName(newValue);
    } else {
      setSelectedCategoryName("");
      setSelectedCategory(null);
    }
  };

  const handleSubcategoryChange = (event, newValue) => {
    if (typeof newValue === "string") {
      // Free text input (user typed manually)
      setSelectedItemName(newValue);
      setSelectedItemId(null); // No ID for free text input
    } else if (newValue && typeof newValue === "object") {
      // Option selected from the list
      setSelectedItemName(newValue.ItemName);
      setSelectedItemId(newValue.ItemId);
    }
  };

  const handleClearSubcategory = () => {
    setSelectedItemName(""); // Clear the input
    setSelectedItemId(null); // Clear the selected item ID
  };

  const handleSubcategoryInputChange = (event, newInputValue) => {
    setSelectedItemName(newInputValue); // Update the input value when the user types
  };
  const handleInputChangeAndBlur = (event, newInputValue) => {
    handleSubcategoryInputChange(event, newInputValue); // Handle input change logic

    if (event.type === "blur") {
      handleSubcategoryBlur(); // Call the blur handler
    }
  };
  const handleTypeChange = (event, newValue) => {
    if (newValue && !selectItemTypeOptions.includes(newValue)) {
      // If it's a new value, add it to the options
      setSelectItemTypeOptions([...selectItemTypeOptions, newValue]);
    }
    setItemTypeOptions(newValue || "");
  };
  const handleClearType = () => {
    setItemTypeOptions(""); // Clear the selected value
  };
  const handleTypeInputChange = (event, newInputValue) => {
    setItemTypeOptions(newInputValue);
  };
  const handleCategoryBlur = (fff) => {
    // Ensure the selectedCategoryName is a string before applying toLowerCase
    const inputValue = fff ? fff.toLowerCase() : "";

    // Check if the typed value matches any category name
    const isMatchingOption = categoryIds.some(
      (category) => category.CategoryName.toLowerCase() === inputValue
    );

    // If no match is found, clear the input
    if (!isMatchingOption) {
      setSelectedCategoryName(""); // Clear the text input field
      setSelectedCategory(null); // Clear the ID as well
    }
  };

  const handleSubcategoryBlur = (event, reason) => {
    if (reason === "blur" || reason === "escape") {
      const itemName = selectedItemName || "";
      console.log("Blur event fired with:", itemName); // Debug log

      if (typeof itemName === "string") {
        const isMatch = itemOptions.some(
          (option) => option.ItemName.toLowerCase() === itemName.toLowerCase()
        ); // Strict match

        if (!isMatch) {
          console.warn("No matching option found for:", itemName);
          setSelectedItemName(""); // Clear the input if no match
          setSelectedItemId(null); // Clear the ID if no match
          setSelectItemTypeOptions([]); // Clear item type options if no match
        } else {
          console.log("Match found!"); // Log if a match is found
        }
      } else {
        console.warn("selectedItemName is not a string:", selectedItemName);
      }
    }
  };

  const handleSubcategoryClose = () => {
    if (selectedItemName) {
      // Ensure there's a matching option, otherwise clear
      const isMatch = itemOptions.some(
        (option) =>
          option.ItemName.toLowerCase() === selectedItemName.toLowerCase()
      );

      if (!isMatch) {
        console.warn("No matching option found for:", selectedItemName);
        setSelectedItemName(""); // Clear the input
        setSelectedItemId(null); // Clear the ID
        setItemTypeOptions("");
        setSelectItemTypeOptions([]); // Reset options if needed
      } else {
        console.log("Match found!"); // Log if a match is found
      }
    }
  };
  const [openRow, setOpenRow] = useState({});

  const toggleRow = (index) => {
    setOpenRow((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle open state for the clicked row
    }));
  };
  const [expandedState, setExpandedState] = useState(items.map(() => false));

  const handleAccordionToggle = (index) => {
    // Ensure that the accordion's expanded state is controlled
    const newExpandedState = expandedState.map((state, i) =>
      i === index ? !state : state
    );
    setExpandedState(newExpandedState);
  };
  const handleAcceptCostingResult = (result, UserItemDetailsId) => {
    if (result.success) {
      console.log("Costing accepted successfully:", result.message);

      // Update the specific item in the `items` array
      const updatedItems = items.map((item) => {
        if (item.UserItemDetailsId === UserItemDetailsId) {
          // Get the current date
          const currentDate = new Date();
          const formattedDate = `${
            currentDate.getMonth() + 1
          }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

          return {
            ...item,
            AcceptStatus: 1, // Update the status to accepted
            AcceptStatusDatestr: formattedDate, // Update with the current date string
          };
        }
        return item; // Return unchanged item
      });

      // Update the state with the new `items` array
      setItems(updatedItems);
    } else {
      console.error("Costing accept failed:", result.message);
      // Handle failure logic, such as showing an error message or retry mechanism
    }
  };

  return (
    <>
      <div>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="topmargin"
        >
          {/* Add Item */} {t("Add Complaint")}
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disabled={editingIndex !== null}
                freeSolo
                options={categoryIds.map((category) => category.CategoryName)}
                value={selectedCategoryName || ""}
                onChange={(event, newValue) => {
                  setSelectedCategoryName(newValue || ""); // Ensure the value is set correctly
                  handleCategoryChange(event, newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setSelectedCategoryName(newInputValue); // Updates when typing
                }}
                onBlur={() => handleCategoryBlur(selectedCategoryName)} // Handles blur event
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Complaint Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* Show clear button if user types a custom value */}
                          {selectedCategoryName &&
                            !categoryIds.some(
                              (category) =>
                                category.CategoryName === selectedCategoryName
                            ) && (
                              <IconButton
                                onClick={() => {
                                  setSelectedCategoryName(""); // Clear the input field
                                  setSelectedCategory(""); // Reset the selected category
                                }}
                              >
                                {/* Use clear icon */}
                              </IconButton>
                            )}
                          {/* Keep the default end adornment (such as the loading spinner) */}
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                freeSolo
                options={itemOptions.filter(
                  (item) =>
                    item &&
                    typeof item === "object" &&
                    item.ItemName &&
                    item.ItemId
                )}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.ItemName || ""
                }
                value={selectedItemName || ""}
                onChange={handleSubcategoryChange}
                onInputChange={handleSubcategoryInputChange}
                onClose={handleSubcategoryClose} // Use onClose instead of onBlur
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Complaint Subcategory"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectedItemName && (
                            <IconButton
                              onClick={handleClearSubcategory}
                            ></IconButton>
                          )}
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Complaint Subcategory",
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                freeSolo
                options={selectItemTypeOptions || []} // Ensure a valid array for options
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : ""
                } // Return string or empty
                value={itemTypeOptions || ""} // Ensure value is always a string
                onChange={handleTypeChange} // Handle changes in selection
                onInputChange={handleTypeInputChange} // Handle manual input typing
                disableClearable // Remove default clear button
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Complaint Type"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {itemTypeOptions && (
                            <IconButton onClick={handleClearType}>
                              <ClearIcon />
                            </IconButton>
                          )}
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(validationErrors.itemTypeOptions)}
                    helperText={validationErrors.itemTypeOptions}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Priority Type</InputLabel>
                <Select
                  // disabled={editingIndex !== null}
                  value={itemTypeUOM || ""}
                  onChange={(e) => setItemTypeUOM(e.target.value)}
                  error={Boolean(validationErrors.itemTypeUOM)}
                >
                  <MenuItem value="">Select an option</MenuItem>
                  {selectedItemTypeUOM.map((UOM) => (
                    <MenuItem key={UOM} value={UOM}>
                      {UOM}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.itemTypeUOM && (
                  <Typography color="error">
                    {validationErrors.itemTypeUOM}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Complaint Description"
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
                fullWidth
                error={Boolean(validationErrors.itemPrice)}
                helperText={
                  validationErrors.itemPrice ? (
                    <Typography color="error">
                      {validationErrors.itemPrice}
                    </Typography>
                  ) : null
                }

                // helperText={validationErrors.itemPrice}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                style={{ backgroundColor: "var(--mainColor)" }}
              >
                Select Images
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              {validationErrors.images && (
                <Typography color="error">{validationErrors.images}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {selectedImages.map((image, index) => (
                  <Grid item xs={4} sm={2} key={index}>
                    <Paper elevation={3} style={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={image.imageUrl}
                        alt={`Selected Image ${index + 1}`}
                      />
                      <IconButton
                        size="small"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: "red",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            style={{ marginTop: "1rem" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddItem}
                style={{ backgroundColor: "var(--mainColor)" }}
              >
                {editingIndex !== null ? "Update Complaint" : "Add Complaint"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={allclearForm}
                style={{ backgroundColor: "var(--mainColor)" }}
              >
                Clear Form
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <Typography variant="h6" gutterBottom>
            Items list
          </Typography>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginBottom: "1rem" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search using Item Name..."
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select a Date"
                  value={todate}
                  onChange={handleDateChange}
                  inputFormat="dd-MM-yyyy"
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      fullWidth
                      error={Boolean(validationErrors.todate)}
                      helperText={
                        validationErrors.todate ? (
                          <Typography color="error">
                            {validationErrors.todate}
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                  PopperProps={{
                    sx: popperStyles, // Apply your custom popper styles here
                  }}
                  shouldDisableDate={(date) => date > new Date()} // Disable future dates
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        govcompdate: "Invalid date format.",
                      }));
                    } else {
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        govcompdate: "",
                      }));
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {/* //table start */}
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <Grid container spacing={2}>
              {Array.isArray(items) &&
                items.map((item, index) => (
                  <Grid item xs={12} key={item.UserItemDetailsId || index}>
                    <Accordion
                      expanded={expandedState[index]} // Controlled accordion state
                      onChange={() => handleAccordionToggle(index)} // Toggle accordion
                      sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{ fontSize: "3rem", color: "primary.main" }}
                          />
                        }
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        sx={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          "&:hover": { backgroundColor: "#e0e0e0" },
                          padding: "16px 24px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={3}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              {`Srl No: ${item.SrNo}`}
                            </Typography>
                          </Grid>

                          {item.AcceptStatus === 1 && (
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                You accept this costing as on date -{" "}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    item.AcceptStatus === 1
                                      ? "success.main"
                                      : "warning.main",
                                }}
                              >
                                {item.AcceptStatusDatestr}
                              </Typography>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6} md={3}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Service Costing count - {item.ServiceCount}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                                color:
                                  item.IsCompleted === 1
                                    ? "success.main"
                                    : "warning.main",
                              }}
                            >
                              {item.IsCompleted === 1 ? (
                                <>
                                  <CheckCircleIcon sx={{ mr: 1 }} />
                                  This Process is Done
                                </>
                              ) : (
                                <>
                                  <HourglassEmptyIcon sx={{ mr: 1 }} />
                                  Pending
                                </>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails sx={{ width: "100%", padding: 0 }}>
                        <CardContent
                          sx={{ padding: "16px 24px", width: "100%" }}
                        >
                          {/* Flexbox layout */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              gap: 2,
                              width: "100%",
                            }}
                          >
                            {/* Image */}
                            <Box
                              component="img"
                              src={`${baseURL}${
                                item.SingleItemImage.split(",")[0]
                              }`}
                              alt={item.ItemName}
                              onClick={() => openDialog(item.MasterItemImage)}
                              sx={{
                                width: { xs: "100%", md: "100px" },
                                height: { xs: "auto", md: "100px" },
                                objectFit: "cover",
                                borderRadius: 1,
                                cursor: "pointer",
                                boxShadow: 2,
                              }}
                            />
                            {/* Complaint Info */}
                            <Box
                              sx={{
                                flexGrow: 1,
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                padding: "16px",
                                width: "100%",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                sx={{ width: "100%" }}
                              >
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Complaint Category: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.CategoryName}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Complaint Regarding: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.ItemName}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Exact Complaint Type: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.ItemType}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Priority: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.UOM}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Complaint Date: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.CreationDatestr}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Description: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      <TruncateTextCell
                                        text={item.ItemDescription}
                                        maxLength={50}
                                      />
                                    </Typography>
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {`Current Status: `}
                                    <Typography
                                      component="span"
                                      sx={{ fontWeight: "normal" }}
                                    >
                                      {item.ItemAvailableStatus}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* Status and Remarks Section */}
                              {(rowStatuses[index] === "4" ||
                                rowStatuses[index] === "5") && (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  value={textStatusRemark[index] || ""}
                                  onChange={handleTextBoxChange(index)}
                                  placeholder="Enter details"
                                  sx={{
                                    borderRadius: "4px",
                                    backgroundColor: "#f5f5f5",
                                    mt: 2,
                                  }}
                                />
                              )}

                              <Select
                                fullWidth
                                value={rowStatuses[index] || ""}
                                onChange={handleStatusOptionChange(index)}
                                displayEmpty
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "4px",
                                  mt: 2,
                                }}
                              >
                                <MenuItem value="">
                                  <em>Choose status for complaint</em>
                                </MenuItem>
                                {ProcessStatusType.map((status) => (
                                  <MenuItem
                                    key={status.value}
                                    value={status.value}
                                  >
                                    {status.label}
                                  </MenuItem>
                                ))}
                              </Select>

                              {/* Actions */}
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mt={2}
                              >
                                <IconButton
                                  onClick={() => handleEditItem(index)}
                                  sx={{ color: "blue" }}
                                >
                                  <EditIcon />
                                </IconButton>
                                {(rowStatuses[index] === 1 ||
                                  rowStatuses[index] === 2 ||
                                  rowStatuses[index] === 3 ||
                                  rowStatuses[index] === 4 ||
                                  rowStatuses[index] === 5) && (
                                  <>
                                    <IconButton
                                      onClick={() => handleCancel(index)}
                                      sx={{ color: "red" }}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        updatecurrentstatus(item, index)
                                      }
                                      sx={{ color: "green" }}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>

                          {/* Collapse for Costing */}
                          <Collapse
                            in={openRow[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box mt={1}>
                              {openRow[index] && (
                                <CostingComponent
                                  userItemId={item.UserItemDetailsId}
                                  onAcceptCostingResult={(result) =>
                                    handleAcceptCostingResult(
                                      result,
                                      item.UserItemDetailsId
                                    )
                                  }
                                />
                              )}
                            </Box>
                          </Collapse>
                        </CardContent>
                        <Box
                          onClick={() => toggleRow(index)}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                            "&:hover": { backgroundColor: "#e0e0e0" },
                            padding: "16px 24px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {openRow[index]
                              ? `Hide Coasting details`
                              : `See Coasting details`}
                          </Typography>
                          <IconButton aria-label="expand row" size="small">
                            {openRow[index] ? (
                              <KeyboardArrowUpIcon
                                sx={{
                                  fontSize: "3rem",
                                  color: "primary.main",
                                }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                sx={{
                                  fontSize: "3rem",
                                  color: "primary.main",
                                }}
                              />
                            )}
                          </IconButton>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </div>

        <Pagination
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      <Box>
        <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
          <DialogContent
            sx={{
              padding: 0,
              margin: 0,
              overflow: "hidden",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "calc(100% - 10px)", // 5px margin on each side
                height: "calc(80vh - 10px)", // 5px margin on top and bottom
                margin: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <IconButton
                onClick={handlePrevImage}
                disabled={dialogImages.length === 0 || currentImageIndex === 0}
                sx={{
                  position: "absolute",
                  left: 16,
                  zIndex: 1,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  borderRadius: "50%",
                }}
              >
                <ArrowBackIcon />
              </IconButton>

              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`${baseURL}${dialogImages[currentImageIndex]}`}
                  alt="Dialog Image"
                  style={{
                    maxWidth: "calc(100% - 10px)", // 5px margin on each side
                    maxHeight: "calc(100% - 10px)", // 5px margin on top and bottom
                    objectFit: "contain", // Ensures the image fits within the container while preserving aspect ratio
                  }}
                />
              </Box>

              <IconButton
                onClick={handleNextImage}
                disabled={
                  dialogImages.length === 0 ||
                  currentImageIndex === dialogImages.length - 1
                }
                sx={{
                  position: "absolute",
                  right: 16,
                  zIndex: 1,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  borderRadius: "50%",
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Imageslider
          open={modalOpen}
          onClose={handleCloseModal}
          imagePaths={imagePaths}
        />
      </Box>
    </>
  );
};

export default AddItem;
